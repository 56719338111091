//////// Owner - Home

export const setOwnerHomeButton = (item) => ({
    type: 'SET_OWNER_HOME_BUTTON',
    payload: item,
});

export const setOwnerHomeCheckLeaders = (item) => ({
    type: 'SET_OWNER_HOME_CHECK_LEADERS',
    payload: item,
});

export const setOwnerHomeCheckLeadersDel = (item) => ({
    type: 'SET_OWNER_HOME_CHECK_LEADERS_DELETE',
    payload: item
});

export const setOwnerHomeCheckPoints = (item) => ({
    type: 'SET_OWNER_HOME_CHECK_POINTS',
    payload: item,
});

export const setOwnerHomeCheckPointsDel = (item) => ({
    type: 'SET_OWNER_HOME_CHECK_POINTS_DELETE',
    payload: item
});

//////// Owner - Warehouse

export const setOwnerWarehouseButton = (item) => ({
    type: 'SET_OWNER_WAREHOUSE_BUTTON',
    payload: item,
});

export const setOwnerLeadersLeaderId = (item) => ({
    type: 'SET_OWNER_LEADERS_LEADER_ID',
    payload: item,
});