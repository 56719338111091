import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setWarehouseNav } from '../../../redux/actions/navigation';
import { setWarehouseLeaderCheck, setWarehousePointCheck } from '../../../redux/actions/warehouseCheckData';
import { fetchWarehouseLeaderPointsData } from '../../../redux/actions/warehouseData';
import './index.scss';

const LeaderDetail = () => {

    const dispatch = useDispatch();

    const {warehouseLeaderPointsData, warehouseLeaderCheck} = useSelector(({warehouseData, warehouseCheckData})=> {
        return {
            warehouseLeaderPointsData: warehouseData.warehouseLeaderPointsData,
            warehouseLeaderCheck: warehouseCheckData.warehouseLeaderCheck
        }
    })

    useEffect(()=>{
        dispatch(fetchWarehouseLeaderPointsData(warehouseLeaderCheck.id))
    }, [warehouseLeaderCheck])

    const addTableItem = (elem, key) =>{
        let {id, name, count} = elem; 
        return(
            <div className={`warehouse__leader-detail-line d-flex align-center justify-between warehouse__leader-detail-line-${id}`} key={key}>
                <div className={`warehouse__leader-detail-point-name`}>{name}</div>
                <div className={`warehouse__leader-detail-envelope-count`}>{count} шт</div>                
                <Link className="warehouse__leader-detail-open-icon" to='/point-detail' onClick={()=>{
                    dispatch(setWarehousePointCheck({id: id, name: name}))
                    sessionStorage.setItem('WarehousePointCheck', JSON.stringify({id: id, name: name}))
                    dispatch(setWarehouseNav('LeaderPointDetail'))
                    sessionStorage.setItem('warehousePage', 'LeaderPointDetail')
                }}>
                    <img className="warehouse__leader-detail-open-icon" src="/img/long-arrow-right-black.svg" alt="" />
                </Link>
            </div>
        )
    }

	return (
        <React.Fragment>
            <div className="warehouse__leader-detail">
                <div className="warehouse__leader-detail-top d-flex justify-between align-center">
                    <div className="warehouse__leader-detail-top-back d-flex align-center">
                        <Link className='warehouse__leader-detail-top-back-icon-container' to='/leaders' onClick={()=>{
                            dispatch(setWarehouseLeaderCheck({id: 0, name: ''}))
                            sessionStorage.setItem('WarehouseLeaderCheck', JSON.stringify({id: 0, name: ''}))
                            dispatch(setWarehouseNav('Leaders'))
                            sessionStorage.setItem('warehousePage', 'Leaders')
                        }}>
                            <img className='warehouse__leader-detail-top-back-icon' src="/img/arrow-left-icon.svg" alt="" /> 
                        </Link>                        
                        <div>{warehouseLeaderCheck.name}</div>
                    </div>
                    <div className='d-flex align-center'>
                        <Link to='/order-history' onClick={()=>{
                            dispatch(setWarehouseNav('OrderHistory'))
                            sessionStorage.setItem('warehousePage', 'OrderHistory')
                        }}>
                            <div className={`warehouse__leader-detail-top-history-btn d-flex justify-between align-center ${(false)? 'active': ''}`}>
                                <div>История</div>
                                <div className="warehouse__leader-detail-top-history-btn-icon"></div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className='warehouse__leader-detail-table-scroll'>
                    <div className="warehouse__leader-detail-line title d-flex align-center justify-between">
                        <div className="warehouse__leader-detail-point-name">Точки</div>
                        <div className="warehouse__leader-detail-envelope-count">Количество пленок</div>
                        <div className="warehouse__leader-detail-open-icon"></div>                        
                    </div>
                    <div className="warehouse__leader-detail-table">
                        {
                            warehouseLeaderPointsData.map((item, key) => addTableItem(item, key))
                        }              
                    </div>
                </div>
            </div>
        </React.Fragment>        
	)
}

export default LeaderDetail;