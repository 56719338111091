import { combineReducers } from 'redux';

import grafData from './grafData';
import navigation from './navigation';
import display from './display';
import popupFilters from './popupFilters';
import filters from './filters';
import ownerData from './ownerData';
import popupBlocks from './popupBlocks';
import moderatorData from './moderatorData';
import moderatorCheckData from './moderatorCheckData';
import moderatorPopupData from './moderatorPopupData';
import notification from './notification';
import franchiserData from './franchiserData';
import franchiserFilters from './franchiserFilters';
import franchiserCheckData from './franchiserCheckData';
import staffData from './staffData';
import siginalConnect from './siginalConnect';
import workZoneData from './workZoneData';
import workZoneCheckData from './workZoneCheckData';
import warehouseData from './warehouseData';
import warehouseCheckData from './warehouseCheckData';
import proxy from './proxy';



const rootReducer = combineReducers({
  grafData,
  navigation,
  display,
  popupFilters,
  filters,
  ownerData,
  popupBlocks,
  moderatorData,
  moderatorCheckData,
  moderatorPopupData,
  notification,
  franchiserData,
  franchiserFilters,
  franchiserCheckData,
  staffData,
  workZoneData,
  workZoneCheckData,
  warehouseData,
  warehouseCheckData,
  proxy,
  siginalConnect

});

export default rootReducer;
