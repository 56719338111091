import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFranchiserWarehousePointsListCheck } from '../../../../redux/actions/franchiserCheckData';
import { fetchFranchiserWarehousePointsList } from '../../../../redux/actions/franchiserData';
import { setFranchisesNav } from '../../../../redux/actions/navigation';

import './index-1.0.scss';

const Points = () => {

    const dispatch = useDispatch();
    let {pointsList} = useSelector(({franchiserData})=> {
        return {
            pointsList: franchiserData.franchiserWarehousePoints
        }
    })

    let [tablePageNum, setTablePageNum] = useState(0)

    useEffect(()=>{
        dispatch(fetchFranchiserWarehousePointsList())
    }, [])

    // useEffect(()=>{
    //     dispatch(fetchOwnerWarehouseLekalsTop(range, month, year))
    // }, [range, month, year])

    const addLekalsTopItem = ({id, name, amount}, key) =>{
        return(
            <div className={`franchises-leaders__lekals-top-line d-flex align-center justify-between franchiser__scroll-warehouse-points-list-line-${id}`} key={key} onClick={()=>{
                dispatch(setFranchisesNav('Points-mold')) 
                dispatch(setFranchiserWarehousePointsListCheck({id: id, name: name, amount: amount}))
            }}>
                <div className="franchises-leaders__lekals-top-name">{name}</div>
                <div className="franchises-leaders__lekals-top-coll">{amount} шт</div>
                <img className="franchises-leaders__lekals-top-transfer" src="/img/arrow-right-block.svg" alt="" />
            </div>
        )
    }

    const pageButtons = (button) =>{
        let pet = tablePageNum
        if(button == "back"){
            setTablePageNum(tablePageNum-1)
        }
        else{
            setTablePageNum(tablePageNum+1)
        }
        // console.log(pet)
    }

	return (
		<div className="franchises-leaders__warehouse-right">
            <div className="franchises-leaders__lekals-top-top d-flex justify-between">
                <div className="franchises-leaders__lekals-top-title purpure">Точки</div>
            </div>
            <div className="franchises-leaders__lekals-top-line title d-flex align-center justify-between">
                <div className="franchises-leaders__lekals-top-name title blue">Точка</div>
                <div className="franchises-leaders__lekals-top-coll title">Количество</div>
                <img className="franchises-leaders__lekals-top-transfer none" src="/img/mold-transfer-icon.svg" alt="" />
            </div>
            <div className='franchises-leaders__lekals-top-scroll'>
                <div className="franchises-leaders__lekals-top-list franchiser__warehouse-points-list">
                    {
                        // lekalsTop.map((item, key) => addLekalsTopItem(item, key))
                        (window.innerWidth > 700)?(

                            pointsList.map((item, key) => addLekalsTopItem(item, key))

                         ):(
                             (tablePageNum * 5 <= pointsList.length)?(
                                pointsList.slice(tablePageNum * 5, tablePageNum * 5 + 5).map((item, key) => addLekalsTopItem(item, key))
                             ):(
                                pointsList.slice(tablePageNum * 5).map((item, key) => addLekalsTopItem(item, key))
                             )
                         )
                    }
                </div>
            </div>
            {
                (window.innerWidth < 700)?(
                    <div className='franchises-leaders__points-sale-table-buttons d-flex justify-between'>
                        <div className={`franchises-leaders__points-sale-table-buttons-item back ${(tablePageNum > 0)? 'active': ''}`} onClick={()=>{pageButtons("back")}}></div>
                        <div className={`franchises-leaders__points-sale-table-buttons-item forward ${(tablePageNum === 0 && pointsList.length > 5 || pointsList.length % tablePageNum > 0)? 'active': ''}`} onClick={()=>{pageButtons("forward")}}></div>
                    </div>
                ): null
            }
        </div>
	)
}

export default Points;