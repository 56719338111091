import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setWarehouseNav } from '../../../redux/actions/navigation';
import { setWarehouseLeaderCheck } from '../../../redux/actions/warehouseCheckData';
import { fetchWarehouseLeadersData } from '../../../redux/actions/warehouseData';
import './index.scss';

const Leaders = () => {

    const dispatch = useDispatch();

    const {warehouseLeadersData} = useSelector(({warehouseData})=> {
        return {
            warehouseLeadersData: warehouseData.warehouseLeadersData
        }
    })

    useEffect(()=>{
        dispatch(fetchWarehouseLeadersData())
    }, [])

    const addTableItem = (elem, key) =>{
        let {id, name, count} = elem; 
        return(
            
            <div className={`warehouse__leaders-line d-flex align-center justify-between warehouse__leaders-line-${id}`} key={key}>
                <div className={`warehouse__leaders-leader-name`}>{name}</div>
                <div className={`warehouse__leaders-envelope-count`}>{count} шт</div>
                <Link className="warehouse__leaders-open-icon" to='/leader-detail' onClick={()=>{
                    dispatch(setWarehouseLeaderCheck({id: id, name: name}))
                    sessionStorage.setItem('WarehouseLeaderCheck', JSON.stringify({id: id, name: name}))
                    dispatch(setWarehouseNav('LeaderDetail'))
                    sessionStorage.setItem('warehousePage', 'LeaderDetail')
                }}>
                    <img className="warehouse__leaders-open-icon" src="/img/long-arrow-right-black.svg" alt="" />
                </Link>
            </div>
            
        )
    }

	return (
        <React.Fragment>
            <div className="warehouse__leaders">
                <div className="warehouse__leaders-top d-flex justify-between align-center">
                    <div className="warehouse__leaders-title">Руководители</div>
                </div>
                <div className='warehouse__leaders-table-scroll'>
                    <div className="warehouse__leaders-line title d-flex align-center justify-between">
                        <div className="warehouse__leaders-leader-name">ФИО</div>
                        <div className="warehouse__leaders-envelope-count">Общее количество пленок</div>
                        <div className="warehouse__leaders-open-icon"></div>                        
                    </div>
                    <div className="warehouse__leaders-table">
                        {
                            warehouseLeadersData.map((item, key) => addTableItem(item, key))
                        }              
                    </div>
                </div>
            </div>
        </React.Fragment>        
	)
}

export default Leaders;