import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {setCalendDisplay} from '../../../redux/actions/display';

import './Salaries.scss'
import {setSalariesButtonsMonth, setSalariesButtonsYear, setSalariesTableRange, setSalariesTableRangeTypeSelect, setSalariesTableYear, setSalariesTableMonth } from '../../../redux/actions/popupFilters';
import { setSalariesCalendLocation } from '../../../redux/actions/navigation';
import { fetchStaffButtonsData, fetchStaffTable } from '../../../redux/actions/staffData';


const Salaries = () => {
	const dispatch = useDispatch();

	const {buttonsData, tableData, tableDate, tableTotalData, tableRange, tableRangeActive, tableRangeTypeSelect, tableMonth, tableYear, buttonsRange, buttonsMonth, buttonsYear} = useSelector(({staffData, popupFilters})=> {
        return {
            buttonsData: staffData.salariesButtonsData,
            tableData: staffData.salariesTableData,
			tableDate: staffData.salariesTableDate,
			tableTotalData: staffData.salariesTableTotalData,
            tableRange: popupFilters.salariesTableRange,
			tableRangeActive: popupFilters.salariesTableRangeActive,
			tableRangeTypeSelect: popupFilters.salariesTableRangeTypeSelect,
			tableMonth: popupFilters.salariesTableMonth,
			tableYear: popupFilters.salariesTableYear,
			buttonsRange: popupFilters.salariesButtonsRange,
            buttonsMonth: popupFilters.salariesButtonsMonth,
            buttonsYear: popupFilters.salariesButtonsYear,
        }
    })
	const [scrollElem, setScrollElem] = useState('')

 	useEffect(()=>{
		dispatch(fetchStaffTable(tableRange))		
		dispatch(fetchStaffButtonsData(buttonsRange, buttonsMonth, buttonsYear))
    }, [])

    useEffect(()=>{
        dispatch(fetchStaffTable(tableRange))
		
    }, [tableRange])

    useEffect(()=>{
        dispatch(fetchStaffButtonsData(buttonsRange, buttonsMonth, buttonsYear))
    }, [buttonsRange, buttonsMonth, buttonsYear])


	let addDates = (item, key) =>{
		return(
			<div className="franchises-leaders__salaries-tabel-item title d-flex justify-center align-center" key={key}>
				<div className="franchises-leaders__salaries-tabel-item-container title">{item}</div>
			</div>
		)
	}

	let addTabelBlock = ({salary, premium, fine, prepayment}, key, type) =>{
		return(
			<div className="franchises-leaders__salaries-tabel-item d-flex justify-center align-center" key={key}> 
				<div className='franchises-leaders__salaries-tabel-item-container'>
					{
						(type === 'Зарплата' && salary !== 0)? <div className="salaries__table-tabel-item-block salary">{salary}₽</div>: 
						(type === 'Премия' && premium !== 0)? <div className="salaries__table-tabel-item-block premium">{premium}₽</div>:
						(type === 'Штраф' && fine !== 0)? <div className="salaries__table-tabel-item-block fine">{fine}₽</div>:
						(type === 'Аванс' && prepayment !== 0)? <div className="salaries__table-tabel-item-block prepayment">{prepayment}₽</div>: null
					}
				</div>
			</div>
		)
	}

	let addTabelGridTitleBlock = (item, key) =>{
		return(
			<div className="franchises-leaders__salaries-tabel-item title d-flex justify-center align-center" key={key}>
				<div className="franchises-leaders__salaries-tabel-item-container title"></div>
			</div>
		)
	}

	let addTabelGridBlock = (item, key) =>{
		return(
			<div className="franchises-leaders__salaries-tabel-item d-flex justify-center align-center" key={key}> 
				<div className='franchises-leaders__salaries-tabel-item-container'>
					
				</div>
			</div>
		)
	}

	let addTotalBlock = ({id, total}, key) =>{
		return(
			<div className={`franchises-leaders__salaries-total-item d-flex justify-center align-center franchiser__scroll-salaries-total-line-${id}`} key={key}>
				<div className="franchises-leaders__salaries-total-item-container">
					{total}₽
				</div>
			</div>
		)
	}

	const [list, setList] = useState(false)

	let positionList = [
        // {
        //     id: 1, 
        //     value: 'Неделя'
        // },
        {
            id: 2, 
            value: 'Полмесяца'
        },
        {
            id: 3, 
            value: 'Месяц'
        },
		// {
        //     id: 4, 
        //     value: 'Квартал'
        // },
        // {
        //     id: 5, 
        //     value: 'Полгода'
        // },
        {
            id: 6, 
            value: 'Год'
        },
    ]

	let buttonTimeFunc = (range) =>{
        return (range.length !== 0 && range[0].toLocaleDateString() !== range[1].toLocaleDateString())? `${range[0].toLocaleDateString()} - ${range[1].toLocaleDateString()}`: (range.length !== 0)? `${range[0].toLocaleDateString()}`: 'Период'
    }

	let buttonTimeFuncButtons = (range, month, year) =>{
        return (range.length !== 0 && range[0].toLocaleDateString() !== range[1].toLocaleDateString())? `${range[0].toLocaleDateString()} - ${range[1].toLocaleDateString()}`: (range.length !== 0)? `${range[0].toLocaleDateString()}`: (month !== 'Месяц' && year === 'Год')? `За ${month} текущего года`:(month !== 'Месяц' && year !== 'Год')? `За ${month} ${year} года`:(month === 'Месяц' && year !== 'Год')? `За ${year} год`: 'За текущий год'
    }

	const getReturnDateMass = (date) =>{
		let dat = date.toLocaleDateString().split('.')
		return dat
	}

	const editDate=(id, value)=>{
		let date = getReturnDateMass(new Date())
		let to = new Date(date[2], date[1]-1, 1)
		let from = new Date(date[2], date[1]-1, 1);
		dispatch(setSalariesTableRangeTypeSelect(({ id: id, value: value})))
		sessionStorage.setItem('staffSalariesTablePopupFiltersPeriodListActive', JSON.stringify({ id: id, value: value}))
        setList(false)
		if(id === 1){
			to= new Date();
			from = new Date();
			if(to.getDay() === 0){
				from.setDate(from.getDate() - 6);
			}
			else{
				to.setDate(to.getDate() - to.getDay() + 7);
				from.setDate(from.getDate() - from.getDay() + 1);
			}
			dispatch(setSalariesTableRange([from, to]))		
		}
		if(id === 2){
			to.setDate(to.getDate()+14)
			dispatch(setSalariesTableRange([from, to]))		
		}
		if(id === 3){
			to.setMonth(to.getMonth()+1)
			to.setDate(to.getDate() - 1)
			dispatch(setSalariesTableRange([from, to]))			
		}
		if(id === 4){
			to.setMonth(to.getMonth() + (to.getMonth()+2)%4)
			to.setDate(to.getDate() - 1)
			from.setMonth(to.getMonth()-3)
			dispatch(setSalariesTableRange([from, to]))
		}
		if(id === 5){
			to = new Date(new Date().getFullYear(), 0, 1)
			to.setMonth(to.getMonth()+6)
			to.setDate(to.getDate() - 1)
			from = new Date(new Date().getFullYear(), 0, 1)
			dispatch(setSalariesTableRange([from, to]))		
		}
		if(id === 6){
			to = new Date(new Date().getFullYear(), 0, 1)
			to.setMonth(to.getMonth()+12)
			to.setDate(to.getDate() - 1)
			from = new Date(new Date().getFullYear(), 0, 1)
			dispatch(setSalariesTableRange([from, to]))		
		}
		sessionStorage.setItem('staffSalariesTablePopupFilters', JSON.stringify({month: 'Месяц', year: from.getFullYear(), range: [from, to]}))
		if(tableYear !== from.getFullYear()) dispatch(setSalariesTableYear(from.getFullYear()))
		if(tableMonth !== 'Месяц') dispatch(setSalariesTableMonth('Месяц'))
    }

	const sendDate = (id, type, date) =>{
		let to = date[1];
		let from = date[0]
		
		if(id === 1){ 
			to.setDate((type === 'plus')? to.getDate()+7: to.getDate()-7)
			from.setDate((type === 'plus')? from.getDate()+7: from.getDate()-7)			
			dispatch(setSalariesTableRange([from, to]))
		}
		if(id === 2){
			if(to.getDate() === 15){
				if(type === 'plus'){
					to.setMonth(to.getMonth()+1)
					to = new Date(to.toLocaleDateString().split('.')[2], to.toLocaleDateString().split('.')[1]-1, 1)
					to.setDate(to.getDate() - 1)
					from.setDate(from.getDate()+15)
				}
				else{
					to = new Date(to.toLocaleDateString().split('.')[2], to.toLocaleDateString().split('.')[1]-1, 1)
					to.setDate(to.getDate() - 1)
					from = new Date(to.toLocaleDateString().split('.')[2], to.toLocaleDateString().split('.')[1]-1, 16)
				}
			}
			else{
				if(type === 'plus'){
					from.setDate(to.getDate()+1)
					to = new Date(from.toLocaleDateString().split('.')[2], from.toLocaleDateString().split('.')[1]-1, 15)
				}
				else{
					to = new Date(to.toLocaleDateString().split('.')[2], to.toLocaleDateString().split('.')[1]-1, 15)
					from = new Date(to.toLocaleDateString().split('.')[2], to.toLocaleDateString().split('.')[1]-1, 1)
				}
			}
			
			dispatch(setSalariesTableRange([from, to]))
		}
		if(id === 3){
			if(type === 'plus'){
				to = new Date(getReturnDateMass(date[1])[2], +getReturnDateMass(date[1])[1]+1, 1)
				to.setDate(to.getDate() - 1)
				from.setMonth(from.getMonth() + 1)
			}
			else{
				to = new Date(getReturnDateMass(date[1])[2], +getReturnDateMass(date[1])[1] - 1, 1)
				to.setDate(to.getDate() - 1)
				from.setMonth(from.getMonth() - 1)
			}			
			dispatch(setSalariesTableRange([from, to]))
		}
		if(id === 4){
			to.setMonth((type === 'plus')? to.getMonth()+4: to.getMonth()-4)
			from.setMonth((type === 'plus')? from.getMonth()+4: from.getMonth()-4)
			dispatch(setSalariesTableRange([from, to]))
		}
		if(id === 5){
			to.setMonth((type === 'plus')? to.getMonth()+6: to.getMonth()-6)
			from.setMonth((type === 'plus')? from.getMonth()+6: from.getMonth()-6)
			dispatch(setSalariesTableRange([from, to]))
		}
		if(id === 6){
			to.setMonth((type === 'plus')? to.getMonth()+12: to.getMonth()-12)
			from.setMonth((type === 'plus')? from.getMonth()+12: from.getMonth()-12)
			dispatch(setSalariesTableRange([from, to]))
		}
		sessionStorage.setItem('staffSalariesTablePopupFilters', JSON.stringify({month: 'Месяц', year: from.getFullYear(), range: [from, to]}))
		if(tableYear !== from.getFullYear()) dispatch(setSalariesTableYear(from.getFullYear()))
		if(tableMonth !== 'Месяц') dispatch(setSalariesTableMonth('Месяц'))
	}

	let positionListAdd = ({id, value}, key) =>{
        return(
            <div className={`franchises-leaders__staf-points-buttons-position-select-list-item salaries`} key={key} onClick={() => editDate(id, value)}>
                {value}
            </div>
        )
    }

	let dataMonth = [
        {
            id: 0,
            value: "Январь"
        },
        {
            id: 1,
            value: "Февраль"
        },
        {
            id: 2,
            value: "Март"
        },
        {
            id: 3,
            value: "Апрель"
        },
        {
            id: 4,
            value: "Май"
        },
        {
            id: 5,
            value: "Июнь"
        },
        {
            id: 6,
            value: "Июль"
        },
        {
            id: 7,
            value: "Август"
        },
        {
            id: 8,
            value: "Сентябрь"
        },
        {
            id: 9,
            value: "Октябрь"
        },
        {
            id: 10,
            value: "Ноябрь"
        },
        {
            id: 11,
            value: "Декабрь"
        }
    ];
	let defaultYear = new Date().getFullYear()
    let dataYear = [
        {
            id: 0,
            value: defaultYear - 5
        },
        {
            id: 1,
            value: defaultYear - 4
        },
        {
            id: 2,
            value: defaultYear - 3
        },
        {
            id: 3,
            value: defaultYear - 2
        },
        {
            id: 4,
            value: defaultYear - 1
        },
        {
            id: 5,
            value: defaultYear
        },
        {
            id: 6,
            value: defaultYear + 1
        },
        {
            id: 7,
            value: defaultYear + 2
        },
        {
            id: 8,
            value: defaultYear + 3
        },
        {
            id: 9,
            value: defaultYear + 4
        },
        {
            id: 10,
            value: defaultYear + 5
        }
    ]

	const [tableMonthActive, setTableMonthActive]  = useState(false);
    const [tableYearActive, setTableYearActive]  = useState(false);

    const tableMonthActivate = () =>{
        setTableMonthActive(!tableMonthActive)
        setTableYearActive(false)        
    }
    const tableYearActivate = () =>{
        setTableYearActive(!tableYearActive)
        setTableMonthActive(false)
    }
    const tableMonthChange = (id) =>{
        setTableMonthActive(false)
		let from = new Date((tableYear === 'Год')? new Date().getFullYear(): tableYear, id, 1);
		let to = new Date((tableYear === 'Год')? new Date().getFullYear(): tableYear, id+1, 1);
		to.setDate(to.getDate() - 1)
		dispatch(setSalariesTableMonth(dataMonth[id].value))
		dispatch(setSalariesTableRange([from, to]))
		sessionStorage.setItem('staffSalariesTablePopupFilters', JSON.stringify({month: dataMonth[id].value, year: from.getFullYear(), range: [from, to]}))
		dispatch(setSalariesTableRangeTypeSelect(({id: 3, value: 'Месяц'})))
		sessionStorage.setItem('staffSalariesTablePopupFiltersPeriodListActive', JSON.stringify({id: 3, value: 'Месяц'}))
    }
    const tableYearChange = (id) =>{
        setTableYearActive(false)
		let from = new Date(+dataYear[id].value, 0, 1);
		let to = new Date(+dataYear[id].value + 1, 0, 1);
		to.setDate(to.getDate() - 1)
		dispatch(setSalariesTableYear(dataYear[id].value))
		dispatch(setSalariesTableRange([from, to]))
		sessionStorage.setItem('staffSalariesTablePopupFilters', JSON.stringify({month: 'Месяц', year: dataYear[id].value, range: [from, to]}))
		if(tableMonth !== 'Месяц') dispatch(setSalariesTableMonth('Месяц'))
		dispatch(setSalariesTableRangeTypeSelect(({id: 6, value: 'Год'})))
		sessionStorage.setItem('staffSalariesTablePopupFiltersPeriodListActive', JSON.stringify({id: 6, value: 'Год'}))
    }

	let gridBlockLength = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]

    const [monthActive, setMonthActive]  = useState(false);
    const [yearActive, setYearActive]  = useState(false);

    const monthActivate = () =>{
        setMonthActive(!monthActive)
        setYearActive(false)        
    }
    const yearActivate = () =>{
        setYearActive(!yearActive)
        setMonthActive(false)
    }
    const monthChange = (id) =>{
        dispatch(setSalariesButtonsMonth(dataMonth[id].value))
        setMonthActive(false)
    }
    const yearChange = (id) =>{
        dispatch(setSalariesButtonsYear(dataYear[id].value))
        setYearActive(false)
    }

    const addMonth = ({id, value}, key, type = '')=>{
        return(
            <div className={`filters__block-item-list-elem ${((type === 'table'&& tableMonth === value) || buttonsMonth === value)? "active": ""}`} key={key} onClick={() => {(type === 'table')? tableMonthChange(id): monthChange(id)}}>{value}</div>
        )
    }
    const addYear = ({id, value}, key, type = '')=>{
        return(
            <div className={`filters__block-item-list-elem ${((type === 'table'&& tableYear === value) || buttonsYear === value)? "active": ""}`} key={key} onClick={() => {(type === 'table')? tableYearChange(id): yearChange(id)}}>{value}</div>
        )
    }
    
	return (
		<React.Fragment>
			<div>
				<div className="franchises-leaders__title">Таблица</div>
				<div className='salaries__table-filters d-flex'>
					<div className="franchises-leaders__staf-points-buttons-position-select-container salaries d-flex align-center">
						<div className={`franchises-leaders__staf-points-buttons-position-select ${(list)? 'active': ''}`} onClick={()=>{
								setList(!list)
							}}>
							{tableRangeTypeSelect.value}
						</div>                            
						<div className={`franchises-leaders__staf-points-buttons-position-select-list ${(list)? 'active': ''}`}>
							<div className="franchises-leaders__staf-points-buttons-position-select-list-scroll">
								{
									positionList.map((item, key)=>positionListAdd(item, key))
								}                                        
							</div>
						</div>
					</div>
					<div className="franchises-leaders__sales-filters__block-item mr-16 sales year">
						<div className={`franchises-leaders__sales-filters__block-item-title franchises-leaders__salaries-filters-block-item-title d-flex align-center justify-center year ${(tableYearActive)? "open": ""}`} onClick={tableYearActivate}>{(tableYear === 'Год' && tableRange.length !== 0 && tableRange[0].getFullYear() === tableRange[1].getFullYear())? tableRange[0].getFullYear(): tableYear}<div className="franchises-leaders__sales-filters__block-item-title-icon"/></div>                        
						<div className={`franchises-leaders__sales-filters__block-item-list ${(tableYearActive)? "open": ""}`}>
							<div className='franchises-leaders__sales-filters__block-item-list-scroll'>
								{
									dataYear.map((item, key) => addYear(item, key, 'table'))
								}
							</div>
						</div>
					</div>
					<div className="franchises-leaders__sales-filters__block-item sales months">
						<div className={`franchises-leaders__sales-filters__block-item-title franchises-leaders__salaries-filters-block-item-title d-flex align-center justify-center months ${(tableMonthActive)? "open": ""}`} onClick={tableMonthActivate}>{(tableMonth === 'Месяц' && tableRange.length !== 0 && tableRange[0].getMonth() === tableRange[1].getMonth())? dataMonth[+tableRange[0].getMonth()].value: tableMonth}<div className="franchises-leaders__sales-filters__block-item-title-icon"/></div>                        
						<div className={`franchises-leaders__sales-filters__block-item-list ${(tableMonthActive)? "open": ""}`}>
							<div className='franchises-leaders__sales-filters__block-item-list-scroll'>
								{
									dataMonth.map((item, key) => addMonth(item, key, 'table'))
								}                               
							</div>
						</div>
					</div>
					<div className='franchises-leaders__salaries-filters-item d-flex'>
						<img className={`franchises-leaders__salaries-filters-item-icon left ${(tableRangeActive)? 'none-click': ''}`} src="/img/arrow-down-grey.svg" alt="" onClick={()=>{
							sendDate(tableRangeTypeSelect.id, 'minus', tableRange)
						}}/> 
							<div onClick={() => {
								dispatch(setSalariesCalendLocation('Table'))
								dispatch(setCalendDisplay(""));
								setTimeout(()=>{
									dispatch(setCalendDisplay("open", "Table"))
								}, 0)
							}}>
								{buttonTimeFunc(tableRange)}
							</div>
						<img className={`franchises-leaders__salaries-filters-item-icon right ${(tableRangeActive)? 'none-click': ''}`} src="/img/arrow-down-grey.svg" alt="" onClick={()=>{
							sendDate(tableRangeTypeSelect.id, 'plus', tableRange)
						}}/>
					</div>
				</div>
				<div className="salaries__table">
					<div className='salaries__table-data-block'>
						<div className="salaries__table-data-block-scrollY d-flex" style={{height: `${(4 * 75)+ 100 + 'px'}`}}>
							<div className="salaries__table-staff">
								<div className="salaries__table-staff-item title d-flex justify-center align-center">
									<div className="salaries__table-staff-item-container">
										Работник
									</div>
								</div>
								<div className={`salaries__table-staff-item d-flex justify-center align-center `}>
									<div className="salaries__table-staff-item-container">
										<div className="salaries__table-staff-item-name">
											Зарплата
										</div>
									</div>
								</div>
								<div className={`salaries__table-staff-item d-flex justify-center align-center `}>
									<div className="salaries__table-staff-item-container">
										<div className="salaries__table-staff-item-name">
											Премии
										</div>
									</div>
								</div>
								<div className={`salaries__table-staff-item d-flex justify-center align-center `}>
									<div className="salaries__table-staff-item-container">
										<div className="salaries__table-staff-item-name">
											Штрафы
										</div>
									</div>
								</div>
								<div className={`salaries__table-staff-item d-flex justify-center align-center `}>
									<div className="salaries__table-staff-item-container">
										<div className="salaries__table-staff-item-name">
											Авансы
										</div>
									</div>
								</div>
							</div>
							<div className='salaries__table-tabel'>
								<div className="salaries__table-tabel-scroll" onScroll={(event) => {
									// setScrollElem('data')
									// scroll(event)
								}}>
									<div className='salaries__table-tabel title'>
										<div className="salaries__table-line title d-flex">
											{
												tableDate.map((item, key) => addDates(item, key))
											}
										</div>
									</div>
									<div className={`salaries__table-tabel-line d-flex `}>
										{
											tableData.map((item, key) => addTabelBlock(item, key, 'Зарплата'))
										}
									</div>
									<div className={`salaries__table-tabel-line d-flex `} >
										{
											tableData.map((item, key) => addTabelBlock(item, key, 'Премия'))
										}
									</div>
									<div className={`salaries__table-tabel-line d-flex `}>
										{
											tableData.map((item, key) => addTabelBlock(item, key, 'Штраф'))
										}
									</div>
									<div className={`salaries__table-tabel-line d-flex `}>
										{
											tableData.map((item, key) => addTabelBlock(item, key, 'Аванс'))
										}
									</div>									
								</div>
								<div className='salaries__table-tabel-grid'>
										<div className='salaries__table-tabel title'>
											<div className="salaries__table-line title d-flex">
												{
													gridBlockLength.map((item, key) => addTabelGridTitleBlock(item, key))
												}
											</div>
											<div className={`salaries__table-tabel-line d-flex `}>
												{
													gridBlockLength.map((item, key) => addTabelGridBlock(item, key, 'Аванс'))
												}
											</div>
											<div className={`salaries__table-tabel-line d-flex `}>
												{
													gridBlockLength.map((item, key) => addTabelGridBlock(item, key, 'Аванс'))
												}
											</div>
											<div className={`salaries__table-tabel-line d-flex `}>
												{
													gridBlockLength.map((item, key) => addTabelGridBlock(item, key, 'Аванс'))
												}
											</div>
											<div className={`salaries__table-tabel-line d-flex `}>
												{
													gridBlockLength.map((item, key) => addTabelGridBlock(item, key, 'Аванс'))
												}
											</div>
										</div>
									</div>
							</div>
							<div className="salaries__table-total">
								<div className="salaries__table-total-item title d-flex justify-center align-center">
									<div className="salaries__table-total-item-container">
										Итого
									</div>
								</div>
								<div className={`salaries__table-total-item d-flex justify-center align-center`}>
									<div className="salaries__table-total-item-container">
										{tableTotalData.salary}₽
									</div>
								</div>
								<div className={`salaries__table-total-item d-flex justify-center align-center`}>
									<div className="salaries__table-total-item-container">
										{tableTotalData.premium}₽
									</div>
								</div>
								<div className={`salaries__table-total-item d-flex justify-center align-center`}>
									<div className="salaries__table-total-item-container">
										{tableTotalData.fine}₽
									</div>
								</div>
								<div className={`salaries__table-total-item d-flex justify-center align-center`}>
									<div className="salaries__table-total-item-container">
										{tableTotalData.prepayment}₽
									</div>
								</div>
							</div>
						</div>					
					</div>
				</div>
			</div>

			<div className="salaries__buttons ">
            	<div className="franchises-leaders__staf-points-top d-flex justify-between align-center">
                    <div className="franchises-leaders__staf-points-title staf">Зарплата и премии</div>
                    <div className='franchises-leaders__sales-filters__block d-flex align-center'>
                        <div className="franchises-leaders__sales-filters__block-item sales year">
                            <div className={`franchises-leaders__sales-filters__block-item-title d-flex align-center justify-center year ${(buttonsYear === "Год")? "": "active"} ${(yearActive)? "open": ""}`} onClick={yearActivate}>{buttonsYear}<div className="franchises-leaders__sales-filters__block-item-title-icon"/></div>                        
                            <div className={`franchises-leaders__sales-filters__block-item-list staff-page ${(yearActive)? "open": ""}`}>
                                <div className='franchises-leaders__sales-filters__block-item-list-scroll staff-page'>
                                    {
                                        dataYear.map((item, key) => addYear(item, key))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="franchises-leaders__sales-filters__block-item sales months">
                            <div className={`franchises-leaders__sales-filters__block-item-title d-flex align-center justify-center months ${(buttonsMonth === "Месяц")? "": "active"} ${(monthActive)? "open": ""}`} onClick={monthActivate}>{buttonsMonth}<div className="franchises-leaders__sales-filters__block-item-title-icon"/></div>                        
                            <div className={`franchises-leaders__sales-filters__block-item-list staff-page ${(monthActive)? "open": ""}`}>
                                <div className='franchises-leaders__sales-filters__block-item-list-scroll staff-page'>
                                    {
                                        dataMonth.map((item, key) => addMonth(item, key))
                                    }                               
                                </div>
                            </div>
                        </div>
                        <div className="franchises-leaders__sales-filters d-flex justify-between">
                            <div className="franchises-leaders__sales-filters months" onClick={() =>  {
								dispatch(setSalariesCalendLocation('Buttons'))
                                dispatch(setCalendDisplay(""));
                                setTimeout(()=>{
                                    dispatch(setCalendDisplay("open", "Buttons"))
                                }, 0)
                            }}>
                                <div className="franchises-leaders__sales-filters-title d-flex align-center justify-center months">Фильтры<img src="/img/arrow-down.svg" alt="" className="franchises-leaders__sales-filters__block-item-title-icon"/></div>                        
                            </div>
                        </div>
                    </div>
                </div>
                <div className="salaries__buttons-container justify-between align-center">
                    <div className="salaries__buttons-sale d-flex flex-column">
                        <div className="d-flex justify-center">
                            <div className="salaries__buttons-icon sale align-center justify-center">
                                <img src="/img/money-black-icon.svg" alt="" />
                            </div>
                            <div>
                                <div className="salaries__buttons-title sale">Заработная плата</div>
                                <div className="salaries__buttons-val sale">{buttonsData.salary}</div>
                                <div className={`salaries__buttons-val-percent d-flex align-center`}>
                                    <img src="/img/graph-circle-icon.svg" alt="" className="salaries__buttons-val-percent-icon"/> 
                                    <div>{buttonTimeFuncButtons(buttonsRange, buttonsMonth, buttonsYear)}</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="salaries__buttons-sale d-flex flex-column">
                        <div className="d-flex justify-center">
                            <div className="salaries__buttons-icon sale align-center justify-center">
                                <img src="/img/money-black-icon.svg" alt="" />
                            </div>
                            <div>
                                <div className="salaries__buttons-title sale">Премии</div>
                                <div className="salaries__buttons-val sale">{buttonsData.premium}</div>
                                <div className={`salaries__buttons-val-percent d-flex align-center`}>
                                    <img src="/img/graph-circle-icon.svg" alt="" className="salaries__buttons-val-percent-icon"/> 
                                    <div>{buttonTimeFuncButtons(buttonsRange, buttonsMonth, buttonsYear)}</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
		</React.Fragment>
	)
}

export default Salaries;