import React from 'react';
import MoldList from './Mold-list/MoldList';
import Points from './Points/Points';
import PointsMold from './Points-mold/PointsMold';
import { useSelector} from 'react-redux';

import './Warehouse-1.0.scss'
// import PurchaseTable from './Purchase-table/Purchase-table';
// import LekalsTop from './Lekals-top/Lekals-top';

const Warehouse = () => {
    let {franchiserNav} = useSelector(({navigation})=> {
        return {
            franchiserNav: navigation.franchiserNav
        }
    })

	return (
		<React.Fragment>
			<div className="franchises-leaders__title">Общая статистика</div>
			<div className="franchises-leaders__warehouse-content d-flex justify-between">
				<MoldList/>
                {
					(franchiserNav === 'Points-mold')? <PointsMold/>: <Points/>
				}
			</div>
		</React.Fragment>
	)
}

export default Warehouse;