import React, { useState } from 'react';
import Search from '../../Search/Search';
import {useDispatch, useSelector} from 'react-redux';
import {setLeadersNav, setOwnerNav, setRole} from '../../../redux/actions/navigation';
import './Header.scss';
import { Link } from 'react-router-dom';

const Header = () => {
    const dispatch = useDispatch();
    const { ownerNav } = useSelector(({ navigation }) => navigation);
    const [openMenu, setOpenMenu] = useState(false)

    const burgerClick = () =>{
        if(!openMenu){
            document.body.style.position = "fixed"
            document.body.style.overflow = 'hidden';
        }
        else{
            document.body.style.position = 'static';
            document.body.style.overflow = 'auto';
        }

        setOpenMenu(!openMenu)
    }

    const {userData} = useSelector(({navigation})=> {
        return {
            userData: navigation.user
        }
    })
    const cutName = (name) =>{
        if(name !== null && name !== ''){
            let cut = name.split(' ')
            if(cut.length === 3){
                return cut[0] + ' ' + cut[1][0] + '.' + ' ' + cut[2][0] + '.'
            }
            else if(cut.length === 2){
                return cut[0] + ' ' + cut[1][0] + '.'
            }
            else return name
        }
        else return name
    }

 	return (
		<header className="owner__header d-flex justify-between">
            {
                (window.innerWidth > 700)?(
                    <div className='owner__header-desktop justify-between'>
                        <Search/>
                        <div className="owner__buttons d-flex align-center justify-end">
                            <div className="owner__buttons-user d-flex align-center">
                                <div className="owner__buttons-user-photo-container d-flex align-center justify-center">
                                    <img src='/img/owner-photo.png' alt="" className="owner__buttons-user-photo"/>
                                </div>
                                
                                <div>
                                    <div className="owner__buttons-user-name d-flex align-center">
                                        <div>{cutName('Кураев Мохмад Умарович')} </div>
                                        {/* <img src="/img/arrow-down.svg" alt="" className="owner__buttons-user-button"/> */}
                                    </div>
                                    <div className="owner__buttons-user-name-verify d-flex align-center">
                                        <img src="/img/verify.svg" alt="" className="owner__buttons-user-name-verify-icon"/>
                                        Владелец
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ):(
                    <div className='owner__header-mobile-container'>
                        
                        <div className='owner__header-mobile justify-between align-center'>
                            <div className={`owner__header-burger ${(openMenu)? 'active': ''}`} onClick={burgerClick}>
                                <div className='owner__header-burger-item top'></div>
                                <div className='owner__header-burger-item center'></div>
                                <div className='owner__header-burger-item bottom'></div>
                            </div>
                            <img src="/img/mobile-logo.svg" alt="" className="owner__header-mobile-logo"/>                
                        </div>
                                
                        
                        <div className={`owner__header-mobile-menu ${(openMenu)? 'active': ''}`}>
                            <Search/>
                            <div className='owner__header-mobile-border'></div>
                            <div className='test d-flex flex-wrap justify-between'>
                                <div className="owner__nav">
                                    <div className={`owner__nav-item-active ${ownerNav}`}>
                                    </div>
                                    <Link to='/'>
                                        <div className="owner__nav-item d-flex align-center" onClick={()=>{
                                                dispatch(setOwnerNav("Home"))
                                            }}>
                                            <div className={`owner__nav-item-icon home ${(ownerNav === "Home")? "active" : ""}`}></div>
                                            <div className="owner__nav-item-title" >Главная</div>
                                        </div>
                                    </Link>
                                    <Link to='/leaders' >
                                        <div className="owner__nav-item d-flex align-center" onClick={()=>{
                                                dispatch(setOwnerNav("Leaders"))
                                                dispatch(setLeadersNav("leaders"))
                                            }}>
                                            <div className={`owner__nav-item-icon leaders ${(ownerNav === "Leaders")? "active" : ""}`}></div>
                                            <div className="owner__nav-item-title">Руководители</div>
                                        </div>
                                    </Link>
                                    <Link to='/warehouse'>
                                        <div className="owner__nav-item d-flex align-center" onClick={()=>{
                                                dispatch(setOwnerNav("Warehouse"))
                                            }}>
                                            <div className={`owner__nav-item-icon warehouses ${(ownerNav === "Warehouse")? "active" : ""}`}></div>
                                            <div className="owner__nav-item-title">Склады</div> 
                                        </div>
                                    </Link>
                                </div>
                                     
                            </div>  
                            <div className="owner__sidebar-footer header-footer">
                                <div className="owner__buttons-user d-flex align-center mobile">
                                    <div className="owner__buttons-user-photo-container d-flex align-center justify-center">
                                        <img src='/img/owner-photo.png' alt="" className="owner__buttons-user-photo mobile"/>
                                    </div>
                                    <div>
                                        <div className="owner__buttons-user-name d-flex justify-between align-center mobile">
                                            <div>{cutName('Кураев Мохмад Умарович')} </div> 
                                            {/* <img src="/img/arrow-down.svg" alt="" className="owner__buttons-user-button"/> */}
                                        </div>
                                        <div className="owner__buttons-user-name-verify d-flex align-center mobile">
                                            <img src="/img/verify.svg" alt="" className="owner__buttons-user-name-verify-icon mobile"/>
                                            Владелец
                                        </div>
                                    </div>
                                </div>
                                <button className="owner__logout d-flex align-center" onClick={()=>{
                                    localStorage.setItem('token', '') 
                                    localStorage.setItem('role', '')
                                    sessionStorage.setItem('token', '') 
                                    sessionStorage.setItem('role', '')
                                    dispatch(setRole(''))  
                                }}>
                                    <img src="/img/logout.svg" alt="" className="owner__logout-icon"/>
                                    <div className="owner__logout-title">Выйти из аккаунта</div>                   
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        </header>
	)
}
export default Header;