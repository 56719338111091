async function post(url, type, data = null){
  let response = await fetch(url, {
      method: 'POST', 
      headers: {
          "Accept": "application/json",
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
      },
      body: JSON.stringify(data)
  })
  if (response.ok === true) {
      const user = await response.json();
      return user
  }
  else{
    localStorage.setItem('role', '')
  }
  let dat = response.json
  return dat 
}

async function postNotData(url, type){
  let response = await fetch(url, {
      method: type, 
      headers: {
          "Accept": "application/json",
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
      },
  })
  if (response.ok === true) {
      const user = await response.json();
      return user
  }
  else{
    localStorage.setItem('role', '')
  }
  let dat = response.json
  return dat 
}

export const setModeratorComplaints = (item) => ({
    type: 'SET_MODERATOR_POPUP_NOTIFICATIONS',
    payload: item
  });

  export const setModeratorComplaintsAdd = (item) => ({
    type: 'SET_MODERATOR_POPUP_NOTIFICATIONS_ADD',
    payload: item
  });
  
  export const fetchModeratorComplaints = () => (dispatch) =>{
    postNotData(`/Moderator/GetComplaints?iffer=${1}`, 'GET').then((response)=> {
        dispatch(setModeratorComplaints(response.value))
    })
  }
  
  export const setModeratorHistoryChanges = (item) => ({
    type: 'SET_MODERATOR_POPUP_HISTORY_CHANGES',
    payload: item
  });
  
  export const fetchModeratorHistoryChanges = () => (dispatch) =>{
    postNotData(`/Moderator/GetHistoryData?iffer=${1}`, "GET").then((response)=> {
        dispatch(setModeratorHistoryChanges(response.value))

    })
  }