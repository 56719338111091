import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MaskedInput from '../../MaskedInput/MaskedInput'

import {setFranchisesNav} from '../../../redux/actions/navigation';

import './StaffDetail.scss';
import { fetchFranchiserStaffDetail, setFranchiserStaffDetailStaffData } from '../../../redux/actions/franchiserData';

const StaffDetail = () => {
    const dispatch = useDispatch();

    const {staffPrintingHistory, staffDetail, staffCheckId} = useSelector(({franchiserData, franchiserCheckData})=> {
        return {
            staffPrintingHistory: franchiserData.franchiserStaffDetail,
            staffDetail: franchiserData.franchiserStaffDetailStaffData,
            staffCheckId: franchiserCheckData.franchiserHomeStaffListCheck
        }
    })


    const [list, setList] = useState(false)
    const [open, setOpen] = useState(0)

    const [activeInput, setActiveInput] = useState(0)
    const [editInput, setEditInput] = useState({id: 0, elem: ''})
    const [error, setError] = useState('')


    let [inform, setInform] = useState({
        name: '',
        phone: staffDetail.phone,
        login: '',
        password: '',
        position: {id: 1, value: 'Монтажник'}    
    })

    let [editInform, setEditInform] = useState({
        name: false,
        phone: false,
        login: false,
        password: false     
    })

    const openBlock = (id) =>{
        if(open === id) setOpen(0)
        else setOpen(id)
    }

    const fetchCheckLogin = async(url, query='')=>{
        const response =  await fetch(url + query, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            }
        })
        if (response.ok === true) {
            const dat = await response.json();
            // console.log(dat)
            return dat.value.resultObject
        }
    }

    useEffect(()=>{
        // console.log(staffCheckId.id, sessionStorage.getItem('StaffCheckId'))
        dispatch(fetchFranchiserStaffDetail(sessionStorage.getItem('StaffCheckId')))
    }, [])

    const addTableItem = ({id, model, code, amount, clientPhone, printType, price, date, comment}, key) =>{
        return(
            <div className={`franchises-leaders__staf-points-line-container franchiser__scroll-staff-detail-line-${id}`} key={key}>
                <div className={`franchises-leaders__staf-points-line d-flex align-center justify-between`}>
                    <div className="franchises-leaders__staf-points-model" title={model}>{model}</div>
                    <div className="franchises-leaders__staf-points-code" >{code}({amount}шт)</div>
                    <div className="franchises-leaders__staf-points-staff" title=''>{clientPhone}</div>
                    <div className="franchises-leaders__staf-points-date">{(printType === null || printType === '')?'Другое': printType}</div>
                    <div className="franchises-leaders__staf-points-price">{price}₽</div>
                    <img src="/img/arrow-bottom-icon.svg" alt="" className={`franchises-leaders__staf-points-additionally-open ${(open === id)? 'active': ''}`} onClick={()=>openBlock(id)}/>
                </div>
                <div className={`franchises-leaders__staf-points-additionally d-flex ${(open === id)? 'active': ''}`}>
                    <div className="franchises-leaders__staf-points-additionally-item comment">
                        <div className="franchises-leaders__staf-points-additionally-item-label">Комментарий</div>
                        <div className="franchises-leaders__staf-points-additionally-item-comment-scroll">
                            <div className="franchises-leaders__staf-points-additionally-item-comment">{comment}</div>
                        </div>
                    </div>

                    <div>
                        <div className="franchises-leaders__staf-points-additionally-item phone">
                            <div className="franchises-leaders__staf-points-additionally-item-label">Время и дата</div>
                            <div className="franchises-leaders__staf-points-additionally-item-phone">
                                {date}
                            </div>
                        </div>

                        <div className="franchises-leaders__staf-points-additionally-item model">
                            <div className="franchises-leaders__staf-points-additionally-item-label model">Модель</div>
                            <div className="franchises-leaders__staf-points-additionally-item-model">
                                {(model !== '' && model !== null)? model: 'Не заполенено'}
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const popupClose = async(event) =>{
        if(event.target !== editInput.elem) setActiveInput(0)
        if(editInput.id === activeInput && event.target !== editInput.elem){
            setEditInput(0)
            setActiveInput(0)
            let data = {}
            let boolPost = false;
            
            if(activeInput === 1 && inform.name !== '' && inform.name !== staffCheckId.name) {
                // dispatch(setDemoTasksDetail({...demoTaskDetail, task: {...demoTaskDetail.task, title: inform.taskTitle}}))
                data = {
                    StaffId: staffCheckId.id,
                    Name: inform.name,
                }
                postData(data)
            }

            if(activeInput === 2 && (inform.phone !== '+7(___) ___-__-__' || inform.phone !== '') && inform.phone !== staffCheckId.phone) {
                // dispatch(setDemoTasksDetail({...demoTaskDetail, task: {...demoTaskDetail.task, title: inform.taskTitle}}))
                data = {
                    StaffId: staffCheckId.id,
                    Phone: inform.phone,
                }
                postData(data)
            }

            if(activeInput === 3 && inform.login !== '' && inform.login !== staffCheckId.login) {
                // dispatch(setDemoTasksDetail({...demoTaskDetail, task: {...demoTaskDetail.task, title: inform.taskTitle}}))
                fetchCheckLogin('/Login/CheckHaveLogin?login=', inform.login).then(async(availability)=>{
                    if(!availability){
                        data = {
                            StaffId: staffCheckId.id,
                            Login: inform.login,
                        }
                        postData(data)
                    }
                    else setError("Такой логин уже существует*")
                })
            }

            if(activeInput === 4 && inform.password !== '' && inform.password !== staffCheckId.password) {
                // dispatch(setDemoTasksDetail({...demoTaskDetail, task: {...demoTaskDetail.task, title: inform.taskTitle}}))
                data = {
                    StaffId: staffCheckId.id,
                    Password: inform.password,
                }
                postData(data)
            }


            if((activeInput === 1 && inform.name !== '') || (activeInput === 2 && (inform.phone !== '+7(___) ___-__-__' || inform.phone !== '')) ||  (activeInput === 3 && inform.login !== '') || (activeInput === 4 && inform.password !== '')) setError('')
            else setError('Необходимо заполнить поле*')
        }
    }

    let positionList = [
        {
            id: 1, 
            value: 'Поклейщик'
        },
        {
            id: 2, 
            value: 'Управляющий'
        },
        {
            id: 3, 
            value: 'Маркетолог'
        },
    ]

    const editPosition=(id, value)=>{
        setInform({...inform, position: {id: id, value: value}})
        dispatch(setFranchiserStaffDetailStaffData({...staffDetail, position: value}))
        setList(false)

        let data = {
            StaffId: staffCheckId.id,
            Post: value,
        }
        postData(data)
    }

    let positionListAdd = ({id, value}, key) =>{
        return(
            <div className={`franchises-leaders__staf-points-buttons-position-select-list-item `} key={key} onClick={() => editPosition(id, value)}>
                {value}
            </div>
        )
    }

    const postData = async(data) =>{

        let response = await fetch('/francheaser/EditWorker', {
            method: 'POST', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })
        if (response.ok === true) {
            const data = await response.json();
            // console.log(data)

            // dispatch(setFranchiserStaffListAdd({
            //     id: data.value.result, 
            //     name: cutName(inform.name),
            //     fullName: inform.name,
            //     status: 'Работающий',
            //     satausColor: 'green',
            //     phone: inform.phone, 
            //     historySales: 0, 
            //     salary: 0,
            //     login: inform.login,
            //     password: inform.password,
            //     position: inform.position
            // }))
        }
        else{
            localStorage.setItem('role', '')
        }
    }


	return (
        <React.Fragment>
            <div onClick={(event) =>{
                if(activeInput !== 0) popupClose(event)
            }}>
                <Link to='/staff'>
                    <div className='franchises-leaders__staf-points-back d-flex align-center' onClick={()=>{
                        dispatch(setFranchisesNav("Staff"))
                        sessionStorage.setItem('page', 'Staff')
                        dispatch(setFranchiserStaffDetailStaffData({id: 0,  name: '', fullName: '', phone: '', login: '', password: '', position: 'Монтажник'}))
                    }}> <img src="../../../img/arrow-left.svg" alt="" /> Анкета сотрудника</div>
                </Link>
                <div className="franchises-leaders__staf-points-buttons ">
                    <div className="franchises-leaders__staf-points-top d-flex justify-between align-center">
                        <div className="franchises-leaders__staf-points-title staf">Информация о сотруднике</div>
                        <div className='franchises-leaders__staf-points-top-description'>Нажмите на поле чтобы изменить данные сотрудника*</div>
                    </div>
                    <div className="franchises-leaders__staf-points-buttons-container justify-between align-center">
                        <div className="franchises-leaders__staf-points-buttons-item d-flex flex-column">
                            <label htmlFor="" className='franchises-leaders__staf-points-buttons-label'>ФИО</label>
                            <div className='franchises-leaders__staf-points-buttons-before-container'>
                                <input type="text" defaultValue={staffDetail.fullName} placeholder='Введите ФИО' className={`franchises-leaders__staf-points-buttons-input ${(activeInput === 1)? 'focus': ''}`} onClick={() => {if(activeInput !== 1) setActiveInput(1) }} onInput={(event)=>{
                                    setInform({...inform, name: event.target.value})
                                    setEditInform({...editInform, name: true})                                
                                    if(editInput.id !== 1) setEditInput({id: 1, elem: event.target})
                                }}/>
                            </div>
                            <label htmlFor="" className='franchises-leaders__staf-points-buttons-label'>Номер телефона</label>
                            <div className='franchises-leaders__staf-points-buttons-before-container'>
                                {/* <input type="text" defaultValue={staffDetail.phone} placeholder='Введите номер телефона' className={`moderator__leaders-staf-points-buttons-input ${(activeInput === 2)? 'focus': ''}`} onClick={() => {if(activeInput !== 2) setActiveInput(2)}} onInput = {(event)=>{
                                    setInform({...inform, phone: event.target.value})
                                    setEditInform({...editInform, phone: true})                                
                                    if(editInput.id !== 2) setEditInput({id: 2, elem: event.target})
                                }}/> */}
                                {
                                    (staffDetail.phone !== '')? (
                                        <MaskedInput
                                            className={`franchises-leaders__staf-points-buttons-input wer ${(activeInput === 2)? 'focus': ''}`}
                                            mask="+7(999) 999-99-99"
                                            name = 'phone'
                                            defaultValue={staffDetail.phone}
                                            placeholder='Введите номер телефона'
                                            onChange={(event)=>{
                                                setInform({...inform, phone: event.target.value})
                                                setEditInform({...editInform, phone: true})                                
                                                if(editInput.id !== 2) setEditInput({id: 2, elem: event.target})
                                            }}
                                            onClick={() => {if(activeInput !== 2) setActiveInput(2) }} 
                                        />
                                    ): <input type="text" defaultValue={staffDetail.phone} placeholder='Введите номер телефона' className={`franchises-leaders__staf-points-buttons-input ${(activeInput === 2)? 'focus': ''}`} onClick={() => {if(activeInput !== 2) setActiveInput(2)}} onInput = {(event)=>{
                                        setInform({...inform, phone: event.target.value})
                                        setEditInform({...editInform, phone: true})                                
                                        if(editInput.id !== 2) setEditInput({id: 2, elem: event.target})
                                    }}/>
                                }
                            </div>
                            
                        </div>
                        <div className="franchises-leaders__staf-points-buttons-item d-flex flex-column">
                            <div className="franchises-leaders__staf-points-buttons-position">
                                <label htmlFor="" className='franchises-leaders__staf-points-buttons-label'>Должность</label>
                                <div className="franchises-leaders__staf-points-buttons-position-select-container d-flex align-center">
                                    <div className={`franchises-leaders__staf-points-buttons-position-select ${(list)? 'active': ''}`} onClick={()=>{
                                            setList(!list)
                                        }}>
                                        {staffDetail.position}
                                    </div>                            
                                    <div className={`franchises-leaders__staf-points-buttons-position-select-list ${(list)? 'active': ''}`}>
                                        <div className="franchises-leaders__staf-points-buttons-position-select-list-scroll">
                                            {
                                                positionList.map((item, key)=>positionListAdd(item, key))
                                            }                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="franchises-leaders__staf-points-buttons-input-container d-flex justify-between">
                                <div className="franchises-leaders__staf-points-buttons-input-fifty">
                                    <label htmlFor="" className='franchises-leaders__staf-points-buttons-label'>Логин</label>
                                    <div className='franchises-leaders__staf-points-buttons-before-container'>
                                        <input type="text" defaultValue={staffDetail.login} placeholder='Введите логин из 8 символов' className={`franchises-leaders__staf-points-buttons-input ${(activeInput === 3)? 'focus': ''}`} onClick={() => {if(activeInput !== 3) setActiveInput(3) }} onInput={(event)=>{
                                            setInform({...inform, login: event.target.value})
                                            setEditInform({...editInform, login: true})                                
                                            if(editInput.id !== 3) setEditInput({id: 3, elem: event.target})
                                        }}/>
                                    </div>
                                    
                                </div> 

                                <div className="franchises-leaders__staf-points-buttons-input-fifty">
                                    <label htmlFor="" className='franchises-leaders__staf-points-buttons-label'>Пароль</label>
                                    <div className='franchises-leaders__staf-points-buttons-before-container'>
                                        <input type="text" defaultValue={staffDetail.password} placeholder='Введите пароль из 8 символов' className={`franchises-leaders__staf-points-buttons-input ${(activeInput === 4)? 'focus': ''}`} onClick={() => {if(activeInput !== 4) setActiveInput(4) }} onInput={(event)=>{
                                            setInform({...inform, password: event.target.value})
                                            setEditInform({...editInform, password: true})                                
                                            if(editInput.id !== 4) setEditInput({id: 4, elem: event.target})
                                        }}/>
                                    </div>
                                    
                                </div>   
                            </div>                     
                        </div>
                    </div>
                    <div className='popup__error'>{error}</div>
                </div>
                <div className="franchises-leaders__staf-points">
                <div className="franchises-leaders__staf-points-top d-flex justify-between align-center">
                        <div className="franchises-leaders__staf-points-title birch">История печати</div>
                    </div>
                    <div className='franchises-leaders__staf-points-table-scroll'>
                        <div className="franchises-leaders__staf-points-line title d-flex align-center justify-between">
                            <div className="franchises-leaders__staf-points-model">Модель</div>
                            <div className="franchises-leaders__staf-points-code">Артикул</div>
                            <div className="franchises-leaders__staf-points-staff">Сотовый клиента</div>
                            <div className="franchises-leaders__staf-points-date">Тип печати</div>
                            <div className="franchises-leaders__staf-points-price blue">Цена</div>
                            <img src="/img/arrow-down-block.svg" alt="" className="franchises-leaders__staf-points-additionally-open none"/>
                        </div>
                        <div className="franchises-leaders__staf-points-table">
                            {
                                staffPrintingHistory.map((item, key) => addTableItem(item, key))
                            }                        
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>        
	)
}

export default StaffDetail;