import React, {useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setPopupBackgroundFunc, setPopupOpenBlock} from '../../../../redux/actions/popupBlocks';
import {setModeratorCatalog } from '../../../../redux/actions/moderatorData';
import { fetchModeratorHistoryChanges} from '../../../../redux/actions/moderatorPopupData';
import { setModeratorCatalogLekaloCheck } from '../../../../redux/actions/moderatorCheckData';

import './index.scss';


const LekaloEdit = ({connection, edit}) => {
    
    const dispatch = useDispatch();
    const [error, setError] = useState('') 
    const [checkBox, setCheckBox] = useState(true)
    const [lekaloEdit, setLekaloEdit] = useState({svgDownload: edit, pltDownload: edit});
    const {moderatorCatalogLekaloCheck, moderatorCatalog} = useSelector(({moderatorCheckData, moderatorData})=> {
        return {
            moderatorCatalogLekaloCheck: moderatorCheckData.moderatorCatalogLekaloCheck,
            moderatorCatalog: moderatorData.moderatorCatalog
        }
    })

    const [inform, setInform] = useState({
        name: moderatorCatalogLekaloCheck.name,
        lekaloPlt: '',
        lekaloSvg: '',
        lekaloHeight: moderatorCatalogLekaloCheck.lekaloHeight,
        lekaloWidth: moderatorCatalogLekaloCheck.lekaloWidth,
    })
    const [clickAddBtn, setClickAddBtn] = useState(false)

    useEffect(()=>{
        return null;
    }, [connection, edit])

    const uploadPhoto = (event, type) => {
        const files = event.target.files;
        if(type === "svg"){
            setInform({...inform, lekaloSvg: files.length !== 0})
            setLekaloEdit({...lekaloEdit, svgDownload: true})
        }
        else if(type === "plt") {
            setInform({...inform, lekaloPlt: files.length !== 0})
            setLekaloEdit({...lekaloEdit, pltDownload: true})
        }        
    }

    const fetchGet = async(url, query='')=>{
        const response =  await fetch(url + query, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            }
        })
        if (response.ok === true) {
            const dat = await response.json();
            return dat.value
        }
    }

    const mailingFunc = (text) =>{
        fetchGet(`/Moderator/GetPoints`).then(async(data)=> {
            const response =  await fetch('/Moderator/SetNotification', {
                method: 'POST', 
                headers: {
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                },
                body: JSON.stringify({Title: text, Points: data.map(item => item.id), DeadLine: new Date()})
            })
            if (response.ok === true) {
                const dat = await response.json();
                // console.log(dat.value.resultObject)
                await connection.invoke("SendModerator", dat.value.resultObject);         
            }
        })
    }


    const addLekaloPost = async(event) =>{
        if(!clickAddBtn && inform.lekaloHeight !== '' && inform.lekaloWidth !== '' && inform.lekaloSvg && inform.lekaloPlt && inform.name !== ''){
            setClickAddBtn(true)
            const formData = new FormData(event)
            formData.append('FolderId', moderatorCatalog.id)
            formData.set('Width', inform.lekaloWidth.replace('.', ','))
            formData.set('Height', inform.lekaloHeight.replace('.', ','))
            // console.log(inform.lekaloHeight, inform.lekaloWidth)
            const response =  await fetch('/Moderator/LekaloSet', {
                method: 'POST', 
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                },
                body: formData
            })
            if (response.ok === true) {
                const dat = await response.json();
                if(dat.value.isSuccessful){
                    let lekalo = {
                        id: dat.value.result,
                        name: inform.name,
                        lekaloPlt: true,
                        lekaloSvg: true,
                        lekaloHeight: inform.lekaloHeight,
                        lekaloWidth: inform.lekaloWidth,
                    }
                    dispatch(setModeratorCatalog({...moderatorCatalog, folderData: [...moderatorCatalog.folderData, lekalo]}))
                    event.reset()
                    setLekaloEdit({svgDownload: false, pltDownload: false})
                    dispatch(fetchModeratorHistoryChanges())
                    setError("")
                    if(checkBox) mailingFunc(`Добавлено лекало ${inform.name}`)

                }
                else setError(dat.value.isSuccessful)
                setClickAddBtn(false)
            }
            else{
                setClickAddBtn(false)
            }
            
        }
        else if(!clickAddBtn){
            setError("Необходимо заполнить все поля*")
        }
    }

    const editLekaloPost = async(event) =>{
        if(!clickAddBtn && inform.lekaloHeight !== '' && inform.lekaloWidth !== '' && inform.name !== ''){
            setClickAddBtn(true)
            const formData = new FormData(event)
            formData.append('FolderId', moderatorCatalog.id)
            formData.append('Id', moderatorCatalogLekaloCheck.id)
            formData.set('Width', inform.lekaloWidth.replace('.', ','))
            formData.set('Height', inform.lekaloHeight.replace('.', ','))
            const response =  await fetch('/Moderator/EditLekalo', {
                method: 'POST', 
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                },
                body: formData
            })
            if (response.ok === true) {
                const dat = await response.json();
                if(dat.value.isSuccessful){
                    let lekalo = {
                        id: moderatorCatalogLekaloCheck.id,
                        name: inform.name,
                        type: 'lekalo',
                        lekaloHeight: inform.lekaloHeight,
                        lekaloWidth: inform.lekaloWidth,
                    }
                    let test = moderatorCatalog.folderData;
                    test[moderatorCatalogLekaloCheck.position] = lekalo;
                    dispatch(setModeratorCatalogLekaloCheck(lekalo))
                    dispatch(setModeratorCatalog({...moderatorCatalog, folderData: test}))
                    dispatch(setPopupBackgroundFunc(false))
                    dispatch(setPopupOpenBlock(''))
                    dispatch(fetchModeratorHistoryChanges())
                    setError("")
                    if(checkBox) mailingFunc(`Лекало ${inform.name} изменено`)
                }
                else setError(dat.value.isSuccessful)
                setClickAddBtn(false)
            }
            else{
                setClickAddBtn(false)
            }
            
        }
        else{
            setError("Необходимо заполнить все поля*")
        }
    }
    const postData = (event) =>{
        // event.preventDefault();
        // if(edit) editLekaloPost(event.target)
        // else addLekaloPost(event.target)
        if(edit) editLekaloPost(event)
        else addLekaloPost(event)
    }

    const OnkeyEnterFunc = (event)=>{
        var key= event.keyCode || event.which;
        if (key === 13){
            postData(event.currentTarget)
        }
    }
  
	return (
        <div className="moderator__popup-container moderator__popup-lekalo-edit-container lekalo-edit">
            <div className="moderator__popup-top moderator__popup-lekalo-edit-top d-flex justify-between align-center">
                <div className="popup__title">{(edit)? "Подробно лекало": "Добавление лекала"}</div>
                <div className="popup__close-btn" onClick={()=>{
                    dispatch(setPopupBackgroundFunc(false))
                    dispatch(setPopupOpenBlock(''))
                }}></div>
            </div>
            <form action="" className='moderator__lekalo-form' onSubmit={postData} onKeyDown={(event)=>OnkeyEnterFunc(event)}>
                <div className={`moderator__popup-lekalo-edit-btn-container d-flex justify-between ${(edit)? "edit": ''}`}>
                    <div className={`moderator__popup-lekalo-edit-btn ${(lekaloEdit.svgDownload)? 'active': ''}`}>
                        <input name='Svg' id="moderator__popup-lekalo-edit-btn-input-plt" type="file" multiple accept=".svg" className="moderator__popup-lekalo-edit-btn-input plt" onChange={(event)=> uploadPhoto(event, 'svg')}/>
                        <label htmlFor="moderator__popup-lekalo-edit-btn-input-plt" className="moderator__popup-lekalo-edit-btn-label plt d-flex justify-center align-center">
                            {
                                (lekaloEdit.svgDownload)?(
                                    <img src="../../../img/lekalo-svg.svg" alt="" />
                                ): (
                                    <div>svg</div>
                                )
                            }
                        </label>
                    </div>
                    <div className={`moderator__popup-lekalo-edit-btn ${(lekaloEdit.pltDownload)? 'active': ''}`}>
                        <input name='Lekalo' id="moderator__popup-lekalo-edit-btn-input-svg" type="file" multiple accept=".plt" className="moderator__popup-lekalo-edit-btn-input svg" onChange={(event)=> uploadPhoto(event, 'plt')}/>
                        <label htmlFor="moderator__popup-lekalo-edit-btn-input-svg" className="moderator__popup-lekalo-edit-btn-label svg d-flex justify-center align-center">
                            {
                                (lekaloEdit.pltDownload)?(                                        
                                    <img src="./img/lekalo-plt.svg" alt="" />
                                ): (
                                    <div>plt</div>
                                )
                            }
                        </label>
                    </div>
                </div>
                <label htmlFor="" className='moderator__popup-label'>
                    Название
                </label>
                <input type="text" name='Name' defaultValue={`${(edit)? moderatorCatalogLekaloCheck.name: ''}`} placeholder='Front, Back...' className="moderator__popup-input name" onInput={(event)=>{
                    setInform({...inform, name: event.target.value})
                }}/>

                <label htmlFor="" className='moderator__popup-label'>
                    Размеры
                </label>
                <div className="moderator__popup-lekalo-edit-size-blocks d-flex justify-between">
                    <div className="moderator__popup-input size d-flex align-center">
                        <div className='moderator__popup-input-title'>Ш:</div>
                        <input name='Width' step='0.1' type="number" defaultValue={(edit)? moderatorCatalogLekaloCheck.lekaloWidth: null} className="moderator__popup-input zero height" onInput={(event)=>{
                            setInform({...inform, lekaloWidth: event.target.value})
                        }}/>
                    </div>
                    <div className="moderator__popup-input size d-flex align-center">
                        <div className='moderator__popup-input-title'>В:</div>
                        <input name='Height' step='0.1' type="number" defaultValue={(edit)? moderatorCatalogLekaloCheck.lekaloHeight: null} className="moderator__popup-input zero width" onInput={(event)=>{
                            setInform({...inform, lekaloHeight: event.target.value})
                        }}/>
                    </div>
                </div>
                <div className='popup__error'>{error}</div>
                <div className={`moderator__popup-mailing ${(checkBox)? 'active': ''}`} onClick={()=>{
                    setCheckBox(!checkBox)
                }}>
                    Отправить рассылку о добавлении
                </div>                
                <button type='button' onClick={()=>{
                    postData(document.querySelector('.moderator__lekalo-form'))
                }} className={`moderator__popup-save ${(clickAddBtn)? 'deactive': ''}`}>
                    {(edit)? "Сохранить изменение": "Сохранить"}
                </button>
            </form>
                                
        </div>
	)
}
export default LekaloEdit;