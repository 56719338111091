import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
// import {setPopupOpenBlock} from '../../../redux/actions/popupBlocks';
import {setModeratorNav} from '../../../redux/actions/navigation';
import { fetchModeratorHistoryChanges } from '../../../redux/actions/moderatorData';

import './HistoryChanges.scss';

import LekaloIcon from '../../../img/lekalo-icon.svg';


const HistoryChanges = () => {

    // const { openBlock } = useSelector(({ popupBlocks }) => popupBlocks);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(setModeratorNav('HistoryChanges'))
        dispatch(fetchModeratorHistoryChanges())
    }, [])

    const {moderatorHistoryChanges} = useSelector(({moderatorData})=> {
        return {
            moderatorHistoryChanges: moderatorData.moderatorHistoryChanges,
        }
    })

    const refreshLekalo = async(id) =>{
        let response = await fetch(`/Moderator/RecoveryHistory?id=${id}`, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
        })
        if (response.ok === true) {
            const data = await response.json();
            // console.log(data)
            dispatch(fetchModeratorHistoryChanges())
        }
        
    }

    const addTableItem = ({lekalo, statusColor, status, path, date, id}, key) =>{
        return(
            <div className={`moderator__history-changes-line d-flex align-center justify-between moderator__history-changes-line-${id}`} key={key}>
                <div className="moderator__history-changes-lekalo d-flex align-center">
                    <img src={LekaloIcon} alt="" className="moderator__history-changes-lekalo-icon"/>
                    <div>
                        <span className="d-block">{lekalo}</span>
                        <div className="moderator__buttons-history-changes-list-item-name-path">
                            {path}
                        </div>
                    </div>
                    
                </div>
                <div className="moderator__history-changes-status align-center d-flex">
                    <div className={`moderator__history-changes-status-point ${statusColor}`}></div>
                    <div className={`moderator__history-changes-status-title ${statusColor}`}>{status}</div>
                </div>
                <div className="moderator__history-changes-date">{date}</div>
                <img className={`moderator__history-changes-refresh-icon ${(statusColor === 'grey')? 'opacity': ''}`} src="/img/refresh-icon.svg" alt="" onClick={() => refreshLekalo(id)}/>             
            </div>
        )
    }

	return (
        <React.Fragment>
			<div className="moderator__title">История</div>
			<div className="moderator__history-changes">
                <div className="moderator__history-changes-title">История изменений</div>
                <div className="moderator__history-changes-table">
                    <div className="moderator__history-changes-table-scrollX">
                        <div className="moderator__history-changes-line title d-flex align-center justify-between">
                            <div className="moderator__history-changes-lekalo">Название</div>
                            <div className="moderator__history-changes-status">Статус</div>
                            <div className="moderator__history-changes-date">Дата изменения</div>
                            <div className="moderator__history-changes-refresh-icon none"></div>
                        </div>
                        <div className="moderator__history-changes-table-scroll">
                            {
                                moderatorHistoryChanges.map((item, key) => addTableItem(item, key))
                            }
                        </div>
                    </div>
                </div>
			</div>
		</React.Fragment>
	)
}
export default HistoryChanges;