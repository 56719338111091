import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFranchiserWarehousePointsListCheck, setFranchiserWarehouseTransfer } from '../../../../redux/actions/franchiserCheckData';
import { fetchFranchiserWarehousePointsMold, setFranchiserWarehousePointsMoldEdit } from '../../../../redux/actions/franchiserData';
import { setFranchisesNav } from '../../../../redux/actions/navigation';
import { setFranchisersWarehousePopupMenuPage, setPopupBackgroundFunc, setPopupOpenBlock } from '../../../../redux/actions/popupBlocks';
import './index-2.0.scss';
import { setPopupOpenBlockEvent } from './../../../../redux/actions/popupBlocks';

const LekalsTop = () => {

    const dispatch = useDispatch();
    let {pointsMoldList, checkPoint, openBlock, franchiserWarehousePopupMenuPage} = useSelector(({franchiserData, franchiserCheckData, popupBlocks})=> {
        return {
            pointsMoldList: franchiserData.franchiserWarehousePointsMold,
            checkPoint: franchiserCheckData.franchiserWarehousePointsListCheck,
            openBlock: popupBlocks.openBlock,
            franchiserWarehousePopupMenuPage: popupBlocks.franchiserWarehousePopupMenuPage,
        }
    })

    let [tablePageNum, setTablePageNum] = useState(0)
    const [envelopeEditCount, setEnvelopeEditCount] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(()=>{
        dispatch(fetchFranchiserWarehousePointsMold(checkPoint.id))
    }, [])

    const fetchGet = async(url, query='')=>{
        const response =  await fetch(url + query, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            }
        })
        if (response.ok === true) {
            const dat = await response.json();
            return dat.value
        }
    }

    const EnvelopeWriteOffFunc = (filmId, id, type) =>{
        if(envelopeEditCount > 0 && envelopeEditCount !== ''){
            let num = (type === 'minus')? -1: 1;
            fetchGet(`/francheaser/EditFilmAmount?filmId=`, `${id}&count=${+envelopeEditCount * num}&placeId=${checkPoint.id}`).then((dat)=>{
                if(dat.isSuccess){
                    setEnvelopeEditCount('')
                    dispatch(setFranchiserWarehousePointsMoldEdit({id: id, amount: dat.resultObject}))
                    dispatch(setPopupOpenBlock(''))
                    dispatch(setPopupOpenBlockEvent(''))
                    dispatch(setFranchisersWarehousePopupMenuPage('main'))
                    setErrorMessage('')
                }
                else{
                    setErrorMessage('Недостаточно пленок для переноса*')
                    setTimeout(()=>{setErrorMessage('')}, 5000)
                }
            })
        }
    }

    const addLekalsTopItem = ({id, code, amount, filmId}, key) =>{
        return(
            <div className={`franchises-leaders__lekals-top-line d-flex align-center justify-between franchiser__scroll-warehouse-points-mold-list-line-${id}`} key = {key}>
                <div className="franchises-leaders__lekals-top-code">#{code}</div>
                <div className={`franchises-leaders__lekals-top-coll ${(amount < 30)? 'red': ''}`}>{amount}шт</div>
                <img className="franchises-leaders__lekals-top-transfer title popup-open-button" src="/img/mold-transfer-icon.svg" alt="" onClick={()=>{
                    dispatch(setPopupBackgroundFunc(true))
                    dispatch(setPopupOpenBlock('TransferMold'))
                    dispatch(setFranchiserWarehouseTransfer({id: checkPoint.id, select: checkPoint.name, moldCode: code, moldId: id}))}
                }/>

                <div className="franchises__warehouse-table-menu-container d-flex align-center justify-center">
                    <div className='franchises__warehouse-table-menu-button popup-open-button' onClick={(event) => {
                        dispatch(setFranchisersWarehousePopupMenuPage('main'))
                        dispatch(setPopupOpenBlock((openBlock === `franchiserWarehousePointTableElemMenu_${id}`)? "": `franchiserWarehousePointTableElemMenu_${id}`))
                        dispatch(setPopupOpenBlockEvent(event.target))
                        setEnvelopeEditCount('')
                    }}></div>
                    <div className={`franchises__warehouse-table-menu-popup ${franchiserWarehousePopupMenuPage} franchiserWarehousePointTableElemMenu_${id} ${(openBlock === `franchiserWarehousePointTableElemMenu_${id}`)? "active": ""}`}>
                        <div className={`franchises__warehouse-table-menu-popup-step main ${(franchiserWarehousePopupMenuPage === 'main')? 'open': 'close'}`}>
                            <div className='franchises__warehouse-table-menu-popup-item d-flex align-center' onClick={() => dispatch(setFranchisersWarehousePopupMenuPage('writeoff'))}>
                                <img className='franchises__warehouse-table-menu-popup-item-icon' src="/img/warehouse-writeoff-envelope-icon.svg" alt="" />
                                <div className='franchises__warehouse-table-menu-popup-item-title'>Списать количество</div>
                            </div>

                            <div className='franchises__warehouse-table-menu-popup-line'></div>
                            
                            <div className='franchises__warehouse-table-menu-popup-item d-flex align-center' onClick={() => dispatch(setFranchisersWarehousePopupMenuPage('addedEnvelope'))}>
                                <img className='franchises__warehouse-table-menu-popup-item-icon' src="/img/add-envelope-icon-ball.svg" alt="" />
                                <div className='franchises__warehouse-table-menu-popup-item-title'>Добавить количество</div>
                            </div>
                        </div>

                        <div className={`franchises__warehouse-table-menu-popup-step writeoff ${(franchiserWarehousePopupMenuPage === 'writeoff')? 'open': 'close'}`}>
                            <div className='franchises__warehouse-table-menu-popup-item title d-flex align-center'>
                                <img className='franchises__warehouse-table-menu-popup-item-icon' src="/img/warehouse-envelope-popup-steps-title-icon.svg" alt="" />
                                <div className='franchises__warehouse-table-menu-popup-item-title'>Списать количество</div>
                            </div>
                            <div className='franchises__warehouse-table-menu-popup-item-input-container'>
                                <input className='franchises__warehouse-table-menu-popup-item-input' defaultValue={envelopeEditCount} type="number" placeholder='Введите количество' onInput={(event)=>{
                                    setEnvelopeEditCount(event.target.value)
                                }}/>
                            </div>                            
                            <div className='franchises__warehouse-table-menu-popup-button-container d-flex justify-between'>
                                <div className='franchises__warehouse-table-menu-popup-button red' onClick={()=>{EnvelopeWriteOffFunc(filmId, id, 'minus')}}>Списать</div>
                                <div className='franchises__warehouse-table-menu-popup-button' onClick={() => {
                                    dispatch(setFranchisersWarehousePopupMenuPage('main'))
                                    setEnvelopeEditCount('')
                                }}>Отмена</div>
                            </div>
                        </div>

                        <div className={`franchises__warehouse-table-menu-popup-step addedEnvelope ${(franchiserWarehousePopupMenuPage === 'addedEnvelope')? 'open': 'close'}`}>
                            <div className='franchises__warehouse-table-menu-popup-item title d-flex align-center'>
                                <img className='franchises__warehouse-table-menu-popup-item-icon' src="/img/warehouse-envelope-popup-steps-title-icon.svg" alt="" />
                                <div className='franchises__warehouse-table-menu-popup-item-title'>Добавить количество</div>
                            </div>
                            <div className='franchises__warehouse-table-menu-popup-item-input-container'>
                                <input className='franchises__warehouse-table-menu-popup-item-input' defaultValue={envelopeEditCount} type="number" placeholder='Введите количество' onInput={(event)=>{
                                    setEnvelopeEditCount(event.target.value)
                                }}/>
                            </div>                            
                            <div className='franchises__warehouse-table-menu-popup-button-container d-flex justify-between'>
                                <div className='franchises__warehouse-table-menu-popup-button blue' onClick={()=>{EnvelopeWriteOffFunc(filmId, id, 'plus')}}>Добавить</div>
                                <div className='franchises__warehouse-table-menu-popup-button' onClick={() => {
                                    dispatch(setFranchisersWarehousePopupMenuPage('main'))
                                    setEnvelopeEditCount('')
                                }}>Отмена</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const pageButtons = (button) =>{
        let pet = tablePageNum
        if(button == "back"){
            setTablePageNum(tablePageNum-1)
        }
        else{
            setTablePageNum(tablePageNum+1)
        }
        // console.log(pet)
    }

	return (
		<div className="franchises-leaders__warehouse-right">
            <div className="franchises-leaders__lekals-top-top d-flex justify-between">
                <div className='franchises-leaders__lekals-top-top-back d-flex align-center' onClick={()=>{
                    dispatch(setFranchisesNav('Warehouse'))
                    dispatch(setFranchiserWarehousePointsListCheck({ id: 0,  name: '', amount: 0}))
                }}> <img src="../../../../img/arrow-left.svg" alt="" />{checkPoint.name}</div>
                <div className="franchises-leaders__lekals-top-top-transfer-btn d-flex justify-between align-center" onClick={()=>{
                    dispatch(setPopupBackgroundFunc(true))
                    dispatch(setPopupOpenBlock('TransferMold'))
                    dispatch(setFranchiserWarehouseTransfer({id: checkPoint.id, select: checkPoint.name, moldCode: '', moldId: 0}))
                }}>
                    <div>Перенос</div>
                    <img className="franchises-leaders__lekals-top-top-transfer-btn-icon" src="/img/transfer-grey-icon.svg" alt="" />
                </div>
            </div>
            <div className="franchises-leaders__lekals-top-line title d-flex align-center justify-between">
                <div className="franchises-leaders__lekals-top-code title">Артикул</div>
                <div className="franchises-leaders__lekals-top-coll title blue">Количество</div>
                <img className="franchises-leaders__lekals-top-transfer none" src="/img/mold-transfer-icon.svg" alt="" />
                <div className='franchises__warehouse-table-menu-button none'></div>
                <div className='franchises-leaders__table-error'>{errorMessage}</div>
            </div>
            <div className='franchises-leaders__lekals-top-scroll'>
                <div className="franchises-leaders__lekals-top-list franchiser__warehouse-points-mold-list">
                    {
                        // lekalsTop.map((item, key) => addLekalsTopItem(item, key))
                        (window.innerWidth > 700)?(

                            pointsMoldList.map((item, key) => addLekalsTopItem(item, key))

                         ):(
                             (tablePageNum * 5 <= pointsMoldList.length)?(
                                pointsMoldList.slice(tablePageNum * 5, tablePageNum * 5 + 5).map((item, key) => addLekalsTopItem(item, key))
                             ):(
                                pointsMoldList.slice(tablePageNum * 5).map((item, key) => addLekalsTopItem(item, key))
                             )
                         )
                    }
                </div>
            </div>
            {
                (window.innerWidth < 700)?(
                    <div className='franchises-leaders__points-sale-table-buttons d-flex justify-between'>
                        <div className={`franchises-leaders__points-sale-table-buttons-item back ${(tablePageNum > 0)? 'active': ''}`} onClick={()=>{pageButtons("back")}}></div>
                        <div className={`franchises-leaders__points-sale-table-buttons-item forward ${(tablePageNum === 0 && pointsMoldList.length > 5 || pointsMoldList.length % tablePageNum > 0)? 'active': ''}`} onClick={()=>{pageButtons("forward")}}></div>
                    </div>
                ): null
            }
        </div>
	)
}

export default LekalsTop;