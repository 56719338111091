const initialState = {
    url: 'https://bswork.pro/'   
  };
  
  const proxy = (state = initialState, action) => {
    if (action.type === 'SET_PROXY_URL') {
      return {
        ...state,
        role: action.payload,
      };
    }
    return state;
  };
  
  export default proxy;