import React, {useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { setPopupBackgroundFunc, setPopupOpenBlock} from '../../../../redux/actions/popupBlocks';
import calendar from '../../../../img/calendar-icon.svg'
import MaskedInput from '../../../MaskedInput/MaskedInput';
import { fetchModeratorPoints } from '../../../../redux/actions/moderatorData';

const Mailing = ({connection}) => {
    const dispatch = useDispatch()
    const [list, setList] = useState(false)

    let [checkPointFull, setCheckPointFull] = useState(true)
    const [error, setError] = useState('') 

    let [inform, setInform] = useState({
        AdressTo: [],
        Title: '',
        Date: new Date().toLocaleDateString()
    })
    const [clickAddBtn, setClickAddBtn] = useState(false)

    const {moderatorPoints} = useSelector(({moderatorData})=> {
        return {
            moderatorPoints: moderatorData.moderatorPoints,
        }
    })

    useEffect(()=>{
        dispatch(fetchModeratorPoints())
    }, [])

    const addListItem = ({id, name}, key) => {
        return(
            <div className={`moderator__popup-point-select-list-item ${(checkPointFull || inform.AdressTo.indexOf(id) !== -1)? 'active': ''}`} key={key} onClick={()=>{
                if(inform.AdressTo.indexOf(id) !== -1){
                    let perem = inform.AdressTo;
                    perem.splice(perem.indexOf(id), 1)
                    setInform({...inform, AdressTo: perem})
                }
                else{                    
                    if(inform.AdressTo.length === moderatorPoints.length-1){
                        setCheckPointFull(true)
                    }
                    setInform({...inform, AdressTo: [...inform.AdressTo, id]})
                }
            }}>
                {name}
            </div>
        )
    }

    const sendMessage = async(event, message) => {
        event.preventDefault();
        let data = message.Date
        data = data.split('.')
        if(!clickAddBtn && message.Title !== '' && message.Date !== '' && (message.AdressTo.length !== 0 || checkPointFull)){
            if(+data[0] <= 31 && +data[1] <= 12){
                setClickAddBtn(true)
                data = new Date(data[2], data[1]-1, data[0], 8, 0)
                try {
                    setClickAddBtn(false)
                    setError('')
                    let obj = {
                        Title: message.Title, 
                        Points: (checkPointFull)? []: message.AdressTo, 
                        DeadLine: data
                    }
                    // console.log(obj)
                    const response =  await fetch('/Moderator/SetNotification', {
                        method: 'POST', 
                        headers: {
                            "Accept": "application/json",
                            'Content-Type': 'application/json',
                            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                        },
                        body: JSON.stringify(obj)
                    })
                    if (response.ok === true) {
                        const dat = await response.json();
                        // console.log(dat.value.resultObject)                
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                        await connection.invoke("SendModerator", dat.value.resultObject);         
                    }
                    // await connection.invoke("SendModerator", {Title: message.Title, AdressTo: (checkPointFull)? [0]: message.AdressTo, Date: data, HistoryStatusId: 7, NoTypeId: 2});
                } catch (e) {
                    setClickAddBtn(false)
                //   console.log(e);
                }
            }
            else{
                setError('Некорректная дата*')
            }
        }
        else if(!clickAddBtn){
            setError('Необходимо заполнить все поля*')
        }        
      }
      const handleChange = (event) => {
        setInform({
          ...inform, Date: event.target.value
        });
      }
	return (
            <form className="moderator__popup-container" onSubmit={(event) => sendMessage(event, inform)}>
                <div className="moderator__popup-top d-flex justify-between align-center add-leaders">
                    <div className="popup__title green">Рассылка</div>
                    <div className="popup__close-btn" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                    }}></div>
                </div>
                <label htmlFor="" className='moderator__popup-label'>Текст рассылки</label>
                <div className="moderator__popup-description-input-container">
                    <textarea name="" id="" className="moderator__popup-description-input" defaultValue=''
                    onInput={(event)=>{
                        setInform({...inform, Title: event.target.value})
                    }}>
                                                
                    </textarea>
                </div>
                <div className="moderator__popup-input-container d-flex justify-between">
                    <div className="moderator__popup-date">
                        <label htmlFor="" className='moderator__popup-label'>Дата</label>
                        <div className="moderator__popup-date-container d-flex align-center">
                            <img src={calendar} alt="" className="moderator__popup-date-icon" />
                            <MaskedInput
                                className='moderator__popup-date-input'
                                mask="99.99.9999"
                                defaultValue={inform.Date}
                                placeholder={'12.12.2022'}
                                onChange={handleChange}
                            />                           
                        </div>
                    </div>

                    <div className="moderator__popup-point">
                        <label htmlFor="" className='moderator__popup-label'>Точка</label>
                        <div className="moderator__popup-point-select-container d-flex align-center">
                            <div className={`moderator__popup-point-select ${(list)? 'active': ''}`} onClick={()=>{
                                    setList(!list)
                                }}>
                                Все точки
                            </div>                            
                            <div className={`moderator__popup-point-select-list ${(list)? 'active': ''}`}>
                                <div className="moderator__popup-point-select-list-scroll">
                                <div className={`moderator__popup-point-select-list-item ${(checkPointFull)? 'active': ''}`} onClick={()=>{
                                    setCheckPointFull(!checkPointFull)
                                }}>
                                    Все точки
                                </div>
                                    {
                                        moderatorPoints.map((item, key) => addListItem(item, key))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='popup__error'>{error}</div>
                <button type='submit' className={`moderator__popup-save ${(clickAddBtn)? 'deactive': ''}`}>Отправить</button>                    
            </form>
	)
}
export default Mailing;