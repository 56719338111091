export const setModeratorComplaintsCheck = (item) => ({
    type: 'SET_MODERATOR_COMPLAINTS_CHECK',
    payload: item
});

export const setModeratorFolderCheck = (item) => ({
    type: 'SET_MODERATOR_FOLDER_CHECK',
    payload: item
});

export const setModeratorArkhiveLoadingFolderCheck = (item) => ({
    type: 'SET_MODERATOR_ARKHIVE_LOADING_FOLDER_CHECK',
    payload: item
});

export const setModeratorCatalogLekaloCheck = (item) => ({
    type: 'SET_MODERATOR_CATALOG_LEKALO_CHECK',
    payload: item
});

export const setModeratorLeaderCheck = (item) => ({
    type: 'SET_MODERATOR_LEADER_CHECK',
    payload: item
});
export const setModeratorLeaderEditCheck = (item) => ({
    type: 'SET_MODERATOR_LEADER_EDIT_CHECK',
    payload: item
});

export const setModeratorPointEditCheck = (item) => ({
    type: 'SET_MODERATOR_POINT_EDIT_CHECK',
    payload: item
});

export const setModeratorDeliteCheck = (item) => ({
    type: 'SET_MODERATOR_DELITE_CHECK',
    payload: item
});

export const setModeratorArhiveLoadCheck = (item) => ({
    type: 'SET_MODERATOR_ARHIVE_LOAD_FOLDER_CHECK',
    payload: item
});

export const setModeratorFolderDeliteLoadingCheckId = (item) => ({
    type: 'SET_MODERATOR_FOLDER_DELITE_LOADING_CHECK_ID',
    payload: item
});

export const setModeratorFolderDeliteLoadingCheckIdDelete = (item) => ({
    type: 'SET_MODERATOR_FOLDER_DELITE_LOADING_CHECK_ID_DELETE',
    payload: item
});