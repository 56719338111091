import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import LekaloEdit from './LekaloEdit/LekaloEdit'
import AddLeaders from './AddLeaders/AddLeaders'
import AddPoints from './AddPoints/AddPoints'
import ComplaintsDetail from './ComplaintsDetail/ComplaintsDetail'
import Mailing from './Mailing/Mailing'
import DelitePopup from './DelitePopup/DelitePopup'
import { setPopupBackgroundFunc, setPopupOpenBlock } from '../../../redux/actions/popupBlocks';

import './index.scss';


const Popup = ({connection}) => {

    const dispatch = useDispatch()

    const {popupBack, openBlock} = useSelector(({ popupBlocks})=> {
        return {
            openBlock: popupBlocks.openBlock,
            popupBack: popupBlocks.popupBack
        }
    })

    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target){
            dispatch(setPopupBackgroundFunc(false))
            dispatch(setPopupOpenBlock(''))
        }
    }

	return (
        <div className={`popup__back ${popupBack}`}>
            <div className="popup__back-wrap d-flex justify-center align-center" onClick={(event)=> ClosePopup(event)}>
                {
                    (openBlock === "LekaloEdit" || openBlock === 'LekaloAdd')? <LekaloEdit connection={connection} edit={openBlock === "LekaloEdit"}/>
                    :(openBlock === 'AddLeaders')? <AddLeaders/>
                    :(openBlock === 'AddPoints')? <AddPoints/>
                    :(openBlock === 'ComplaintsDetail')? <ComplaintsDetail/>
                    :(openBlock === 'Maling')? <Mailing connection={connection}/>
                    :(openBlock === 'Delite')? <DelitePopup/>
                    : null
                }
            </div>
        </div>
	)
}
export default Popup;