import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setPopupBackgroundFunc, setPopupOpenBlock, setPopupOpenBlockEvent } from '../../../redux/actions/popupBlocks';
import {setModeratorArhiveLoadCheck, setModeratorCatalogLekaloCheck, setModeratorDeliteCheck, setModeratorFolderCheck} from '../../../redux/actions/moderatorCheckData';
import { fetchModeratorCatalog, setModeratorCatalog, setModeratorCatalogEdit } from '../../../redux/actions/moderatorData';

import './Catalog.scss';
import AddFolderIcon from '../../../img/add-folder-icon.svg'
import AddLekaloIcon from '../../../img/add-lekalo-icon.svg'
import PathIcon from '../../../img/moderator-path-icon.svg'
import { setModeratorCatalogDelete } from './../../../redux/actions/moderatorData';
import { setModeratorArkhiveLoadingFolderCheck } from './../../../redux/actions/moderatorCheckData';
import { setModeratorNav } from '../../../redux/actions/navigation';


const Catalog = () => {
        
    const {moderatorCatalog, moderatorFolderCheck, moderatorFolderDeliteLoadingCheckId, moderatorArkhiveLoadingFolderCheck, openBlock} = useSelector(({moderatorData, moderatorCheckData, popupBlocks})=> {
        return {
            moderatorCatalog: moderatorData.moderatorCatalog,
            moderatorFolderCheck: moderatorCheckData.moderatorFolderCheck,
            moderatorArkhiveLoadingFolderCheck: moderatorCheckData.moderatorArkhiveLoadingFolderCheck,
            moderatorFolderDeliteLoadingCheckId: moderatorCheckData.moderatorFolderDeliteLoadingCheckId,
            openBlock: popupBlocks.openBlock
        }
    })

    useEffect(()=>{
        dispatch(fetchModeratorCatalog(moderatorFolderCheck))
        dispatch(setModeratorNav("Catalog"))
        sessionStorage.setItem('moderatorNav', 'Catalog')
    }, [])

    const dispatch = useDispatch()

    const [contextMenuFolder, setContextMenuFolder] = useState(0)
    const [contextMenuLekalo, setContextMenuLekalo] = useState(0)
    const [lekaloArchiveLoad, setLekaloArchiveLoad] = useState('')
    const [lekaloArchiveLoadProgress, setLekaloArchiveLoadProgress] = useState({
        default: 0,
        realTime: 0
    })

    const [lekalsLoadProgress, setLekalsLoadProgress] = useState({
        result: 0,
        count: 0
    })
    
    const [file, setFile] = useState('')

    const [editFolder, setEditFolder] = useState({
        open: false,
        status: false,
        name: '_______',
        id: 0,
        elem: ''
    })

    const [addFolderEdit, setAddFolderEdit] = useState({
        status: false,
        name: '',
        id: 0,
        elem: ''
    })

    const [contextMenu, setContextMenu] = useState(false)

    const [addFolder, setAddFolder] = useState(false)

    const [loadingStopButton, setLoadingStopButton] = useState(false)
    const [downloadLekalsArchiveStep, setDownloadLekalsArchiveStep] = useState('send')

    const loadingPauseButtonRef = useRef()
    const loadingStopButtonRef = useRef()
    const fileInput = useRef()

    useEffect(()=>{
        if(downloadLekalsArchiveStep === 'send' && moderatorArkhiveLoadingFolderCheck === moderatorFolderCheck) dispatch(fetchModeratorCatalog(moderatorFolderCheck))
    }, [downloadLekalsArchiveStep])

    useEffect(()=>{
        if(addFolder) scrollToComplaints()
    }, [addFolder])

    function scrollTo(element, to, duration) {
        // console.log(element, to)
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;
            
        var animateScroll = function(){        
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }
    
    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };

    const scrollToComplaints = () =>{
        let elem = document.querySelector(`.moderator__catalog-folder.add-folder-block`);
        scrollTo(document.querySelector('.moderator__catalog-container'), +elem.offsetTop - +document.querySelector('.moderator__catalog-container').offsetTop, 500)
    }

    const fetchGet = async(url, query='')=>{
        const response =  await fetch(url + query, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            }
        })
        if (response.ok === true) {
            const dat = await response.json();
            return dat.value
        }
        else return 0
    }
    
    const sendArchiveFetch = async(file, fileId, startByte, endByteId)=>{
        const response =  await fetch("/Moderator/LoaderFile", {
            method: 'POST',  
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
            body: JSON.stringify({Data: file.slice(startByte, endByteId), Id: fileId})
        })
        if (response.ok === true) {
            const dat = await response.json();
            return lekaloArchiveLoad
        }
        else return 0
    }
    const reader = new FileReader();
    const uploadFileReader = (event) => {
        const files = event.target.files;
        const file = files[0];
        if(file !== undefined){
            reader.readAsArrayBuffer(file);
            reader.addEventListener('load', (event) => {
                setFile({fileId: `${file.name}-${file.size}-${+file.lastModifiedDate}`, size: file.size, file: event.target.result})
                sendArchivePost(event.target.result, `${file.name}-${file.size}-${+file.lastModifiedDate}`, file.size)
            });
        }        
    }

    const sendArchivePostRecurse = (file, startByte, fileId, size) =>{
        if(loadingPauseButtonRef.current && !loadingStopButtonRef.current.classList.contains('active')){
            let endByteId = (size - startByte < 100000)? startByte + (size - startByte): startByte + 100000
            sendArchiveFetch(file, fileId, startByte, endByteId).then(()=>{
                setLekaloArchiveLoadProgress({...lekaloArchiveLoadProgress, default: endByteId, realTime: (endByteId / (size / 100)).toFixed(1)})
                // console.log(startByte, endByteId, size)
                if(endByteId < size-1){
                    sendArchivePostRecurse(file, endByteId, fileId, size)
                }
                else{
                    // dispatch(setPopupOpenBlock(''))
                    // dispatch(setPopupOpenBlockEvent(''))
                    fetchGet('/Moderator/TryParse?id=', fileId+`&folderId=${moderatorArkhiveLoadingFolderCheck}`).then((value)=>{
                        if(value !== 0){
                            setDownloadLekalsArchiveStep('download')
                            uploadArchiveProgressFunc()
                            dispatch(setModeratorArhiveLoadCheck(0))
                            sessionStorage.setItem('ModeratorArhiveLoadingFolderCheckId', 0)
                        }
                    })
                    fileInput.current.value = ''
                }            
            }) 
        }
        else{
            // console.log(size, startByte)
        }
        
    }

    const uploadArchiveProgressFunc = () =>{
        fetchGet('/Moderator/GetImportResult').then((value)=>{
            if(value.resultObject.before < value.resultObject.after){
                setLekalsLoadProgress({...lekalsLoadProgress, result: value.resultObject.before, count: value.resultObject.after})
                setTimeout(() => uploadArchiveProgressFunc(), 300)
            }
            else{
                setLekalsLoadProgress({...lekalsLoadProgress, result: value.resultObject.after, count: value.resultObject.after})
                setTimeout(()=>{
                    dispatch(setPopupOpenBlock(''))
                    dispatch(setPopupOpenBlockEvent(''))
                    setDownloadLekalsArchiveStep('send')
                    setLekaloArchiveLoad('')
                    setFile('')
                }, 500)
            }
        })        
    }

    const sendArchivePost = (file, fileId, size) =>{
        let fileUnitArray = new Int8Array(file)
        fileUnitArray = Array.prototype.slice.call(fileUnitArray);
        fetchGet('/Moderator/GetImportResult').then((value)=>{
            if(value.resultObject.after === value.resultObject.before){
                fetchGet('/Moderator/getLoaderFile?id=', fileId).then(value =>{
                    if(value.resultObject < size){
                        dispatch(setPopupOpenBlock("moderatorLoadingLekals"))
                        dispatch(setPopupOpenBlockEvent(document.querySelector('.moderator__catalog-lekals-archive-download-button')))
                        setLekaloArchiveLoadProgress({...lekaloArchiveLoadProgress, default: value.resultObject, realTime: (value.resultObject / (size / 100)).toFixed(1)})
                        setLekaloArchiveLoad('loading')
                        sendArchivePostRecurse(fileUnitArray, value.resultObject, fileId, size)
                    }
                })
            }
            else{
                dispatch(setPopupOpenBlock("moderatorLoadingLekals"))
                dispatch(setPopupOpenBlockEvent(document.querySelector('.moderator__catalog-lekals-archive-download-button')))
                setDownloadLekalsArchiveStep('download')
                uploadArchiveProgressFunc()
            }
        })
    }

    const ClickPauseAndPlay = () =>{
        if(lekaloArchiveLoad === 'loading'){
            setLekaloArchiveLoad('pause')
        }
        else if(lekaloArchiveLoad === 'pause'){
            setLekaloArchiveLoad('loading')
            sendArchivePost(file.file, file.fileId, file.size)
        }
    }

    const ClickStopButton = () =>{
        // setLoadingStopButton(!loadingStopButton)
        fetchGet('/Moderator/StopLoader?id=', file.fileId)
        dispatch(setPopupOpenBlock(''))
        dispatch(setPopupOpenBlockEvent(''))
        setLekaloArchiveLoad('')
        setFile('')
        fileInput.current.value = ''
    }

    const FolderContextMenu = (id) =>{
        setContextMenuFolder(id);
        setContextMenu({name: 'Iphone 13 Pro max', status: true})
    }

    const LekaloContextMenu = (id) =>{
        setContextMenuLekalo(id);
        setContextMenu({...contextMenu, status: true})  
    }

    const chooseEditFolder = (id, name) =>{
        setEditFolder({...editFolder, id: id, open: true, name: name})
        setContextMenuFolder(0);
        setContextMenuLekalo(0);
        // setTimeout(()=>{
        //     if(!editFolder.status) setEditFolder({...editFolder, status: false})
        //     console.log(editFolder.status)
        // }, 2000)
    }

    const ContextMenuClose = (event) =>{
        if(contextMenu){
            setContextMenuFolder(0)
            setContextMenuLekalo(0)
            setContextMenu({...contextMenu, status: false})
        }

        if(editFolder.elem !== event.target && (editFolder.status && editFolder.open)){            
            if(editFolder.name !== ''){
                dispatch(setModeratorCatalogEdit(editFolder.name, editFolder.id))
                fetchGet('/Moderator/EdiFolder?', `id=${editFolder.id}&title=${editFolder.name}`)
                setEditFolder({status: false, name: '____________________', elem: '', id: 0})
            }
            else{
                alert('Необходимо задать название папки*')
            }
            
        }

        if(addFolderEdit.elem !== event.target && addFolder){            
            if(addFolderEdit.name != ''){                
                post('/Moderator/FolderSet', {ParrentId: moderatorCatalog.id, Name: addFolderEdit.name})
                setAddFolder(false)
                setAddFolderEdit({name: '', elem: '', id: 0})
            }
            else if(!(event.target.classList.contains('moderator__catalog-folder-name'))){
                setAddFolder(false)
                setAddFolderEdit({name: '', elem: '', id: 0})
            }
        }
    }
    const removeFolder = async(id, name)=> {
        let response = await fetch(`/Moderator/GetFolders?id=${id}`, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
        })
        if (response.ok === true) {
            const data = await response.json();
            if(data.value.folderData.length === 0){
                let response = await fetch(`/Moderator/DeleteFolder?id=${id}`, {
                    method: 'GET', 
                    headers: {
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                    },
                })
                if (response.ok === true) {
                    const data = await response.json();
                    if(data.value){
                        // let folderData = moderatorCatalog.folderData;
                        // folderData.splice(position, 1);
                        // dispatch(setModeratorCatalog({...moderatorCatalog, folderData: folderData}));
                        dispatch(setModeratorCatalogDelete(id))
                    }
                }
                                
            }
            else{
                // alert('В папке есть содержимое, ее нельзя удалить')
                dispatch(setModeratorDeliteCheck({id: id, name: name, type: 'Folder'}))
                dispatch(setPopupBackgroundFunc(true))
                dispatch(setPopupOpenBlock('Delite'))
            }  
        }
        else{
          localStorage.setItem('role', '')
        }
    }

    const removeLekalo = async(id, position)=> {
        let response = await fetch(`/Moderator/DeleteLekalo?lekaloId=${id}`, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
        })
        if (response.ok === true) {
            const data = await response.json();
            if(data.value){
                let folderData = moderatorCatalog.folderData;
                folderData.splice(position, 1);
                dispatch(setModeratorCatalog({...moderatorCatalog, folderData: folderData}));
            }
        }
        
    }

    const post = async(url, data) =>{
        const response =  await fetch(url, {
            method: 'POST', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })
        if (response.ok === true) {
            const dat = await response.json();
            if(dat.value.isSuccessful){
                let folder = {
                    name: data.Name,
                    type: 'folder',
                    id: dat.value.result
                }
                dispatch(setModeratorCatalog({...moderatorCatalog, folderData: [...moderatorCatalog.folderData, folder]}))
            }
            // else console.log(dat.value.isSuccessful)
        }
    }

    const AddFolder = ({id, name}, key) =>{
        return(
            <div className={`moderator__catalog-folder ${(moderatorFolderDeliteLoadingCheckId.indexOf(id) !== -1)? 'none-click': ''}}`} onContextMenu={() => FolderContextMenu(id)} key={key}>
                <div className='moderator__catalog-folder-icon' onClick={() => {
                    dispatch(fetchModeratorCatalog(id))
                    dispatch(setModeratorFolderCheck(id))
                    sessionStorage.setItem('ModeratorCheckFolderId', id)
                }}>
                    <div className={`moderator_catalog-ball-spinner-container ${(moderatorFolderDeliteLoadingCheckId.indexOf(id) !== -1)? 'active': ''}`}>
                        <div className="moderator_catalog-ball-spinner">
                            <div className="moderator_catalog-ball-spinner-blob top"></div>
                            <div className="moderator_catalog-ball-spinner-blob bottom"></div>
                            <div className="moderator_catalog-ball-spinner-blob left"></div>                        
                            <div className="moderator_catalog-ball-spinner-blob move-blob"></div>
                        </div>
                    </div>
                </div>
                <textarea readOnly={(id !== editFolder.id)? true:''} className={`moderator__catalog-folder-name ${(id === editFolder.id)? 'active':''}`} value={(id === editFolder.id)? editFolder.name: name} onInput={(event)=>{
                    setEditFolder({...editFolder, name: event.target.value})                    
                }}
                onClick={(event) =>{
                    setEditFolder({...editFolder, elem: event.target, status: true})
                }}></textarea>
                <div className={`moderator__catalog-folder-popup ${(id === contextMenuFolder)? 'active': ''}`}>
                    <div className="moderator__catalog-folder-popup-item edit d-flex align-center justify-between" onClick={() => { 
                        chooseEditFolder(id, name)
                    }}>Переименовать </div>
                    <div className="moderator__catalog-folder-popup-item remove d-flex align-center justify-between" onClick={() => removeFolder(id, name)}>Удалить</div>
                </div>

                
            </div>
        )
    }

    const AddLekalo = ({id, name}, key) =>{
        return(
            <div className='moderator__catalog-lekalo' onContextMenu={() => LekaloContextMenu(id)} key={key}>
                <div className='moderator__catalog-lekalo-icon'></div>
                <div className='moderator__catalog-lekalo-name'>
                    {name}
                </div>
                <div className={`moderator__catalog-folder-popup ${(id === contextMenuLekalo)? 'active': ''}`}>
                    <div className="moderator__catalog-folder-popup-item edit d-flex align-center justify-between" onClick={()=>{
                        dispatch(setModeratorCatalogLekaloCheck({...moderatorCatalog.folderData[key], position: key}))
                        dispatch(setPopupBackgroundFunc(true))
                        dispatch(setPopupOpenBlock('LekaloEdit'))
                    }}>Редактировать </div>
                    <div className="moderator__catalog-folder-popup-item remove d-flex align-center justify-between" onClick={() => removeLekalo(id, key)}>Удалить</div>
                </div>
            </div>
        )
    }

    const AddPathItem = ({name, id}, key) =>{
        return(
            <div className='moderator__catalog-path-item d-flex align-center' key={key} onClick={() =>{
                dispatch(fetchModeratorCatalog(id))
                dispatch(setModeratorFolderCheck(id))
                sessionStorage.setItem('ModeratorCheckFolderId', id)
            }}>
                <img src={PathIcon} alt="" className='moderator__catalog-path-icon' />
                {name}
            </div>
        )
    }
    
    
	return (
		<div className="moderator__catalog" onClick={(event) =>{
            ContextMenuClose(event)
        }}>
			<div className="moderator__title">Лекала</div>
            <div className='moderator__catalog-content'>
                <div className='moderator__catalog-content-top d-flex justify-between align-start'>
                    <div className="moderator__catalog-title">Каталог лекал</div>
                    <div className='d-flex'>
                        {
                            
                            (moderatorCatalog.folderData.length === 0 && moderatorCatalog.id !== 0)?(
                                <React.Fragment>
                                    <div className='moderator__catalog-button add-lekalo d-flex align-center' onClick={()=>{
                                        dispatch(setPopupBackgroundFunc(true))
                                        dispatch(setPopupOpenBlock('LekaloAdd'))
                                    }}>
                                        <img src={AddLekaloIcon} alt="" className="moderator__catalog-button-icon"/>
                                        Добавить лекало
                                    </div>
                                    <div className='moderator__catalog-button add-folder d-flex align-center' onClick={() => {
                                        setAddFolder(true)
                                        // scrollToComplaints()
                                    }}>
                                        <img src={AddFolderIcon} alt="" className="moderator__catalog-button-icon"/>
                                        Создать папку
                                    </div>
                                    <div className={`moderator__catalog-lekals-archive-download ${(lekaloArchiveLoad)? 'active': ''}`}>
                                        <div className='moderator__catalog-lekals-archive-download-button d-flex align-center popup-open-button' onClick={(event)=>{
                                            if(lekaloArchiveLoad){
                                                dispatch(setPopupOpenBlock((openBlock === "moderatorLoadingLekals")? "": "moderatorLoadingLekals"))
                                                dispatch(setPopupOpenBlockEvent(event.currentTarget))
                                            }
                                            else{
                                                dispatch(setModeratorArkhiveLoadingFolderCheck(moderatorFolderCheck))
                                                sessionStorage.setItem('ModeratorArkhiveLoadingCheckFolderId', moderatorFolderCheck)                                         
                                                fileInput.current.click();
                                            }
                                        }}>                                            
                                            <img className='moderator__catalog-lekals-archive-download-icon' src="/img/moderator-download-lekals-icon.svg" alt="" />
                                            <div className={`moderator__catalog-lekals-archive-download-title`}>Загрузить архив</div>
                                        </div>
                                        <div className={`moderator__catalog-lekals-archive-download-process-block moderatorLoadingLekals ${downloadLekalsArchiveStep} ${(openBlock === "moderatorLoadingLekals")? 'active': ''}`} onClick={()=>{}}>
                                            <div className={`moderator__catalog-lekals-archive-download-process-block-step send-leakals-step ${(downloadLekalsArchiveStep === 'send')? 'active': ''}`}>
                                                <div className='moderator__catalog-lekals-archive-download-process-top d-flex justify-end align-center'>
                                                {/* <div className='moderator__catalog-lekals-archive-download-process-top d-flex align-center'> */}
                                                    <div className='moderator__catalog-lekals-archive-download-process-title d-flex align-center'>
                                                        <svg className="spinner" viewBox="0 0 50 50">
                                                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                                        </svg>
                                                        <div>
                                                            Отправлено: {lekaloArchiveLoadProgress.realTime}%
                                                        </div>
                                                    </div>                                           
                                                    <div className='moderator__catalog-lekals-archive-download-process-buttons-container d-flex justify-end'>
                                                    {/* <div className='moderator__catalog-lekals-archive-download-process-buttons-container d-flex justify-end' style={{display: 'none'}}> */}
                                                        <div className='moderator__catalog-lekals-archive-download-process-buttons pause' onClick={ClickPauseAndPlay}>
                                                            {
                                                                (lekaloArchiveLoad === 'loading')? <img ref={loadingPauseButtonRef} className='moderator__catalog-lekals-archive-download-process-buttons-icon' src="/img/moderator-download-pause.svg" alt=""/>
                                                                : <img className='moderator__catalog-lekals-archive-download-process-buttons-icon' src="/img/moderator-download-play.svg" alt=""/>
                                                            }
                                                        </div>
                                                        <div ref={loadingStopButtonRef} className={`moderator__catalog-lekals-archive-download-process-buttons cancel ${(loadingStopButton)? 'active': ''}`} onClick={ClickStopButton}>
                                                            <img  className={`moderator__catalog-lekals-archive-download-process-buttons-icon`} src="/img/moderator-download-stop.svg" alt=""/>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='moderator__catalog-lekals-archive-download-process-progress-bar-container'>
                                                    <div className='moderator__catalog-lekals-archive-download-process-progress-bar' style={{width: `${lekaloArchiveLoadProgress.realTime}%`}}>                                              
                                                    </div>
                                                </div>
                                                <input ref={fileInput} className='moderator__catalog-lekals-archive-download-input' type="file" accept=".zip" onChange={(event)=> uploadFileReader(event)}/>
                                            </div>

                                            <div className={`moderator__catalog-lekals-archive-download-process-block-step download-leakals-step  ${(downloadLekalsArchiveStep === 'download')? 'active': ''}`}>
                                                <div className='moderator__catalog-lekals-archive-download-process-top d-flex align-center'>
                                                    <div className='moderator__catalog-lekals-archive-download-process-title d-flex align-center'>
                                                        <svg className="spinner" viewBox="0 0 50 50">
                                                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                                        </svg>
                                                        <div>
                                                            Загружено: {lekalsLoadProgress.result} из {lekalsLoadProgress.count}
                                                        </div>
                                                    </div>                                           
                                                </div>
                                                <div className='moderator__catalog-lekals-archive-download-process-progress-bar-container'>
                                                    <div className='moderator__catalog-lekals-archive-download-process-progress-bar' style={{width: `${(lekalsLoadProgress.result !== 0)? (lekalsLoadProgress.result / (lekalsLoadProgress.count / 100)).toFixed(): 0}%`}}>                                              
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ):
                            (moderatorCatalog.folderData.length !== 0 && moderatorCatalog.folderData[0].type !== 'folder')?(
                                <div className='moderator__catalog-button add-lekalo d-flex align-center' onClick={()=>{
                                    dispatch(setPopupBackgroundFunc(true))
                                    dispatch(setPopupOpenBlock('LekaloAdd'))
                                }}>
                                    <img src={AddLekaloIcon} alt="" className="moderator__catalog-button-icon"/>
                                    Добавить лекало
                                </div>
                            ):(
                                <React.Fragment>
                                    <div className='moderator__catalog-button add-folder d-flex align-center' onClick={() => {
                                        setAddFolder(true)                                        
                                    }}>
                                        <img src={AddFolderIcon} alt="" className="moderator__catalog-button-icon"/>
                                        Создать папку
                                    </div>
                                    <div className={`moderator__catalog-lekals-archive-download ${(lekaloArchiveLoad)? 'active': ''}`}>
                                        <div className='moderator__catalog-lekals-archive-download-button d-flex align-center popup-open-button' onClick={(event)=>{
                                            if(lekaloArchiveLoad){
                                                dispatch(setPopupOpenBlock((openBlock === "moderatorLoadingLekals")? "": "moderatorLoadingLekals"))
                                                dispatch(setPopupOpenBlockEvent(event.currentTarget))
                                            }
                                            else{
                                                dispatch(setModeratorArkhiveLoadingFolderCheck(moderatorFolderCheck))
                                                sessionStorage.setItem('ModeratorArkhiveLoadingCheckFolderId', moderatorFolderCheck)                                          
                                                fileInput.current.click();
                                            }
                                        }}>                                            
                                            <img className='moderator__catalog-lekals-archive-download-icon' src="/img/moderator-download-lekals-icon.svg" alt="" />
                                            <div className={`moderator__catalog-lekals-archive-download-title`}>Загрузить архив</div>
                                        </div>
                                        <div className={`moderator__catalog-lekals-archive-download-process-block moderatorLoadingLekals ${downloadLekalsArchiveStep} ${(openBlock === "moderatorLoadingLekals")? 'active': ''}`} onClick={()=>{}}>
                                            <div className={`moderator__catalog-lekals-archive-download-process-block-step send-leakals-step ${(downloadLekalsArchiveStep === 'send')? 'active': ''}`}>
                                                <div className='moderator__catalog-lekals-archive-download-process-top d-flex justify-end align-center'>
                                                {/* <div className='moderator__catalog-lekals-archive-download-process-top d-flex align-center'> */}
                                                    <div className='moderator__catalog-lekals-archive-download-process-title d-flex align-center'>
                                                        <svg className="spinner" viewBox="0 0 50 50">
                                                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                                        </svg>
                                                        <div>
                                                            Отправлено: {lekaloArchiveLoadProgress.realTime}%
                                                        </div>
                                                    </div>                                           
                                                    <div className='moderator__catalog-lekals-archive-download-process-buttons-container d-flex justify-end'>
                                                    {/* <div className='moderator__catalog-lekals-archive-download-process-buttons-container d-flex justify-end' style={{display: 'none'}}> */}
                                                        <div className='moderator__catalog-lekals-archive-download-process-buttons pause' onClick={ClickPauseAndPlay}>
                                                            {
                                                                (lekaloArchiveLoad === 'loading')? <img ref={loadingPauseButtonRef} className='moderator__catalog-lekals-archive-download-process-buttons-icon' src="/img/moderator-download-pause.svg" alt=""/>
                                                                : <img className='moderator__catalog-lekals-archive-download-process-buttons-icon' src="/img/moderator-download-play.svg" alt=""/>
                                                            }
                                                        </div>
                                                        <div ref={loadingStopButtonRef} className={`moderator__catalog-lekals-archive-download-process-buttons cancel ${(loadingStopButton)? 'active': ''}`} onClick={ClickStopButton}>
                                                            <img  className={`moderator__catalog-lekals-archive-download-process-buttons-icon`} src="/img/moderator-download-stop.svg" alt=""/>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='moderator__catalog-lekals-archive-download-process-progress-bar-container'>
                                                    <div className='moderator__catalog-lekals-archive-download-process-progress-bar' style={{width: `${lekaloArchiveLoadProgress.realTime}%`}}>                                              
                                                    </div>
                                                </div>
                                                <input ref={fileInput} className='moderator__catalog-lekals-archive-download-input' type="file" accept=".zip" onChange={(event)=> uploadFileReader(event)}/>
                                            </div>

                                            <div className={`moderator__catalog-lekals-archive-download-process-block-step download-leakals-step  ${(downloadLekalsArchiveStep === 'download')? 'active': ''}`}>
                                                <div className='moderator__catalog-lekals-archive-download-process-top d-flex align-center'>
                                                    <div className='moderator__catalog-lekals-archive-download-process-title d-flex align-center'>
                                                        <svg className="spinner" viewBox="0 0 50 50">
                                                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                                        </svg>
                                                        <div>
                                                            Загружено: {lekalsLoadProgress.result} из {lekalsLoadProgress.count}
                                                        </div>
                                                    </div>                                           
                                                </div>
                                                <div className='moderator__catalog-lekals-archive-download-process-progress-bar-container'>
                                                    <div className='moderator__catalog-lekals-archive-download-process-progress-bar' style={{width: `${(lekalsLoadProgress.result !== 0)? (lekalsLoadProgress.result / (lekalsLoadProgress.count / 100)).toFixed(): 0}%`}}>                                              
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                                
                            )
                        }                        
                    </div>
                </div>
                <div className='moderator__catalog-path d-flex'>
                    <div className='moderator__catalog-path-item d-flex align-center' onClick={() =>{
                        dispatch(fetchModeratorCatalog(0))
                        dispatch(setModeratorFolderCheck(0))
                        sessionStorage.setItem('ModeratorCheckFolderId', 0)
                    }}>
                        Каталог
                    </div>
                    {
                        moderatorCatalog.path.map((item, key) => AddPathItem(item, key))
                    }
                </div>

                <div className='moderator__catalog-container d-flex justify-between flex-wrap'>
                    {
                        (moderatorCatalog.folderData.length !== 0 && moderatorCatalog.folderData[0].type !== 'folder')? (
                            moderatorCatalog.folderData.map((item, key) => AddLekalo(item, key))
                        ):(moderatorCatalog.folderData.length !== 0 && moderatorCatalog.folderData[0].type === 'folder')?(
                            moderatorCatalog.folderData.map((item, key) => AddFolder(item, key))
                        ): null
                        
                    }

                    {
                        (addFolder)?(
                            <div className='moderator__catalog-folder add-folder-block'>
                                <div className='moderator__catalog-folder-icon'></div>
                                <textarea className={`moderator__catalog-folder-name active`} onInput={(event)=>{
                                    setAddFolderEdit({...addFolderEdit, name: event.target.value})                    
                                }}
                                onClick={(event) =>{
                                    setAddFolderEdit({...addFolderEdit, elem: event.target, status: true})
                                }}></textarea>

                                {/* <div className='moderator__catalog-folder-loading'></div> */}
                            </div>
                        ): null
                    }

                    {
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((item, key) =>{
                            return(
                                <div className='moderator__catalog-folder hidden' key={key}>
                                </div>
                            )
                        })
                    }

                </div>


            </div>
		</div>
	)
}

export default Catalog; 