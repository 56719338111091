import React, {useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setModeratorLeaderEditCheck } from '../../../../redux/actions/moderatorCheckData';
import { setModeratorLeaders } from '../../../../redux/actions/moderatorData';
import { setPopupBackgroundFunc, setPopupOpenBlock} from '../../../../redux/actions/popupBlocks';
import MaskedInput from '../../../MaskedInput/MaskedInput'

import './index.scss';

const AddLeaders = () => {
    const dispatch = useDispatch()

    const {moderatorLeaders, moderatorLeaderEditCheck, moderatorLeadersPoints} = useSelector(({moderatorData, moderatorCheckData})=> {
        return {
            moderatorLeaders: moderatorData.moderatorLeaders,
            moderatorLeaderEditCheck: moderatorCheckData.moderatorLeaderEditCheck,
            moderatorLeadersPoints: moderatorData.moderatorLeadersPoints
        }
    })

    const reader = new FileReader();
    const [fil, setFile] = useState((moderatorLeaderEditCheck.id !== '')? moderatorLeaderEditCheck.image: '')

    const [inform, setInform] = useState((moderatorLeaderEditCheck.id !== '')? moderatorLeaderEditCheck:
    { 
        name: '',
        address: '',
        photo: '',
        phone: '',
        mail: '',
        login: '',
        password: ''
    })

    const [editInform, setEditInform] = useState({ 
        name: false,
        address: false,
        photo: false,
        phone: false,
        mail: false,
        login: false,
        password: false
    })

    const [clickAddBtn, setClickAddBtn] = useState(false)

    const [error, setError] = useState('') 

    const fetchCheckLogin = async(url, query='')=>{
        const response =  await fetch(url + query, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            }
        })
        if (response.ok === true) {
            const dat = await response.json();
            // console.log(dat)
            return dat.value.resultObject
        }
    }

    const uploadPhoto = (event) => {
        const files = event.target.files;
        const file = files[0];
        setInform({...inform, photo: event.target.files.length !== 0})
        reader.readAsDataURL(file);
        reader.addEventListener('load', (event) => {
            setFile(event.target.result)
            setInform({...inform, photo: true})
            setEditInform({...editInform, photo: true})
        });        
    }

    const postData = (event) =>{
        event.preventDefault();
        if(moderatorLeaderEditCheck.id !== ''){
            editLeader(event)
        }
        else{
            addLeader(event)
        }
    }

    const cutName = (name) =>{
        let cut = name.split(' ')
        if(cut.length === 3){
            return cut[0] + ' ' + cut[1][0] + '.' + ' ' + cut[2][0] + '.'
        }
        else if(cut.length === 2){
            return cut[0] + ' ' + cut[1][0] + '.'
        }
        else return name
    }

    const addLeader = async(event) =>{
        if(!clickAddBtn && inform.name !== '' && inform.address !== '' && inform.mail !== '' && inform.login !== '' && inform.password !== '' && inform.phone !== '' && inform.photo){
            const formData = new FormData(event.target)
            setClickAddBtn(true)
            fetchCheckLogin('/Login/CheckHaveLogin?login=', inform.login).then(async(availability)=>{
                if(!availability){
                    setError("")
                    const response =  await fetch('/Moderator/LeaderSet', {
                        method: 'POST', 
                        headers: {
                            "Accept": "application/json",
                            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                        },
                        body: formData
                    })
                    if (response.ok === true) {
                        const dat = await response.json();
                        let leader = {
                            id: dat.value.result, 
                            name: inform.name, 
                            abbreviatedName: cutName(inform.name),
                            address: inform.address,
                            login: inform.login,
                            image: dat.value.resultObject,
                            password: inform.password, 
                            phone: inform.phone, 
                            mail: inform.mail
                        }
                        
                        setError("")
                        dispatch(setModeratorLeaders([...moderatorLeaders, leader]))    
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                        setClickAddBtn(false)
                    }
                    else{
                        setClickAddBtn(false)
                    }
                }
                else {
                    setClickAddBtn(false)
                    setError("Такой логин уже существует*") 
                }
            })
        }
        else if(!clickAddBtn){
            setError('Необходимо заполнить все поля*')
        }
    }

    const editLeader = async(event) =>{
        let formData = new FormData(event.target)
        if(editInform.name || editInform.address || editInform.photo || editInform.mail || editInform.phone || editInform.login || editInform.password){
            let data = new FormData();
            if(editInform.name) data.append('Name', inform.name)
            if(editInform.address) data.append('Address', inform.address)
            if(editInform.photo) data.append('Photo', formData.get('photo'))
            if(editInform.mail) data.append('Mail', inform.mail)
            if(editInform.phone) data.append('Phone', inform.phone)
            if(editInform.login) data.append('Login', inform.login)
            if(editInform.password) data.append('Password', inform.password)

            data.append('Id', moderatorLeaderEditCheck.id)

            if(!clickAddBtn && inform.name !== '' && inform.address !== '' && inform.phone !== '' && inform.mail !== '' && inform.login !== '' && inform.password !== ''){
                setClickAddBtn(true)
                fetchCheckLogin('/Login/CheckHaveLogin?login=', inform.login).then(async(availability)=>{
                    if(!availability || !editInform.login){
                        setError("")                        
                        const response =  await fetch("/Moderator/LeaderEdit", {
                            method: 'POST', 
                            headers: {
                                "Accept": "application/json",
                                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                            },
                            body: data
                        })
                        if (response.ok === true) {
                            const dat = await response.json();  
                            let leader = {
                                id: moderatorLeaderEditCheck.id, 
                                name: inform.name, 
                                abbreviatedName: cutName(inform.name),
                                address: inform.address,
                                login: inform.login,
                                image:  './' + dat.value.resultObject.split('/').slice(3).join('/'), 
                                password: inform.password, 
                                phone: inform.phone, 
                                mail: inform.mail
                            }
                            setError("")
                            let leaders = moderatorLeaders.slice();
                            leaders[moderatorLeaderEditCheck.key] = leader;
                            dispatch(setModeratorLeaders(leaders))    
                            dispatch(setPopupBackgroundFunc(false))
                            dispatch(setPopupOpenBlock(''))
                            dispatch(setModeratorLeaderEditCheck({id: '', name: '', login: "", password: "", phone: "", mail: "", image: '', address: ''}))
                            setClickAddBtn(false)
                        }
                        else{
                            setClickAddBtn(false)
                        }
                    }
                    else {
                        setClickAddBtn(false)
                        setError("Такой логин уже существует*")
                    }
                })
            }
            else if(!clickAddBtn){
                setError("Все поля должны быть заполнены*")
            }
        }
        else{
            setError("Изменений не найдено*")
        }
    }

	return (
            <div className="moderator__popup-container">
                <div className="moderator__popup-top d-flex justify-between align-center add-leaders">
                    <div className="popup__title orange">{(moderatorLeaderEditCheck.id !== '')? 'Редактирование руководителя': 'Добавление руководителя'}</div>
                    <div className="popup__close-btn" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                        dispatch(setModeratorLeaderEditCheck({id: '', name: '', login: "", password: "", phone: "", mail: "", image: '', address: ''}))
                    }}></div>
                </div>
                <form action="" onSubmit={postData}>
                    <div className="moderator__popup-name-photo-container d-flex justify-between align-center">
                        <input name='photo' id='moderator__popup-photo-input' className='moderator__popup-photo-input' type="file" multiple accept="image/*,image/jpeg" onChange={(event)=> uploadPhoto(event)}/>
                        <label htmlFor="moderator__popup-photo-input" className={`moderator__popup-photo-label d-flex justify-center align-center ${(fil !== '')? 'active': ''}`}>
                            <img className='moderator__popup-photo-label-img' src={fil} alt="" />
                        </label>

                        <div className='moderator__popup-leader-inform'>
                            <label htmlFor="" className='moderator__popup-label mt-0'>ФИО</label>
                            <input name='Name' type="text" defaultValue={(moderatorLeaderEditCheck.id !== '')? moderatorLeaderEditCheck.name: ''} placeholder='Введите ФИО руководителя' className="moderator__popup-input" onInput={(event)=>{
                                setInform({...inform, name: event.target.value})
                                setEditInform({...editInform, name: true})

                            }}/>
                            <label htmlFor="" className='moderator__popup-label'>Адрес</label>
                            <input name='address' type="text" defaultValue={(moderatorLeaderEditCheck.id !== '')? moderatorLeaderEditCheck.address: ''} placeholder='Введите адрес руководителя' className="moderator__popup-input"onInput={(event)=>{
                                setInform({...inform, address: event.target.value})
                                setEditInform({...editInform, address: true})
                            }}/>
                        </div>
                    </div>
                
                    <div className="moderator__popup-input-container d-flex justify-between">
                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Телефон</label>
                            <MaskedInput
                                className='moderator__popup-input'
                                mask="+7(999) 999-99-99"
                                // value={(moderatorLeaderEditCheck.id !== '')? moderatorLeaderEditCheck.phone: inform.phone}
                                defaultValue={(moderatorLeaderEditCheck.id !== '')? moderatorLeaderEditCheck.phone: inform.phone}
                                name = 'phone'
                                placeholder='Введите телефон руководителя'
                                onChange={(event)=>{
                                    setInform({...inform, phone: event.target.value})
                                    setEditInform({...editInform, phone: true})
                                }}
                            />
                        </div> 

                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>E-mail</label>
                            <input name='Mail' type='e-mail' defaultValue={(moderatorLeaderEditCheck.id !== '')? moderatorLeaderEditCheck.mail: ''} placeholder='Введите e-mail руководителя' className="moderator__popup-input" onInput={(event)=>{
                                setInform({...inform, mail: event.target.value})
                                setEditInform({...editInform, mail: true})
                            }}/>
                        </div>   
                    </div>
                    <div className="moderator__popup-input-container d-flex justify-between">
                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Логин</label>
                            <input name='Login' type="text" defaultValue={(moderatorLeaderEditCheck.id !== '')? moderatorLeaderEditCheck.login: ''} placeholder='Введите логин руководителя' className="moderator__popup-input" onInput={(event)=>{
                                setInform({...inform, login: event.target.value})
                                setEditInform({...editInform, login: true})
                            }}/>
                        </div> 

                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Пароль</label>
                            <input name='Password' type="text" defaultValue={(moderatorLeaderEditCheck.id !== '')? moderatorLeaderEditCheck.password: ''} placeholder='Введите пароль руководителя' className="moderator__popup-input" onInput={(event)=>{
                                setInform({...inform, password: event.target.value})
                                setEditInform({...editInform, password: true})
                            }}/>
                        </div>   
                    </div> 
                    <div className='popup__error'>{error}</div>               
                    <button type='submit' className={`moderator__popup-save ${(clickAddBtn)? 'deactive': ''}`}>{(moderatorLeaderEditCheck.id !== '')? 'Сохранить': 'Добавить'}</button>    
                </form>                    
            </div>
	)
}
export default AddLeaders;