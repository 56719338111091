import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setWarehouseNav } from '../../../redux/actions/navigation';
import { setWarehouseHistoryOrderCheck } from '../../../redux/actions/warehouseCheckData';
import { fetchWarehouseOrderHistoryDetailData } from '../../../redux/actions/warehouseData';
import './index.scss';

const OrderHistoryDetail = () => {

    const dispatch = useDispatch();

    const {warehouseOrderHistoryDetailData, warehouseHistoryOrderCheck} = useSelector(({warehouseData, warehouseCheckData})=> {
        return {
            warehouseOrderHistoryDetailData: warehouseData.warehouseOrderHistoryDetailData,
            warehouseHistoryOrderCheck: warehouseCheckData.warehouseHistoryOrderCheck,
        }
    })

    useEffect(()=>{
        dispatch(fetchWarehouseOrderHistoryDetailData(warehouseHistoryOrderCheck.id))
    }, [warehouseHistoryOrderCheck])

    const addTableItem = (elem, key) =>{
        let {id, article, name, count} = elem; 
        return(
            <div className={`warehouse__order-history-detail-line d-flex align-center justify-between warehouse__order-history-detail-line-${id}`} key={key}>
                <div className="warehouse__order-history-detail-article">#{article}</div>
                <div className="warehouse__order-history-detail-envelope-name">{name}</div>
                <div className="warehouse__order-history-detail-envelope-count">{count} шт</div>                 
            </div>
        )
    }

	return (
        <React.Fragment>
            <div className="warehouse__order-history-detail">
                <div className="warehouse__order-history-detail-top d-flex justify-between align-center">
                    <div className="warehouse__order-history-detail-top-back d-flex align-center">
                        <Link className='warehouse__order-history-detail-top-back-icon-container' to='/order-history' onClick={()=>{
                            dispatch(setWarehouseHistoryOrderCheck({id: 0, date: ''}))
                            sessionStorage.setItem('WarehouseHistoryOrderCheck', JSON.stringify({id: 0, date: ''}))
                            dispatch(setWarehouseNav('OrderHistory'))
                            sessionStorage.setItem('warehousePage', 'OrderHistory')
                        }}>
                            <img className='warehouse__order-history-detail-top-back-icon' src="/img/arrow-left-icon.svg" alt="" /> 
                        </Link>                        
                        <div className='warehouse__order-history-detail-top-back-title'>№{warehouseHistoryOrderCheck.id}<span>-{warehouseHistoryOrderCheck.date}</span></div>
                    </div>
                </div>
                <div className='warehouse__order-history-detail-table-scroll'>
                    <div className="warehouse__order-history-detail-line title d-flex align-center justify-between">
                        <div className="warehouse__order-history-detail-article">Артикул</div>
                        <div className="warehouse__order-history-detail-envelope-name">Название пленки</div>
                        <div className="warehouse__order-history-detail-envelope-count">Количество</div>                       
                    </div>
                    <div className="warehouse__order-history-detail-table">
                        {
                            warehouseOrderHistoryDetailData.map((item, key) => addTableItem(item, key))
                        }              
                    </div>
                </div>
            </div>
        </React.Fragment>        
	)
}

export default OrderHistoryDetail;