async function post(url, type, data = null){
    let response = await fetch(url, {
        method: type, 
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    })
    if (response.ok === true) {
        const user = await response.json();
        return user
    }
    else{
        localStorage.setItem('role', '')
      }
    let dat = response.json
    return dat 
}

async function postNotData(url, type){
    let response = await fetch(url, {
        method: type, 
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
        },
    })
    if (response.ok === true) {
        const user = await response.json();
        return user
    }
    let dat = response.json
    return dat 
}

let monthKey = {
    "Январь": 1,
    "Февраль": 2,
    "Март": 3,
    "Апрель": 4,
    "Май": 5,
    "Июнь": 6,
    "Июль": 7,
    "Август": 8,
    "Сентябрь": 9,
    "Октябрь": 10,
    "Ноябрь": 11,
    "Декабрь": 12
}



export const setStaffTableData = (item) => ({
    type: 'SET_STAFF_TABLE_DATA',
    payload: item
});

export const setStaffTableDate = (item) => ({
    type: 'SET_STAFF_TABLE_DATE',
    payload: item
});

export const setStaffTableTotalData = (item) => ({
    type: 'SET_STAFF_TABLE_TOTAL_DATA',
    payload: item
});

export const setStaffButtons = (item) => ({
    type: 'SET_STAFF_BUTTONS_DATA',
    payload: item
});

export const fetchStaffTable = (range) => (dispatch) =>{
    // dispatch(setOwnerHomeGrafLoading(true))
    let data = {
        From: (range.length > 0)? range[0].toLocaleDateString('en-US'): '',
        To: (range.length > 0)? range[1].toLocaleDateString('en-US'): '',
    }

    postNotData(`/worker/tabledata?`+ new URLSearchParams(data), "GET").then((response)=> {
        // console.log(response.value.resultObject.salariesTableDate, response.value.resultObject.salariesTableDate, response.value.resultObject.salariesTableTotalData)
        dispatch(setStaffTableData(response.value.resultObject.salariesTableData))
        dispatch(setStaffTableDate(response.value.resultObject.salariesTableDate))
        dispatch(setStaffTableTotalData(response.value.resultObject.salariesTableTotalData))
    })

}

export const fetchStaffButtonsData = (range, month, year) => (dispatch) =>{
    // dispatch(setOwnerHomeGrafLoading(true))
    let data = {
        month: (month === "Месяц")? 0: monthKey[month], 
        year:  (year === "Год")? 0: year,
        From: (range.length > 0)? range[0].toLocaleDateString('en-US'): '',
        To: (range.length > 0)? range[1].toLocaleDateString('en-US'): '',
    }

    postNotData(`/worker/mainbuutons?`+ new URLSearchParams(data), "GET").then((response)=> {
        // console.log(response.value.resultObject)
        dispatch(setStaffButtons(response.value.resultObject))
    })

}