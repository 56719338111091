const initialState = {
    workZoneCheckLekalo: { 
      id: (sessionStorage.getItem('lekaloCheckId') !== null)? +sessionStorage.getItem('lekaloCheckId') :0,
      src: (sessionStorage.getItem('lekaloCheckSrc') !== null)? sessionStorage.getItem('lekaloCheckSrc') :'',
      name: (sessionStorage.getItem('lekaloCheckName') !== null)? sessionStorage.getItem('lekaloCheckName') :'',
      width: (sessionStorage.getItem('lekaloCheckWidth') !== null)? +sessionStorage.getItem('lekaloCheckWidth') :0,
      height: (sessionStorage.getItem('lekaloCheckHeight') !== null)? +sessionStorage.getItem('lekaloCheckHeight') :0,
      defaultWidth: (sessionStorage.getItem('lekaloCheckDefaultWidth') !== null)? +sessionStorage.getItem('lekaloCheckDefaultWidth') :0,
      defaultHeight: (sessionStorage.getItem('lekaloCheckDefaultHeight') !== null)? +sessionStorage.getItem('lekaloCheckDefaultHeight') :0,
      type: 'add'
    },

    workZoneCheckFolder: (sessionStorage.getItem('CheckFolderId') !== null)? +sessionStorage.getItem('CheckFolderId') :0,

    workZonePopupPrintType: (sessionStorage.getItem('workZonePopupPrintType') !== null)? sessionStorage.getItem('workZonePopupPrintType'): 'interact',

    workZonePlotterConfiguration: {
      pressure: (localStorage.getItem('PlotterConfigurationPressure') !== null)? +localStorage.getItem('PlotterConfigurationPressure') :0,
      speed: (localStorage.getItem('PlotterConfigurationSpeed') !== null)? +localStorage.getItem('PlotterConfigurationSpeed') :0
    },

    workZoneCheckClient: (sessionStorage.getItem('WorkzoneCheckClient') !== null)? JSON.parse(sessionStorage.getItem('WorkzoneCheckClient')):
    {
      id: 0, 
      lekaloId: '',
      date: '', 
      model: '', 
      printType:'', 
      phone: '',
      envelope: '',
      envelopeCount: 1,
      staff: '',
      price: '',   
      guarantee: '',
      comment: '',
      width: '',
      height: '',
      withOutFilm: false,
      formalized: false
    },
    workZoneCheckLastStaff: (sessionStorage.getItem('WorkzoneCheckLastStaff') !== null)? JSON.parse(sessionStorage.getItem('WorkzoneCheckLastStaff')): {id: 0, name: ''},
    workZoneSearch: {
      isActive: false,
      type: '',
      id: 0
    }
};
    
    const workZoneCheckData = (state = initialState, action) => {
      if (action.type === 'SET_WORK_ZONE_CHECK_LEKALO') {
        return {
          ...state,
          workZoneCheckLekalo: action.payload,
        };
      }
      else if (action.type === 'SET_WORK_ZONE_CHECK_LEKALO_RESIZE') {
        return {
          ...state,
          workZoneCheckLekalo: {...state.workZoneCheckLekalo, width: action.payload.width, height: action.payload.height},
        };
      }
      else if (action.type === 'SET_WORK_ZONE_CHECK_CLIENT') {
        sessionStorage.setItem('WorkzoneCheckClient', JSON.stringify({...state.workZoneCheckClient, ...action.payload}))
        return {
          ...state,
          workZoneCheckClient: action.payload,
        };
      }
      else if (action.type === 'SET_WORK_ZONE_CHECK_LAST_STAFF') {
        sessionStorage.setItem('WorkzoneCheckLastStaff', JSON.stringify(action.payload))
        return {
          ...state,
          workZoneCheckLastStaff: {...state.workZoneCheckClient, ...action.payload},
        };
      }
      else if (action.type === 'SET_WORK_ZONE_CHECK_CLIENT_EDIT') {
        sessionStorage.setItem('WorkzoneCheckClient', JSON.stringify({...state.workZoneCheckClient, ...action.payload}))
        return {
          ...state,
          workZoneCheckClient: {...state.workZoneCheckClient, ...action.payload},
        };
      }
      else if (action.type === 'SET_WORK_ZONE_CHECK_CLIENT_ID') {
        sessionStorage.setItem('WorkzoneCheckClient', JSON.stringify({...state.workZoneCheckClient, ...action.payload}))
        // sessionStorage.setItem('WorkzoneCheckClient', JSON.stringify({...state.workZoneCheckClient, id: action.payload.id, model: action.payload.lekalo, lekaloId: action.payload.lekaloId}))
        return {
          ...state,
          workZoneCheckClient: {...state.workZoneCheckClient, ...action.payload},
          // workZoneCheckClient: {...state.workZoneCheckClient, id: action.payload.id, model: action.payload.model, lekaloId: action.payload.lekaloId},
        };
      }
      else if (action.type === 'SET_WORK_ZONE_CHECK_CLIENT_RESET') {
        sessionStorage.setItem('WorkzoneCheckClient', JSON.stringify(action.payload))
        return {
          ...state,
          workZoneCheckClient: action.payload,
        };
      }
      else if (action.type === 'SET_WORK_ZONE_PLOTTER_CONFIGURATION') {
        return {
          ...state,
          workZonePlotterConfiguration: action.payload,
        };
      }
      else if (action.type === 'SET_WORK_ZONE_POPUP_PRINT_TYPE') {
        return {
          ...state,
          workZonePopupPrintType: action.payload,
        };
      }
      else if (action.type === 'SET_WORK_ZONE_SEARCH') {
        return {
          ...state,
          workZoneSearch: action.payload,
        };
      }
      return state;
    };
    
    export default workZoneCheckData;