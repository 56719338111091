const initialState = {
  ownerHomePoints: [],
  ownerHomePointsId: [],
  ownerHomeLeaders: [],
  ownerWarehousePurchase: [],
  ownerWarehouseLekalsTop: [],
  ownerLeadersLeaderList: [],
  ownerLeadersLeaderData: {
    name: "_____________________",
    sale: {
        value: 0,
        percent: 0,
        class: "green"
    },
    income: {
        value: 0,
        percent: 0,
        class: "red"
    },
    purchase: {
        value: 0,
        percent: 0,
        class: "green"
    }
    
},
  ownerLeadersLeaderStaf: [],
  ownerLeadersPurchaseBtn: [{
    name: "Закуп",
    value: 0,
    percent: 100,
    class: "green"
},
{
    name: "Сумма продаж",
    value: 0,
    percent: 100,
    class: "green"
}],
  ownerHomeBtn: [{
    name: "Продажи",
    value: 0,
    percent: 100,
    class: "green"
},
{
    name: "Доход",
    value: 0,
    percent: 100,
    class: "green"
}],
  };
  
  const ownerData = (state = initialState, action) => {
    if (action.type === 'SET_OWNER_HOME_POINTS') {
      return {
        ...state,
        ownerHomePoints: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_HOME_POINTS_ID') {
      return {
        ...state,
        ownerHomePointsId: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_HOME_LEADERS') {
      return {
        ...state,
        ownerHomeLeaders: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_WAREHOUSE_PURCHASE') {
      return {
        ...state,
        ownerWarehousePurchase: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_WAREHOUSE_LEKALS_TOP') {
      return {
        ...state,
        ownerWarehouseLekalsTop: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_LEADER_LEADERS_LIST') {
      return {
        ...state,
        ownerLeadersLeaderList: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_LEADER_LEADER_DATA') {
      return {
        ...state,
        ownerLeadersLeaderData: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_LEADER_LEADER_STAF') {
      return {
        ...state,
        ownerLeadersLeaderStaf: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_LEADER_PURCHASE_BTN') {
      return {
        ...state,
        ownerLeadersPurchaseBtn: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_HOME_BTN') {
      return {
        ...state,
        ownerHomeBtn: action.payload,
      };
    }
    return state;
  };
  
  export default ownerData;