import React, {useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setPopupBackgroundFunc, setPopupOpenBlock} from '../../../../redux/actions/popupBlocks';
import lekalo from '../../../../img/moderator-lekalo-icon.svg'
import { setModeratorComplaints } from '../../../../redux/actions/moderatorData';
import { setModeratorComplaintsStatusEdit } from './../../../../redux/actions/moderatorData';

const AddPoints = () => {
    const dispatch = useDispatch()

    const {moderatorComplaintsCheck, moderatorComplaints} = useSelector(({moderatorCheckData, moderatorData})=> {
        return {
            moderatorComplaintsCheck: moderatorCheckData.moderatorComplaintsCheck,
            moderatorComplaints: moderatorData.moderatorComplaints 
        }
    })
    const [error, setError] = useState('')
    const [status, setStatus] = useState(moderatorComplaintsCheck.statusColor)
    const [clickAddBtn, setClickAddBtn] = useState(false)
    const editComplaintsStatus = async() =>{
        if(!clickAddBtn && moderatorComplaintsCheck.statusColor !== status){
            setClickAddBtn(true)
            let response = await fetch(`/Moderator/NoStateChange?id=${moderatorComplaintsCheck.id}&state=${(status === 'grey')? 6: 5}`, {
                method: 'GET', 
                headers: {
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                },
            })
            if (response.ok === true) {
                const dat = await response.json();
                dispatch(setModeratorComplaintsStatusEdit(dat.value.resultObject))
                setError("")
                setClickAddBtn(false)
                dispatch(setPopupBackgroundFunc(false))
                dispatch(setPopupOpenBlock(''))    
            }
            else{
                setClickAddBtn(false)
                localStorage.setItem('role', '')
            }
        }

        else if(!clickAddBtn) setError("Изменений не найдено*")
    }

    
	return (
            <div className="moderator__popup-container">
                <div className="moderator__popup-top d-flex justify-between align-center add-leaders">
                    <div className="popup__title green">Жалоба подробно</div>
                    <div className="popup__close-btn" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                    }}></div>
                </div>
                <label htmlFor="" className='moderator__popup-label'>Адрес</label>
                <div className="moderator__popup-input">{moderatorComplaintsCheck.address}</div>
                <label htmlFor="" className='moderator__popup-label'>Жалоба на лекало</label>
                <div className="moderator__popup-lekalo-input-container d-flex align-center justify-between">
                    <img className="moderator__popup-lekalo-input-icon" src={lekalo} alt="" />
                    <div className="moderator__popup-lekalo-input">{(moderatorComplaintsCheck.lekalo !== null)? moderatorComplaintsCheck.lekalo: 'Лекало не выбрано'}</div>
                </div>
                <label htmlFor="" className='moderator__popup-label'>Описание</label>
                <div className="moderator__popup-description-input-container">
                    <div className="moderator__popup-description-input">
                        {moderatorComplaintsCheck.complaints}
                    </div>
                </div>
                <label htmlFor="" className='moderator__popup-label'>Статус</label>
                <div className={`moderator__popup-status-button ${status}`} onClick={()=>{
                    if(status === 'green') setStatus('grey')
                    else setStatus('green')
                    
                }}>
                    {
                        (status === 'green')? 'Исправлено': 'Не исправлено'
                    }
                </div>
                <div className='popup__error'>{error}</div>
                <div className={`moderator__popup-save ${(clickAddBtn)? 'deactive': ''}`} onClick={() => editComplaintsStatus()}>Сохранить</div>                    
            </div>
	)
}
export default AddPoints;