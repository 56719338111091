import React, {useState, useEffect} from 'react';
import { Route } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
// import {setOwner} from '../../redux/actions/notification';
// import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import Home from './Home/Home';
import PointPrinting from './Point-printing/PointPrinting';
import Staff from './Staff/Staff';
import StaffDetail from './StaffDetail/StaffDetail';
import Warehouse from './Warehouse/Warehouse';
import Popup from './Popup/Popup';
import Salaries from './Salaries/Salaries';

import Calendar from './Calendaries/Picker';

 
import './FranchisesLeaders.scss';
import { setFranchisersWarehousePopupMenuPage, setPopupOpenBlock, setPopupOpenBlockEvent } from '../../redux/actions/popupBlocks';




const FranchisesLeaders = () => {
	const dispatch = useDispatch()
	const { calendDisplay} = useSelector(({ display }) => display);
	// const [connection, setConnection] = useState()

	const {role, openBlock, openBlockEvent, location} = useSelector(({navigation, popupBlocks})=> {
    return {
        role: navigation.role,
        openBlock: popupBlocks.openBlock,
        openBlockEvent: popupBlocks.openBlockEvent,
        location: navigation.franchiserNav
    }
})

	// let access_token = (localStorage.getItem('save-autorize') === 'true'? localStorage.getItem('token'): sessionStorage.getItem('token'));

    // const notific = async () =>{
    //     const connection = new HubConnectionBuilder()
    //         .withUrl('https://localhost:7119/chat', {accessTokenFactory: () => access_token, transport: HttpTransportType.WebSockets, skipNegotiation: true})
    //         .configureLogging(LogLevel.Information)
    //         .build();
    
    //         connection.on("NotificationAdd", (notification) => {
    //             dispatch(setOwner(notification))
		// 		try {
		// 			let audio = new Audio('/img/notification-audio.mp3');
    //             	audio.play();
		// 		} catch (error) {
					
		// 		}
                
    //         });           
    //     await connection.start();
		// setConnection(connection)

    // }
    const [calend, setCalend] = useState(true)
    
    // useEffect(() =>{
    //     if(role !== ''){
    //         notific()
    //     }
    // }, [role])

    useEffect(() =>{
      setCalend(false)
      setTimeout(() => {
        setCalend(true)
      }) 
    }, [location])
    
      // const closeConnection = async () => {
      //   try {
      //     await connection.stop();
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }

    const closePopup = (event) =>{
      if(openBlock !== '' && !event.target.classList.contains('popup-open-button')){
        let block = document.querySelector(`.${openBlock}`)
        let clickBlock = event.target; 
        if(clickBlock !== openBlockEvent && clickBlock !== block && block && !block.contains(clickBlock)){
            dispatch(setPopupOpenBlock(''))
            dispatch(setPopupOpenBlockEvent(''))
            if(openBlock.indexOf('franchiserWarehouseTableElemMenu_') !== -1) dispatch(setFranchisersWarehousePopupMenuPage('main'))
        }
        
      }
    }
	return (
		<React.Fragment>
      <div className="franchises-leaders" onClick={(event)=>closePopup(event)}>
        <Sidebar/>
        <Header/>
        <div className="franchises-leaders__wrap">
          
          <Route path='/point-printing' component={PointPrinting} exact/>
          <Route path='/staff' component={Staff} exact/>
          <Route path='/staff-detail' component={StaffDetail} exact/>
          <Route path='/warehouse' component={Warehouse} exact/>
          <Route path='/salaries' component={Salaries} exact/>
          <Route path='/' component={Home} exact/>
        </div>
        {
          (calend)? <Calendar  display = {calendDisplay}/>: null
        }      
      </div>
      <Popup/>
    </React.Fragment>
	)
}

export default FranchisesLeaders;