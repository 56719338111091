import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import './index.scss';

import { setPopupBackgroundFunc, setPopupOpenBlock, setPopupOpenBlockEvent } from '../../../redux/actions/popupBlocks';
import Calendar from '../Calendaries/Picker';
import { setWorkZoneCheckClient, setWorkZonePopupPrintType, setWorkZoneSearch } from '../../../redux/actions/workZoneCheckData';
import { fetchWorkZoneHistoryData } from '../../../redux/actions/workZoneData';


const History = () => {

    const dispatch = useDispatch();

    const cutName = (name) =>{
        let cut = name.split(' ')
        if(cut.length === 3){
            return cut[0] + ' ' + cut[1] + ' ' + cut[2][0] + '.'
        }
        else return name
    }

    const {workZoneHistoryData, workZoneSearch, openBlock, workZoneHistoryDate} = useSelector(({workZoneData, workZoneCheckData, popupFilters, popupBlocks})=> {
        return {
            workZoneHistoryData: workZoneData.workZoneHistoryData,
            workZoneSearch: workZoneCheckData.workZoneSearch,
            openBlock: popupBlocks.openBlock,
            workZoneHistoryDate: popupFilters.workZoneHistoryDate
        }
    })

    function scrollTo(element, to, duration) {
        // console.log(element)
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;
            
        var animateScroll = function(){        
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    const formatDate = (date) =>{
        let month = [
            "янв",
            "фев",
            "мар",
            "апр",
            "май",
            "июн",
            "июл",
            "авг",
            "сен",
            "окт",
            "ноя",
            "дек"
        ]

        return `${date.getDate()} ${month[date.getMonth()]}, ${date.getHours()}:${date.getMinutes('mm')}`
    }

    useEffect(()=>{
        if(workZoneSearch.isActive && workZoneSearch.type === 'history' && workZoneHistoryData.length !== 0){
            let elem = document.querySelector(`.workZone__history-line-${workZoneSearch.id}`);
            // console.log(elem)
            if(elem !== null){
                elem.classList.add("active");
                setTimeout(()=>{
                    elem.classList.remove("active");
                }, 3000)
                scrollTo(document.querySelector('.workZone__history-table'), +elem.offsetTop - +document.querySelector('.workZone__history-table').offsetTop, 1000)
                dispatch(setWorkZoneSearch({isActive: false, type: '', id: 0}))
            }
        }   
    }, [workZoneHistoryData])

    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };

    useEffect(()=>{
        dispatch(fetchWorkZoneHistoryData(workZoneHistoryDate.date))
    }, [workZoneHistoryDate])

    const addTableItem = (elem, key) =>{
        let {id, date, model, staff, price, formalized} = elem; 
        return(
            <div className={`workZone__history-line d-flex align-center justify-between workZone__history-line-${id}`} key={key} onClick={()=>{
                dispatch(setWorkZoneCheckClient(elem))
                dispatch(setWorkZonePopupPrintType('interact'))
                dispatch(setPopupBackgroundFunc(true))
                dispatch(setPopupOpenBlock('PrintMold'))
            }}>
                <div className={`workZone__history-line-background-status d-flex align-center justify-between ${(formalized)? 'active': ''}`}>
                    <div className="workZone__history-date">{date}</div>
                    <div className={`workZone__history-model`}>{model}</div>
                    <div className={`workZone__history-staff`}>{(staff !== null)? cutName(staff): 'Сотрудник не выбран'}</div>                 
                    <div className="workZone__history-price">{price}₽</div>
                    <div className="workZone__history-status-container d-flex align-center justify-center">
                        {
                            (!formalized)? (
                                <div className="workZone__history-status green"></div>
                            ): null
                        }
                    </div>
                </div>
            </div>
        )
    }

	return (
        <React.Fragment>
            <div className="workZone__history">
                <div className="workZone__history-top d-flex justify-between align-center">
                    <div className="workZone__history-title staf">История</div>
                    <div className='workZone__history-filters-block d-flex align-center'>
                        <div className={`workZone__history-filters`}>
                            <div className={`workZone__history-filters-title d-flex popup-open-button align-center justify-center ${(openBlock === "workZoneHistoryCalend")? 'active': ''}`} onClick={(event) => {
                                dispatch(setPopupOpenBlock((openBlock === "workZoneHistoryCalend")? "": "workZoneHistoryCalend"))
                                dispatch(setPopupOpenBlockEvent(event.target))
                            }}>
                                Фильтры <div className="workZone__history-filters-title-icon"></div>
                            </div>                        
                        </div>
                        {
                            (openBlock === "workZoneHistoryCalend")? <Calendar calendActive={true} page={'history'} name={'workZoneHistoryCalend'}/>: null
                        }
                    </div>
                </div>
                <div className='workZone__history-table-scroll'>
                    <div className="workZone__history-line title d-flex align-center justify-between">
                        <div className={`workZone__history-line-background-status d-flex align-center justify-between`}>
                            <div className="workZone__history-date">Дата и время</div>
                            <div className="workZone__history-model">Модель</div>
                            <div className="workZone__history-staff">Сотрудник</div> 
                            <div className="workZone__history-price">Цена</div>
                            <div className="workZone__history-status-container d-flex align-center justify-center"></div>
                        </div>                        
                    </div>
                    <div className="workZone__history-table">
                        {
                            workZoneHistoryData.map((item, key) => addTableItem(item, key))
                        }              
                    </div>
                </div>
            </div>
        </React.Fragment>        
	)
}

export default History;