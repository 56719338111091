const initialState = {
    workZoneClientsFullData: [
    ],
    workZoneClientsData: [
    ],
    workZoneHistoryData: [
    ],
    workZoneCatalogData: [
    ],
    workZoneCatalogPath: [
    ],
    workZoneCashbox: 0,
    workZoneNotification: [
    ],
    workZonePlottersData: []
};
    
    const workZoneData = (state = initialState, action) => {
      if (action.type === 'SET_WORK_ZONE_CLIENTS_DATA') {
        return {
          ...state,
          workZoneClientsData: action.payload,
        };
      }
      else if (action.type === 'SET_WORK_ZONE_CLIENTS_FULL_DATA') {
        return {
          ...state,
          workZoneClientsFullData: action.payload,
        };
      }
	    else if (action.type === 'SET_WORK_ZONE_HISTORY_DATA') {
        return {
          ...state,
          workZoneHistoryData: action.payload,
        };
      }
      else if (action.type === 'SET_WORK_ZONE_HISTORY_DATA_EDIT') {
        let data = state.workZoneHistoryData.slice();
        let index = data.findIndex(x => x.id === action.id)
        if(index !== -1){
          data[index] = action.payload
          return {
            ...state,
            workZoneHistoryData: data,
          };
        }
      }
      else if (action.type === 'SET_WORK_ZONE_CATALOG_DATA') {
        return {
          ...state,
          workZoneCatalogData: action.payload,
        };
      }
      else if (action.type === 'SET_WORK_ZONE_CATALOG_PATH') {
        return {
          ...state,
          workZoneCatalogPath: action.payload,
        };
      }
      else if (action.type === 'SET_WORK_ZONE_CASHBOX') {
        return {
          ...state,
          workZoneCashbox: action.payload,
        };
      }
      else if (action.type === 'SET_WORK_ZONE_NOTIFICATION') {
        return {
          ...state,
          workZoneNotification: action.payload,
        };
      }
      else if (action.type === 'SET_WORK_ZONE_NOTIFICATION_ADD') {
        return {
          ...state,
          workZoneNotification: [action.payload, ...state.workZoneNotification],
        };
      }
      else if (action.type === 'SET_WORK_ZONE_PLOTTERS_DATA') {
        return {
          ...state,
          workZonePlottersData: action.payload,
        };
      }
      return state;
    };
    
    export default workZoneData;