import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setFranchiserStaffList } from '../../../../redux/actions/franchiserData';
import { setModeratorLeaders, setModeratorLeadersPoints } from '../../../../redux/actions/moderatorData';
import { setPopupBackgroundFunc, setPopupOpenBlock} from '../../../../redux/actions/popupBlocks';

import './index.scss';

const LayoffsPopup = () => {
    const dispatch = useDispatch() 
    
    const {franchiserLayoffsStaffCheck, franchiserStaffData} = useSelector(({franchiserCheckData, franchiserData})=> {
        return {
            franchiserLayoffsStaffCheck: franchiserCheckData.franchiserLayoffsStaffCheck,
            franchiserStaffData: franchiserData.franchiserStaffData
        }
    })
    // console.log(franchiserLayoffsStaffCheck)

    const StaffInactively = async () => {
        let data = {
            id: franchiserLayoffsStaffCheck.id
        }

        let response = await fetch(`/francheaser/DismissalWorker?`+ new URLSearchParams(data), {
            method: "GET", 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
        })
        if (response.ok === true) {
            // const user = await response.json();
            let data = franchiserStaffData.slice()
            let index = data.findIndex((item)=> item.id === franchiserLayoffsStaffCheck.id)
            data[index] = {...data[index], status: 'Уволен', statusColor: 'red'}
            dispatch(setFranchiserStaffList(data))
            dispatch(setPopupBackgroundFunc(false))
            dispatch(setPopupOpenBlock(''))
        }
    }
        
	return (
            <div className="moderator__popup-container delite">
                <div className="moderator__popup-delite-title">Вы действительно хотите уволить работника <span>{franchiserLayoffsStaffCheck.name}</span>?</div>
                <div className='moderator__popup-delite-btn d-flex justify-between align-center'>
                    <div className="moderator__popup-delite-btn-item delite" onClick={StaffInactively}>Уволить</div>
                    <div className="moderator__popup-delite-btn-item close" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                    }}>Отменить</div>    
                </div>                
               
            </div>
	)
}
export default LayoffsPopup;