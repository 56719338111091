export const setOwnerHomeLeaders = (item) => ({
    type: 'SET_OWNER_HOME_LEADERS',
    payload: item
});

async function post(url, type, data = null){
    let response = await fetch(url, {
        method: 'POST', 
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    })
    if (response.ok === true) {
        const user = await response.json();
        return user
    }
    else{
        localStorage.setItem('role', '')
      }
    let dat = response.json
    return dat 
}

async function postNotData(url, type){
    let response = await fetch(url, {
        method: type, 
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
        },
    })
    if (response.ok === true) {
        const user = await response.json();
        return user
    }
    let dat = response.json
    return dat 
}

let monthKey = {
    "Январь": 1,
    "Февраль": 2,
    "Март": 3,
    "Апрель": 4,
    "Май": 5,
    "Июнь": 6,
    "Июль": 7,
    "Август": 8,
    "Сентябрь": 9,
    "Октябрь": 10,
    "Ноябрь": 11,
    "Декабрь": 12
}

export const fetchOwnerHomeLeaders = (range, month, year) => (dispatch) =>{
    
    let data = {
        month: (month === "Месяц")? 0: monthKey[month],
        year:  (year === "Год")? 0: year,
    }

    if(range.length > 0){
        data.SFrom = range[0].toLocaleDateString()
        data.STo = range[1].toLocaleDateString()
    }

    post("/api/leaderdata", "POST", data).then((response)=> {
        dispatch(setOwnerHomeLeaders(response.value))
    })
    
}

export const setOwnerHomePoints = (item) => ({
    type: 'SET_OWNER_HOME_POINTS',
    payload: item
});

export const setOwnerHomePointsId = (item) => ({
    type: 'SET_OWNER_HOME_POINTS_ID',
    payload: item
});

export const fetchOwnerHomePoints = (range, month, year, ownerHomeCheckLeaders) => (dispatch) =>{

    let data = {
        month: (month === "Месяц")? 0: monthKey[month],
        year:  (year === "Год")? 0: year,
        DirectorId: ownerHomeCheckLeaders
    }

    if(range.length > 0){
        data.SFrom = range[0].toLocaleDateString()
        data.STo = range[1].toLocaleDateString()
    }

    post("/api/salespoints", "POST", data).then((response)=> {
        dispatch(setOwnerHomePoints(response.value.data))
        dispatch(setOwnerHomePointsId(response.value.id))
    })

}

export const setOwnerWarehousePurchase = (item) => ({
    type: 'SET_OWNER_WAREHOUSE_PURCHASE',
    payload: item
});

export const fetchOwnerWarehousePurchase = (range, month, year) => (dispatch) =>{
    let data = {
        month: (month === "Месяц")? 0: monthKey[month],
        year:  (year === "Год")? 0: year,        
    }

    if(range.length > 0){
        data.SFrom = range[0].toLocaleDateString()
        data.STo = range[1].toLocaleDateString()
    }

    post("/stock/warehouses", "POST", data).then((response)=> {
        dispatch(setOwnerWarehousePurchase(response.value))
    })
}

export const setOwnerWarehouseLekalsTop = (item) => ({
    type: 'SET_OWNER_WAREHOUSE_LEKALS_TOP',
    payload: item
});

export const fetchOwnerWarehouseLekalsTop = (range, month, year) => (dispatch) =>{
    let data = {
        month: (month === "Месяц")? 0: monthKey[month],
        year:  (year === "Год")? 0: year,        
    }

    if(range.length > 0){
        data.SFrom = range[0].toLocaleDateString()
        data.STo = range[1].toLocaleDateString()
    }

    post("/stock/warehouseslekalo", "POST", data).then((response)=> {
        dispatch(setOwnerWarehouseLekalsTop(response.value))
    })
}

export const setOwnerLeaderLeadersList = (item) => ({
    type: 'SET_OWNER_LEADER_LEADERS_LIST',
    payload: item
});

export const fetchOwnerLeaderLeadersList = () => (dispatch) =>{
    postNotData("/leaderpage/tabledata", "GET").then((response)=> {
        dispatch(setOwnerLeaderLeadersList(response.value))
    })
}
export const setOwnerLeaderLeaderData = (item) => ({
    type: 'SET_OWNER_LEADER_LEADER_DATA',
    payload: item
});

export const fetchOwnerLeaderLeaderData = (id) => (dispatch) =>{
    postNotData(`/leaderpage/leadersinform?id=${id}`, "GET").then((response)=> {
        dispatch(setOwnerLeaderLeaderData(response.value))
    })
}

export const setOwnerLeaderLeaderStaf = (item) => ({
    type: 'SET_OWNER_LEADER_LEADER_STAF',
    payload: item
});

export const fetchOwnerLeaderLeaderStaf = (id) => (dispatch) =>{
    postNotData(`/leaderpage/stafdata?id=${id}`, "GET").then((response)=> {
        dispatch(setOwnerLeaderLeaderStaf(response.value))
    })
}

export const setOwnerLeaderPurchaseBtn = (item) => ({
    type: 'SET_OWNER_LEADER_PURCHASE_BTN',
    payload: item
});

export const fetchOwnerLeaderPurchaseBtn = (month, year, range) => (dispatch) =>{
    let data = {
        month: (month === "Месяц")? 0: monthKey[month],
        year:  (year === "Год")? 0: year,
    }

    if(range.length > 0){
        data.SFrom = range[0].toLocaleDateString()
        data.STo = range[1].toLocaleDateString()
    }

    post("/stock/mainbuttons", "POST", data).then((response)=> {
        dispatch(setOwnerLeaderPurchaseBtn(response.value))
    })
}

export const setOwnerHomeBtn = (item) => ({
    type: 'SET_OWNER_HOME_BTN',
    payload: item
});

export const fetchOwnerHomeBtn = (range, month, year, ownerHomeCheckLeaders, ownerHomeCheckPoints) => (dispatch) =>{
    let data = {
        month: (month === "Месяц")? 0: monthKey[month],
        year:  (year === "Год")? 0: year,
        DirectorId: ownerHomeCheckLeaders,
        PlaceId: ownerHomeCheckPoints
    }

    if(range.length > 0){
        data.SFrom = range[0].toLocaleDateString()
        data.STo = range[1].toLocaleDateString()
    }

    post("/api/mainbuttons", "POST", data).then((response)=> {
        dispatch(setOwnerHomeBtn(response.value))
    })
}