export const setWarehouseHomeTransferEnvelopeCheckData = (item) => ({
  type: 'SET_WAREHOUSE_TRANSFER_CHECK_DATA',
  payload: item
});

export const setWarehouseLeaderCheck = (item) => ({
  type: 'SET_WAREHOUSE_LEADER_CHECK',
  payload: item
});

export const setWarehousePointCheck = (item) => ({
  type: 'SET_WAREHOUSE_POINT_CHECK',
  payload: item
});

export const setWarehouseHistoryOrderCheck = (item) => ({
  type: 'SET_WAREHOUSE_HISTORY_ORDER_CHECK',
  payload: item
});

export const setWarehouseEnvelopeSort = (item) => ({
  type: 'SET_WAREHOUSE_ENVELOPE_SORT',
  payload: item
});

export const setWarehouseEditEnvelopeCheck = (item) => ({
  type: 'SET_WAREHOUSE_EDIT_ENVELOPE_CHECK',
  payload: item
});

export const setWarehouseHomeTransferLeaderCheck = (item) => ({
  type: 'SET_WAREHOUSE_HOME_TRANSFER_LEADER_CHECK',
  payload: item
});
