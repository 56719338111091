import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {} from '../../../redux/actions/filters';
import { fetchModeratorCatalog } from '../../../redux/actions/moderatorData';
import './Search.scss';

const Search = () => {
    const dispatch = useDispatch()
    const {navigation, moderatorLeaders, moderatorLeadersStaf, moderatorLeadersPoints, moderatorHistoryChanges, moderatorComplaints} = useSelector(({navigation, moderatorData})=> {
        return {
            navigation: navigation.moderatorNav,
            moderatorLeaders: moderatorData.moderatorLeaders,
            moderatorLeadersStaf: moderatorData.moderatorLeadersStaf,
            moderatorLeadersPoints: moderatorData.moderatorLeadersPoints,
            moderatorHistoryChanges: moderatorData.moderatorHistoryChanges,
            moderatorComplaints: moderatorData.moderatorComplaints
        }
    })


    let [searchList, setSearchList] = useState([]);
    let [searchActive, setSearchActive] = useState(false);    

    const scrollToLeader = (id) =>{
        let elem = document.querySelector(`.moderator__scroll-leaders-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        // console.log(elem)
        scrollTo(document.querySelector('.moderator__leaders-table'), +elem.offsetTop - +document.querySelector('.moderator__leaders-table').offsetTop, 1000)
    }
    
    const pageScroll = (element) =>{
        let headerOffset = 45;
        let elementPosition = element.getBoundingClientRect().top;
        let offsetPosition = elementPosition - headerOffset;
        // console.log(offsetPosition)
        window.scrollTo({
            top: offsetPosition,
        });
    }

    const scrollToLeaderStaf = (id, type) =>{
       if(type === 'points'){
            pageScroll(document.querySelector('.moderator__leaders-staf-points'))
            let elem = document.querySelector(`.moderator__scroll-leaders-staf-points-line-${id}`);
            elem.classList.add("active");
            setTimeout(()=>{
                elem.classList.remove("active");
            }, 3000)
            scrollTo(document.querySelector('.moderator__leaders-staf-points-table'), +elem.offsetTop - +document.querySelector('.moderator__leaders-staf-points-table').offsetTop, 1000)
        }
        if(type === 'staf'){
            let elem = document.querySelector(`.moderator__scroll-leaders-staf-line-${id}`);
            elem.classList.add("active");
            setTimeout(()=>{
                elem.classList.remove("active");
            }, 3000)
            scrollTo(document.querySelector('.moderator__leaders-staf-table'), +elem.offsetTop - +document.querySelector('.moderator__leaders-staf-table').offsetTop, 1000)
        }
    }

    const scrollToHistory = (id) =>{
        let elem = document.querySelector(`.moderator__history-changes-line-${id}`);
        // console.log(elem)
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.moderator__history-changes-table-scroll'), +elem.offsetTop - +document.querySelector('.moderator__history-changes-table-scroll').offsetTop, 1000)
    }

    const scrollToComplaints = (id) =>{
        let elem = document.querySelector(`.moderator__complaints-line-${id}`);
        // console.log(elem)
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.moderator__complaints-table'), +elem.offsetTop - +document.querySelector('.moderator__complaints-table').offsetTop, 1000)
    }

    function scrollTo(element, to, duration) {
        // console.log(element, to)
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;
            
        var animateScroll = function(){        
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }
    
    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };

    const addListItem = (item, key) =>{
        if(navigation === 'Catalog'){
            return(
                <div className='moderator__search-list-item' key={key} onClick={()=>{
                    dispatch(fetchModeratorCatalog(item.id))
                    setSearchActive(false)
                }}>
                    <div className={`moderator__search-list-item-home-leader}`}>{item.name}</div>
                </div>
            )
        }
        else if(navigation === 'Leaders'){
            return(
                <div className='moderator__search-list-item' key={key} onClick={()=>{
                    scrollToLeader(item.id)
                    setSearchActive(false)
                }}>
                    <div className={`moderator__search-list-item-home-leader}`}>{item.name}</div>
                </div>
            )
        }
        else if(navigation === 'LeadersStaf'){
            if(item.type === 'staf'){
                return(
                    <div className='moderator__search-list-item' key={key} onClick={()=>{
                        scrollToLeaderStaf(item.id, item.type)
                        setSearchActive(false)
                    }}>
                        <div className={`moderator__search-list-item-home-leader}`}>{item.name}</div>
                    </div>
                )
            }
            else if(item.type === 'points'){
                return(
                    <div className='moderator__search-list-item ' key={key} onClick={()=>{
                        scrollToLeaderStaf(item.id, item.type)
                        setSearchActive(false)
                    }}>
                        <div className='owner__search-list-item-home-points'>
                            <div className="moderator__search-list-item-home-points-name" >Название: {item.name}</div>
                            <div >Адрес: {item.address}</div>
                        </div>
                    </div>
                )
            }
        }
        else if(navigation === 'HistoryChanges'){
            return(
                <div className='moderator__search-list-item' key={key} onClick={()=>{
                    scrollToHistory(item.id)
                    setSearchActive(false)
                }}>
                    <div className={`moderator__search-list-item-home-leader}`}>{(item.lekalo !== null)? item.lekalo: 'Лекало не выбрано'}</div>
                    <div className="moderator__buttons-history-changes-list-item-name-path">
                        {item.path}
                    </div>
                </div>
            )
        }
        else if(navigation === 'Complaints'){
            return(
                <div className='moderator__search-list-item' key={key} onClick={()=>{
                    scrollToComplaints(item.id)
                    setSearchActive(false)
                }}>
                    <div className='owner__search-list-item-home-points'>
                        <div >Точка: {item.point}</div>
                        <div className="moderator__search-list-item-home-points-name" >Лекало: {(item.lekalo !== null)? item.lekalo: 'Лекало не выбрано'}</div>                        
                    </div>
                </div>
            )
        }
    }


    const searchInput = async(event) =>{
        let val = event.target.value;
        let data = []
        if(val.length >= 3){
            if(navigation === "Catalog"){
                const response =  await fetch(`/Moderator/SearchCatalog?str=${val}`, {
                    method: 'GET', 
                    headers: {
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                    }
                })
                if (response.ok === true) {
                    const dat = await response.json();
                    data = dat.value
                }
            }
            else if(navigation === "Leaders"){
                for(let item of moderatorLeaders){
                    if(item.name.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push(item)
                    }            
                }
            }
            else if(navigation === "LeadersStaf"){
                for(let item of moderatorLeadersStaf){
                    if(item.name.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push({...item, type: "staf"})
                    }            
                }
                for(let item of moderatorLeadersPoints){
                    // console.log(item)
                    if(item.name.toLowerCase().search(val.toLowerCase()) != -1 || item.address.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push({...item, type: "points"})
                    }            
                }
            }
            else if(navigation === "HistoryChanges"){
                for(let item of moderatorHistoryChanges){
                    if((item.lekalo !== null && item.lekalo.toLowerCase().search(val.toLowerCase()) != -1) || item.path.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push(item)
                    }   
                }
            }
            else if(navigation === "Complaints"){
                for(let item of moderatorComplaints){
                    if((item.lekalo !== null && item.lekalo.toLowerCase().search(val.toLowerCase()) != -1) || item.point.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push(item)
                    }   
                }
            }
        }
        else{
            setSearchActive(false)
        }
        if(data.length != 0) setSearchActive(true)
        setSearchList(data)
    }
	return (
		<div className={`owner__search-blur ${(searchActive)? "active": ""}`}>
            <div className={`owner__search d-flex align-center ${(searchActive)? "active": ""}`}>
                <div className={`owner__search-container d-flex align-center ${(searchActive)? "active": ""}`}>
                    <div className="owner__search-icon"></div>
                    <input type="text" placeholder="Поиск" className={`owner__search-input`} onInput={searchInput}/>                    
                    <img src="/img/close-circle.svg" alt="" className="owner__search-close" onClick={()=>{
                        document.querySelector(".owner__search-input").value = "";
                        setSearchActive(false);
                    }}/>
                </div>
                <div className={`owner__search-list ${(searchActive)? "active": ""}`}>
                    <div className="owner__search-list-title">
                        Возможно вы ищете
                    </div>
                    <div className="owner__search-list-scrol">
                        {
                            searchList.map((item, key) => addListItem(item, key))
                        }
                    </div>
                </div>
            </div>
        </div>
	)
}

export default Search;