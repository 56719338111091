import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import './index.scss';

import { setPopupBackgroundFunc, setPopupOpenBlock, setPopupOpenBlockEvent } from '../../../redux/actions/popupBlocks';
import Calendar from '../Calendaries/Picker';
import { setWorkZoneCheckClient, setWorkZonePopupPrintType, setWorkZoneSearch } from '../../../redux/actions/workZoneCheckData';
import { fetchWorkZoneClientsData, setWorkZoneClientsData } from '../../../redux/actions/workZoneData';

const Clients = () => {

    const dispatch = useDispatch();

    let [searchInput, setSearchInput] = useState('')

    const {workZoneClientsFullData, workZoneClientsData, workZoneSearch, openBlock, workZoneClientsDate} = useSelector(({workZoneData, workZoneCheckData, popupBlocks, popupFilters})=> {
        return {
            workZoneClientsFullData: workZoneData.workZoneClientsFullData,
            workZoneClientsData: workZoneData.workZoneClientsData,
            workZoneSearch: workZoneCheckData.workZoneSearch,
            openBlock: popupBlocks.openBlock,
            workZoneClientsDate: popupFilters.workZoneClientsDate
        }
    })

    function scrollTo(element, to, duration) {
        // console.log(element, to, duration)
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;
            
        var animateScroll = function(){        
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }
    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };

    

    useEffect(()=>{       
        dispatch(fetchWorkZoneClientsData(workZoneClientsDate.date)) 
    }, [workZoneClientsDate])

    useEffect(()=>{
        if(workZoneSearch.isActive && workZoneSearch.type === 'clients' && workZoneClientsFullData.length !== 0){
            let elem = document.querySelector(`.workZone__clients-line-${workZoneSearch.id}`);
            // console.log(elem, workZoneSearch.id)/
            if(elem !== null){
                elem.classList.add("active");
                setTimeout(()=>{
                    elem.classList.remove("active");
                }, 3000)
                scrollTo(document.querySelector('.workZone__clients-table'), +elem.offsetTop - +document.querySelector('.workZone__clients-table').offsetTop, 1000)
                dispatch(setWorkZoneSearch({isActive: false, type: '', id: 0}))
            }
        }   
    }, [workZoneClientsFullData])

    const addTableItem = (elem, key) =>{
        let {id, date, phone, model, guarantee} = elem;
        return(
            <div className={`workZone__clients-line d-flex align-center justify-between workZone__clients-line-${id}`} key={key}>
                <div className="workZone__clients-date">{date}</div>
                <a href={`tel:${phone}`} className="workZone__clients-phone">{phone}</a>
                <div className={`workZone__clients-model`}>{model}</div>                
                <div className="workZone__clients-guarantee">{(guarantee<0)? 0: guarantee}</div>
                <img src="./img/arrow-right-block.svg" alt="" className="workZone__clients-open" onClick={()=>{
                    dispatch(setWorkZoneCheckClient(elem))
                    dispatch(setWorkZonePopupPrintType('watch'))
                    dispatch(setPopupBackgroundFunc(true))
                    dispatch(setPopupOpenBlock('PrintMold'))
                }}/>
            </div>
        )
    }

    const searchInputFunc = (event) =>{
        setSearchInput(event.target.value)
        console.log(event.target.value)
        if(event.target.value !== ''){
            let sData = workZoneClientsFullData.filter((item) => item.phone.replace(/[\D]+/g, '').search(event.target.value.replace(/[\D]+/g, '')) !== -1)
            dispatch(setWorkZoneClientsData(sData))
        }
        else  dispatch(setWorkZoneClientsData([]))
    }

	return (
        <React.Fragment>
            <div className="workZone__clients">
                <div className="workZone__clients-top d-flex justify-between align-center">
                    <div className="workZone__clients-title staf">Клиенты</div>
                    <div className={`workZone__clients-search-container d-flex align-center`}>
                        <input type="text" placeholder="Введите номер телефона" className='workZone__clients-search-input' value={searchInput} onInput={(event) => searchInputFunc(event)}/>
                        <div className="workZone__clients-search-icon"></div>                   
                    </div>
                    <div className='workZone__clients-filters-block d-flex align-center'>
                        <div className={`workZone__clients-filters`}>
                            <div className={`workZone__clients-filters-title popup-open-button d-flex align-center justify-center ${(openBlock === "workZoneClientsCalend")? 'active': ''}`} onClick={(event) => {
                                dispatch(setPopupOpenBlock((openBlock === "workZoneClientsCalend")? "": "workZoneClientsCalend"))
                                dispatch(setPopupOpenBlockEvent(event.target))
                            }}>
                                Фильтры <div className="workZone__clients-filters-title-icon"></div>
                            </div>                        
                        </div>
                        {/* <Calendar calendActive={calendActive}/> */}
                        {
                            (openBlock === "workZoneClientsCalend")? <Calendar calendActive={true} page={'clients'} name={'workZoneClientsCalend'}/>: null
                        }
                    </div>
                </div>
                <div className='workZone__clients-table-scroll'>
                    <div className="workZone__clients-line title d-flex align-center justify-between">
                        <div className="workZone__clients-date">Дата и время</div>
                        <div className="workZone__clients-phone">Сотовый</div>
                        <div className="workZone__clients-model">Модель</div>
                        <div className="workZone__clients-guarantee">Гарантия</div>
                        <img src="/img/arrow-right-block" alt="" className="workZone__clients-open none"></img>
                        
                    </div>
                    <div className="workZone__clients-table">
                        {
                            ((searchInput !== '')? workZoneClientsData: workZoneClientsFullData).map((item, key) => addTableItem(item, key))
                        }             
                    </div>
                </div>
            </div>
        </React.Fragment>        
	)
}

export default Clients;