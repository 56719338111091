import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Route } from 'react-router-dom';

import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import Workspase from './Workspase/Workspace';
import Clients from './Clients/Clients';
import History from './History/History';
import Popup from './Popup/Popup';

import { setPopupOpenBlock, setPopupOpenBlockEvent} from '../../redux/actions/popupBlocks';
import { setSiginalConnect } from '../../redux/actions/siginalConnect';

import './WorkZone.scss';
import { setNotificationActive, setSession } from './../../redux/actions/navigation';


const WorkZone = () => {
	const dispatch = useDispatch()
	const [connection, setConnection] = useState()

	const {nav, role, openBlock, openBlockEvent, session} = useSelector(({navigation, popupBlocks})=> {
    return {
        nav: navigation.workZoneNav,
        role: navigation.role,
        session: navigation.session,
        openBlock: popupBlocks.openBlock,
        openBlockEvent: popupBlocks.openBlockEvent,
    }
})

let access_token = (localStorage.getItem('save-autorize') === 'true'? localStorage.getItem('token'): sessionStorage.getItem('token'));
  const notific = async () => {

    const connection = new HubConnectionBuilder()
     //.withUrl('https://localhost:7119/chat', {accessTokenFactory: () => access_token, transport: HttpTransportType.WebSockets, skipNegotiation: true})
    .withUrl('https://bswork.pro/chat', {accessTokenFactory: () => access_token, transport: HttpTransportType.WebSockets, skipNegotiation: true})
    .configureLogging(LogLevel.Information)
    .build();

    connection.on("WorkZoneNotificationAdd", (data) => {
      dispatch(setSiginalConnect({type: 'new-notification', obj: {elem: data}}))
      dispatch(setNotificationActive(true))
    });
    
    await connection.start();
    setConnection(connection)

  }
    
  useEffect(() =>{
      if (role !== '') {          
        notific()
      }
  }, [role])

  const closeConnection = async () => {
    try {
      await connection.stop();
    } catch (e) {
      console.log(e);
    }
  }

  const closePopup = (event) =>{
    if(session !== 1) dispatch(setSession(1)) 
    if(openBlock !== '' && !event.target.classList.contains('popup-open-button')){
      let block = document.querySelector(`.${openBlock}`)
      let clickBlock = event.target; 
      if(clickBlock !== openBlockEvent && clickBlock !== block && block && !block.contains(clickBlock)){
          dispatch(setPopupOpenBlock(''))
          dispatch(setPopupOpenBlockEvent(''))
      }
    }
  }
  return (
		<React.Fragment>
      <div className={`work-zone ${(nav !== 'Workspase')? 'sidebar-none': ''}`} onClick={(event)=>closePopup(event)}>
        <Sidebar status={(nav !== 'Workspase')? 'sidebar-none': ''} connection={connection}/>
        <Header status={(nav !== 'Workspase')? 'sidebar-none': ''}/>
        <div className="work-zone__wrap">          
          					
          <Route path='/Clients' component={Clients} exact />
          <Route path='/History' component={History} exact />
          <Route path='/' component={Workspase} exact />
        </div>
      </div>
      <Popup />
    </React.Fragment>
	)
}

export default WorkZone;