const initialState = {
    warehouseTransferEnvelopeCheck: [],
    warehouseEnvelopeSort: (sessionStorage.getItem('warehouseEnvelopeSort') !== null)? JSON.parse(sessionStorage.getItem('warehouseEnvelopeSort')) :{
      type: '',
      name: ''
    },
    warehouseLeaderCheck: (sessionStorage.getItem('WarehouseLeaderCheck') !== null)? JSON.parse(sessionStorage.getItem('WarehouseLeaderCheck')) :{
      id: 0,
      name: ''
    },
    warehousePointCheck: (sessionStorage.getItem('WarehousePointCheck') !== null)? JSON.parse(sessionStorage.getItem('WarehousePointCheck')) :{
      id: 0,
      name: ''
    },
    warehouseHistoryOrderCheck: (sessionStorage.getItem('WarehouseHistoryOrderCheck') !== null)? JSON.parse(sessionStorage.getItem('WarehouseHistoryOrderCheck')) :{
      id: 0,
      date: '',
    },

    warehouseEditEnvelopeCheck:{
      id: 0,
      name: '',
      article: '',
      count: '',
      summ: '',
      filmId: 0
    },

    warehouseHomeTransferLeaderCheck:{
      id: 0,
      name: '',
    }
};
    
    const workZoneCheckData = (state = initialState, action) => {
      if (action.type === 'SET_WAREHOUSE_TRANSFER_CHECK_DATA') {
        return {
          ...state,
          warehouseTransferEnvelopeCheck: action.payload,
        };
      }
      else if (action.type === 'SET_WAREHOUSE_ENVELOPE_SORT') {
        sessionStorage.setItem('warehouseEnvelopeSort', JSON.stringify(action.payload))
        return {
          ...state,
          warehouseEnvelopeSort: action.payload,
        };
      }
      else if (action.type === 'SET_WAREHOUSE_LEADER_CHECK') {
        return {
          ...state,
          warehouseLeaderCheck: action.payload,
        };
      }
      else if (action.type === 'SET_WAREHOUSE_POINT_CHECK') {
        return {
          ...state,
          warehousePointCheck: action.payload,
        };
      }
      else if (action.type === 'SET_WAREHOUSE_HISTORY_ORDER_CHECK') {
        return {
          ...state,
          warehouseHistoryOrderCheck: action.payload,
        };
      }
      else if (action.type === 'SET_WAREHOUSE_EDIT_ENVELOPE_CHECK') {
        return {
          ...state,
          warehouseEditEnvelopeCheck: action.payload,
        };
      }
      else if (action.type === 'SET_WAREHOUSE_HOME_TRANSFER_LEADER_CHECK') {
        return {
          ...state,
          warehouseHomeTransferLeaderCheck: action.payload,
        };
      }
      return state;
    };
    
    export default workZoneCheckData;