import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setModeratorLeadersDelete, setModeratorLeadersPointsDelete } from '../../../../redux/actions/moderatorData';
import { setPopupBackgroundFunc, setPopupOpenBlock} from '../../../../redux/actions/popupBlocks';

import './index.scss';
import { setModeratorDeliteCheck, setModeratorFolderDeliteLoadingCheckId, setModeratorFolderDeliteLoadingCheckIdDelete } from './../../../../redux/actions/moderatorCheckData';
import { setModeratorCatalogDelete } from './../../../../redux/actions/moderatorData';

const DeletePopup = () => {
    const dispatch = useDispatch() 
    
    const {moderatorDeliteCheck} = useSelector(({moderatorCheckData})=> {
        return {
            moderatorDeliteCheck: moderatorCheckData.moderatorDeliteCheck,
        }
    })

    const deliteLeader = async() => {
        let response = await fetch(`/Moderator/DeleteLeader?id=${moderatorDeliteCheck.id}`, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
        })
        if (response.ok === true) {
            const dat = await response.json();
            if(dat.value.result === 1){
                dispatch(setModeratorLeadersDelete(moderatorDeliteCheck.id))
                dispatch(setModeratorDeliteCheck({id: 0, name: '', type: ''}))
                dispatch(setPopupBackgroundFunc(false))
                dispatch(setPopupOpenBlock(''))
            }
        }
        else{
          localStorage.setItem('role', '')
        }
    }

    const delitePoint = async() =>{
        let response = await fetch(`/Moderator/DelerePlaces?id=${moderatorDeliteCheck.id}`, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
        })
        if (response.ok === true) {
            const dat = await response.json();
            if(dat.value.result === 1){
                dispatch(setModeratorLeadersPointsDelete(moderatorDeliteCheck.id))
                dispatch(setModeratorDeliteCheck({id: 0, name: '', type: ''}))
                dispatch(setPopupBackgroundFunc(false))
                dispatch(setPopupOpenBlock(''))
            }
        }
        else{
          localStorage.setItem('role', '')
        }
    }

    const deleteFolder = async() =>{
        let elem = {id: moderatorDeliteCheck.id, name: moderatorDeliteCheck.name};
        dispatch(setModeratorFolderDeliteLoadingCheckId(moderatorDeliteCheck.id))
        dispatch(setModeratorDeliteCheck({id: 0, name: '', type: ''}))
        dispatch(setPopupBackgroundFunc(false))
        dispatch(setPopupOpenBlock(''))

        let response = await fetch(`/Moderator/DeleteFolder?id=${moderatorDeliteCheck.id}`, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
        })
        if (response.ok === true) {
            const data = await response.json();
            if(data.value){
                dispatch(setModeratorFolderDeliteLoadingCheckIdDelete(elem.id))
                dispatch(setModeratorCatalogDelete(moderatorDeliteCheck.id))
            }
        }
        else{
            dispatch(setModeratorFolderDeliteLoadingCheckIdDelete(elem.id))
            alert(`Не удалось удалить папку ${elem.name}. Попытайтесь снова.`)
        }
    }

    const TitleList = (type, name) =>{
        switch(type){
            case 'Leader': return <React.Fragment>Вы действительно хотите удалить пользователя <span>{name}</span>?</React.Fragment>
            case 'Point': return <React.Fragment>Вы действительно хотите удалить точку <span>{name}</span>?</React.Fragment>
            case 'Folder': return <React.Fragment>В папке <span>{name}</span> есть содержимое. <br/> Вы действительно хотите ее удалить?</React.Fragment>
        }
    }
        
	return (
            <div className="moderator__popup-container delite">
                <div className="moderator__popup-delite-title">{TitleList(moderatorDeliteCheck.type, moderatorDeliteCheck.name)}</div>
                <div className='moderator__popup-delite-btn d-flex justify-between align-center'>
                    <div className="moderator__popup-delite-btn-item delite" onClick={()=>{
                        if(moderatorDeliteCheck.type === 'Leader') deliteLeader()
                        else if(moderatorDeliteCheck.type === 'Point') delitePoint()
                        else if(moderatorDeliteCheck.type === 'Folder') deleteFolder()
                    }}>Удалить</div>

                    <div className="moderator__popup-delite-btn-item close" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                    }}>Отменить</div>    
                </div>                
               
            </div>
	)
}
export default DeletePopup;