import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setPopupBackgroundFunc, setPopupOpenBlock} from '../../../redux/actions/popupBlocks';
import {setModeratorNav} from '../../../redux/actions/navigation';
import { setModeratorComplaintsCheck } from '../../../redux/actions/moderatorCheckData';

import './Complaints.scss';

import LekaloIcon from '../../../img/lekalo-icon.svg';
import Mailing from '../../../img/mailing-icon.svg'
import { fetchModeratorComplaints, setModeratorComplaintsAdd } from '../../../redux/actions/moderatorData';
import { setSiginalConnectReset } from '../../../redux/actions/siginalConnect';


const Complaints = () => {
    const dispatch = useDispatch()
    
    const {moderatorComplaints, siginalConnect, session} = useSelector(({moderatorData, navigation, siginalConnect})=> {
        return {
            moderatorComplaints: moderatorData.moderatorComplaints,
            session: navigation.session,
            siginalConnect: siginalConnect.connect
        }
    })

    useEffect(()=>{
        dispatch(setModeratorNav('Complaints'))
        dispatch(fetchModeratorComplaints())
    }, [])

    useEffect(()=>{
        if(siginalConnect.type === 'new-moderator-notification'){
            // console.log(siginalConnect)
            dispatch(setModeratorComplaintsAdd(siginalConnect.obj.elem))
            if (session === 1) {
                let audio = new Audio('/img/notification-audio.mp3');
                audio.play();
            }
            dispatch(setSiginalConnectReset())
        }
    }, [siginalConnect])

    

    const addTableItem = ({id, point, lekalo, complaints, statusColor}, key) =>{
        return(
            <div className={`moderator__complaints-line d-flex align-center justify-between moderator__complaints-line-${id}`} key={key} onClick={()=>{
                dispatch(setModeratorComplaintsCheck({...moderatorComplaints[key], key: key}))
                dispatch(setPopupBackgroundFunc(true))
                dispatch(setPopupOpenBlock('ComplaintsDetail'))

            }}>
                <div className="moderator__complaints-address">{point}</div>
                <div className="moderator__complaints-lekalo d-flex align-center">
                    <img src={LekaloIcon} alt="" className="moderator__complaints-lekalo-icon"/>
                    <span className="d-block">{(lekalo === null)? 'Лекало не было выбрано': lekalo}</span>
                </div>
                <div className="moderator__complaints-description">{complaints}</div>
                <div className="moderator__complaints-status">
                    <div className={`moderator__complaints-status-point ${statusColor}`}></div>
                </div>
            </div> 
        )
    }

	return (
        <React.Fragment>
			<div className="moderator__title">Жалобы</div>
			<div className="moderator__complaints">
                <div className="moderator__leaders-top d-flex justify-between align-center">
                    <div className="moderator__leaders-title staf">Список жалоб</div>
                    <div className="moderator__leaders-add-leader-btn d-flex justify-center align-center" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(true))
                        dispatch(setPopupOpenBlock('Maling'))
                    }}>
                        <img src={Mailing} alt="" className="moderator__leaders-add-leader-btn-icon" />
                        <div className="moderator__leaders-add-leader-btn-title">Отправить рассылку</div>
                    </div>
                </div>
                <div className="moderator__complaints-table">
                    <div className="moderator__complaints-table-scrollX">
                        <div className="moderator__complaints-line title d-flex align-center justify-between">
                            <div className="moderator__complaints-address">Адрес жалобы</div>
                            <div className="moderator__complaints-lekalo title">Лекало</div>
                            <div className="moderator__complaints-description">Описание жалобы</div>
                            <div className="moderator__complaints-status">Статус</div>
                        </div>
                        <div className="moderator__complaints-table-scroll">
                            {
                                moderatorComplaints.map((item, key) => addTableItem(item, key))
                            }
                        </div>
                    </div>
                </div>
			</div>
		</React.Fragment>
	)
}
export default Complaints;