import { event } from 'jquery';
import React, {useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setPopupBackgroundFunc, setPopupOpenBlock} from '../../../../redux/actions/popupBlocks';
import { setWarehouseHomeDataAdd } from '../../../../redux/actions/warehouseData';

import './index.scss';
import { setWarehouseHomeDataEdit } from './../../../../redux/actions/warehouseData';
import { setWarehouseEditEnvelopeCheck } from '../../../../redux/actions/warehouseCheckData';

const AddEnvelope = () => {
    const dispatch = useDispatch()

    const {warehouseEditEnvelopeCheck} = useSelector(({warehouseCheckData})=> {
        return {
            warehouseEditEnvelopeCheck: warehouseCheckData.warehouseEditEnvelopeCheck
        }
    })

    const [inform, setInform] = useState((warehouseEditEnvelopeCheck.id !== 0)? warehouseEditEnvelopeCheck: {
        id: 0,
        name: '',
        article: '',
        count: '',
        summ: '',
    })
    
    const [isEdit] = useState(warehouseEditEnvelopeCheck.id !== 0)
    const [error, setError] = useState('')
    const [clickAddBtn, setClickAddBtn] = useState(false)

    const postDataFunc = async(url, data) =>{
        const response =  await fetch(url, {
            method: 'POST', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })
        if (response.ok === true) {
            const dat = await response.json();
            return dat.value.resultObject;  
        }
        else return false
    }

    const fetchGet = async(url, query='')=>{
        const response =  await fetch(url + query, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            }
        })
        if (response.ok === true) {
            const dat = await response.json();
            return dat.value
        }
    }
    
    const postData = async(event) =>{
        event.preventDefault()
        if((!clickAddBtn && inform.name !== '' && inform.article !== '' && inform.count !== '' && inform.summ !== '') || (!clickAddBtn && isEdit && inform.name !== '')){
            setClickAddBtn(true)
            if(!isEdit){
                postDataFunc('/stock/addFilms', inform).then((obj) =>{
                    if(obj){
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                        dispatch(setWarehouseHomeDataAdd(obj))
                        setError('')                   
                    }
                    setClickAddBtn(false)
                })
            }
            else{
                // console.log({id: warehouseEditEnvelopeCheck.id, name: inform.name}, warehouseEditEnvelopeCheck.name)
                fetchGet(`/stock/renameFilm?filmId=`, `${warehouseEditEnvelopeCheck.filmId}&name=${inform.name}`).then((obj) =>{
                    if(obj){
                        dispatch(setWarehouseHomeDataEdit({...warehouseEditEnvelopeCheck, name: inform.name}))
                        dispatch(setWarehouseEditEnvelopeCheck({id: 0, name: '', article: '', count: '', filmId: '', summ: ''}))
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                        setError('')                   
                    }
                    setClickAddBtn(false)
                })
            }
        }
        else{
            setError('Необходимо заполнить все поля*')
        }
    }

	return (
            <div className="warehouse__popup-container">
                <div className="warehouse__popup-top d-flex justify-between align-center add-leaders">
                    <div className="popup__title blue">{(isEdit)? 'Редактирование': 'Добавление'}</div>
                    <div className="popup__close-btn" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                    }}></div>
                </div>
                <form action="" onSubmit={postData}>
                    <label htmlFor="" className='warehouse__popup-label'>Название</label>
                    <input name='EnvelopeName' autoComplete="off" type="text" readOnly={isEdit && clickAddBtn} defaultValue={inform.name} placeholder='Введите название пленки' className="warehouse__popup-input" onInput={(event)=>{
                        setInform({...inform, name: event.target.value})
                    }}/> 
                    <div className='d-flex justify-between'>
                        <div className='warehouse__popup-input-third'>
                            <label htmlFor="" className='warehouse__popup-label'>Артикул</label>
                            <input name='EnvelopeArticle' type="number"  readOnly={isEdit} defaultValue={inform.article} placeholder='Введите артикул' className={`warehouse__popup-input ${(isEdit)? 'grey': ''}`} onInput={(event)=>{
                                setInform({...inform, article: event.target.value})
                            }}/> 
                        </div>
                        <div className='warehouse__popup-input-third'>
                            <label htmlFor="" className='warehouse__popup-label'>Количество</label>
                            <input name='EnvelopeCount' type="number" readOnly={isEdit} defaultValue={inform.count} placeholder='Введите кол-во' className={`warehouse__popup-input ${(isEdit)? 'grey': ''}`} onInput={(event)=>{
                                setInform({...inform, count: event.target.value})
                            }}/> 
                        </div>
                        <div className='warehouse__popup-input-third'>
                            <label htmlFor="" className='warehouse__popup-label'>Цена</label>
                            <input name='EnvelopePrice' type="number" readOnly={isEdit} defaultValue={inform.summ} placeholder='Введите цену' className={`warehouse__popup-input ${(isEdit)? 'grey': ''}`} onInput={(event)=>{
                                setInform({...inform, summ: event.target.value})
                            }}/> 
                        </div>
                    </div>
                    <div className='popup__error'>{error}</div>               
                    
                    <button type='submit' className={`warehouse__popup-save d-flex align-center justify-center ${(clickAddBtn)? 'deactive': ''}`}>{(isEdit)? 'Сохранить': 'Добавить'} <img className={`warehouse__popup-save-icon ${(isEdit)? 'ds-none': ''}`} src="/img/plus-white-icon.svg" alt="" /></button>
                        
                </form>                    
            </div>
	)
}
export default AddEnvelope;