import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import {setFranchisesNav} from '../../../redux/actions/navigation';
import {setFranchiserStaffMonth, setFranchiserStaffYear} from '../../../redux/actions/popupFilters';
import {setCalendDisplay} from '../../../redux/actions/display';
import './Staff.scss';
import { Link } from 'react-router-dom';

import AddLeaderIcon from '../../../img/add-leader-icon.svg'
import { setPopupBackgroundFunc, setPopupOpenBlock} from '../../../redux/actions/popupBlocks';
import { setFranchiserHomeStaffListCheck, setFranchiserLayoffsStaffCheck } from '../../../redux/actions/franchiserCheckData';
import { fetchFranchiserStaffButtonsData, fetchFranchiserStaffData, fetchFranchiserStaffDetail, setFranchiserStaffList } from '../../../redux/actions/franchiserData';

const LeaderStaf = () => {

    const dispatch = useDispatch();

    let [tablePageNum, setTablePageNum] = useState(0)

    

    const {buttonsData, staffData, range, month, year} = useSelector(({franchiserData, popupFilters})=> {
        return {
            buttonsData: franchiserData.franchiserStaffButtonsData,
            staffData: franchiserData.franchiserStaffData,
            range: popupFilters.franchiserStaffRange,
            month: popupFilters.franchiserStaffMonth,
            year: popupFilters.franchiserStaffYear,
        }
    })

    useEffect(()=>{
        dispatch(fetchFranchiserStaffButtonsData(range, month, year))
        dispatch(fetchFranchiserStaffData(range, month, year))
    }, [])

    useEffect(()=>{
        dispatch(fetchFranchiserStaffButtonsData(range, month, year))
        dispatch(fetchFranchiserStaffData(range, month, year))
    }, [range, month, year])

    let buttonTimeFunc = (range, month, year) =>{
        return (range.length !== 0 && range[0].toLocaleDateString() !== range[1].toLocaleDateString())? `${range[0].toLocaleDateString()} - ${range[1].toLocaleDateString()}`: (range.length !== 0)? `${range[0].toLocaleDateString()}`: (month !== 'Месяц' && year === 'Год')? `За ${month} текущего года`:(month !== 'Месяц' && year !== 'Год')? `За ${month} ${year} года`:(month === 'Месяц' && year !== 'Год')? `За ${year} год`: 'За текущий год'
    }

    let dataMonth = [
        {
            id: 0,
            value: "Январь"
        },
        {
            id: 1,
            value: "Февраль"
        },
        {
            id: 2,
            value: "Март"
        },
        {
            id: 3,
            value: "Апрель"
        },
        {
            id: 4,
            value: "Май"
        },
        {
            id: 5,
            value: "Июнь"
        },
        {
            id: 6,
            value: "Июль"
        },
        {
            id: 7,
            value: "Август"
        },
        {
            id: 8,
            value: "Сентябрь"
        },
        {
            id: 9,
            value: "Октябрь"
        },
        {
            id: 10,
            value: "Ноябрь"
        },
        {
            id: 11,
            value: "Декабрь"
        }
    ];
    let defaultYear = new Date().getFullYear()
    let dataYear = [
        {
            id: 0,
            value: defaultYear - 5
        },
        {
            id: 1,
            value: defaultYear - 4
        },
        {
            id: 2,
            value: defaultYear - 3
        },
        {
            id: 3,
            value: defaultYear - 2
        },
        {
            id: 4,
            value: defaultYear - 1
        },
        {
            id: 5,
            value: defaultYear
        },
        {
            id: 6,
            value: defaultYear + 1
        },
        {
            id: 7,
            value: defaultYear + 2
        },
        {
            id: 8,
            value: defaultYear + 3
        },
        {
            id: 9,
            value: defaultYear + 4
        },
        {
            id: 10,
            value: defaultYear + 5
        }
    ]

    const fetchCheckLogin = async(url, query='')=>{
        const response =  await fetch(url + query, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            }
        })
        if (response.ok === true) {
            const dat = await response.json();
            // console.log(dat)
            return dat.value
        }
    }

    const [monthActive, setMonthActive]  = useState(false);
    const [yearActive, setYearActive]  = useState(false);

    const monthActivate = () =>{
        setMonthActive(!monthActive)
        setYearActive(false)        
    }
    const yearActivate = () =>{
        setYearActive(!yearActive)
        setMonthActive(false)
    }
    const monthChange = (id) =>{
        dispatch(setFranchiserStaffMonth(dataMonth[id].value))
        setMonthActive(false)
    }
    const yearChange = (id) =>{
        dispatch(setFranchiserStaffYear(dataYear[id].value))
        setYearActive(false)
    }

    const addMonth = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(month === value)? "active": ""}`} key={key} onClick={() => {monthChange(id)}}>{value}</div>
        )
    }
    const addYear = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(year === value)? "active": ""}`} key={key} onClick={() => {yearChange(id)}}>{value}</div>
        )
    }

    const cutName = (name) =>{
        let cut = name.split(' ')
        if(cut.length === 3){
            return cut[0] + ' ' + cut[1] + ' ' + cut[2][0] + '.'
        }
        else return name
    }

    

    const addStaf = ({id, name, fullName, status, statusColor, phone, historySales, garanty, position, login, password}, key) =>{
        return(
            <div className={`franchises-leaders__staf-line d-flex align-center justify-between franchiser__scroll-staff-line-${id}`} key={key}>
                <div className="franchises-leaders__staf-person" title={fullName}>{cutName(fullName)}</div>
                <div className={`franchises-leaders__staf-login ${statusColor}`}>{status}</div>
                <a href={`tel:89284123232`} className="franchises-leaders__staf-phone">{phone}</a>
                <div className="franchises-leaders__staf-password">{historySales} поклеек</div>
                <div className="franchises-leaders__staf-email">{garanty}</div>
                <img  src="./img/layoffs-icon.svg" alt="" className={`edit-icon-block d-flex justify-center align-center ${(statusColor === 'red')? 'inactively': ''}`} onClick={()=>{
                    dispatch(setFranchiserLayoffsStaffCheck({id, name: fullName}))
                    dispatch(setPopupBackgroundFunc(true))
                    dispatch(setPopupOpenBlock('LayoffsStaff'))                  
                }}/>
                <Link to='/staff-detail' onClick={()=>{
                    dispatch(setFranchisesNav("Staff-Detail"))
                    sessionStorage.setItem('page', 'Staff-Detail')
                    // dispatch(fetchFranchiserStaffDetail(id))
                    dispatch(setFranchiserHomeStaffListCheck({id, name, fullName, status, statusColor, phone, historySales, garanty, position, login, password}))
                    sessionStorage.setItem('StaffCheckId', id)
                }}>
                    <img src="./img/arrow-right-block.svg" alt="" className="owner__leaders-staf-open"></img>
                </Link>
            </div>
        )
    }

    const pageButtons = (button) =>{
        let pet = tablePageNum
        if(button == "back"){
            setTablePageNum(tablePageNum-1)
        }
        else{
            setTablePageNum(tablePageNum+1)
        }
        // console.log(pet)
    }

	return (
        <React.Fragment>
            <div className="franchises-leaders__staf-buttons ">
            <div className="franchises-leaders__staf-points-top d-flex justify-between align-center">
                    <div className="franchises-leaders__staf-points-title staf">Общая статистика</div>
                    <div className='franchises-leaders__sales-filters__block d-flex align-center'>
                        <div className="franchises-leaders__sales-filters__block-item sales year">
                            <div className={`franchises-leaders__sales-filters__block-item-title d-flex align-center justify-center year ${(year === "Год")? "": "active"} ${(yearActive)? "open": ""}`} onClick={yearActivate}>{year}<div className="franchises-leaders__sales-filters__block-item-title-icon"/></div>                        
                            <div className={`franchises-leaders__sales-filters__block-item-list ${(yearActive)? "open": ""}`}>
                                <div className='franchises-leaders__sales-filters__block-item-list-scroll'>
                                    {
                                        dataYear.map((item, key) => addYear(item, key))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="franchises-leaders__sales-filters__block-item sales months">
                            <div className={`franchises-leaders__sales-filters__block-item-title d-flex align-center justify-center months ${(month === "Месяц")? "": "active"} ${(monthActive)? "open": ""}`} onClick={monthActivate}>{month}<div className="franchises-leaders__sales-filters__block-item-title-icon"/></div>                        
                            <div className={`franchises-leaders__sales-filters__block-item-list ${(monthActive)? "open": ""}`}>
                                <div className='franchises-leaders__sales-filters__block-item-list-scroll'>
                                    {
                                        dataMonth.map((item, key) => addMonth(item, key))
                                    }                               
                                </div>
                            </div>
                        </div>
                        <div className="franchises-leaders__sales-filters d-flex justify-between">
                            <div className="franchises-leaders__sales-filters months" onClick={() => {
                                dispatch(setCalendDisplay(""));
                                setTimeout(()=>{
                                    dispatch(setCalendDisplay("open", "franchiserStaff"))
                                }, 0)
                            }}>
                                <div className={`franchises-leaders__sales-filters-title d-flex align-center justify-center months ${(range.length > 0)? 'active': ''}`}>Фильтры<div className="franchises-leaders__sales-filters-title-icon"/></div>                        
                            </div>
                        </div>
                    </div>
                </div>
                <div className="franchises-leaders__staf-buttons-container justify-between align-center">
                    <div className="franchises-leaders__staf-buttons-sale d-flex flex-column">
                        <div className="d-flex justify-center">
                            <div className="franchises-leaders__staf-buttons-icon sale align-center justify-center">
                                <img src="/img/money-black-icon.svg" alt="" />
                            </div>
                            <div>
                                <div className="franchises-leaders__staf-buttons-title sale">Заработная плата</div>
                                <div className="franchises-leaders__staf-buttons-val sale">{buttonsData.salary.value}</div>
                                <div className={`franchises-leaders__staf-buttons-val-percent d-flex align-center`}>
                                    <img src="/img/graph-circle-icon.svg" alt="" className="franchises-leaders__staf-buttons-val-percent-icon"/> 
                                    <div>{buttonTimeFunc(range, month, year)}</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="franchises-leaders__staf-buttons-income d-flex flex-column">
                        <div className="d-flex justify-center">
                            <div className="franchises-leaders__staf-buttons-icon income align-center justify-center">
                                <img src="/img/people-black-icon.svg" alt="" /></div>
                            <div>
                                <div className="franchises-leaders__staf-buttons-title income">Количество сотрудников</div>
                                <div className="franchises-leaders__staf-buttons-val income">{buttonsData.staff.value} человек</div>
                                <div className={`franchises-leaders__staf-buttons-val-percent d-flex align-center`}>
                                    <img src="/img/layoffs-circle-icon.svg" alt="" className="franchises-leaders__leaders-staf-buttons-val-percent-icon"/> 
                                    <div>{buttonsData.staff.layoffs} увольнения</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="franchises-leaders__staf">
            <div className="franchises-leaders__staf-points-top d-flex justify-between align-center">
                    <div className="franchises-leaders__staf-points-title staf">Список сотрудников</div>
                    <div className="franchises-leaders__staf-add-leader-btn d-flex justify-center align-center" onClick={()=>{
                            dispatch(setPopupBackgroundFunc(true))
                            dispatch(setPopupOpenBlock('AddStaff'))
                        }}>
                        <img src={AddLeaderIcon} alt="" className="moderator__leaders-add-leader-btn-icon" />
                        <div className="franchises-leaders__staf-add-leader-btn-title">Добавить сотрудника</div>
                    </div>
                </div>
                <div className='franchises-leaders__staf-table-scroll'>
                    <div className="franchises-leaders__staf-line title d-flex align-center">
                        <div className="franchises-leaders__staf-person">ФИО</div>
                        <div className="franchises-leaders__staf-login blue">Статус</div>
                        <div className="franchises-leaders__staf-phone">Телефон</div>
                        <div className="franchises-leaders__staf-password">История продаж</div>
                        <div className="franchises-leaders__staf-email">Гарантия</div>
                        <div className='edit-icon-block d-flex justify-center align-center none'></div>
                        <img src="/img/arrow-right-block" alt="" className="franchises-leaders__staf-open none"></img>
                        
                    </div>
                    <div className="franchises-leaders__staf-table">
                        {
                            (window.innerWidth > 700)?(
                                staffData.map((item, key) => addStaf(item, key))
                            ):(
                                (tablePageNum * 5 <= staffData.length)?(
                                    staffData.slice(tablePageNum * 5, tablePageNum * 5 + 5).map((item, key) => addStaf(item, key))
                                ):(
                                    staffData.slice(tablePageNum * 5).map((item, key) => addStaf(item, key))
                                )
                            )
                        }                   
                    </div>
                </div>
                {
                    (window.innerWidth < 700)?(
                        <div className='franchises-leaders__points-sale-table-buttons d-flex justify-between'>
                            <div className={`franchises-leaders__points-sale-table-buttons-item back ${(tablePageNum > 0)? 'active': ''}`} onClick={()=>{pageButtons("back")}}></div>
                            <div className={`franchises-leaders__points-sale-table-buttons-item forward ${(tablePageNum === 0 && staffData.length > 5 || staffData.length % tablePageNum > 0)? 'active': ''}`} onClick={()=>{pageButtons("forward")}}></div>
                        </div>
                    ): null
                }
            </div>
        </React.Fragment>        
	)
}

export default LeaderStaf;