const initialState = {
  warehouseHomeData: [
  ],

  warehouseLeadersData: [
  ],

  warehouseLeaderPointsData: [
  ],

  warehouseLeaderPointDetailData: [
  ],

  warehouseOrderHistoryData: [
  ],

  warehouseOrderHistoryDetailData: [
  ],

  warehouseLadersListlData: [
  ],

  warehousePointsListlData: [
  ]
};
    
    const warehouseData = (state = initialState, action) => {
      if (action.type === 'SET_WAREHOUSE_HOME_DATA') {
        return {
          ...state,
          warehouseHomeData: action.payload,
        };
      }
      else if (action.type === 'SET_WAREHOUSE_HOME_DATA_ADD') {
        return {
          ...state,
          warehouseHomeData: [...state.warehouseHomeData, action.payload],
        };
      }
      else if (action.type === 'SET_WAREHOUSE_HOME_DATA_EDIT') {
        let data = state.warehouseHomeData.slice();
        let index = data.findIndex(x => x.id === action.payload.id)
        if(index !== -1){
          data[index] = action.payload
          return {
            ...state,
            warehouseHomeData: data,
          };
        }
      }
      else if (action.type === 'SET_WAREHOUSE_HOME_DATA_DELETE') {
        let data = state.warehouseHomeData.slice();
        let index = data.findIndex(x => x.id === action.id)
        if(index !== -1){
          data.splice(index, 1)
          return {
            ...state,
            warehouseHomeData: data,
          };
        }
      }
      else if (action.type === 'SET_WAREHOUSE_LEADERS_DATA') {
        return {
          ...state,
          warehouseLeadersData: action.payload,
        };
      }
      else if (action.type === 'SET_WAREHOUSE_LEADER_POINTS_DATA') {
        return {
          ...state,
          warehouseLeaderPointsData: action.payload,
        };
      }
      else if (action.type === 'SET_WAREHOUSE_LEADER_POINT_DETAIL_DATA') {
        return {
          ...state,
          warehouseLeaderPointDetailData: action.payload,
        };
      }
      else if (action.type === 'SET_WAREHOUSE_ORDER_HISTORY_DATA') {
        return {
          ...state,
          warehouseOrderHistoryData: action.payload,
        };
      }
      else if (action.type === 'SET_WAREHOUSE_ORDER_HISTORY_DATA_EDIT') {
        let data = state.warehouseOrderHistoryData.slice();
        let index = data.findIndex(x => x.id === action.payload.id)
        if(index !== -1){
          data[index] = action.payload
          return {
            ...state,
            warehouseOrderHistoryData: data,
          };
        }
      }
      else if (action.type === 'SET_WAREHOUSE_ORDER_HISTORY_DETAIL_DATA') {
        return {
          ...state,
          warehouseOrderHistoryDetailData: action.payload,
        };
      }
      else if (action.type === 'SET_LEADERS_LIST_DATA') {
        return {
          ...state,
          warehouseLadersListlData: action.payload,
        };
      }
      else if (action.type === 'SET_POINTS_LIST_DATA') {
        return {
          ...state,
          warehousePointsListlData: action.payload,
        };
      }
      return state;
    };
    
    export default warehouseData;