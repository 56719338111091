import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import {fetchOwnerLeaderLeadersList} from '../../../../redux/actions/ownerData';
import {setOwnerLeadersLeaderId} from '../../../../redux/actions/filters';
import {setLeadersNav, setOwnerNav} from '../../../../redux/actions/navigation';
import { Link } from 'react-router-dom';

import './Leaders-table-1.0.scss'

const LeadersTable = () => {
    const dispatch = useDispatch();
    
    let {tableData} = useSelector(({ownerData})=> {
        return {
            tableData: ownerData.ownerLeadersLeaderList
        }
    })

    let [tablePageNum, setTablePageNum] = useState(0)
    useEffect(()=>{
        dispatch(fetchOwnerLeaderLeadersList())
        dispatch(setOwnerNav('Leaders'))
        dispatch(setLeadersNav('leaders'))
    }, [])

    const addPercentBlock = ({percent, color}) =>{
        if(color === "green"){
            return(
                <div className="owner__leaders-val-percent d-flex align-center green">
                    <img src="/img/arrow-up-green.svg" alt="" className="owner__leaders-val-percent-icon"/> 
                    <div>{percent}%</div>
                </div>
            )
        }
        else{
            return(
                <div className="owner__leaders-val-percent d-flex align-center red">
                    <img src="/img/arrow-down-red.svg" alt="" className="owner__leaders-val-percent-icon"/> 
                    <div>{percent}%</div>
                </div>
            )
        }
    }
    const addTableItem = ({id, photo, name, amountPoints, income, sales, purchase}, key) =>{
        return(
            <Link to='/leaders/staf' key={key}onClick={()=>{
                dispatch(setLeadersNav("staf"))
                dispatch(setOwnerLeadersLeaderId(id))
                localStorage.setItem("leaderId", id)
            }}>
                <div className={`owner__leaders-line d-flex align-center justify-between sroll-leaders-line-${id}`}  >
                    <div className="owner__leaders-person d-flex align-center">
                        <div className="owner__leaders-person-photo-container d-flex align-center justify-center">
                            <img src={photo} alt="" className="owner__leaders-person-photo"/>
                        </div>
                        <span className="d-block">{name}</span>
                    </div>
                    <div className="owner__leaders-amount-points">{amountPoints}</div>
                    <div className="owner__leaders-income align-center">
                        <div>{income.value}</div>
                        {
                            addPercentBlock(income)
                        }
                    </div>                   
                    <div className="owner__leaders-sales align-center">
                        <div>{sales.value}</div>
                        {
                            addPercentBlock(sales)
                        }
                    </div>
                    <div className="owner__leaders-purchase align-center">
                        <div>{purchase.value}</div>
                        {
                            addPercentBlock(purchase)
                        }
                    </div>
                    <div className="owner__leaders-open-icon"><img src="/img/arrow-right.svg" alt=""/></div>
                </div>
            </Link>
        )
    }

    const pageButtons = (button) =>{
        let pet = tablePageNum
        if(button == "back"){
            setTablePageNum(tablePageNum-1)
        }
        else{
            setTablePageNum(tablePageNum+1)
        }
        // console.log(pet)
    }

	return (
        <React.Fragment>
			<div className="owner__title">Таблица</div>
			<div className="owner__content d-flex justify-between">
                <div className="owner__leaders">
                    <div className="owner__leaders-title">Список руководителей</div>
                    <div className="owner__leaders-table-scroll">
                        <div className="owner__leaders-line title d-flex align-center justify-between">
                            <div className="owner__leaders-person">ФИО</div>
                            <div className="owner__leaders-amount-points">Количество точек</div>
                            <div className="owner__leaders-income">Доход</div>
                            <div className="owner__leaders-sales">Продажи</div>
                            <div className="owner__leaders-purchase">Закуп</div>
                            <div className="owner__leaders-open-icon none"></div>
                        </div>
                        <div className="owner__leaders-table">
                            {
                                (window.innerWidth > 700)?(

                                   tableData.map((item, key) => addTableItem(item, key))

                                ):(
                                    (tablePageNum * 5 <= tableData.length)?(
                                        tableData.slice(tablePageNum * 5, tablePageNum * 5 + 5).map((item, key) => addTableItem(item, key))
                                    ):(
                                        tableData.slice(tablePageNum * 5).map((item, key) => addTableItem(item, key))
                                    )
                                )
                            }
                            
                        </div>
                    </div>
                    {
                        (window.innerWidth < 700)?(
                            <div className='owner__points-sale-table-buttons d-flex justify-between'>
                                <div className={`owner__points-sale-table-buttons-item back ${(tablePageNum > 0)? 'active': ''}`} onClick={()=>{pageButtons("back")}}></div>
                                <div className={`owner__points-sale-table-buttons-item forward ${((tablePageNum === 0 && tableData.length > 5) || tableData.length > (tablePageNum+1) * 5)? 'active': ''}`} onClick={()=>{pageButtons("forward")}}></div>
                            </div>
                        ): null
                    }
                </div>
			</div>
		</React.Fragment>
        
	)
}

export default LeadersTable;