import React, {useEffect, useRef} from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

const GrafBar = ({data, addClass, buttonActive, selRange}) =>{ 
    let chartGrafOne = useRef(null);
    let monthNum = {
      "1": "Янв",
      "2":"Фев",
      "3":"Мар",
      "4": "Апр",
      "5": "Май",
      "6": "Июн",
      "7": "Июл",
      "8": "Авг",
      "9": "Сен",
      "10": "Окт",
      "11": "Ноя",
      "12": "Дек"
  }
    const Data = () =>{
      var chartData = [];
      for(let item of data) {
        chartData.push({
          month: (selRange)? item.name: monthNum[item.name],
          value: item.value.toFixed(0)
        });
      }
      return chartData;
    }

    useEffect(() => {
    chartGrafOne.current = am4core.create(addClass, am4charts.XYChart);
    // Add data
    chartGrafOne.current.data = Data();
    chartGrafOne.current.fontFamily = 'Montserrat';
    chartGrafOne.current.fontSize = '80%';
    // Create axes

    let categoryAxis = chartGrafOne.current.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "month";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 320
    categoryAxis.renderer.labels.template.verticalCenter = "center";
    categoryAxis.renderer.labels.template.horizontalCenter = "right";

    chartGrafOne.current.yAxes.push(new am4charts.ValueAxis());

    // Create series
    let series = chartGrafOne.current.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "month";
    series.name = "Value";
    series.columns.template.tooltipText = `${buttonActive}: {valueY.value}`;
    series.columns.template.fillOpacity = 1;

    let columnTemplate = series.columns.template;
    columnTemplate.width = am4core.percent(80);
    columnTemplate.maxWidth = 90;
    columnTemplate.column.cornerRadius(8, 8, 0, 0);
    columnTemplate.strokeWidth = 1;
    columnTemplate.strokeOpacity = 0;
    columnTemplate.fill = am4core.color("#2A85FF");

    chartGrafOne.current.scrollbarX = new am4core.Scrollbar();
    chartGrafOne.current.scrollbarX.parent = chartGrafOne.current.bottomAxesContainer;

    });
    useEffect(() => {
        chartGrafOne.current.data = Data();
    });
    return(
            <div className={addClass} ></div>
    )
}

export default GrafBar;