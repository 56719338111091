const initialState = {
    franchiserHomePointsCheck: {
      id: (sessionStorage.getItem('pointsCheckId') !== null)? sessionStorage.getItem('pointsCheckId') :0,
      name: (sessionStorage.getItem('pointsCheckName') !== null)? sessionStorage.getItem('pointsCheckName') :''
    },

    franchiserLayoffsStaffCheck: {id: 0, name: ''},

    franchiserHomeStaffListCheck:{
      id: (sessionStorage.getItem('StaffCheckId') !== null)? sessionStorage.getItem('StaffCheckId') :0,
      name: '',
      fullName: '',
      status: '',
      satausColor: '',
      phone: '', 
      historySales: 0,
      salary: 0,
      login: '',
      password: '',
      position: ''
    },

    franchiserHomePointsListCheck:(sessionStorage.getItem('FranchiserHomePointsListCheck') !== null)? JSON.parse(sessionStorage.getItem('FranchiserHomePointsListCheck')): [
    ],

    franchiserWarehousePointsListCheck:{
      id: 0, 
      name: '', 
      amount: 0,
    },

    

    franchiserWarehouseTransfer:{
      id: 0, 
      select: '',
      moldCode: '',
      moldId: 0,
      filmId: 0
    },

    franchiserSalariesCheckDay:{
      userId: '',
      salary: '',
      premium: '',
      fine: '',
      prepayment: '',
      date: ''
    }
};
    
    const franchiserCheckData = (state = initialState, action) => {
      if (action.type === 'SET_FRANCHISER_HOME_POINTS_CHECK') {
        return {
          ...state,
          franchiserHomePointsCheck: action.payload,
        };
      }
      else if (action.type === 'SET_FRANCHISER_HOME_STAFF_LIST_CHECK') {
        return {
          ...state,
          franchiserHomeStaffListCheck: action.payload,
        };
      }
      else if (action.type === 'SET_FRANCHISER_HOME_POINTS_LIST_CHECK') {
        sessionStorage.setItem('FranchiserHomePointsListCheck', JSON.stringify([...state.franchiserHomePointsListCheck, action.payload]))
        return {
          ...state,
          franchiserHomePointsListCheck: action.payload,
        };
      }
      else if (action.type === 'SET_FRANCHISER_HOME_POINTS_LIST_CHECK_EDIT') {
        let data = state.franchiserHomePointsListCheck.slice();
        let index = data.indexOf(action.payload)
        if(index !== -1){
          data.splice(index, 1)
          sessionStorage.setItem('FranchiserHomePointsListCheck', JSON.stringify(data))
          return {
            ...state,
            franchiserHomePointsListCheck: data,
          };
        }
        sessionStorage.setItem('FranchiserHomePointsListCheck', JSON.stringify([...state.franchiserHomePointsListCheck, action.payload]))
        return {
          ...state,
          franchiserHomePointsListCheck: [...state.franchiserHomePointsListCheck, action.payload],
        };
      }
      else if (action.type === 'SET_FRANCHISER_LAYOFFS_STAFF_CHECK') {
        return {
          ...state,
          franchiserLayoffsStaffCheck: action.payload,
        };
      }
      else if (action.type === 'SET_FRANCHISER_WAREHOUSE_POINTS_LIST_CHECK') {
        return {
          ...state,
          franchiserWarehousePointsListCheck: action.payload,
        };
      }
      else if (action.type === 'SET_FRANCHISER_WAREHOUSE_TRANSFER') {
        return {
          ...state,
          franchiserWarehouseTransfer: action.payload,
        };
      }
      else if (action.type === 'SET_FRANCHISER_SALARIES_CHECK_DAY') {
        return {
          ...state,
          franchiserSalariesCheckDay: action.payload,
        };
      }
      return state;
    };
    
    export default franchiserCheckData;