import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {} from '../../../redux/actions/filters';
import { fetchModeratorCatalog } from '../../../redux/actions/moderatorData';
import { setPopupOpenBlock, setPopupOpenBlockEvent } from '../../../redux/actions/popupBlocks';
import './Search.scss';

const Search = () => {
    const dispatch = useDispatch()
    const {navigation, openBlock, warehouseHomeData, warehouseLeadersData, warehouseLeaderPointsData, warehouseLeaderPointDetailData, warehouseOrderHistoryData, warehouseOrderHistoryDetailData} = useSelector(({navigation, warehouseData, popupBlocks})=> {
        return {
            navigation: navigation.warehouseNav,
            openBlock: popupBlocks.openBlock,
            warehouseHomeData: warehouseData.warehouseHomeData,
            warehouseLeadersData: warehouseData.warehouseLeadersData,
            warehouseLeaderPointsData: warehouseData.warehouseLeaderPointsData,
            warehouseLeaderPointDetailData: warehouseData.warehouseLeaderPointDetailData,
            warehouseOrderHistoryData: warehouseData.warehouseOrderHistoryData,
            warehouseOrderHistoryDetailData: warehouseData.warehouseOrderHistoryDetailData
        }
    })


    let [searchList, setSearchList] = useState([]);
    let [inputValue, setInputValue] = useState('');
    
    useEffect(()=>{
        setInputValue('')
        setSearchList([])
    }, [openBlock])

    const scrollToHome = (id) =>{
        let elem = document.querySelector(`.warehouse__home-table-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        // console.log(elem)
        scrollTo(document.querySelector('.warehouse__home-table'), +elem.offsetTop - +document.querySelector('.warehouse__home-table').offsetTop, 1000)
    }

    const scrollToLeaders = (id) =>{
        let elem = document.querySelector(`.warehouse__leaders-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        // console.log(elem)
        scrollTo(document.querySelector('.warehouse__leaders-table'), +elem.offsetTop - +document.querySelector('.warehouse__leaders-table').offsetTop, 1000)
    }

    const scrollToLeaderDetail = (id) =>{
        let elem = document.querySelector(`.warehouse__leader-detail-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.warehouse__leader-detail-table'), +elem.offsetTop - +document.querySelector('.warehouse__leader-detail-table').offsetTop, 1000)
    }

    const scrollToPointDeatail = (id) =>{
        console.log(id)
        let elem = document.querySelector(`.warehouse__leader-point-detail-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.warehouse__leader-point-detail-table'), +elem.offsetTop - +document.querySelector('.warehouse__leader-point-detail-table').offsetTop, 1000)
    }

    const scrollToOrderHistory = (id) =>{
        let elem = document.querySelector(`.warehouse__order-history-line-${id}`);
        // console.log(elem)
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.warehouse__order-history-table'), +elem.offsetTop - +document.querySelector('.warehouse__order-history-table').offsetTop, 1000)
    }

    const scrollToOrderHistoryDetail = (id) =>{
        let elem = document.querySelector(`.warehouse__order-history-detail-line-${id}`);
        // console.log(elem)
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.warehouse__order-history-detail-table'), +elem.offsetTop - +document.querySelector('.warehouse__order-history-detail-table').offsetTop, 1000)
    }

    function scrollTo(element, to, duration) {
        // console.log(element, to)
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;
            
        var animateScroll = function(){        
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }
    
    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };

    const addListItem = (item, key, type) =>{
        if(navigation === 'Home'){
            return(                
                <div className='d-flex justify-between align-center' key={key} onClick={()=>{
                    scrollToHome(item.id)
                    dispatch(setPopupOpenBlock(''))
                    dispatch(setPopupOpenBlockEvent(''))
                    setInputValue('')
                }}>
                     <div className='warehouse__search-list-item'>
                        <div className='d-flex'>
                            <div className={`warehouse__search-list-item-block mr-10`}>{item.article},</div>
                            <div className={`warehouse__search-list-item-block mr-10`}>{item.name}</div>
                        </div>                                            
                    </div>
                </div>
            )
        }
        else if(navigation === 'Leaders'){
            return(                
                <div className='d-flex justify-between align-center' key={key} onClick={()=>{
                    scrollToLeaders(item.id)
                    dispatch(setPopupOpenBlock(''))
                    dispatch(setPopupOpenBlockEvent(''))
                    setInputValue('')
                }}>
                     <div className='warehouse__search-list-item'>
                        <div className='d-flex'>
                            <div className={`warehouse__search-list-item-block`}>{item.name}</div>
                        </div>                       
                    </div>
                </div>
            )
        }
        else if(navigation === 'LeaderDetail'){
            return(                
                <div className='d-flex justify-between align-center' key={key} onClick={()=>{
                    scrollToLeaderDetail(item.id)
                    dispatch(setPopupOpenBlock(''))
                    dispatch(setPopupOpenBlockEvent(''))
                    setInputValue('')
                }}>
                     <div className='warehouse__search-list-item'>
                        <div className='d-flex'>
                            <div className={`warehouse__search-list-item-block`}>{item.name}</div>
                        </div>                       
                    </div>
                </div>
            )
        }
        else if(navigation === 'LeaderPointDetail'){
            return(                
                <div className='d-flex justify-between align-center' key={key} onClick={()=>{
                    console.log(item)
                    scrollToPointDeatail(item.article)
                    dispatch(setPopupOpenBlock(''))
                    dispatch(setPopupOpenBlockEvent(''))
                    setInputValue('')
                }}>
                    <div className='warehouse__search-list-item'>
                        <div className='d-flex'>
                            <div className={`warehouse__search-list-item-block mr-10`}>{item.article},</div>
                            <div className={`warehouse__search-list-item-block mr-10`}>{item.name}</div>
                        </div>                                            
                    </div>
                </div>
            )
        }
        else if(navigation === 'OrderHistory'){
            return(                
                <div className='d-flex justify-between align-center' key={key} onClick={()=>{
                    scrollToOrderHistory(item.id)
                    dispatch(setPopupOpenBlock(''))
                    dispatch(setPopupOpenBlockEvent(''))
                    setInputValue('')
                }}>
                    <div className='warehouse__search-list-item'>
                        <div className='d-flex'>
                            <div className={`warehouse__search-list-item-block mr-10`}>Заказ №{item.id},</div>
                            <div className={`warehouse__search-list-item-block mr-10`}>{item.date}</div>
                        </div>                                            
                    </div>
                </div>
            )
        }
        else if(navigation === 'OrderHistoryDetail'){
            return(                
                <div className='d-flex justify-between align-center' key={key} onClick={()=>{
                    scrollToOrderHistoryDetail(item.id)
                    dispatch(setPopupOpenBlock(''))
                    dispatch(setPopupOpenBlockEvent(''))
                    setInputValue('')
                }}>
                    <div className='warehouse__search-list-item'>
                        <div className='d-flex'>
                            <div className={`warehouse__search-list-item-block mr-10`}>{item.article},</div>
                            <div className={`warehouse__search-list-item-block mr-10`}>{item.name}</div>
                        </div>                                            
                    </div>
                </div>
            )
        }
    }


    const searchInput = (event) =>{
        let val = event.target.value;
        setInputValue(val)
        let data = []
        if(val.length > 0){
            if(navigation === "Home"){
                for(let item of warehouseHomeData){
                    if(item.name.toLowerCase().search(val.toLowerCase()) !== -1 || `${item.article}`.search(val) !== -1){
                        data.push(item)
                    }            
                }
            }
            else if(navigation === "Leaders"){
                for(let item of warehouseLeadersData){
                    if(item.name.toLowerCase().search(val.toLowerCase()) !== -1){
                        data.push(item)
                    }   
                }
            }
            else if(navigation === "LeaderDetail"){
                for(let item of warehouseLeaderPointsData){
                    if(item.name.toLowerCase().search(val.toLowerCase()) !== -1){
                        data.push(item)
                    }   
                }
            }
            else if(navigation === "LeaderPointDetail"){
                for(let item of warehouseLeaderPointDetailData){
                    if(item.name.toLowerCase().search(val.toLowerCase()) !== -1 || `${item.article}`.search(val) !== -1){
                        data.push(item)
                    }   
                }
            }
            else if(navigation === "OrderHistory"){
                for(let item of warehouseOrderHistoryData){
                    if(item.date.search(val) !== -1 || `${item.id}`.search(val) !== -1){
                        data.push(item)
                    }   
                }
            }
            else if(navigation === "OrderHistoryDetail"){
                for(let item of warehouseOrderHistoryDetailData){
                    if(item.name.toLowerCase().search(val.toLowerCase()) !== -1 || `${item.article}`.search(val) !== -1){
                        data.push(item)
                    }   
                }
            }
        }
        else{
            dispatch(setPopupOpenBlock(''))
        }
        if(data.length !== 0) dispatch(setPopupOpenBlock('warehouseSearchBlock'))
        setSearchList(data)
    }
	return (
        <div className={`warehouse__search-blur warehouseSearchBlock popup-open-button ${(openBlock === 'warehouseSearchBlock')? "active": ""}`} onClick={(event)=>{
            if(openBlock !== 'warehouseSearchBlock'){
                dispatch(setPopupOpenBlock('warehouseSearchBlock'))
                dispatch(setPopupOpenBlockEvent(event.target))
                event.currentTarget.querySelector('input').focus()
            }
        }}>
            <div className={`warehouse__search d-flex align-center ${(openBlock === 'warehouseSearchBlock' && searchList.length !== 0)? "active": ""}`}>
                <div className={`warehouse__search-container d-flex align-center ${(openBlock === 'warehouseSearchBlock')? "active": ""}`}>
                    <div className="warehouse__search-icon"></div>
                    <input type="text" placeholder="Поиск" className={`warehouse__search-input`} value={inputValue} onInput={searchInput}/>                    
                    <img src="/img/close-circle.svg" alt="" className="warehouse__search-close" onClick={()=>{
                        setInputValue('')
                        dispatch(setPopupOpenBlock(''))
                        dispatch(setPopupOpenBlockEvent(''))
                    }}/>
                </div>
                <div className={`warehouse__search-list ${(openBlock === 'warehouseSearchBlock' && searchList.length !== 0)? "active": ""}`}>
                    <div className="warehouse__search-list-title">
                        Возможно вы ищете
                    </div>
                    <div className="warehouse__search-list-scrol">
                        {
                            searchList.map((item, key) => addListItem(item, key, 'searchList'))
                        }
                        
                    </div> 
                </div>
            </div>
        </div>

	)
}

export default Search;