import React, {useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setFranchiserWarehouseTransfer } from '../../../../redux/actions/franchiserCheckData';
import { setFranchiserWarehouseMoldList, setFranchiserWarehousePointsList, setFranchiserWarehousePointsMold } from '../../../../redux/actions/franchiserData';
import { setPopupBackgroundFunc, setPopupOpenBlock} from '../../../../redux/actions/popupBlocks';

import './index.scss';

const AddLeaders = () => {
    const dispatch = useDispatch()
    const [whitherList, setWhitherList] = useState(false)
    const [whereList, setWhereList] = useState(false)

    const {transferCheckData, pointsList, pointsMoldList, warehouseMoldList, checkPoint} = useSelector(({franchiserCheckData, franchiserData})=> {
        return {
            transferCheckData: franchiserCheckData.franchiserWarehouseTransfer,
            pointsList: franchiserData.franchiserWarehousePoints,
            pointsMoldList: franchiserData.franchiserWarehousePointsMold,
            warehouseMoldList: franchiserData.franchiserWarehouseMoldList,
            checkPoint: franchiserCheckData.franchiserWarehousePointsListCheck
        }
    })

    const [whitherListData, setWhitherListData] = useState({
        id: 0,
        name: 'Выберите точку/склад'
    })

    const [whereListData, setWhereListData] = useState((transferCheckData.name === '')? {
        id: 0,
        name: 'Выберите точку/склад'
    }: {id: transferCheckData.id, name: transferCheckData.select})

    const [inform, setInform] = useState(
    { 
        code: (transferCheckData.moldCode !== '')? transferCheckData.moldCode: '',
        amount: '',
    })

    const [error, setError] = useState('') 

    const [clickAddBtn, setClickAddBtn] = useState(false)

    const postFunc = async(data) =>{

        let response = await fetch('/francheaser/FilmTransfer', {
            method: 'POST', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })
        if (response.ok === true) {
            const dat = await response.json();
            console.log(dat.value)
            return dat.value
        }
    }

    const postData = (event) =>{
        event.preventDefault();

        let data =  {}
        
        if(!clickAddBtn && inform.amount != '' && inform.amount !== 0 && inform.code != '' && inform.code !== 0 && whereListData.name !== 'Выберите точку/склад' && whitherListData.name !== 'Выберите точку/склад'){
            setClickAddBtn(true)
            if(whereListData.id !== 0 && whitherListData.id !== 0){
                data = {
                    FromOnPlace: true,
                    FromId: whereListData.id, 
                    ToOnPlace: true,
                    ToId: whitherListData.id,
                    Article: +inform.code,
                    Amount: +inform.amount,
                }
            }
            if(whereListData.id === 0 && whitherListData.id !== 0){
                data = {
                    FromOnPlace: false,
                    ToOnPlace: true,
                    ToId: whitherListData.id,
                    Article: +inform.code, 
                    Amount: +inform.amount,
                }
            }
            if(whereListData.id !== 0 && whitherListData.id === 0){
                data = {
                    FromOnPlace: true,
                    FromId: whereListData.id, 
                    ToOnPlace: false,
                    Article: +inform.code, 
                    Amount: +inform.amount,
                }
            }
    
            if(data !== {}) postFunc(data).then((dat)=>{
                if(dat.isSuccess){
                    if(whereListData.id !== 0 && transferCheckData.id ===  whereListData.id){
                        let index = pointsMoldList.indexOf(pointsMoldList.find(task => task.code === +inform.code))
                        let dataa = pointsMoldList.slice()
                        

                        if(index !== -1){
                            dataa[index] = {...dataa[index], amount: dataa[index].amount - +inform.amount}
                        }
                        else {
                            let warehouseListIndex = warehouseMoldList.indexOf(warehouseMoldList.find(task => task.code === +inform.code))
                            let warehouseListData = warehouseMoldList.slice()
                            dataa.push({...warehouseListData[warehouseListIndex], amount: +inform.amount})
                        }
                        dispatch(setFranchiserWarehousePointsMold(dataa))
                    }
                    else if(whereListData.id === 0 && whereListData.name ===  'Склад'){
                        let index = warehouseMoldList.indexOf(warehouseMoldList.find(task => task.code === +inform.code))
                        let dataa = warehouseMoldList.slice()
                        

                        if(index !== -1){
                            dataa[index] = {...dataa[index], amount: dataa[index].amount - +inform.amount}
                        }
                        else {
                            let pointsListIndex = pointsMoldList.indexOf(pointsMoldList.find(task => task.code === +inform.code))
                            let pointsListData = pointsMoldList.slice()
                            dataa.push({...pointsListData[pointsListIndex], amount: +inform.amount})
                        }

                        dispatch(setFranchiserWarehouseMoldList(dataa))
                        
                        // console.log(dat[index])
                        
                        if(checkPoint.id === 0){
                            let dataa = pointsList.slice()
                            let index = dataa.indexOf(dataa.find(task => task.id === +whitherListData.id))
                            // console.log(dat[index])
                            

                            if(index !== -1){
                                dataa[index] = {...dataa[index], amount: dataa[index].amount + +inform.amount}
                            }
                            else {
                                let warehouseListIndex = warehouseMoldList.indexOf(warehouseMoldList.find(task => task.id === +whitherListData.id))
                                let warehouseListData = warehouseMoldList.slice()
                                dataa.push({...warehouseListData[warehouseListIndex], amount: +inform.amount})
                            }

                            dispatch(setFranchiserWarehousePointsList(dataa))
                        }
                    }
            
                    if(checkPoint.id === whitherListData.id && whitherListData.id !== 0){
                        let index = pointsMoldList.indexOf(pointsMoldList.find(task => task.code === +inform.code))
                        let dataa = pointsMoldList.slice()  
                        if(index !== -1){
                            dataa[index] = {...dataa[index], amount: dataa[index].amount + +inform.amount}
                        }
                        else {
                            let warehouseListIndex = warehouseMoldList.indexOf(warehouseMoldList.find(task => task.code === +inform.code))
                            let warehouseListData = warehouseMoldList.slice()
                            dataa.push({...warehouseListData[warehouseListIndex], amount: +inform.amount})
                        }

                        dispatch(setFranchiserWarehousePointsMold(dataa))
                    }
                    else if(whitherListData.id === 0 && whitherListData.name ===  'Склад'){
                        let index = warehouseMoldList.indexOf(warehouseMoldList.find(task => task.code === +inform.code))
                        let dataa = warehouseMoldList.slice()
                        // console.log(dat, index, dat[index], inform.code)
                        if(index !== -1){
                            dataa[index] = {...dataa[index], amount: dataa[index].amount + +inform.amount}
                        }
                        else {
                            let pointsListIndex = pointsMoldList.indexOf(pointsMoldList.find(task => task.code === +inform.code))
                            let pointsListData = pointsMoldList.slice()
                            dataa.push({...pointsListData[pointsListIndex], amount: +inform.amount})
                        }
                        dispatch(setFranchiserWarehouseMoldList(dataa))
                    }
            
                    dispatch(setPopupBackgroundFunc(false))
                    dispatch(setPopupOpenBlock(''))
                    dispatch(setFranchiserWarehouseTransfer({id: 0,  select: '', moldCode: '', moldId: 0}))
                }
                else{
                    setError(dat.informMessage)
                    setClickAddBtn(false)
                }
            })
    
    
            
        }
        else if(!clickAddBtn){
            setError('Необходимо заполнить все поля*')
        }
    }

    let selectListItem = (type, id, name) =>{
        if(type === 'whereList'){
            setWhereListData({id: id, name: name})
            setWhereList(false)
        } 
        else if(type === 'whitherList'){
            setWhitherListData({id: id, name: name})
            setWhitherList(false)
        }
        
    }

    let addListItem = ({id, name}, key, type) =>{
        return(
            <div className={`franchises-leaders__staf-points-buttons-position-select-list-item ${(id === whitherListData.id || id === whereListData.id)? 'none': ''}`} key={key} onClick={()=> selectListItem(type, id, name, key)}>
                {name}
            </div>
        )
    }
    

	return (
            <div className="moderator__popup-container">
                <div className="moderator__popup-top d-flex justify-between align-center add-leaders">
                    <div className="popup__title purpure">Перенос пленки</div>
                    <div className="popup__close-btn" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                        dispatch(setFranchiserWarehouseTransfer({id: 0,  select: '', moldCode: '', moldId: 0}))
                    }}></div>
                </div>
                <form action="" onSubmit={postData}>
                    <div className="moderator__popup-input-container d-flex justify-between">
                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Откуда</label>
                            <div className="franchises-leaders__staf-points-buttons-position-select-container d-flex align-center">
                                <div className={`franchises-leaders__staf-points-buttons-position-select ${(whereList)? 'active': ''}`} onClick={()=>{
                                        setWhereList(!whereList)
                                        setWhitherList(false)
                                    }}>

                                    {whereListData.name}
                                </div>                            
                                <div className={`franchises-leaders__staf-points-buttons-position-select-list ${(whereList)? 'active': ''}`}>
                                    <div className="franchises-leaders__staf-points-buttons-position-select-list-scroll">
                                        <div className={`franchises-leaders__staf-points-buttons-position-select-list-item ${(whitherListData.name === 'Склад' || whereListData.name === 'Склад')? 'none': ''}`} onClick={()=> selectListItem('whereList', 0, 'Склад')}>
                                            Склад
                                        </div>
                                        {
                                            pointsList.map((item, key)=> addListItem(item, key, 'whereList'))
                                        }                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Куда</label>
                            <div className="franchises-leaders__staf-points-buttons-position-select-container d-flex align-center">
                                <div className={`franchises-leaders__staf-points-buttons-position-select ${(whitherList)? 'active': ''}`} onClick={()=>{
                                        setWhitherList(!whitherList)
                                        setWhereList(false)
                                    }}>
                                    {whitherListData.name}
                                </div>                            
                                <div className={`franchises-leaders__staf-points-buttons-position-select-list ${(whitherList)? 'active': ''}`}>
                                    <div className="franchises-leaders__staf-points-buttons-position-select-list-scroll">
                                        <div className={`franchises-leaders__staf-points-buttons-position-select-list-item ${(whitherListData.name === 'Склад' || whereListData.name === 'Склад')? 'none': ''}`} onClick={()=> selectListItem('whitherList', 0, 'Склад')}>
                                            Склад
                                        </div>
                                        {
                                            pointsList.map((item, key)=> addListItem(item, key, 'whitherList'))
                                        }                                        
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </div>
                    <div className="moderator__popup-input-container d-flex justify-between">
                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Артикул</label>
                            <input name='Login' type="text" defaultValue={(transferCheckData.moldCode !== '')? transferCheckData.moldCode: ''} placeholder='Введите артикул пленки' className="moderator__popup-input" onInput={(event)=>{
                                setInform({...inform, code: event.target.value})
                                // setEditInform({...editInform, code: true})
                            }}/>
                        </div> 

                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Количество</label>
                            <input name='Password' type="number" placeholder='Введите количество пленки' className="moderator__popup-input" onInput={(event)=>{
                                setInform({...inform, amount: event.target.value})
                                // setEditInform({...editInform, amount: true})
                            }}/>
                        </div>   
                    </div> 
                    <div className='popup__error'>{error}</div>               
                    <button type='submit' className={`moderator__popup-save ${(clickAddBtn)? 'deactive': ''}`}>Перенести</button>    
                </form>                    
            </div>
	)
}
export default AddLeaders;