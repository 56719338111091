import React, { useState, useEffect } from 'react';
import Search from '../Search/Search';
import {useDispatch, useSelector} from 'react-redux';
import { setPopupOpenBlock, setPopupOpenBlockEvent} from '../../../redux/actions/popupBlocks';
import {setRole} from '../../../redux/actions/navigation';
import './Header.scss';
import { fetchWorkZoneCashbox, fetchWorkZoneNotification, fetchWorkZonePlottersData, setWorkZoneNotificationAdd } from '../../../redux/actions/workZoneData';
import { setSiginalConnectReset } from '../../../redux/actions/siginalConnect';
import { setNotificationActive } from './../../../redux/actions/navigation';

const Header = ({status}) => {
    const dispatch = useDispatch();

    const { openBlock, workZoneCashbox, workZoneNotification, workZonePlottersData, siginalConnect, session, userData, notificationActive } = useSelector(({popupBlocks, workZoneData, siginalConnect, navigation})=> {
        return {
            openBlock: popupBlocks.openBlock,
            workZoneCashbox: workZoneData.workZoneCashbox,
            workZoneNotification: workZoneData.workZoneNotification,
            workZonePlottersData: workZoneData.workZonePlottersData,
            session: navigation.session,
            userData: navigation.user,
            siginalConnect: siginalConnect.connect,
            notificationActive: navigation.notificationActive
        }
    })

    const [driversList, setDriversList] = useState({
        isActive: false,
        listData: []
    })

    
    // const [cashbox, setCashbox] = useState({
    //     inputActive: false,
    //     val: null,
    // })

    useEffect(()=>{
        if(siginalConnect.type === 'new-notification'){
            // console.log(siginalConnect)
            dispatch(setWorkZoneNotificationAdd(siginalConnect.obj.elem))
            if (session === 1) {
                let audio = new Audio('/img/notification-audio.mp3');
                audio.play();
            }
            dispatch(setSiginalConnectReset())
        }
    }, [siginalConnect])

    useEffect(()=>{
        dispatch(fetchWorkZoneCashbox())
        dispatch(fetchWorkZoneNotification())
    }, [])

    let AddPlotterListElem = ({name, src}, key)=>{
        return(
            <div className='work-zone__buttons-popup-user-drivers-list-item d-flex justify-between align-center' key={key}>
                <div className='work-zone__buttons-popup-user-drivers-list-item-name'>
                    {name}
                </div>
                <a href={src} download>
                    <img src="/img/work-zone-download-icon.svg" className='work-zone__buttons-popup-user-drivers-list-item-icon' alt="" />
                </a>
            </div>
        )
    }

    let AddNotificationListElem = ({date, discription, status }, key)=>{
        return(
            <div className='work-zone__buttons-popup-workZoneNotification-list-item d-flex' key={key}>
                <div className={`work-zone__buttons-popup-workZoneNotification-list-item-circle ${(!status)? 'active': ''}`}>
                </div>
                <div className='work-zone__buttons-popup-workZoneNotification-list-item-container'>
                    <div className='work-zone__buttons-popup-workZoneNotification-list-item-date'>
                        {date}г
                    </div>
                    <div className='work-zone__buttons-popup-workZoneNotification-list-item-text'>
                        {discription}
                    </div>
                </div> 
            </div>
        )
    }

 	return (
		<header className={`work-zone__header d-flex justify-between ${status}`}>
            <div className='work-zone__header-desktop justify-between'>
                <Search/>
                <div className="work-zone__buttons d-flex align-center justify-between">
                    <div className="work-zone__buttons-item">
                        <div className={`work-zone__buttons-icon cashbox popup-open-button ${(openBlock === "workZoneCashbox")? "active": ""}`} onClick={(event) =>{
                            dispatch(setPopupOpenBlock((openBlock === "workZoneCashbox")? "": "workZoneCashbox"))
                            dispatch(setPopupOpenBlockEvent(event.target))
                            // setCashbox({inputActive: false, val: null})
                        }}>
                            {/* <img src="/img/notification.svg" alt="" className="work-zone__buttons-notifications-icon"/> */}
                        </div>
                        {/* <div className={`work-zone__buttons-popup workZoneCashbox ${(openBlock === "workZoneCashbox")? "active": ""}`} >
                            <div className={`work-zone__buttons-popup-workZoneCashbox-title ${(!cashbox.inputActive)? 'border': ''}`}>
                                30000₽
                            </div>
                            <div className={`work-zone__buttons-popup-workZoneCashbox-input-container ${(cashbox.inputActive)? 'active': ''}`}>
                                <input type="number" defaultValue={cashbox.val} placeholder='Введите сумму' className='work-zone__buttons-popup-workZoneCashbox-input' onInput={(event) =>{
                                    setCashbox({...cashbox, val: event.target.value})
                                }}/>
                            </div>
                            <div className='work-zone__buttons-popup-workZoneCashbox-button d-flex align-center justify-center' onClick={() =>{
                                if(cashbox.inputActive){
                                    // console.log(cashbox.val)
                                }
                                else{
                                    setCashbox({...cashbox, inputActive: true})
                                }
                            }}>
                                <img src="/img/work-zone-plus-green-circle.svg" alt="" className='work-zone__buttons-popup-workZoneCashbox-button-icon'/>
                                <div>Добавить</div>
                            </div>                           
                        </div> */}
                        <div className={`work-zone__buttons-popup workZoneCashbox d-flex align-center justify-between ${(openBlock === "workZoneCashbox")? "active": ""}`} >
                            <div className='work-zone__buttons-popup-workZoneCashbox-button d-flex align-center'>
                                <div>Касса:</div>
                            </div>
                            <div className={`work-zone__buttons-popup-workZoneCashbox-title`}>
                                {workZoneCashbox}₽
                            </div>                         
                        </div>
                    </div>
                    <div className="work-zone__buttons-item">
                        <div className={`work-zone__buttons-icon notifications popup-open-button ${(openBlock === "workZoneNotification")? "active": ""} ${(!notificationActive)? "notific-none": ""}`} onClick={(event) =>{
                            dispatch(setPopupOpenBlock((openBlock === "workZoneNotification")? "": "workZoneNotification"))
                            dispatch(setPopupOpenBlockEvent(event.target))
                            dispatch(setNotificationActive(false))
                        }}>
                            {/* <img src="/img/notification.svg" alt="" className="work-zone__buttons-notifications-icon"/> */}
                        </div>
                        <div className={`work-zone__buttons-popup workZoneNotification ${(openBlock === "workZoneNotification")? "active": ""}`} >
                            <div className="work-zone__buttons-popup-workZoneNotification-title">Уведомления</div>
                            <div className='work-zone__buttons-popup-workZoneNotification-list'>
                                <div className='work-zone__buttons-popup-workZoneNotification-list-scroll'>
                                    {
                                        workZoneNotification.map((item, key)=>AddNotificationListElem(item, key))
                                    }
                                    {
                                        (workZoneNotification.length === 0)? <div className='work-zone__buttons-popup-notification-null'>У вас нет уведомлений</div>: null
                                    }
                                </div> 
                                
                            </div>                          
                        </div>
                    </div>

                    <div className="work-zone__buttons-item">
                        <div className="work-zone__buttons-user popup-open-button d-flex align-center" onClick={(event) =>{
                            dispatch(setPopupOpenBlock((openBlock === "workZoneUserMenu")? "": "workZoneUserMenu"))
                            dispatch(setPopupOpenBlockEvent(event.target))
                            setDriversList(false)
                            
                        }}>
                            <div className="work-zone__buttons-user-photo-background popup-open-button d-flex justify-center align-center">
                                <img src={userData.src} alt="" className="work-zone__buttons-user-photo popup-open-button"/>
                            </div>
                            <div>
                                <div className="work-zone__buttons-user-name d-flex align-center popup-open-button">
                                    <span className='popup-open-button'>{userData.name}</span> <img src="/img/arrow-down.svg" alt="" className="work-zone__buttons-user-button popup-open-button"/>
                                </div>
                                <div className="work-zone__buttons-user-name-verify d-flex align-center popup-open-button">
                                    <img src="/img/verify.svg" alt="" className="work-zone__buttons-user-name-verify-icon popup-open-button"/>
                                    Точка
                                </div>
                            </div>
                        </div>

                        <div className={`work-zone__buttons-popup user workZoneUserMenu ${(openBlock === "workZoneUserMenu")? "active": ""}`} >
                            <div className='work-zone__buttons-popup-user-item d-flex align-center'>
                                <img src={userData.src} alt="" className="work-zone__buttons-popup-user-photo"/>
                                <div className="work-zone__buttons-popup-user-name d-flex align-center">
                                   {userData.name}                                   
                                </div>
                            </div>
                            <div className='work-zone__buttons-popup-user-item download-drivers'>
                                <div className='work-zone__buttons-popup-user-drivers-title' onClick={()=>{
                                    if(workZonePlottersData.length === 0) dispatch(fetchWorkZonePlottersData())
                                    setDriversList({...driversList, isActive: !(driversList.isActive)})
                                }}>Скачать драйвера</div>
                                <div className={`work-zone__buttons-popup-user-drivers-list ${(driversList.isActive)? 'active': ''}`}>
                                    <div className='work-zone__buttons-popup-user-drivers-list-scroll'>
                                        {
                                            workZonePlottersData.map((item, key)=>AddPlotterListElem(item, key))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="work-zone__buttons-popup-user-logout d-flex align-center" onClick={()=>{
                                localStorage.setItem('token', '') 
                                localStorage.setItem('role', '')
                                sessionStorage.setItem('token', '') 
                                sessionStorage.setItem('role', '')
                                sessionStorage.setItem('workzonePage', 'Workspase')
                                dispatch(setRole(''))
                            }}>
                                <img src="/img/logout.svg" alt="" className="work-zone__buttons-popup-user-logout-icon"/>
                                <div className="work-zone__buttons-popup-user-logout-title">Выйти из аккаунта</div>                   
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
        </header>
	)
}
export default Header;