import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOwnerHomeCheckLeaders, setOwnerHomeCheckLeadersDel, setOwnerHomeCheckPoints, setOwnerHomeCheckPointsDel} from '../../redux/actions/filters';
import './Search.scss';

const Search = () => {
    const dispatch = useDispatch()
    const {leaderData, pointsData, purchaseData, leadersLeaderData, leadersStaf, navigation, leadersNav, leadersCheck, pointsCheck} = useSelector(({ownerData, navigation, filters})=> {
        return {
            leaderData: ownerData.ownerHomeLeaders,
            pointsData: ownerData.ownerHomePoints,
            purchaseData: ownerData.ownerWarehousePurchase,
            leadersLeaderData: ownerData.ownerLeadersLeaderList,
            leadersStaf: ownerData.ownerLeadersLeaderStaf,
            navigation: navigation.ownerNav,
            leadersNav: navigation.leadersNav,
            leadersCheck: filters.ownerHomeCheckLeaders,
            pointsCheck: filters.ownerHomeCheckPoints,
        }
    })


    let [searchList, setSearchList] = useState([]);
    let [searchActive, setSearchActive] = useState(false);

    const leaderChange = (id) =>{
        if(leadersCheck.indexOf(id) !== -1){
            leadersCheck.splice(leadersCheck.indexOf(id), 1)
            dispatch(setOwnerHomeCheckLeadersDel(leadersCheck)) 
        }
        else{
            dispatch(setOwnerHomeCheckLeaders(id))
        }
    }

    const checkPoint = (id) =>{
        if(pointsCheck.indexOf(id) !== -1){
            pointsCheck.splice(pointsCheck.indexOf(id), 1)
            dispatch(setOwnerHomeCheckPointsDel(pointsCheck)) 
        }
        else{
            dispatch(setOwnerHomeCheckPoints(id))
        }
    }

    const scrollToLeader = (id) =>{
        let elem = document.querySelector(`.sroll-leaders-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.owner__leaders-table'), +elem.offsetTop - +document.querySelector('.owner__leaders-table').offsetTop, 1000)
        // setSearchActive(false)
    }

    const scrollToLeaderStaf = (id) =>{
        let elem = document.querySelector(`.scroll-leaders-staf-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.owner__leaders-staf-table'), +elem.offsetTop - +document.querySelector('.owner__leaders-staf-table').offsetTop, 1000)
        // setSearchActive(false)
    }

    const scrollToPurchase = (id) =>{
        let elem = document.querySelector(`.scroll__purchase-table-line-${id}`);
        // console.log(elem)
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.owner__purchase-table'), +elem.offsetTop - +document.querySelector('.owner__purchase-table').offsetTop, 1000)
        // setSearchActive(false)
    }

    function scrollTo(element, to, duration) {
        // console.log(element, to)
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;
            
        var animateScroll = function(){        
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }
    
    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };

    const addListItem = (item, key) =>{
        if(item.type == "homeLeaders"){            
            return(
                <div className='owner__search-list-item ' key={key} onClick={() => {leaderChange(item.id)}}>                        
                    <div className={`owner__search-list-item-home-leader ${(leadersCheck.indexOf(item.id) !== -1)? "active": ""}`}>Руководитель: {item.name}</div>
                </div>
            )
        }
        else if(item.type == "homePoints"){
            return(
                <div className='owner__search-list-item' key={key} onClick={() => {checkPoint(item.id)}}>
                    <div className={`owner__search-list-item-home-points ${(pointsCheck.indexOf(item.id) !== -1)? "active": ""}`}>
                        <div className="owner__search-list-item-home-points-name" >Руководитель: {item.leader}</div>
                        <div >Точка: {item.name}, {item.city}</div>
                    </div>                       
                    
                </div>
            )
        }
        else if(item.type == "leadersLeaderData"){
            return(
                <div className='owner__search-list-item' key={key} onClick={()=>{scrollToLeader(item.id)}}>                        
                    <div className="owner__search-list-item-leader">Руководитель: {item.name}</div>
                </div>
            )
        }
        else if(item.type == "leadersLeaderStaf"){
            return(
                <div className='owner__search-list-item' key={key} onClick={()=>{scrollToLeaderStaf(item.id)}}>                        
                    <div className="owner__search-list-item-leader">Сотрудник: {item.name}</div>
                </div>
            )
        }
        else if(item.type == "warehousePurchase"){
            return(
                <div className='owner__search-list-item' key={key} onClick={()=>{scrollToPurchase(item.directorId)}}>                        
                    <div className="owner__search-list-item-leader">Покупатель: {item.name}</div>
                </div>
            )
        }
    }

    const searchInput = (event) =>{
        let val = event.target.value;
        let data = []
        if(val != ''){
            if(navigation === "Home"){
                for(let item of leaderData){
                    if(item.name.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push({...item, type: "homeLeaders"})
                    }            
                }
                for(let item of pointsData){
                    if(item.name.toLowerCase().search(val.toLowerCase()) != -1 || item.city.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push({...item, type: "homePoints"})
                    }            
                }
            }
            else if(navigation === "Leaders"){
                // console.log(leadersNav, leadersLeaderData)
                if(leadersNav == "leaders"){
                    for(let item of leadersLeaderData){
                        if(item.name.toLowerCase().search(val.toLowerCase()) != -1){
                            data.push({...item, type: "leadersLeaderData"})
                        }            
                    }
                }
                else if(leadersNav == "staf"){
                    for(let item of leadersStaf){
                        if(item.name.toLowerCase().search(val.toLowerCase()) != -1){
                            data.push({...item, type: "leadersLeaderStaf"})
                        }            
                    }
                }
            }
            else if(navigation === "Warehouse"){
                for(let item of purchaseData){
                    if(item.name.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push({...item, type: "warehousePurchase"})
                    }            
                }
            }
        }
        else{
            setSearchActive(false)
        }
        if(data.length != 0) setSearchActive(true)
        setSearchList(data)
    }
	return (
		<div className='owner-search__zindex owner-search'>
            <div className={`owner__search-blur ${(searchActive)? "active": ""}`}>
                <div className={`owner__search d-flex align-center ${(searchActive)? "active": ""}`}>
                    <div className={`owner__search-container d-flex align-center ${(searchActive)? "active": ""}`}>
                        <div className="owner__search-icon"></div>
                        <input type="text" placeholder="Поиск" className={`owner__search-input`} onInput={searchInput}/>                    
                        <img src="/img/close-circle.svg" alt="" className="owner__search-close" onClick={()=>{
                            document.querySelector(".owner__search-input").value = "";
                            setSearchActive(false);
                        }}/>
                    </div>
                    <div className={`owner__search-list ${(searchActive)? "active": ""}`}>
                        <div className="owner__search-list-title">
                            Возможно вы ищете
                        </div>
                        <div className="owner__search-list-scrol">
                            {
                                searchList.map((item, key) => addListItem(item, key))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)
}

export default Search;