import {React, useState} from 'react';
import Calendar from 'react-calendar';
import {useDispatch, useSelector} from 'react-redux';
import {setCalendDisplay} from '../../../redux/actions/display';
import { setPopupOpenBlock, setPopupOpenBlockEvent } from '../../../redux/actions/popupBlocks';
import { setWorkZoneClientsDate, setWorkZoneHistoryDate } from '../../../redux/actions/popupFilters';
import "./Picker.scss";

function Picker({calendActive, page, name}){
    const dispatch = useDispatch();
    const [selDate, setSelDate] = useState(null)
    // const [selTime, setSelTime] = useState({hours: '', minutes: ''})
    const [timeError, setTimeError] = useState({hours: false, minutes: false})
    // const [timeActive, setTimeActive] = useState(false)
    const [calend, setCalend] = useState()

    if(calendActive){
        setTimeout(() => setCalend('open'), 100);
    }

    let onChange = date => {
        setSelDate(date)    
    }

    let reset = () => {
        if(page === "clients"){
            dispatch(setWorkZoneClientsDate({date: null}))            
        }
        else if(page === "history"){
            dispatch(setWorkZoneHistoryDate({date: null}))
        }
        dispatch(setPopupOpenBlock(''))
        dispatch(setPopupOpenBlockEvent(''))    
    }

    let selDateFunc = () =>{
        dispatch(setPopupOpenBlock(''))
        dispatch(setPopupOpenBlockEvent(''))  

        if(page === "clients"){
            if(selDate !== null){
                dispatch(setWorkZoneClientsDate({date: selDate.toLocaleDateString()}))
            }
        }
        else if(page === "history"){
            // if(selTime.hours <= 23 && selTime.minutes < 59 && selDate !== null){
            if(selDate !== null){
                // dispatch(setWorkZoneHistoryDate({date: selDate.toLocaleDateString(), time: `${(selTime.hours !== '' && selTime.hours !== 0)? selTime.hours: '00'}:${(selTime.minutes !== '' && selTime.minutes !== 0)? selTime.minutes: '00'}`}))
                dispatch(setWorkZoneHistoryDate({date: selDate.toLocaleDateString()}))
            }
            
            // if(selTime.hours > 23) setTimeError({...timeError, hours: true})
            // if(selTime.minutes > 59) setTimeError({...timeError, minutes: true})  

        }
    } 

    return (
        <div className={`calendar ${calend} ${name}`}>
            <div className={`calendar__calendar-container`}>
                {
                    (calendActive || !calendActive)? <Calendar onChange={onChange} selectRange={false}/>: null
                }                   
            </div>
            {/* <div className={`calendar__time-label ${(page === 'clients')? 'none': ''}`}>Время</div>
            <div className={`calendar__time-container d-flex align-center ${(page === 'clients')? 'none': ''}`}>
                <div className={`calendar__time-input-opacity d-flex align-center ${(!timeActive)? 'active': ''}`}>
                    <div className='calendar__time-input-block d-flex align-center'>
                        <input type="number" readOnly={(timeActive)? null: 'readOnly'} step="1" placeholder='00'  className='calendar__time-input' onInput={(event) =>{
                            setSelTime({...selTime, hours: +event.target.value})
                        }}/>
                        <span className='calendar__time-input-span'>ч</span>
                    </div>
                    <span>:</span>
                    <div className='calendar__time-input-block d-flex align-center'>
                        <input type="number" readOnly={(timeActive)? null: 'readOnly'} step="1" placeholder='00' className='calendar__time-input' onInput={(event) =>{
                            setSelTime({...selTime, minutes: +event.target.value})
                        }}/>
                        <span className='calendar__time-input-span'>м</span>
                    </div>
                </div>
                <div className={`calendar__time-checkbox ${(timeActive)? 'active': ''}`} onClick={()=>{
                    setTimeActive(!timeActive)
                }}></div>
            </div> */}
            <div className={`calendar__buttons d-flex top-none`}>                        
                <div className='calendar__buttons-item accept' onClick={selDateFunc}>Принять</div>
                <div className='calendar__buttons-item reset' onClick={reset}>Отмена</div>
            </div>
        </div>
    )
}
export default Picker;
