async function post(url, type, data = null){
  let response = await fetch(url, {
      method: 'POST', 
      headers: {
          "Accept": "application/json",
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
      },
      body: JSON.stringify(data)
  })
  if (response.ok === true) {
      const user = await response.json();
      return user
  }
  else{
    localStorage.setItem('role', '')
  }
  let dat = response.json
  return dat 
}

async function postNotData(url, type){
  let response = await fetch(url, {
      method: type, 
      headers: {
          "Accept": "application/json",
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
      },
  })
  if (response.ok === true) {
      const user = await response.json();
      return user
  }
  else{
    localStorage.setItem('role', '')
  }
  let dat = response.json
  return dat 
}

export const setModeratorCatalog = (item) => ({
  type: 'SET_MODERATOR_CATALOG',
  payload: item
});

export const setModeratorCatalogEdit = (item, id) => ({
  type: 'SET_MODERATOR_CATALOG_EDIT',
  payload: item,
  id: id
});

export const setModeratorCatalogDelete = (item) => ({
  type: 'SET_MODERATOR_CATALOG_DELETE',
  payload: item
});

export const fetchModeratorCatalog = (id) => (dispatch) =>{
  postNotData(`/Moderator/GetFolders?id=${id}`, "GET").then((response)=> {
      dispatch(setModeratorCatalog(response.value))
  })  
}

export const setModeratorPoints = (item) => ({
  type: 'SET_MODERATOR_POINTS',
  payload: item
});

export const fetchModeratorPoints = () => (dispatch) =>{
  postNotData(`/Moderator/GetPoints`, "GET").then((response)=> {
      dispatch(setModeratorPoints(response.value))
  })  
}

export const setModeratorLeaders = (item) => ({
  type: 'SET_MODERATOR_LEADERS',
  payload: item
});

export const setModeratorLeadersDelete = (item) => ({
  type: 'SET_MODERATOR_LEADERS_DELETE',
  payload: item
});


export const fetchModeratorLeaders = () => (dispatch) =>{
  postNotData("/Moderator/GetLeader", "GET").then((response)=> {
      dispatch(setModeratorLeaders(response.value))
  })
}

export const setModeratorLeadersStaf = (item) => ({
  type: 'SET_MODERATOR_LEADERS_STAF',
  payload: item
});

export const fetchModeratorLeadersStaf = (id) => (dispatch) =>{
  postNotData(`/Moderator/GetLeaderStaff?id=${id}`, "GET").then((response)=> {
      dispatch(setModeratorLeadersStaf(response.value))
  })
}

export const setModeratorLeadersPoints = (item) => ({
  type: 'SET_MODERATOR_LEADERS_POINTS',
  payload: item
});

export const setModeratorLeadersPointsDelete = (item) => ({
  type: 'SET_MODERATOR_LEADERS_POINTS_DELETE',
  payload: item
});

export const fetchModeratorLeadersPoints = (id) => (dispatch) =>{
  postNotData(`/Moderator/LeaderPoint?id=${id}`, "GET").then((response)=> {
      dispatch(setModeratorLeadersPoints(response.value))
  })
}

export const setModeratorHistoryChanges = (item) => ({
  type: 'SET_MODERATOR_HISTORY_CHANGES',
  payload: item
});

export const fetchModeratorHistoryChanges = () => (dispatch) =>{
  postNotData(`/Moderator/GetHistoryData?iffer=${0}`, "GET").then((response)=> {
      dispatch(setModeratorHistoryChanges(response.value))
  })
}

export const setModeratorComplaints = (item) => ({
  type: 'SET_MODERATOR_COMPLAINTS',
  payload: item
});

export const fetchModeratorComplaints = () => (dispatch) =>{
  postNotData(`/Moderator/GetComplaints?iffer=${0}`, 'GET').then((response)=> {
      dispatch(setModeratorComplaints(response.value))
  })
}

export const setModeratorComplaintsAdd = (item) => ({
  type: 'SET_MODERATOR_COMPLAINTS_ADD',
  payload: item
});

export const setModeratorComplaintsStatusEdit = (item) => ({
  type: 'SET_MODERATOR_COMPLAINTS_STATUS_EDIT',
  payload: item
});
