const initialState = {
    moderatorCatalog: {
        id: 0,
        path: [],
        folderData: []
    },
    moderatorLeaders: [],
    moderatorLeadersPoints:[],
    moderatorLeadersStaf:[],
    moderatorComplaints:[
    ],
    moderatorHistoryChanges: [
    ],
    moderatorPoints: [
    ]
};
    
    const moderatorData = (state = initialState, action) => {
      if (action.type === 'SET_MODERATOR_CATALOG') {
        return {
          ...state,
          moderatorCatalog: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_CATALOG_EDIT') {
        let data = state.moderatorCatalog.folderData;
        let index = data.findIndex(x => x.id === action.id)
        if(index !== -1){
          data[index] = {...data[index], name: action.payload}
          return {
            ...state,
            moderatorCatalog: {...state.moderatorCatalog, folderData: data},
          };
        }
      }
      else if (action.type === 'SET_MODERATOR_CATALOG_DELETE') {
        let data = state.moderatorCatalog.folderData;
        let index = data.findIndex(x => x.id === action.payload)
        if(index !== -1){
          data.splice(index, 1)
          return {
            ...state,
            moderatorCatalog: {...state.moderatorCatalog, folderData: data},
          };
        }
      }
      else if (action.type === 'SET_MODERATOR_LEADERS') {
        return {
          ...state,
          moderatorLeaders: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_LEADERS_DELETE') {
        let data = state.moderatorLeaders.slice();
        let index = data.findIndex(x => x.id === action.payload)
        if(index !== -1){
          data.splice(index, 1)
          return {
            ...state,
            moderatorLeaders: data,
          };
        }
      }
      else if (action.type === 'SET_MODERATOR_LEADERS_POINTS') {
        return {
          ...state,
          moderatorLeadersPoints: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_LEADERS_POINTS_DELETE') {
        let data = state.moderatorLeadersPoints.slice();
        let index = data.findIndex(x => x.id === action.payload)
        if(index !== -1){
          data.splice(index, 1)
          return {
            ...state,
            moderatorLeadersPoints: data,
          };
        }
      }
      else if (action.type === 'SET_MODERATOR_LEADERS_STAF') {
        return {
          ...state,
          moderatorLeadersStaf: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_COMPLAINTS') {
        return {
          ...state,
          moderatorComplaints: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_COMPLAINTS_ADD') {
        return {
          ...state,
          moderatorComplaints: [action.payload, ...state.moderatorComplaints],
        };
      }
      else if (action.type === 'SET_MODERATOR_COMPLAINTS_STATUS_EDIT') {
        let data = state.moderatorComplaints.slice();
        let index = data.findIndex(x => x.id === action.payload.id)
        if(index !== -1){
          data[index] = {...data[index], ...action.payload}
          return {
            ...state,
            moderatorComplaints: data,
          };
        }
      }
      else if (action.type === 'SET_MODERATOR_HISTORY_CHANGES') {
        return {
          ...state,
          moderatorHistoryChanges: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_POINTS') {
        return {
          ...state,
          moderatorPoints: action.payload,
        };
      }
      return state;
    };
    
    export default moderatorData;