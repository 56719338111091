import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LeaderStafTable from './Table/Table'
import LeaderPoints from './Points/Points'

import {setModeratorNav} from '../../../../redux/actions/navigation';
import { fetchModeratorLeadersStaf } from '../../../../redux/actions/moderatorData';

import './LeadersStaf.scss';



const LeadersStaf = () => {
    const dispatch = useDispatch();

    const {moderatorLeaderCheck} = useSelector(({moderatorCheckData})=> {
        return {
            moderatorLeaderCheck: moderatorCheckData.moderatorLeaderCheck,
        }
    })

    useEffect(()=>{
        dispatch(setModeratorNav('LeadersStaf'))
        // dispatch(fetchModeratorLeadersStaf(moderatorLeaderCheck.id))
    }, [])

	return (
        <div className='moderator__leaders-staf-container'>
            <Link to='/leaders'>
                <div className='moderator__leaders-staf-back d-flex align-center' onClick={()=>{
                    dispatch(setModeratorNav('Leaders'))
                }}> <img src="/img/arrow-left-block.svg" alt="" />{moderatorLeaderCheck.name}</div>
            </Link>
            
            <LeaderStafTable/>
            <LeaderPoints/>
        </div>      
	)
}

export default LeadersStaf;