import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {} from '../../../redux/actions/filters';
import { fetchModeratorCatalog } from '../../../redux/actions/moderatorData';
import './Search.scss';

const Search = () => {
    const dispatch = useDispatch()
    const {navigation, franchiserHomePointsList,  franchiserPointsPrinting, franchiserStaffData, franchiserStaffDetail, franchiserWarehouseMoldList, franchiserWarehousePoints, franchiserWarehousePointsMold, franchiserSalariesData,} = useSelector(({navigation, franchiserData})=> {
        return {
            navigation: navigation.franchiserNav,
            franchiserHomePointsList: franchiserData.franchiserHomePointsList,
            franchiserPointsPrinting: franchiserData.franchiserPointsPrinting,
            franchiserStaffData: franchiserData.franchiserStaffData,
            franchiserStaffDetail: franchiserData.franchiserStaffDetail,
            franchiserWarehouseMoldList: franchiserData.franchiserWarehouseMoldList,
            franchiserWarehousePoints: franchiserData.franchiserWarehousePoints,
            franchiserWarehousePointsMold: franchiserData.franchiserWarehousePointsMold,
            franchiserSalariesData: franchiserData.franchiserSalariesData
        }
    })


    let [searchList, setSearchList] = useState([]);
    let [searchActive, setSearchActive] = useState(false);   
    let [inputValue, setInputValue] = useState('');   

    const scrollToHomePoints = (id) =>{
        let elem = document.querySelector(`.franchises-leaders__right-list-item-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.franchises-leaders__right-list'), +elem.offsetTop - +document.querySelector('.franchises-leaders__right-list').offsetTop, 1000)
    }

    const scrollToPointsPrinting = (id) =>{
        let elem = document.querySelector(`.frnachiser__scroll-points-printing-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.moderator__leaders-staf-points-table'), +elem.offsetTop - +document.querySelector('.moderator__leaders-staf-points-table').offsetTop, 1000)
    }

    const scrollToStaff = (id) =>{
        let elem = document.querySelector(`.franchiser__scroll-staff-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.owner__leaders-staf-table'), +elem.offsetTop - +document.querySelector('.owner__leaders-staf-table').offsetTop, 1000)
    }

    const scrollToStaffDetail = (id) =>{
        let elem = document.querySelector(`.franchiser__scroll-staff-detail-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.moderator__leaders-staf-points-table'), +elem.offsetTop - +document.querySelector('.moderator__leaders-staf-points-table').offsetTop, 1000)
    }

    const scrollToWarehouseMoldList = (id) =>{
        let elem = document.querySelector(`.franchiser__scroll-warehouse-mold-list-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.franchiser__warehouse-mold-list'), +elem.offsetTop - +document.querySelector('.franchiser__warehouse-mold-list').offsetTop, 1000)
    }

    const scrollToWarehousePointsList = (id) =>{
        let elem = document.querySelector(`.franchiser__scroll-warehouse-points-list-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.franchiser__warehouse-points-list'), +elem.offsetTop - +document.querySelector('.franchiser__warehouse-points-list').offsetTop, 1000)
    }

    const scrollToWarehousePointsMoldList = (id) =>{
        let elem = document.querySelector(`.franchiser__scroll-warehouse-points-mold-list-line-${id}`);
        elem.classList.add("active");
        setTimeout(()=>{
            elem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.franchiser__warehouse-points-mold-list'), +elem.offsetTop - +document.querySelector('.franchiser__warehouse-points-mold-list').offsetTop, 1000)
    }

    const scrollToSalaries = (id) =>{
        let staffElem = document.querySelector(`.franchises__scroll-salaries-staff-line${id}`);
        staffElem.classList.add("active");
        setTimeout(()=>{
            staffElem.classList.remove("active");
        }, 3000)
    
        let tabelElem = document.querySelector(`.franchiser__scroll-salaries-tabel-line${id}`);
        tabelElem.classList.add("active");
        setTimeout(()=>{
            tabelElem.classList.remove("active");
        }, 3000)

        let totalElem = document.querySelector(`.franchiser__scroll-salaries-total-line-${id}`);
        totalElem.classList.add("active");
        setTimeout(()=>{
            totalElem.classList.remove("active");
        }, 3000)
        scrollTo(document.querySelector('.franchises-leaders__salaries-data-block'), +totalElem.offsetTop - +document.querySelector('.franchises-leaders__salaries-total').offsetTop, 1000)

    }

    function scrollTo(element, to, duration) {
        // console.log(element)
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;
            
        var animateScroll = function(){        
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }
    
    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };

    const addListItem = (item, key) =>{
        if(navigation === 'Home'){
            return(
                <div className='moderator__search-list-item' key={key} onClick={()=>{
                    scrollToHomePoints(item.id)
                    setSearchActive(false)
                    setInputValue('')
                }}>
                    <div className={`moderator__search-list-item-home-leader`}>{item.name}</div>
                </div>
            )
        }
        else if(navigation === 'PointPrinting'){
            return(
                <div className='moderator__search-list-item' key={key} onClick={()=>{
                    scrollToPointsPrinting(item.id)
                    setSearchActive(false)
                    setInputValue('')
                }}>
                    <div className='d-flex mb-8'>
                        <div className={`moderator__search-list-item-home-leader mr-30`}>Модель: {item.model}</div>
                        <div className={`moderator__search-list-item-home-leader`}>Артикул: {item.code}</div>
                    </div>
                    <div className='d-flex'>
                        <div className={`moderator__search-list-item-home-leader mr-30`}>Сотрудник: {item.staffFull}</div>
                        <div className={`moderator__search-list-item-home-leader`}>Сотовый клиета: {item.clientPhone}</div>
                    </div>
                    
                </div>
            )
        }
        else if(navigation === 'Staff'){
            return(
                <div className='moderator__search-list-item' key={key} onClick={()=>{
                    scrollToStaff(item.id)
                    setSearchActive(false)
                    setInputValue('')
                }}>
                    <div className={`moderator__search-list-item-home-leader`}>{item.fullName}</div>
                </div>
            )
        }
        else if(navigation === 'Staff-Detail'){
            return(
                <div className='moderator__search-list-item' key={key} onClick={()=>{
                    scrollToStaffDetail(item.id)
                    setSearchActive(false)
                    setInputValue('')
                }}>

                    <div className='d-flex mb-8'>
                        <div className={`moderator__search-list-item-home-leader mr-30`}>Модель: {item.model}</div>
                        <div className={`moderator__search-list-item-home-leader`}>Артикул: {item.code}</div>
                    </div>                    
                    <div className={`moderator__search-list-item-home-leader`}>Сотовый клиета: {item.clientPhone}</div>
                </div>
            )
        }
        else if(navigation === 'Warehouse' || navigation === 'Points-mold'){
            if(item.type == "moldlist"){            
                return(
                    <div className='moderator__search-list-item' key={key} onClick={()=>{
                        scrollToWarehouseMoldList(item.id)
                        setSearchActive(false)
                        setInputValue('')
                    }}>
                        <div className={`moderator__search-list-item-home-leader`}>Артикул: {item.code}</div>
                    </div>
                )
            }
            else if(item.type == "pointslist"){            
                return(
                    <div className='moderator__search-list-item' key={key} onClick={()=>{
                        scrollToWarehousePointsList(item.id)
                        setSearchActive(false)
                        setInputValue('')
                    }}>
                        <div className={`moderator__search-list-item-home-leader`}>Точка: {item.name}</div>
                    </div>
                )
            }
            else if(item.type == "pointsMoldlist"){            
                return(
                    <div className='moderator__search-list-item' key={key} onClick={()=>{
                        scrollToWarehousePointsMoldList(item.id)
                        setSearchActive(false)
                        setInputValue('')
                    }}>
                        <div className={`moderator__search-list-item-home-leader`}>Артикул: {item.code}</div>
                    </div>
                )
            }
        }
        else if(navigation === 'Salaries'){
            return(
                <div className='moderator__search-list-item' key={key} onClick={()=>{
                    scrollToSalaries(item.id)
                    setSearchActive(false)
                    setInputValue('')
                }}>
                    
                    <div className='d-flex'>
                        <div className={`moderator__search-list-item-home-leader mr-30`}>Сотрудник: {item.fullName}</div>
                        <div className={`moderator__search-list-item-home-leader`}>Должность: {item.position}</div>
                    </div>
                </div>
            )
        }
    }


    const searchInput = async(event) =>{
        let val = event.target.value;
        setInputValue(event.target.value)
        let data = []
        if(val.length >= 3){
            // console.log(navigation, 'franchiserHomePointsList', franchiserHomePointsList,  'franchiserPointsPrinting',franchiserPointsPrinting, 'franchiserStaffData',franchiserStaffData, 'franchiserStaffDetail',franchiserStaffDetail)
            if(navigation === "Home"){
                for(let item of franchiserHomePointsList){
                    if(item.name.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push(item)
                    }            
                }
            }
            else if(navigation === "PointPrinting"){
                for(let item of franchiserPointsPrinting){
                    if(item.clientPhone.search(val.toLowerCase()) != -1 || `${item.code}`.search(val.toLowerCase()) != -1 || item.model.toLowerCase().search(val.toLowerCase()) != -1 || item.staffFull.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push(item)
                    }            
                }
            }
            else if(navigation === "Staff"){
                for(let item of franchiserStaffData){
                    if(item.fullName.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push(item)
                        // console.log(item)
                    }            
                }
            }
            else if(navigation === "Staff-Detail"){
                for(let item of franchiserStaffDetail){
                    if(item.clientPhone.search(val.toLowerCase()) != -1 || `${item.code}`.search(val.toLowerCase()) != -1 || item.model.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push(item)
                    }            
                }
            }
            else if(navigation === "Warehouse"){
                for(let item of franchiserWarehouseMoldList){
                    if(`${item.code}`.search(val.toLowerCase()) != -1){
                        data.push({...item, type: "moldlist"})
                    }   
                }
                for(let item of franchiserWarehousePoints){
                    if(item.name.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push({...item, type: "pointslist"})
                    }   
                }
            }
            else if(navigation === "Points-mold"){
                for(let item of franchiserWarehouseMoldList){
                    if(`${item.code}`.search(val.toLowerCase()) != -1){
                        data.push({...item, type: "moldlist"})
                    }   
                }
                for(let item of franchiserWarehousePointsMold){
                    if(`${item.code}`.search(val.toLowerCase()) != -1){
                        data.push({...item, type: "pointsMoldlist"})
                    }   
                }
            }
            else if(navigation === "Salaries"){
                for(let item of franchiserSalariesData){
                    if(item.fullName.toLowerCase().search(val.toLowerCase()) != -1 || item.position.toLowerCase().search(val.toLowerCase()) != -1){
                        data.push(item)
                    }   
                }
            }
        }
        else{
            setSearchActive(false)
        }
        if(data.length != 0) setSearchActive(true)
        setSearchList(data)
    }
	return (
		<div className={`owner__search-blur ${(searchActive)? "active": ""}`}>
            <div className={`owner__search d-flex align-center ${(searchActive)? "active": ""}`}>
                <div className={`owner__search-container d-flex align-center ${(searchActive)? "active": ""}`}>
                    <div className="owner__search-icon"></div>
                    <input type="text" placeholder="Поиск" className={`owner__search-input`} value={inputValue} onInput={searchInput}/>                    
                    <img src="/img/close-circle.svg" alt="" className="owner__search-close" onClick={()=>{
                        document.querySelector(".owner__search-input").value = "";
                        setSearchActive(false);
                    }}/>
                </div>
                <div className={`owner__search-list ${(searchActive)? "active": ""}`}>
                    <div className="owner__search-list-title">
                        Возможно вы ищете
                    </div>
                    <div className="owner__search-list-scrol">
                        {
                            searchList.map((item, key) => addListItem(item, key))
                        }
                    </div>
                </div>
            </div>
        </div>
	)
}

export default Search;