import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { setPopupBackgroundFunc, setPopupOpenBlock } from '../../../redux/actions/popupBlocks';

import PrintMold from './PrintMold/PrintMold'

import './index.scss';
import { setWorkZoneCheckClientReset, setWorkZonePopupPrintType } from '../../../redux/actions/workZoneCheckData';


const Popup = () => {

    const dispatch = useDispatch()

    const {popupBack, openBlock} = useSelector(({ popupBlocks})=> {
        return {
            openBlock: popupBlocks.openBlock,
            popupBack: popupBlocks.popupBack

        }
    })

    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target){
            console.log(openBlock)
            if(openBlock === 'PrintMold'){
                dispatch(setWorkZoneCheckClientReset())
                dispatch(setWorkZonePopupPrintType('interact'))
                sessionStorage.setItem('workZonePopupPrintType', 'interact')
            }

            dispatch(setPopupBackgroundFunc(false))
            dispatch(setPopupOpenBlock(''))
        }
    }

	return (
        <div className={`popup__back ${popupBack}`}>
            <div className="popup__back-wrap d-flex justify-center align-center" onClick={(event)=> ClosePopup(event)}>
                {
                    (openBlock === "PrintMold")? <PrintMold/>
                    : null
                }
            </div>
        </div>
	)
}
export default Popup;