import React from 'react';
import {useDispatch} from 'react-redux';
import { Link } from 'react-router-dom';
import {setFranchisesNav, setRole} from '../../../redux/actions/navigation';

import './Sidebar.1.0.scss';

const Sidebar = () =>{
    const dispatch = useDispatch();
	return (
		<aside className="franchises-leaders__sidebar">
            <img src="/img/logo.svg" alt="" className="franchises-leaders__logo"/>
            <div className="franchises-leaders__nav">
                <div className={`franchises-leaders__nav-item-active Home`}>
                </div>
                <Link to='/' className='d-flex justify-center'>
                    <div className="franchises-leaders__nav-item d-flex align-center" onClick={()=>{
                            dispatch(setFranchisesNav("Home"))
                            sessionStorage.setItem('page', 'Home')
                        }}>
                        <div className={`franchises-leaders__nav-item-icon home active`}></div>
                        <div className={`franchises-leaders__nav-item-title active`} >Главная</div>
                    </div>
                </Link>
            </div> 
            <div className="franchises-leaders__sidebar-footer">
                <button className="franchises-leaders__logout d-flex align-center" onClick={()=>{
                    localStorage.setItem('token', '') 
                    localStorage.setItem('role', '')
                    sessionStorage.setItem('token', '') 
                    sessionStorage.setItem('role', '')
                    dispatch(setRole(''))  
                }}>
                    <img src="/img/logout.svg" alt="" className="franchises-leaders__logout-icon"/>
                    <div className="franchises-leaders__logout-title">Выйти из аккаунта</div>                   
                </button>
            </div>
        </aside>
	)
}

export default Sidebar;