// let dat = new Date().toLocaleDateString().split('.')
let to = new Date(new Date().toLocaleDateString().split('.')[2], new Date().toLocaleDateString().split('.')[1], 1)
to.setDate(to.getDate() - 1)
// to.setMonth(to.getMonth()+1)

const initialState = {
    ownerHomeRange: (sessionStorage.getItem('OwnerHomePopupFilters') !== null && JSON.parse(sessionStorage.getItem('OwnerHomePopupFilters')).range.length !== 0)? [new Date(JSON.parse(sessionStorage.getItem('OwnerHomePopupFilters')).range[0]), new Date(JSON.parse(sessionStorage.getItem('OwnerHomePopupFilters')).range[1])]: [new Date(), new Date()],
    ownerHomeMonth: (sessionStorage.getItem('OwnerHomePopupFilters') !== null)? JSON.parse(sessionStorage.getItem('OwnerHomePopupFilters')).month: "Месяц",
    ownerHomeYear: (sessionStorage.getItem('OwnerHomePopupFilters') !== null)? JSON.parse(sessionStorage.getItem('OwnerHomePopupFilters')).year: "Год",

    ownerWarehouseGrafRange: (sessionStorage.getItem('OwnerWarehouseGrafRange') !== null && JSON.parse(sessionStorage.getItem('OwnerWarehouseGrafRange')).range.length !== 0)? [new Date(JSON.parse(sessionStorage.getItem('OwnerWarehouseGrafRange')).range[0]), new Date(JSON.parse(sessionStorage.getItem('OwnerWarehouseGrafRange')).range[1])]: [new Date(), new Date()],
    ownerWarehouseGrafMonth: (sessionStorage.getItem('OwnerWarehouseGrafRange') !== null)? JSON.parse(sessionStorage.getItem('OwnerWarehouseGrafRange')).month: "Месяц",
    ownerWarehouseGrafYear: (sessionStorage.getItem('OwnerWarehouseGrafRange') !== null)? JSON.parse(sessionStorage.getItem('OwnerWarehouseGrafRange')).year: "Год",

    ownerWarehousePurchaseRange: (sessionStorage.getItem('OwnerWarehousePurchaseRange') !== null && JSON.parse(sessionStorage.getItem('OwnerWarehousePurchaseRange')).range.length !== 0)? [new Date(JSON.parse(sessionStorage.getItem('OwnerWarehousePurchaseRange')).range[0]), new Date(JSON.parse(sessionStorage.getItem('OwnerWarehousePurchaseRange')).range[1])]: [new Date(), new Date()],
    ownerWarehousePurchaseMonth: (sessionStorage.getItem('OwnerWarehousePurchaseRange') !== null)? JSON.parse(sessionStorage.getItem('OwnerWarehousePurchaseRange')).month: "Месяц",
    ownerWarehousePurchaseYear: (sessionStorage.getItem('OwnerWarehousePurchaseRange') !== null)? JSON.parse(sessionStorage.getItem('OwnerWarehousePurchaseRange')).year: "Год",

    franchiserHomeRange: (sessionStorage.getItem('franchiserHomePopupFilters') !== null && JSON.parse(sessionStorage.getItem('franchiserHomePopupFilters')).range.length !== 0)? [new Date(JSON.parse(sessionStorage.getItem('franchiserHomePopupFilters')).range[0]), new Date(JSON.parse(sessionStorage.getItem('franchiserHomePopupFilters')).range[1])]: [new Date(), new Date()],
    franchiserHomeMonth: (sessionStorage.getItem('franchiserHomePopupFilters') !== null)? JSON.parse(sessionStorage.getItem('franchiserHomePopupFilters')).month: "Месяц",
    franchiserHomeYear: (sessionStorage.getItem('franchiserHomePopupFilters') !== null)? JSON.parse(sessionStorage.getItem('franchiserHomePopupFilters')).year: "Год",

    franchiserPointPrintingRange: (sessionStorage.getItem('franchiserPointsPrintingPopupFilters') !== null && JSON.parse(sessionStorage.getItem('franchiserPointsPrintingPopupFilters')).range.length !== 0)? [new Date(JSON.parse(sessionStorage.getItem('franchiserPointsPrintingPopupFilters')).range[0]), new Date(JSON.parse(sessionStorage.getItem('franchiserPointsPrintingPopupFilters')).range[1])]: [],
    franchiserPointPrintingMonth: (sessionStorage.getItem('franchiserPointsPrintingPopupFilters') !== null)? JSON.parse(sessionStorage.getItem('franchiserPointsPrintingPopupFilters')).month: "Месяц",
    franchiserPointPrintingYear: (sessionStorage.getItem('franchiserPointsPrintingPopupFilters') !== null)? JSON.parse(sessionStorage.getItem('franchiserPointsPrintingPopupFilters')).year: "Год",

    franchiserStaffRange: (sessionStorage.getItem('franchiserStaffPopupFilters') !== null && JSON.parse(sessionStorage.getItem('franchiserStaffPopupFilters')).range.length !== 0)? [new Date(JSON.parse(sessionStorage.getItem('franchiserStaffPopupFilters')).range[0]), new Date(JSON.parse(sessionStorage.getItem('franchiserStaffPopupFilters')).range[1])]: [],
    franchiserStaffMonth: (sessionStorage.getItem('franchiserStaffPopupFilters') !== null)? JSON.parse(sessionStorage.getItem('franchiserStaffPopupFilters')).month: "Месяц",
    franchiserStaffYear: (sessionStorage.getItem('franchiserStaffPopupFilters') !== null)? JSON.parse(sessionStorage.getItem('franchiserStaffPopupFilters')).year: "Год",

    franchiserSalariesRange: (sessionStorage.getItem('franchiserSalariesPopupFilters') !== null && JSON.parse(sessionStorage.getItem('franchiserSalariesPopupFilters')).range.length !== 0)? [new Date(JSON.parse(sessionStorage.getItem('franchiserSalariesPopupFilters')).range[0]), new Date(JSON.parse(sessionStorage.getItem('franchiserSalariesPopupFilters')).range[1])]: [new Date(new Date().toLocaleDateString().split('.')[2], new Date().toLocaleDateString().split('.')[1] - 1, 1), to],
    franchiserSalariesMonth: (sessionStorage.getItem('franchiserSalariesPopupFilters') !== null)? JSON.parse(sessionStorage.getItem('franchiserSalariesPopupFilters')).month: "Месяц",
    franchiserSalariesYear: (sessionStorage.getItem('franchiserSalariesPopupFilters') !== null)? JSON.parse(sessionStorage.getItem('franchiserSalariesPopupFilters')).year: new Date().getFullYear(),
    franchiserSalariesRangeActive: (sessionStorage.getItem('franchiserSalariesPopupFilters') !== null)? JSON.parse(sessionStorage.getItem('franchiserSalariesPopupFilters')).rangeActive: false,
    franchiserSalariesRangeTypeSelect: (sessionStorage.getItem('franchiserSalariesPopupFiltersPeriodListActive') !== null)? JSON.parse(sessionStorage.getItem('franchiserSalariesPopupFiltersPeriodListActive')): {id: 3, value: 'Месяц'},

    salariesTableRange: (sessionStorage.getItem('staffSalariesTablePopupFilters') !== null && JSON.parse(sessionStorage.getItem('staffSalariesTablePopupFilters')).range.length !== 0)? [new Date(JSON.parse(sessionStorage.getItem('staffSalariesTablePopupFilters')).range[0]), new Date(JSON.parse(sessionStorage.getItem('staffSalariesTablePopupFilters')).range[1])]: [new Date(new Date().toLocaleDateString().split('.')[2], new Date().toLocaleDateString().split('.')[1] - 1, 1), to],
    salariesTableMonth: (sessionStorage.getItem('staffSalariesTablePopupFilters') !== null)? JSON.parse(sessionStorage.getItem('staffSalariesTablePopupFilters')).month: "Месяц",
    salariesTableYear: (sessionStorage.getItem('staffSalariesTablePopupFilters') !== null)? JSON.parse(sessionStorage.getItem('staffSalariesTablePopupFilters')).year: new Date().getFullYear(),
    salariesTableRangeActive: (sessionStorage.getItem('staffSalariesPopupFilters') !== null)? JSON.parse(sessionStorage.getItem('staffSalariesTablePopupFilters')).rangeActive: false,
    salariesTableRangeTypeSelect: (sessionStorage.getItem('staffSalariesTablePopupFiltersPeriodListActive') !== null)? JSON.parse(sessionStorage.getItem('staffSalariesTablePopupFiltersPeriodListActive')): {id: 3, value: 'Месяц'},

    salariesButtonsRange: [],
    salariesButtonsMonth: "Месяц",
    salariesButtonsYear: "Год",

    // workZoneHistoryDate: {date: null, time: '00:00'},
    workZoneHistoryDate: {date: null},
    workZoneClientsDate: {date: null},
};
  
  const filters = (state = initialState, action) => {
    if (action.type === 'SET_FILTERS_OWNER_HOME_RANGE') {
        return {
            ...state,
            ownerHomeRange: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_OWNER_HOME_MONTH') {
        return {
            ...state,
            ownerHomeMonth: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_OWNER_HOME_YEAR') {
        return {
            ...state,
            ownerHomeYear: action.payload,
        };
    }

    else if (action.type === 'SET_FILTERS_OWNER_WAREHOUSE_GRAF_RANGE') {
        return {
            ...state,
            ownerWarehouseGrafRange: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_OWNER_WAREHOUSE_GRAF_MONTH') {
        return {
            ...state,
            ownerWarehouseGrafMonth: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_OWNER_WAREHOUSE_GRAF_YEAR') {
        return {
            ...state,
            ownerWarehouseGrafYear: action.payload,
        };
    }

    else if (action.type === 'SET_FILTERS_OWNER_WAREHOUSE_PURCHASE_RANGE') {
        return {
            ...state,
            ownerWarehousePurchaseRange: action.payload,
        };
    }

    else if (action.type === 'SET_FILTERS_OWNER_WAREHOUSE_PURCHASE_MONTH') {
        return {
            ...state,
            ownerWarehousePurchaseMonth: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_OWNER_WAREHOUSE_PURCHASE_YEAR') {
        return {
            ...state,
            ownerWarehousePurchaseYear: action.payload,
        };
    }

    else if (action.type === 'SET_FILTERS_FRANCHISER_HOME_RANGE') {
        return {
            ...state,
            franchiserHomeRange: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_FRANCHISER_HOME_MONTH') {
        return {
            ...state,
            franchiserHomeMonth: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_FRANCHISER_HOME_YEAR') {
        return {
            ...state,
            franchiserHomeYear: action.payload,
        };
    }

    else if (action.type === 'SET_FILTERS_FRANCHISER_POINTS_PRINTING_RANGE') {
        return {
            ...state,
            franchiserPointPrintingRange: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_FRANCHISER_POINTS_PRINTING_MONTH') {
        return {
            ...state,
            franchiserPointPrintingMonth: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_FRANCHISER_POINTS_PRINTING_YEAR') {
        return {
            ...state,
            franchiserPointPrintingYear: action.payload,
        };
    }

    else if (action.type === 'SET_FILTERS_FRANCHISER_STAFF_RANGE') {
        return {
            ...state,
            franchiserStaffRange: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_FRANCHISER_STAFF_MONTH') {
        return {
            ...state,
            franchiserStaffMonth: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_FRANCHISER_STAFF_YEAR') {
        return {
            ...state,
            franchiserStaffYear: action.payload,
        };
    }

    else if (action.type === 'SET_FILTERS_FRANCHISER_SALARIES_RANGE') {
        return {
            ...state,
            franchiserSalariesRange: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_FRANCHISER_SALARIES_RANGE_ACTIVE') {
        return {
            ...state,
            franchiserSalariesRangeActive: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_FRANCHISER_SALARIES_RANGE_TYPE_SELECT') {
        return {
            ...state,
            franchiserSalariesRangeTypeSelect: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_FRANCHISER_SALARIES_MONTH') {
        return {
            ...state,
            franchiserSalariesMonth: action.payload,
            franchiserSalariesRangeActive: false
        };
    }
    else if (action.type === 'SET_FILTERS_FRANCHISER_SALARIES_YEAR') {
        return {
            ...state,
            franchiserSalariesYear: action.payload,
            franchiserSalariesRangeActive: false
        };
    }

    else if (action.type === 'SET_FILTERS_SALARIES_TABLE_RANGE') {
        return {
            ...state,
            salariesTableRange: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_STAFF_SALARIES_TABLE_RANGE_ACTIVE') {
        return {
            ...state,
            salariesTableRangeActive: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_STAFF_SALARIES_TABLE_RANGE_TYPE_SELECT') {
        return {
            ...state,
            salariesTableRangeTypeSelect: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_SALARIES_TABLE_MONTH') {
        return {
            ...state,
            salariesTableMonth: action.payload,
            salariesTableRangeActive: false
        };
    }
    else if (action.type === 'SET_FILTERS_SALARIES_TABLE_YEAR') {
        return {
            ...state,
            salariesTableYear: action.payload,
            salariesTableRangeActive: false
        };
    }

    else if (action.type === 'SET_FILTERS_SALARIES_BUTTONS_RANGE') {
        return {
            ...state,
            salariesButtonsRange: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_SALARIES_BUTTONS_MONTH') {
        return {
            ...state,
            salariesButtonsMonth: action.payload,
        };
    }
    else if (action.type === 'SET_FILTERS_SALARIES_BUTTONS_YEAR') {
        return {
            ...state,
            salariesButtonsYear: action.payload,
        };
    }
    else if (action.type === 'SET_WORK_ZONE_HISTORY_DATE') {
        return {
            ...state,
            workZoneHistoryDate: action.payload,
        };
    }
    else if (action.type === 'SET_WORK_ZONE_CLIENTS_DATE') {
        return {
            ...state,
            workZoneClientsDate: action.payload,
        };
    }
    return state;
  };
  
  export default filters;