import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { setPopupBackgroundFunc, setPopupOpenBlock } from '../../../redux/actions/popupBlocks';

import AddEnvelope from './AddEnvelope/AddEnvelope'
import TransferEnvelope from './TransferEnvelope/TransferEnvelope';

import './index-1.0.scss';


const Popup = () => {

    const dispatch = useDispatch()

    const {popupBack, openBlock} = useSelector(({ popupBlocks})=> {
        return {
            openBlock: popupBlocks.openBlock,
            popupBack: popupBlocks.popupBack

        }
    })

    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target){
            dispatch(setPopupBackgroundFunc(false))
            dispatch(setPopupOpenBlock(''))
        }
    }

	return (
        <div className={`popup__back ${popupBack}`}>
            <div className="popup__back-wrap d-flex justify-center align-center" onClick={(event)=> ClosePopup(event)}>
                {
                    (openBlock === "WarehouseAddEnvelope")? <AddEnvelope/>
                    :(openBlock === "warehouseTransferEnvelope")? <TransferEnvelope/>
                    : null
                }
            </div>
        </div>
	)
}
export default Popup;