import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Route } from 'react-router-dom';

import Header from './Header/Header'; 
import Popup from './Popup/Popup';
import Home from './Home/Home';
import Leaders from './Leaders/Leaders';
import LeaderDetail from './LeaderDetail/LeaderDetail';
import LeaderPointDetail from './LeaderPointDetail/LeaderPointDetail';
import OrderHistory from './OrderHistory/OrderHistory';
import OrderHistoryDetail from './OrderHistoryDetail/OrderHistoryDetail';

import { setPopupOpenBlock, setPopupOpenBlockEvent, setWarehousePopupMenuPage} from '../../redux/actions/popupBlocks';

import './Warehouse.scss';





const Warehouse = () => {
	const dispatch = useDispatch()

	const { openBlock, openBlockEvent} = useSelector(({popupBlocks})=> {
    return {
        openBlock: popupBlocks.openBlock,
        openBlockEvent: popupBlocks.openBlockEvent
    }
  })

  const closePopup = (event) =>{
    if(openBlock !== '' && !event.target.classList.contains('popup-open-button')){
      let block = document.querySelector(`.${openBlock}`)
      let clickBlock = event.target; 
      if(clickBlock !== openBlockEvent && clickBlock !== block && block && !block.contains(clickBlock)){
        dispatch(setPopupOpenBlock(''))
        dispatch(setPopupOpenBlockEvent(''))
        if(openBlock.indexOf('warehouseHomeTableElemMenu_') !== -1) dispatch(setWarehousePopupMenuPage('main'))
      }
      
    }
  }
  return (
		<React.Fragment>
      <div className={`warehouse`} onClick={(event)=>closePopup(event)}>
        <Header/>
        <div className="warehouse__wrap">          
          <Route path='/' component={Home} exact />
          <Route path='/leaders' component={Leaders} exact />
          <Route path='/leader-detail' component={LeaderDetail} exact />
          <Route path='/point-detail' component={LeaderPointDetail} exact />
          <Route path='/order-history' component={OrderHistory} exact />
          <Route path='/order-history-detail' component={OrderHistoryDetail} exact />
        </div>
      </div>
      <Popup />
    </React.Fragment>
	)
}

export default Warehouse;