import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

export const setFranchiserHomeSalesIncomeGraff = (item) => ({
    type: 'SET_FRANCHISER_HOME_INCOME_GRAFF',
    payload: item
});

export const setFranchiserHomeSalesIncomeButtons = (item) => ({
    type: 'SET_FRANCHISER_HOME_INCOME_BUTTONS',
    payload: item
});

export const setFranchiserHomePointsList = (item) => ({
    type: 'SET_FRANCHISER_HOME_POINTS_LIST',
    payload: item
});

export const setFranchiserPointsPrinting = (item) => ({
    type: 'SET_FRANCHISER_POINTS_PRINTING',
    payload: item
});

export const setFranchiserStaffList = (item) => ({
    type: 'SET_FRANCHISER_STAFF_DATA',
    payload: item
});

export const setFranchiserSalariesDate = (item) => ({
    type: 'SET_FRANCHISER_SALARIES_DATE',
    payload: item
});

export const setFranchiserStaffListAdd = (item) => ({
    type: 'SET_FRANCHISER_STAFF_LIST_ADD',
    payload: item
});

export const setFranchiserStaffButtons = (item) => ({
    type: 'SET_FRANCHISER_STAFF_BUTTONS_DATA',
    payload: item
});

export const setFranchiserStaffDetailStaffData = (item) => ({
    type: 'SET_FRANCHISER_STAFF_DETAIL_STAFF_DATA',
    payload: item
});

export const setFranchiserStaffDetail = (item) => ({
    type: 'SET_FRANCHISER_STAFF_DETAIL',
    payload: item
});

export const setFranchiserWarehouseMoldList = (item) => ({
    type: 'SET_FRANCHISER_WAREHOUSE_MOLD_LIST',
    payload: item
});

export const setFranchiserWarehouseMoldListEdit = (item) => ({
    type: 'SET_FRANCHISER_WAREHOUSE_MOLD_LIST_EDIT',
    payload: item
});

export const setFranchiserWarehousePointsMold = (item) => ({
    type: 'SET_FRANCHISER_POINTS_MOLD',
    payload: item
});

export const setFranchiserWarehousePointsMoldEdit = (item) => ({
    type: 'SET_FRANCHISER_POINTS_MOLD_EDIT',
    payload: item
});

export const setFranchiserWarehousePointsList = (item) => ({
    type: 'SET_FRANCHISER_POINTS',
    payload: item
});

export const setFranchiserSalariesData = (item) => ({
    type: 'SET_FRANCHISER_SALARIES_DATA',
    payload: item
});









async function post(url, type, data = null){
    let response = await fetch(url, {
        method: type, 
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    })
    if (response.ok === true) {
        const user = await response.json();
        return user
    }
    let dat = response.json
    return dat 
}

async function postNotData(url, type){
    let response = await fetch(url, {
        method: type, 
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
        },
    })
    if (response.ok === true) {
        const user = await response.json();
        return user
    }
    let dat = response.json
    return dat 
}

let monthKey = {
    "Январь": 1,
    "Февраль": 2,
    "Март": 3,
    "Апрель": 4,
    "Май": 5,
    "Июнь": 6,
    "Июль": 7,
    "Август": 8,
    "Сентябрь": 9,
    "Октябрь": 10,
    "Ноябрь": 11,
    "Декабрь": 12
}



export const fetchFranchiserHomeSalesIncomeGraff = (buttonActive, range, month, year) => (dispatch) =>{
    let data = {
        month: (month === "Месяц")? 0: monthKey[month], 
        year:  (year === "Год" && month === "Месяц")? 0:(month !== "Месяц" && year === "Год")? new Date().getFullYear(): year,
        incSale: (buttonActive === "sales")? 1: 0,
    }
    if((range.length > 0)){
        data.From = range[0].toLocaleDateString('en-US')
        data.To = range[1].toLocaleDateString('en-US')
    }

    postNotData(`/francheaser/GetGraf?`+ new URLSearchParams(data), "GET").then((response)=> {
        dispatch(setFranchiserHomeSalesIncomeGraff(response.value.resultObject))
    })

}

export const fetchFranchiserHomeSalesIncomeButtons = (range, month, year, placeId) => (dispatch) =>{
    let data = {
        month: (month === "Месяц")? 0: monthKey[month], 
        year:  (year === "Год" && month === "Месяц")? 0:(month !== "Месяц" && year === "Год")? new Date().getFullYear(): year,
        placeId: placeId
    }
    if((range.length > 0)){
        data.From = range[0].toLocaleDateString('sv-SE')
        data.To = range[1].toLocaleDateString('sv-SE')
    }

    post(`/francheaser/GetMainButtons`, "POST", data).then((response)=> {
        dispatch(setFranchiserHomeSalesIncomeButtons(response.value.resultObject))
    })

}

export const fetchFranchiserHomePointsList = () => (dispatch) =>{
    postNotData(`/francheaser/GetPlacesForDirector`, "GET").then((response)=> {
        dispatch(setFranchiserHomePointsList(response.value.resultObject))
    })

}

export const fetchFranchiserPointsPrinting = (id, range, month, year) => (dispatch) =>{
    let data = {
        month: (month === "Месяц")? 0: monthKey[month], 
        year:  (year === "Год" && month === "Месяц")? 0:(month !== "Месяц" && year === "Год")? new Date().getFullYear(): year,
        id: id
    }
    if((range.length > 0)){
        data.From = range[0].toLocaleDateString('en-US')
        data.To = range[1].toLocaleDateString('en-US')
    }

    postNotData(`/francheaser/GetPointPrinting?`+ new URLSearchParams(data), "GET").then((response)=> {
        dispatch(setFranchiserPointsPrinting(response.value.resultObject))
    })

}

export const fetchFranchiserStaffButtonsData = (range, month, year) => (dispatch) =>{
    let data = {
        month: (month === "Месяц")? 0: monthKey[month], 
        year:  (year === "Год" && month === "Месяц")? 0:(month !== "Месяц" && year === "Год")? new Date().getFullYear(): year,
    }

    if((range.length > 0)){
        data.From = range[0].toLocaleDateString('en-US')
        data.To = range[1].toLocaleDateString('en-US')
    }

    postNotData(`/francheaser/MainButtonsForStaffs?`+ new URLSearchParams(data), "GET").then((response)=> {
        dispatch(setFranchiserStaffButtons(response.value.resultObject))
    })

}

export const fetchFranchiserStaffData = (range, month, year) => (dispatch) =>{
    let data = {
        month: (month === "Месяц")? 0: monthKey[month], 
        year:  (year === "Год" && month === "Месяц")? 0:(month !== "Месяц" && year === "Год")? new Date().getFullYear(): year,
    }

    if((range.length > 0)){
        data.From = range[0].toLocaleDateString('en-US')
        data.To = range[1].toLocaleDateString('en-US')
    }
    
    postNotData(`/francheaser/GetStaffData?`+ new URLSearchParams(data), "GET").then((response)=> {
        dispatch(setFranchiserStaffList(response.value.resultObject))
    })
}

export const fetchFranchiserStaffDetail = (id) => (dispatch) =>{
    let data = {
        id: id
    }
    postNotData(`/francheaser/GetStaffDetail?`+ new URLSearchParams(data), "GET").then((response)=> {
        dispatch(setFranchiserStaffDetail(response.value.resultObject.sales))
        dispatch(setFranchiserStaffDetailStaffData(response.value.resultObject.data))
    })
}

export const fetchFranchiserWarehouseMoldList = () => (dispatch) =>{
    postNotData(`/francheaser/GetMoldList`, "GET").then((response)=> {
        dispatch(setFranchiserWarehouseMoldList(response.value.resultObject))
    })
}

export const fetchFranchiserWarehousePointsList = () => (dispatch) =>{
    postNotData(`/francheaser/GetPlaceMoldList`, "GET").then((response)=> {
        dispatch(setFranchiserWarehousePointsList(response.value.resultObject))
    })

}

export const fetchFranchiserWarehousePointsMold = (id) => (dispatch) =>{
    let data = {
        id: id
    }
    postNotData(`/francheaser/GetPlaceMoldDetail?`+ new URLSearchParams(data), "GET").then((response)=> {
        dispatch(setFranchiserWarehousePointsMold(response.value.resultObject))
    })
}

export const fetchFranchiserSalaries = (range) => (dispatch) =>{
    let data = {
        From: range[0].toLocaleDateString('en-US'),
        To: range[1].toLocaleDateString('en-US')
    }

    postNotData(`/francheaser/GetWageTable?`+ new URLSearchParams(data), "GET").then((response)=> {
        dispatch(setFranchiserSalariesData(response.value.resultObject.workers))
        dispatch(setFranchiserSalariesDate(response.value.resultObject.calendar))
    })

}