import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import {setFranchisesNav, setRole} from '../../../redux/actions/navigation';

import './Sidebar.1.0.scss';

const Sidebar = () =>{
    const dispatch = useDispatch();
    const {franchiserNav} = useSelector(({navigation})=> {
        return {
            franchiserNav: navigation.franchiserNav
        }
    })
	return (
		<aside className="franchises-leaders__sidebar">
            <img src="/img/logo.svg" alt="" className="franchises-leaders__logo"/>
            <div className="franchises-leaders__nav">
                <div className={`franchises-leaders__nav-item-active ${franchiserNav}`}>
                </div>
                <Link to='/' className='d-flex justify-center'>
                    <div className="franchises-leaders__nav-item d-flex align-center" onClick={()=>{
                            dispatch(setFranchisesNav("Home"))
                            sessionStorage.setItem('franchPage', 'Home')
                        }}>
                        <div className={`franchises-leaders__nav-item-icon home-icon ${(franchiserNav === "Home")? "active" : ""}`}></div>
                        <div className={`franchises-leaders__nav-item-title ${(franchiserNav === "Home")? "active" : ""}`} >Точки</div>
                    </div>
                </Link>
                <Link to='/staff' className='d-flex justify-center'>
                    <div className="franchises-leaders__nav-item d-flex align-center" onClick={()=>{
                            dispatch(setFranchisesNav("Staff"))
                            sessionStorage.setItem('franchPage', 'Staff')
                        }}>
                        <div className={`franchises-leaders__nav-item-icon staff-icon ${(franchiserNav === "Staff")? "active" : ""}`}></div>
                        <div className={`franchises-leaders__nav-item-title ${(franchiserNav === "Staff")? "active" : ""}`}>Сотрудники</div>
                    </div>
                </Link>
                <Link to='/warehouse' className='d-flex justify-center'>
                    <div className="franchises-leaders__nav-item d-flex align-center" onClick={()=>{
                            dispatch(setFranchisesNav("Warehouse"))
                            sessionStorage.setItem('franchPage', 'Warehouse')
                        }}>
                        <div className={`franchises-leaders__nav-item-icon warehouse-icon ${(franchiserNav === "Warehouse" || franchiserNav === "Points-mold")? "active" : ""}`}></div>
                        <div className={`franchises-leaders__nav-item-title ${(franchiserNav === "Warehouse" || franchiserNav === "Points-mold")? "active" : ""}`}>Склад</div> 
                    </div>
                </Link>
                <Link to='/salaries' className='d-flex justify-center'>
                    <div className="franchises-leaders__nav-item d-flex align-center" onClick={()=>{
                            dispatch(setFranchisesNav("Salaries"))
                            sessionStorage.setItem('franchPage', 'Salaries')
                        }}>
                        <div className={`franchises-leaders__nav-item-icon salaries-icon ${(franchiserNav === "Salaries")? "active" : ""}`}></div>
                        <div className={`franchises-leaders__nav-item-title ${(franchiserNav === "Salaries")? "active" : ""}`}>Зарплаты</div> 
                    </div>
                </Link>
            </div> 
            <div className="franchises-leaders__sidebar-footer">
                <button className="franchises-leaders__logout d-flex align-center" onClick={()=>{
                    localStorage.setItem('token', '') 
                    localStorage.setItem('role', '')
                    sessionStorage.setItem('token', '') 
                    sessionStorage.setItem('role', '')
                    sessionStorage.setItem('franchPage', 'Home')
                    dispatch(setRole(''))  
                }}>
                    <img src="/img/logout.svg" alt="" className="franchises-leaders__logout-icon"/>
                    <div className="franchises-leaders__logout-title">Выйти из аккаунта</div>                   
                </button>
            </div>
        </aside>
	)
}

export default Sidebar;