import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {setCalendDisplay} from '../../../../redux/actions/display';
import {setOwnerWarehousePurchaseMonth, setOwnerWarehousePurchaseYear} from '../../../../redux/actions/popupFilters';
import {fetchOwnerWarehousePurchase} from '../../../../redux/actions/ownerData';
import './Purchase-table.scss'

const PurchaseTable = () => {
    const dispatch = useDispatch()
    const {month, year, range, tableData} = useSelector(({popupFilters, ownerData})=> {
        return {
            month: popupFilters.ownerWarehousePurchaseMonth,
            year: popupFilters.ownerWarehousePurchaseYear,
            range: popupFilters.ownerWarehousePurchaseRange,
            tableData: ownerData.ownerWarehousePurchase
        }
    })
    let dataMonth = [
        {
            id: 0,
            value: "Январь"
        },
        {
            id: 1,
            value: "Февраль"
        },
        {
            id: 2,
            value: "Март"
        },
        {
            id: 3,
            value: "Апрель"
        },
        {
            id: 4,
            value: "Май"
        },
        {
            id: 5,
            value: "Июнь"
        },
        {
            id: 6,
            value: "Июль"
        },
        {
            id: 7,
            value: "Август"
        },
        {
            id: 8,
            value: "Сентябрь"
        },
        {
            id: 9,
            value: "Октябрь"
        },
        {
            id: 10,
            value: "Ноябрь"
        },
        {
            id: 11,
            value: "Декабрь"
        }
    ];
    let defaultYear = new Date().getFullYear()
    let dataYear = [
        {
            id: 0,
            value: defaultYear - 5
        },
        {
            id: 1,
            value: defaultYear - 4
        },
        {
            id: 2,
            value: defaultYear - 3
        },
        {
            id: 3,
            value: defaultYear - 2
        },
        {
            id: 4,
            value: defaultYear - 1
        },
        {
            id: 5,
            value: defaultYear
        },
        {
            id: 6,
            value: defaultYear + 1
        },
        {
            id: 7,
            value: defaultYear + 2
        },
        {
            id: 8,
            value: defaultYear + 3
        },
        {
            id: 9,
            value: defaultYear + 4
        },
        {
            id: 10,
            value: defaultYear + 5
        }
    ]

    let [tablePageNum, setTablePageNum] = useState(0)

    const [monthActive, setMonthActive]  = useState(false);
    const [yearActive, setYearActive]  = useState(false);

    useEffect(()=>{
        dispatch(fetchOwnerWarehousePurchase(range, month, year))
    }, [])

    useEffect(()=>{
        dispatch(fetchOwnerWarehousePurchase(range, month, year))
    }, [range, month, year])

    const monthActivate = () =>{

            setMonthActive(!monthActive)
            setYearActive(false)
        
    }
    const yearActivate = () =>{

            setYearActive(!yearActive)
            setMonthActive(false)
    }
    const monthChange = (id) =>{
        dispatch(setOwnerWarehousePurchaseMonth(dataMonth[id].value))
        setMonthActive(false)
    }
    const yearChange = (id) =>{
        dispatch(setOwnerWarehousePurchaseYear(dataYear[id].value))
        setYearActive(false)
    }

    const addMonth = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(month === value)? "active": ""}`} key={key} onClick={() => {monthChange(id)}}>{value}</div>
        )
    }
    const addYear = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(year === value)? "active": ""}`} key={key} onClick={() => {yearChange(id)}}>{value}</div>
        )
    }

    const addTableItem = ({directorId, buyerPhoto, name, quantity, summ}, key) =>{
        return(
            <div className={`owner__purchase-table-line d-flex align-center justify-between scroll__purchase-table-line-${directorId}`} key={key}>
                <div className="owner__purchase-table-buyer d-flex align-center">
                    <div className="owner__purchase-table-buyer-photo-container d-flex align-center justify-center">
                        <img src={buyerPhoto} alt="" className="owner__purchase-table-buyer-photo"/>
                    </div>
                    <span className="d-block">{name}</span>
                </div>
                <div className="owner__purchase-table-quantity">{quantity}</div>
                <div className="owner__purchase-table-summ">{summ}</div>                        
            </div>
        )
    }

    const pageButtons = (button) =>{
        let pet = tablePageNum
        if(button == "back"){
            setTablePageNum(tablePageNum-1)
        }
        else{
            setTablePageNum(tablePageNum+1)
        }
        // console.log(pet)
    }

	return (
        <div className="owner__purchase">
            <div className="owner__purchase-top d-flex align-center justify-between">
                <div className="owner__purchase-title">
                    Таблица закупа    
                </div>
                <div className='owner__sales-filters__block d-flex align-center'>
                    <div className="owner__sales-filters__block-item sales year">
                        <div className={`owner__sales-filters__block-item-title d-flex align-center justify-center year ${(year === "Год")? "": "active"} ${(yearActive)? "open": ""}`} onClick={yearActivate}>{year}<div className="owner__sales-filters__block-item-title-icon"/></div>                        
                        <div className={`owner__sales-filters__block-item-list ${(yearActive)? "open": ""}`}>
                            <div className='owner__sales-filters__block-item-list-scroll'>
                                {
                                    dataYear.map((item, key) => addYear(item, key))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="owner__sales-filters__block-item sales months">
                        <div className={`owner__sales-filters__block-item-title d-flex align-center justify-center months ${(month === "Месяц")? "": "active"} ${(monthActive)? "open": ""}`} onClick={monthActivate}>{month}<div className="owner__sales-filters__block-item-title-icon"/></div>                        
                        <div className={`owner__sales-filters__block-item-list ${(monthActive)? "open": ""}`}>
                            <div className='owner__sales-filters__block-item-list-scroll'>
                                {
                                    dataMonth.map((item, key) => addMonth(item, key))
                                }                               
                            </div>
                        </div>
                    </div>
                    <div className="owner__sales-filters d-flex justify-between">
                        <div className="owner__sales-filters months" onClick={() => {
                            dispatch(setCalendDisplay(""));
                            setTimeout(()=>{
                                dispatch(setCalendDisplay("open", "ownerWarehousePurchase"))
                            }, 0)
                        }}>
                            <div className="owner__sales-filters-title d-flex align-center justify-center months">Фильтры<img src="/img/arrow-down.svg" alt="" className="owner__sales-filters-title-icon"/></div>                        
                        </div>
                    </div>
                </div>
            </div> 
            <div className="owner__purchase-content">
                <div className='owner__purchase-table-scroll'>
                    <div className="owner__purchase-table-line title d-flex align-center justify-between">
                        <div className="owner__purchase-table-buyer">Покупатель</div>
                        <div className="owner__purchase-table-quantity">Количество</div>
                        <div className="owner__purchase-table-summ">Сумма</div>                    
                    </div>
                    <div className="owner__purchase-table">
                        {
                            (window.innerWidth > 700)?(

                                tableData.map((item, key) => addTableItem(item, key))
                                
                            ):(
                                (tablePageNum * 5 <= tableData.length)?(
                                    tableData.slice(tablePageNum * 5, tablePageNum * 5 + 5).map((item, key) => addTableItem(item, key))
                                ):(
                                    tableData.slice(tablePageNum * 5).map((item, key) => addTableItem(item, key))
                                )
                            )
                        }
                    </div>
                </div>
                {
                    (window.innerWidth < 700)?(
                        <div className='owner__points-sale-table-buttons d-flex justify-between'>
                            <div className={`owner__points-sale-table-buttons-item back ${(tablePageNum > 0)? 'active': ''}`} onClick={()=>{pageButtons("back")}}></div>
                            <div className={`owner__points-sale-table-buttons-item forward ${((tablePageNum === 0 && tableData.length > 5) || tableData.length > (tablePageNum+1) * 5)? 'active': ''}`} onClick={()=>{pageButtons("forward")}}></div>
                        </div>
                    ): null
                }   
            </div>  
        </div>
	)
}

export default PurchaseTable;