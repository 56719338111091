export const setFranchiserHomePointsCheck = (item) => ({
    type: 'SET_FRANCHISER_HOME_POINTS_CHECK',
    payload: item
});

export const setFranchiserHomeStaffListCheck = (item) => ({
    type: 'SET_FRANCHISER_HOME_STAFF_LIST_CHECK',
    payload: item
});

export const setFranchiserWarehousePointsListCheck = (item) => ({
    type: 'SET_FRANCHISER_WAREHOUSE_POINTS_LIST_CHECK',
    payload: item
});

export const setFranchiserWarehouseTransfer = (item) => ({
    type: 'SET_FRANCHISER_WAREHOUSE_TRANSFER',
    payload: item
});

export const setFranchiserSalariesCheckDay = (item) => ({
    type: 'SET_FRANCHISER_SALARIES_CHECK_DAY',
    payload: item
});

export const setFranchiserLayoffsStaffCheck = (item) => ({
    type: 'SET_FRANCHISER_LAYOFFS_STAFF_CHECK',
    payload: item
});

export const setFranchiserPointsListCheck = (item) => ({
    type: 'SET_FRANCHISER_HOME_POINTS_LIST_CHECK',
    payload: item
});

export const setFranchiserPointsListCheckEdit = (item) => ({
    type: 'SET_FRANCHISER_HOME_POINTS_LIST_CHECK_EDIT',
    payload: item
});