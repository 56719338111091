import {React, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setOwnerWarehouseButton } from '../../../../redux/actions/filters';
import {fetchOwnerWarehouseGrafData} from '../../../../redux/actions/grafData';
import {setCalendDisplay} from '../../../../redux/actions/display';
import {setOwnerWarehouseGrafMonth, setOwnerWarehouseGrafYear} from '../../../../redux/actions/popupFilters';
import {fetchOwnerLeaderPurchaseBtn} from '../../../../redux/actions/ownerData';
import './Lekals-purchase-1.0.scss';
import GrafBar from '../../../Amchart/GrafBar' 


const LekalsPurchase = () =>{
    const dispatch = useDispatch();

    const {buttonActive, range, month, year, grafLoading, grafData, buttons} = useSelector(({filters, popupFilters, grafData, ownerData})=> {
        return {
            buttonActive: filters.ownerWarehouseButton,
            range: popupFilters.ownerWarehouseGrafRange,
            month: popupFilters.ownerWarehouseGrafMonth,
            year: popupFilters.ownerWarehouseGrafYear,
            grafLoading: grafData.ownerWarehouseLoading,
            grafData: grafData.ownerWarehouseData,
            buttons: ownerData.ownerLeadersPurchaseBtn
        }
    })
    
    let dataMonth = [
        {
            id: 0,
            value: "Январь"
        },
        {
            id: 1,
            value: "Февраль"
        },
        {
            id: 2,
            value: "Март"
        },
        {
            id: 3,
            value: "Апрель"
        },
        {
            id: 4,
            value: "Май"
        },
        {
            id: 5,
            value: "Июнь"
        },
        {
            id: 6,
            value: "Июль"
        },
        {
            id: 7,
            value: "Август"
        },
        {
            id: 8,
            value: "Сентябрь"
        },
        {
            id: 9,
            value: "Октябрь"
        },
        {
            id: 10,
            value: "Ноябрь"
        },
        {
            id: 11,
            value: "Декабрь"
        }
    ];
    let defaultYear = new Date().getFullYear()
    let dataYear = [
        {
            id: 0,
            value: defaultYear - 5
        },
        {
            id: 1,
            value: defaultYear - 4
        },
        {
            id: 2,
            value: defaultYear - 3
        },
        {
            id: 3,
            value: defaultYear - 2
        },
        {
            id: 4,
            value: defaultYear - 1
        },
        {
            id: 5,
            value: defaultYear
        },
        {
            id: 6,
            value: defaultYear + 1
        },
        {
            id: 7,
            value: defaultYear + 2
        },
        {
            id: 8,
            value: defaultYear + 3
        },
        {
            id: 9,
            value: defaultYear + 4
        },
        {
            id: 10,
            value: defaultYear + 5
        }
    ]


    const [monthActive, setMonthActive]  = useState(false);
    const [yearActive, setYearActive]  = useState(false);

    useEffect(()=>{
        dispatch(fetchOwnerWarehouseGrafData(buttonActive, range, month, year))
        dispatch(fetchOwnerLeaderPurchaseBtn(month, year, range))
    }, [])

    useEffect(()=>{
        dispatch(fetchOwnerWarehouseGrafData(buttonActive, range, month, year))
    }, [buttonActive, range, month, year])

    useEffect(()=>{
        dispatch(fetchOwnerLeaderPurchaseBtn(month, year, range))
    }, [range, month, year])

    const monthActivate = () =>{

            setMonthActive(!monthActive)
            setYearActive(false)
        
    }
    const yearActivate = () =>{

            setYearActive(!yearActive)
            setMonthActive(false)
    }
    const monthChange = (id) =>{
        dispatch(setOwnerWarehouseGrafMonth(dataMonth[id].value))
        setMonthActive(false)
    }
    const yearChange = (id) =>{
        dispatch(setOwnerWarehouseGrafYear(dataYear[id].value))
        setYearActive(false)
    }

    const addMonth = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(month === value)? "active": ""}`} key={key} onClick={() => {monthChange(id)}}>{value}</div>
        )
    }
    const addYear = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(year === value)? "active": ""}`} key={key} onClick={() => {yearChange(id)}}>{value}</div>
        )
    }

    const addValPercent = (type, value, color) => {
        if(color === "green"){
            return(
                <div className={`owner__lekals-purchase-buttons-val-percent d-flex align-center green ${type}`}>
                    <img src="/img/arrow-up-green.svg" alt="" className="owner__lekals-purchase-buttons-val-percent-icon"/> 
                    <div>{value}%</div>
                </div> 
            )
        }
        else if(color === "red"){
            return(
                <div className={`owner__lekals-purchase-buttons-val-percent d-flex align-center red ${type}`}>
                    <img src="/img/arrow-down-red.svg" alt="" className="owner__lekals-purchase-buttons-val-percent-icon"/> 
                    <div>{value}%</div>
                </div>
            )
        }
    }

	return (
		<div className="owner__lekals-purchase">
            <div className="owner__lekals-purchase-top d-flex align-center justify-between">
                <div className="owner__lekals-purchase-title">Закупка и продажи пленок</div>
                <div className='owner__sales-filters__block d-flex align-center'>
                    <div className="owner__sales-filters__block-item sales year">
                        <div className={`owner__sales-filters__block-item-title d-flex align-center justify-center year ${(year === "Год")? "": "active"} ${(yearActive)? "open": ""}`} onClick={yearActivate}>{year}<div className="owner__sales-filters__block-item-title-icon"/></div>                        
                        <div className={`owner__sales-filters__block-item-list ${(yearActive)? "open": ""}`}>
                            <div className='owner__sales-filters__block-item-list-scroll'>
                                {
                                    dataYear.map((item, key) => addYear(item, key))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="owner__sales-filters__block-item sales months">
                        <div className={`owner__sales-filters__block-item-title d-flex align-center align-center justify-center months ${(month === "Месяц")? "": "active"} ${(monthActive)? "open": ""}`} onClick={monthActivate}>{month}<div className="owner__sales-filters__block-item-title-icon"/></div>                        
                        <div className={`owner__sales-filters__block-item-list ${(monthActive)? "open": ""}`}>
                            <div className='owner__sales-filters__block-item-list-scroll'>
                                {
                                    dataMonth.map((item, key) => addMonth(item, key))
                                }                               
                            </div>
                        </div>
                    </div>
                    <div className="owner__sales-filters d-flex justify-between">
                        <div className="owner__sales-filters months" onClick={() => {
                            dispatch(setCalendDisplay(""));
                            setTimeout(()=>{
                                dispatch(setCalendDisplay("open", "ownerWarehouseGraf"))
                            }, 0)
                        }}>
                            <div className="owner__sales-filters-title d-flex align-center justify-center months">Фильтры<img src="/img/arrow-down.svg" alt="" className="owner__sales-filters-title-icon"/></div>                        
                        </div>
                    </div>
                </div>
            </div>
            <div className="owner__lekals-purchase-buttons d-flex justify-between">
                <div className={`owner__lekals-purchase-buttons-purchase d-flex align-center justify-center ${(buttonActive === "purchase")? "active": ""}`} onClick={()=>{
                    dispatch(setOwnerWarehouseButton("purchase"))
                }}>
                    <div className="owner__lekals-purchase-buttons-icon purchase align-center justify-center"><img src="/img/shopping-bag.svg" alt="" /></div>
                    <div>
                        <div className="owner__lekals-purchase-buttons-title purchase">Закуп</div>
                        <div className="owner__lekals-purchase-buttons-val purchase">{buttons[0].value}</div>
                        {
                            addValPercent("purchase", buttons[0].percent, buttons[0].class)
                        }
                    </div>
                    
                </div>
                <div className={`owner__lekals-purchase-buttons-income-summ d-flex align-center justify-center ${(buttonActive === "income-summ")? "active": ""}`} onClick={()=>{
                    dispatch(setOwnerWarehouseButton("income-summ"))
                }}>
                    <div className="owner__lekals-purchase-buttons-icon income-summ align-center justify-center"><img src="/img/dollar-circle.svg" alt="" /></div>
                    <div>
                        <div className="owner__lekals-purchase-buttons-title income-summ">Сумма продаж</div>
                        <div className="owner__lekals-purchase-buttons-val income-summ">{buttons[1].value}</div>
                        {
                            addValPercent("income-summ", buttons[1].percent, buttons[1].class)
                        }
                    </div>
                    
                </div>
            </div>
            <div className="owner__lekals-purchase-graphik">
                <GrafBar data = {grafData} selRange={!(range.length === 0 && month === "Месяц")} addClass={"lekals-purchase__graf"} buttonActive={(buttonActive === "purchase")? "Закуп": "Сумма продаж"}/>
                <div className={`graf-loading justify-center align-center ${(grafLoading)? "active": ''}`}>
                    <div className='graf-loading__icon'></div>
                </div>
            </div>
        </div>
	)
}

export default LekalsPurchase;