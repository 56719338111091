const initialState = {
    openBlock: "",
    popupBack: "",
    openBlockEvent: '',
    warehousePopupMenuPage: 'main',
    franchiserWarehousePopupMenuPage: 'main'
  };
  
  const popupBlocks = (state = initialState, action) => {
    if (action.type === 'POPUP_OPEN_BLOCK') {
      return {
        ...state,
        openBlock: action.payload,
      };
    }
    else if (action.type === 'POPUP_BACKGROUND') {
      return {
        ...state,
        popupBack: action.payload,
      };
    }
    else if (action.type === 'POPUP_OPEN_BLOCK_EVENT') {
      return {
        ...state,
        openBlockEvent: action.payload,
      };
    }
    else if (action.type === 'POPUP_WAREHOUSE_POPUP_MENU_PAGE') {
      return {
        ...state,
        warehousePopupMenuPage: action.payload,
      };
    }
    else if (action.type === 'POPUP_FRANCHISER_WAREHOUSE_POPUP_MENU_PAGE') {
      return {
        ...state,
        franchiserWarehousePopupMenuPage: action.payload,
      };
    }
    return state;
  };
  
  export default popupBlocks;