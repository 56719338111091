import React, { useEffect} from 'react';
import {useDispatch} from 'react-redux';
import LekalsPurchase from './Lekals-purchase/Lekals-purchase';
import PurchaseTable from './Purchase-table/Purchase-table';
import LekalsTop from './Lekals-top/Lekals-top';
import { setOwnerNav } from '../../../redux/actions/navigation';

const Warehouse = () => {
	const dispatch = useDispatch()
	useEffect(()=>{
        dispatch(setOwnerNav('Warehouse'))
    }, [])
	return (
		<React.Fragment>
			<div className="owner__title">Склады</div>
			<div className="owner__content justify-between">
				<div className="owner__content-left">
					<LekalsPurchase/>
                    <PurchaseTable/>
				</div>
				<LekalsTop/>
			</div>
		</React.Fragment>
	)
}

export default Warehouse;