import React, {useEffect} from 'react';
import Search from '../Search/Search';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {setPopupBackgroundFunc, setPopupOpenBlock, setPopupOpenBlockEvent} from '../../../redux/actions/popupBlocks';
import {setModeratorNav, setNotificationActive} from '../../../redux/actions/navigation';
import { fetchModeratorComplaints, fetchModeratorHistoryChanges, setModeratorComplaintsAdd } from '../../../redux/actions/moderatorPopupData';


import './Header.scss';

import HistoryChangesIcon from '../../../img/moderator-history-changes.svg';
import HistoryChangesIconActive from '../../../img/moderator-history-changes-active.svg';


import NotificationsIcon from '../../../img/notification.svg';
import NotificationsIconActive from '../../../img/notification-active.svg';

import LekaloIcon from '../../../img/lekalo-icon.svg';
import { setSiginalConnectReset } from '../../../redux/actions/siginalConnect';
import { setModeratorComplaintsCheck } from '../../../redux/actions/moderatorCheckData';



const Header = () => {
    const { openBlock } = useSelector(({ popupBlocks }) => popupBlocks);
    const dispatch = useDispatch();

    const {moderatorPopupHistoryChanges, moderatorPopupNotifications, session, siginalConnect, userData, notificationActive} = useSelector(({moderatorPopupData, navigation, siginalConnect})=> {
        return {
            moderatorPopupHistoryChanges: moderatorPopupData.moderatorPopupHistoryChanges,
            moderatorPopupNotifications: moderatorPopupData.moderatorPopupNotifications,
            session: navigation.session,
            siginalConnect: siginalConnect.connect,
            userData: navigation.user,
            notificationActive: navigation.notificationActive
        }
    })

    useEffect(()=>{
        dispatch(fetchModeratorHistoryChanges())
        dispatch(fetchModeratorComplaints())
    }, [])

    useEffect(()=>{
        if(siginalConnect.type === 'new-moderator-notification'){
            // console.log(siginalConnect)
            dispatch(setModeratorComplaintsAdd(siginalConnect.obj.elem))
            if (session === 1) {
                let audio = new Audio('/img/notification-audio.mp3');
                audio.play();
            }
            dispatch(setSiginalConnectReset())
        }
    }, [siginalConnect])

    const cutName = (name) =>{
        if(name !== null && name !== ''){
            let cut = name.split(' ')
            if(cut.length === 3){
                return cut[0] + ' ' + cut[1][0] + '.' + ' ' + cut[2][0] + '.'
            }
            else if(cut.length === 2){
                return cut[0] + ' ' + cut[1][0] + '.'
            }
            else return name
        }
        else return name
    }
        

    const AddHistoryChangesItem = ({id, lekalo, statusColor, path, parrentId, date}, key) =>{
        return(
            <div className="moderator__buttons-history-changes-list-item d-flex justify-between" key={key}>
                <div className="moderator__buttons-history-changes-list-item-icon d-flex justify-center align-center">
                    <img src={LekaloIcon} alt=""/>
                </div>
                <div className='moderator__buttons-history-changes-list-item-container'>
                    <div className="moderator__buttons-history-changes-list-item-name d-flex justify-between">
                        <div className="moderator__buttons-history-changes-list-item-name-title">
                            {lekalo}
                        </div>
                        <div className="moderator__buttons-history-changes-list-item-status d-flex justify-between">
                            <div className="moderator__buttons-history-changes-list-item-status-time">{date}</div>
                            <div className={`moderator__buttons-history-changes-list-item-status-icon ${statusColor}`}></div>
                        </div>
                    </div>
                    <div className="moderator__buttons-history-changes-list-item-name-path">
                        {path}
                    </div>
                </div>
            </div>
        )
    }

    const AddNotificationsItem = ({id, point, address, lekalo, statusColor, date, complaints}, key) =>{
        return(
            <div className="moderator__buttons-notifications-list-item popup-open-button d-flex justify-between" key={key} onClick={()=>{
                dispatch(setModeratorComplaintsCheck({...moderatorPopupNotifications[key], key: key}))
                dispatch(setPopupBackgroundFunc(true))
                dispatch(setPopupOpenBlock('ComplaintsDetail'))
            }}>
                <div className="moderator__buttons-notifications-list-item-icon d-flex justify-center align-center">
                    <img src={LekaloIcon} alt=""/>
                </div>
                <div className='moderator__buttons-notifications-list-item-container'>
                    <div className="moderator__buttons-notifications-list-item-name d-flex justify-between">
                        <div className={`moderator__buttons-notifications-list-item-name-title ${(lekalo === null)? 'decrease': ''}`}>
                            {(lekalo === null)? complaints: lekalo}
                        </div>
                        <div className="moderator__buttons-notifications-list-item-status d-flex justify-between">
                            <div className="moderator__buttons-notifications-list-item-status-time">{date}</div>
                            <div className={`moderator__buttons-notifications-list-item-status-icon ${statusColor}`}></div>
                        </div>
                    </div>
                    <div className="moderator__buttons-notifications-list-item-name-path" title={`${point}(${address})`}>
                        {point}({address})
                    </div>
                </div>
            </div>
        )
    }

 	return (
		<header className="moderator__header d-flex align-center justify-between">
            <Search/>
            <div className="moderator__buttons d-flex align-center justify-end">
                <div className="moderator__buttons-item">
                    <div className={`moderator__buttons-history-changes popup-open-button d-flex justify-center align-center ${(openBlock === "moderatorButtonsHistoryChangesList")? 'active': ''}`} onClick={(event) =>{
                        let bool = (openBlock === "moderatorButtonsHistoryChangesList")? "": "moderatorButtonsHistoryChangesList";
                        dispatch(setPopupOpenBlock(bool))
                        dispatch(setPopupOpenBlockEvent(event.target))
                    }}>
                        <img src={(openBlock === "moderatorButtonsHistoryChangesList")? HistoryChangesIconActive: HistoryChangesIcon} alt="" className="moderator__buttons-history-changes-icon popup-open-button"/>
                    </div>
                    <div className={`moderator__buttons-popup moderatorButtonsHistoryChangesList ${(openBlock === "moderatorButtonsHistoryChangesList")? 'active': ''}`} >
                        <div className="moderator__buttons-popup-title" >История изменений</div>
                        <div className="moderator__buttons-history-changes-list">
                            {
                                moderatorPopupHistoryChanges.map((item, key) => AddHistoryChangesItem(item, key))
                            }
                        </div>
                        
                        <Link to='/history-changes' onClick={()=>{
                            dispatch(setModeratorNav('HistoryChanges'))
                            dispatch(setPopupOpenBlock(""))
                        }}>
                            <div className="moderator__buttons-popup-btn">
                                Посмотреть всю историю
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="moderator__buttons-item">
                    <div className={`moderator__buttons-notifications popup-open-button d-flex justify-center align-center ${(openBlock === "moderatorButtonsNotification")? 'active': ''} notification ${(!notificationActive)? "notific-none": ""}` } onClick={(event) =>{
                        let bool = (openBlock === "moderatorButtonsNotification")? "": "moderatorButtonsNotification";
                        dispatch(setPopupOpenBlock(bool))
                        dispatch(setPopupOpenBlockEvent(event.target))
                        dispatch(setNotificationActive(false))
                    }}>
                        <img src={(openBlock === "moderatorButtonsNotification")? NotificationsIconActive: NotificationsIcon} alt="" className="moderator__buttons-notifications-icon popup-open-button"/>
                    </div>
                    <div className={`moderator__buttons-popup moderatorButtonsNotification ${(openBlock === "moderatorButtonsNotification")? 'active': ''}`} >
                        <div className="moderator__buttons-popup-title">Жалобы</div>
                        <div className="moderator__buttons-notifications-list">
                            {
                                moderatorPopupNotifications.map((item, key) => AddNotificationsItem(item, key))
                            }

                            {
                                (moderatorPopupNotifications.length === 0 || moderatorPopupNotifications === 0)? <div className='work-zone__buttons-popup-notification-null'>У вас нет уведомлений</div>: null
                            }
                        </div>
                        
                        <Link to='/complaints' onClick={()=>{
                            dispatch(setModeratorNav('Complaints'))
                            dispatch(setPopupOpenBlock(""))
                        }}>
                            <div className="moderator__buttons-popup-btn">
                                Посмотреть все жалобы
                            </div>
                        </Link>
                    </div>
                </div>
                
                <div className="moderator__buttons-user d-flex align-center">
                    <div className="moderator__buttons-user-photo-background d-flex justify-center align-center">
                        <img src={userData.src} alt="" className="moderator__buttons-user-photo"/>
                    </div>
                    <div>
                        <div className="moderator__buttons-user-name d-flex align-center">
                            {cutName(userData.name)}
                            {/* <img src="/img/arrow-down.svg" alt="" className="moderator__buttons-user-button"/> */}
                        </div>
                        {/* <div className="moderator__buttons-user-name-verify d-flex align-center">
                            <img src="/img/verify.svg" alt="" className="moderator__buttons-user-name-verify-icon"/>
                            Модератор
                        </div> */}
                    </div>
                </div>
            </div>             
        </header>
	)
}
export default Header;