const initialState = {
    connect: {
      type: '',
      obj: {}
    },
  };
  
  const siginalConnect = (state = initialState, action) => {
    if (action.type === 'SET_CONNECTION') {
      return {
        ...state,
        connect: action.payload
      };
    }
    else if (action.type === 'SET_CONNECTION_RESET') {
      return {
        ...state,
        connect: { type: '', obj: {}},
      };
    }
    
    return state;
  };
  
  export default siginalConnect;