import React, {useState } from 'react';
import { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setModeratorPointEditCheck } from '../../../../redux/actions/moderatorCheckData';
import { setModeratorLeadersPoints } from '../../../../redux/actions/moderatorData';
import { setPopupBackgroundFunc, setPopupOpenBlock} from '../../../../redux/actions/popupBlocks';

import './index.scss'


const AddPoints = () => {
    const dispatch = useDispatch()

    const {Leader, moderatorLeadersPoints, moderatorPointEditCheck, moderatorLeaders} = useSelector(({moderatorData, moderatorCheckData})=> {
        return {
            Leader: moderatorCheckData.moderatorLeaderCheck,
            moderatorLeadersPoints: moderatorData.moderatorLeadersPoints,
            moderatorPointEditCheck: moderatorCheckData.moderatorPointEditCheck,
            moderatorLeaders: moderatorData.moderatorLeaders
        }
    })
    const [error, setError] = useState('') 

    const [inform, setInform] = useState((moderatorPointEditCheck.id !== '')? moderatorPointEditCheck: {
        name: '',
        address: '',
        login: '',
        password: ''
    })

    const [editInform, setEditInform] = useState({
        name: false,
        address: false,
        login: false,
        password: false
    })

    const [clickAddBtn, setClickAddBtn] = useState(false)

    const [licensedKey, setLicensedKey] = useState((moderatorPointEditCheck.id !== '')? moderatorPointEditCheck.licensedKey: '')
    
    const getKey = async() => {
        let response = await fetch(`/Moderator/CreateKey`, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
        })
        if (response.ok === true) {
            const data = await response.json();
            setLicensedKey(data.value)
        }
    }
    useEffect(()=>{
        if(moderatorPointEditCheck.id === '')  getKey()
    }, [])

    const fetchCheckLogin = async(url, query='')=>{
        const response =  await fetch(url + query, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            }
        })
        if (response.ok === true) {
            const dat = await response.json();
            // console.log(dat)
            return dat.value.resultObject
        }
    }

    const postData = (event) =>{
        event.preventDefault();
        if(moderatorPointEditCheck.id !== ''){
            editPoint(event)
        }
        else{
            addPoint(event)
        }
        
    }

    const editPoint = async() =>{
        if(editInform.name || editInform.address || editInform.login || editInform.password){
            let data = {};
            data = (editInform.name)? {...data, Name: inform.name}: data
            data = (editInform.address)? {...data, Address: inform.address}: data
            data = (editInform.login)? {...data, Login: inform.login}: data
            data = (editInform.password)? {...data, Password: inform.password}: data
            data = {...data, Id: moderatorPointEditCheck.id} 
            
            if(!clickAddBtn && inform.name !== '' && inform.address !== '' && inform.login !== '' && inform.password !== ''){
                setClickAddBtn(true)
                fetchCheckLogin('/Login/CheckHaveLogin?login=', inform.login).then(async(availability)=>{
                    if(!availability  || !editInform.login){                        
                        setError("")
                        const response =  await fetch("/Moderator/EditPlaces", {
                            method: 'POST', 
                            headers: {
                                "Accept": "application/json",
                                'Content-Type': 'application/json',
                                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                            },
                            body: JSON.stringify(data)
                        })
                        if (response.ok === true) {
                            const dat = await response.json();
                            // console.log(dat)                
                            let point = {
                                id: moderatorPointEditCheck.id,
                                name: inform.name, 
                                address: inform.address, 
                                login: inform.login, 
                                password: inform.password,
                                licensedKey: licensedKey
                            }
                            let points = moderatorLeadersPoints;
                            points[moderatorPointEditCheck.key] = point; 
                            dispatch(setModeratorLeadersPoints(points))
                            setError("")
                            dispatch(setPopupBackgroundFunc(false))
                            dispatch(setPopupOpenBlock(''))
                            setClickAddBtn(false)
                        }
                        else{
                            setClickAddBtn(false)
                        }
                    }
                    else {
                        setClickAddBtn(false)
                        setError("Такой логин уже существует*") 
                    }
                })
            }
            else{
                setError("Все поля должны быть заполнены*")
            }
        }
        else if(!clickAddBtn){
            setError("Изменений не найдено*")
        }
    }

    const addPoint = async() =>{
        const data = {
            Name: inform.name,
            Address: inform.address,
            Login: inform.login,
            Password: inform.password,
            LicensionKey: licensedKey,
            DirectorId: Leader.id
        }

        if(!clickAddBtn && data.Name !== '' && data.Address !== '' && data.Login !== '' && data.Password !== ''){
            setClickAddBtn(true)
            fetchCheckLogin('/Login/CheckHaveLogin?login=', inform.login).then(async(availability)=>{
                if(!availability){                    
                    setError("")
                    const response =  await fetch('/Moderator/PointsSet', {
                        method: 'POST', 
                        headers: {
                            "Accept": "application/json",
                            'Content-Type': 'application/json',
                            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                        },
                        body: JSON.stringify(data)
                    })
                    if (response.ok === true) {
                        const dat = await response.json();              
                        let point = {
                            id: dat.value.resultObject, 
                            name: inform.name, 
                            address: inform.address, 
                            login: inform.login, 
                            password: inform.password,
                            licensedKey: licensedKey
                        }
                        let points = moderatorLeadersPoints.slice()
                        points.push(point);
                        dispatch(setModeratorLeadersPoints(points))
                        setError("")
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock('')) 
                        setClickAddBtn(false)
                    }
                    else{
                        setClickAddBtn(false)
                    }
                }
                else {
                    setClickAddBtn(false)
                    setError("Такой логин уже существует*") 
                }
            }) 
            
        }
        else{
            setError("Необходимо заполнить все поля*")
        }
    }
    
	return (
            <form onSubmit={(event) => {
                postData(event)
            }} className="moderator__popup-container">
                <div className="moderator__popup-top d-flex justify-between align-center add-leaders">
                    <div className="popup__title">{(moderatorPointEditCheck.id !== '')? 'Редактировать точку': 'Добавить точку'}</div>
                    <div className="popup__close-btn" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                        dispatch(setModeratorPointEditCheck({id: '',  name: '', login: "",  password: "",  address: '', licensedKey: ''}))
                    }}></div>
                </div>
                <label htmlFor="" className='moderator__popup-label'>Наименование</label>
                <input type="text" defaultValue={(moderatorPointEditCheck.id !== '')? moderatorPointEditCheck.name: ''} placeholder='Введите наименование точки' className="moderator__popup-input" onInput={(event)=>{
                    setInform({...inform, name: event.target.value})
                    setEditInform({...editInform, name: true})
                }}/>
                <label htmlFor="" className='moderator__popup-label'>Адрес</label>
                <input type="text" defaultValue={(moderatorPointEditCheck.id !== '')? moderatorPointEditCheck.address: ''} placeholder='Введите адрес точки' className="moderator__popup-input" onInput={(event)=>{
                    setInform({...inform, address: event.target.value})
                    setEditInform({...editInform, address: true})
                }}/>
                <div className="moderator__popup-input-container d-flex justify-between">
                    <div className="moderator__popup-input-fifty">
                        <label htmlFor="" className='moderator__popup-label'>Логин</label>
                        <input type="text" defaultValue={(moderatorPointEditCheck.id !== '')? moderatorPointEditCheck.login: ''} placeholder='Введите логин из 8 символов' className="moderator__popup-input" onInput={(event)=>{
                            setInform({...inform, login: event.target.value})
                            setEditInform({...editInform, login: true})
                        }}/>
                    </div> 

                    <div className="moderator__popup-input-fifty">
                        <label htmlFor="" className='moderator__popup-label'>Пароль</label>
                        <input type="text" defaultValue={(moderatorPointEditCheck.id !== '')? moderatorPointEditCheck.password: ''} placeholder='Введите пароль из 8 символов' className="moderator__popup-input" onInput={(event)=>{
                            setInform({...inform, password: event.target.value})
                            setEditInform({...editInform, password: true})
                        }}/>
                    </div>   
                </div>
                <label htmlFor="" className='moderator__popup-label'>Лецинзионный ключ</label>
                <div className="moderator__popup-input d-flex align-center justufy-between">
                    <div className="moderator__popup-key">{licensedKey}</div>
                    <div className="moderator__popup-key-copy-button" onClick={()=>{
                        navigator.clipboard.writeText(licensedKey)
                    } }></div>
                </div>
                <div className='popup__error'>{error}</div>             
                <button type='submit' className={`moderator__popup-save ${(clickAddBtn)? 'deactive': ''}`}>{(moderatorPointEditCheck.id !== '')? 'Сохранить': 'Добавить'}</button>                    
            </form>
	)
}
export default AddPoints;