import React, {useEffect, useRef} from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

function GrafLine({data, addClass, buttonActive, selRange}) {
    let chartGrafTwo = useRef(null);
    let monthNum = {
        "1": "Янв",
        "2":"Фев",
        "3":"Мар",
        "4": "Апр",
        "5": "Май",
        "6": "Июн",
        "7": "Июл",
        "8": "Авг",
        "9": "Сен",
        "10": "Окт",
        "11": "Ноя",
        "12": "Дек"
    }
    const Data = () =>{
      var chartData = [];
      for(let item of data) {
        chartData.push({
          month: (selRange)? item.name: monthNum[item.name],
          value: item.value.toFixed(0)
        });
      }
      return chartData;
    }
    useEffect(() => {    
    chartGrafTwo.current = am4core.create(addClass, am4charts.XYChart);
    chartGrafTwo.current.data = Data();
    chartGrafTwo.current.fontFamily = 'Montserrat';
    chartGrafTwo.current.fontSize = '80%';

    var categoryAxis = chartGrafTwo.current.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.ticks.template.disabled = true;
    categoryAxis.renderer.line.opacity = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.dataFields.category = "month";
    categoryAxis.startLocation = 0;
    categoryAxis.endLocation = 1;
    categoryAxis.renderer.grid.template.disabled = false;

    var valueAxis = chartGrafTwo.current.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.line.opacity = 0;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.min = 0;

    var lineSeries = chartGrafTwo.current.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.categoryX = "month";
    lineSeries.dataFields.valueY = "value";
    lineSeries.tooltipText = `${buttonActive}: {valueY.value}`;
    lineSeries.fill = am4core.color("#CABDFF");
    lineSeries.tensionX = 0.80;
    lineSeries.segments.template.strokeWidth = 3.3;
    lineSeries.stroke = am4core.color("#CABDFF");
    lineSeries.segments.template.strokeWidth = 3.5;

    var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    bullet.circle.radius = 4;
    bullet.circle.fill = am4core.color("#CABDFF");
    
    chartGrafTwo.current.cursor = new am4charts.XYCursor();

    chartGrafTwo.current.scrollbarX = new am4core.Scrollbar();
    chartGrafTwo.current.scrollbarX.parent = chartGrafTwo.current.bottomAxesContainer;

    });

    useEffect(() => {
        chartGrafTwo.current.data = Data();
    });



    return(
        <div className={addClass} ></div>
    )
}

GrafLine.defaultProps = {
  data: [
    {
        name: ".1.",
        value: 0
    },
    {
        name: ".2.",
        value: 0
    },
    {
        name: ".3.",
        value: 0
    },
    {
        name: ".4.",
        value: 0
    },
    {
        name: ".5.",
        value: 0
    },
    {
        name: ".6.",
        value: 0
    },
    {
        name: ".7.",
        value: 0
    },
    {
        name: ".8.",
        value: 0
    },
    {
        name: ".9.",
        value: 0
    },
    {
        name: ".10.",
        value: 0
    },
    {
        name: ".11.",
        value: 0
    },
    {
        name: ".12.",
        value: 0
    },
  ], 
  addClass: "sales-income__graf", 
  buttonActive: "Продажи"
}

export default GrafLine