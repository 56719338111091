import { slice } from '@amcharts/amcharts4/.internal/core/utils/Array';
import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { setPopupBackgroundFunc, setPopupOpenBlock, setPopupOpenBlockEvent, setWarehousePopupMenuPage } from '../../../redux/actions/popupBlocks';
import { setWarehouseEditEnvelopeCheck, setWarehouseHomeTransferEnvelopeCheckData } from '../../../redux/actions/warehouseCheckData';
import { fetchWarehouseHomeData, setWarehouseHomeDataDelete, setWarehouseHomeDataEdit } from '../../../redux/actions/warehouseData';
import './index-1.0.scss';
import { setWarehouseHomeData } from './../../../redux/actions/warehouseData';
import { setWarehouseEnvelopeSort } from './../../../redux/actions/warehouseCheckData';
import { setWarehouseNav } from './../../../redux/actions/navigation';

const Home = () => {

    const dispatch = useDispatch();
    const {warehouseHomeData, openBlock, warehousePopupMenuPage, warehouseEnvelopeSort} = useSelector(({warehouseData, popupBlocks, warehouseCheckData})=> {
        return {
            warehouseHomeData: warehouseData.warehouseHomeData,
            openBlock: popupBlocks.openBlock,
            warehousePopupMenuPage: popupBlocks.warehousePopupMenuPage,
            warehouseEnvelopeSort: warehouseCheckData.warehouseEnvelopeSort
        }
    })
    
    useEffect(()=>{
        dispatch(fetchWarehouseHomeData())
        dispatch(setWarehouseEnvelopeSort({type: '', name: ''}))
        dispatch(setWarehouseNav('Home'))
        sessionStorage.setItem('warehousePage', 'Home')
    }, [])

    const [transferEnvelopeCheck, setTransferEnvelopeCheck] = useState([])
    const [transferEnvelopeCheckNull, setTransferEnvelopeCheckNull] = useState([])
    const [transferActive, setTransferActive] = useState(false)
    const [envelopeWriteOffCount, setEnvelopeWriteOffCount] = useState('')
    const [error, setError] = useState('')

    const fetchGet = async(url, query='')=>{
        const response =  await fetch(url + query, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            }
        })
        if (response.ok === true) {
            const dat = await response.json();
            return dat.value
        }
    }

    const onInputEnvelopeCount = (event, elem, id) =>{
        document.querySelector(elem).innerText = +event.target.value;
        event.target.value = +event.target.value;
        event.target.style.width = document.querySelector(elem).offsetWidth + 'px';
        let index = transferEnvelopeCheck.findIndex(x => x.elemId === id)
        let data = transferEnvelopeCheck.slice();
        data[index] = {...data[index], count: +event.target.value}
        setTransferEnvelopeCheck(data)
    }

    const CheckTransferElem = (id, filmId) =>{
        if(!transferActive) setTransferActive(true)
        let index = transferEnvelopeCheck.findIndex(x => x.elemId === id)
        if(index !== -1){
            let data = transferEnvelopeCheck.slice();        
            data.splice(index, 1)
            setTransferEnvelopeCheck(data)

        }
        else{
            setTransferEnvelopeCheck([...transferEnvelopeCheck, {elemId: id, id: filmId, count: 0}])
        }
    }

    const CheckTransferElemFull = () =>{
        if(!transferActive) setTransferActive(true)
        if(warehouseHomeData.length !== transferEnvelopeCheck.length){
            let data = transferEnvelopeCheck.slice();
            warehouseHomeData.forEach(({id, filmId}) =>{
                if(transferEnvelopeCheck.findIndex(item => item.elemId === id) === -1){
                    data.push({elemId: id, id: filmId, count: 0})
                }
                    
            })
            setTransferEnvelopeCheck(data)
        }
        else{
            setTransferEnvelopeCheck([])
        }
    }

    const EnvelopeWriteOffFunc = (id, count) =>{
        if(envelopeWriteOffCount > 0 && envelopeWriteOffCount !== ''){
            if(count >= envelopeWriteOffCount){
                fetchGet(`/stock/minusFilm?id=`, `${id}&count=${envelopeWriteOffCount}`).then((dat)=>{
                    // console.log(dat.resultObject)
                    setEnvelopeWriteOffCount('')
                    dispatch(setWarehouseHomeDataEdit(dat.resultObject))
                    dispatch(setPopupOpenBlock(''))
                    dispatch(setPopupOpenBlockEvent(''))
                    dispatch(setWarehousePopupMenuPage('main'))
                    setError('')
                })
            }
            else setError('Недостаточно пленок для списания*')
        }
    }

    const EnvelopeDeleteFunc = (id) =>{
        fetchGet(`/stock/removeFilm?id=`, `${id}`).then((dat)=>{
            // console.log(dat)
            dispatch(setWarehouseHomeDataDelete(id))
            dispatch(setPopupOpenBlock(''))
            dispatch(setPopupOpenBlockEvent(''))
            dispatch(setWarehousePopupMenuPage('main'))
        })
    }

    function sortByMass(arr, name, type) {
        console.log(type)
        if(type === 'ascending'){
            return arr.sort((a, b) => +a[name] > +b[name] ? 1 : -1);
        }
        else{
            return arr.sort((a, b) => +a[name] < +b[name] ? 1 : -1);
        }        
    }

    let sortByFunc = (arr, name) =>{        
        if(warehouseEnvelopeSort.name === '' || warehouseEnvelopeSort.name !== name){
            dispatch(setWarehouseHomeData(sortByMass(arr, name, 'ascending')))
            dispatch(setWarehouseEnvelopeSort({type: 'ascending', name: name}))
        }
        else{            
            dispatch(setWarehouseHomeData(sortByMass(arr, name, (warehouseEnvelopeSort.type === 'ascending')? 'descending':'ascending')))
            dispatch(setWarehouseEnvelopeSort({type: (warehouseEnvelopeSort.type === 'ascending')? 'descending':'ascending', name: name}))
        }
        
    }

    const addTableItem = (elem, key) =>{
        let {id, article, name, count, filmId} = elem; 
        return(
            <div className={`warehouse__home-table-line d-flex align-center justify-between ${(transferEnvelopeCheckNull.findIndex(x => x.id === id) !== -1)? 'border-red': ''} warehouse__home-table-line-${id}`} key={key} onClick={()=>{
                let index = transferEnvelopeCheckNull.findIndex(x => x.id === id)
                if(index !== -1){
                    let data = transferEnvelopeCheckNull.slice()
                    data.splice(index, 1)
                    setTransferEnvelopeCheckNull(data)
                    if(data.length === 0) setError('')
                }
            }}>
                <div className={`warehouse__home-table-check ${(transferEnvelopeCheck.findIndex(x => x.elemId === id)!== -1)? 'active': ''}`} onClick={()=>CheckTransferElem(id, filmId)}></div>
                <div className="warehouse__home-table-articul">#{article}</div>
                <div className="warehouse__home-table-name">{name}</div>
                <div className="warehouse__home-table-count" onClick={(event) =>{if(transferActive) event.currentTarget.querySelector('input').focus()}}>
                    {
                        (transferActive)?(
                            <React.Fragment>
                                <span className={`warehouse__home-table-count-input-width envelope-count-width-${id}`}></span>
                                <input readOnly={(transferEnvelopeCheck.findIndex(x => x.elemId === id) !== -1)? false: true} className={`warehouse__home-table-count-input`} type="number" defaultValue={0} onInput={(event)=> onInputEnvelopeCount(event, `.envelope-count-width-${id}`, id)} /> шт
                                <span>({count} шт)</span>
                            </React.Fragment>
                        ): `${count} шт`
                    }
                </div>
                <div className="warehouse__home-table-menu-container d-flex align-center justify-center">
                    <div className='warehouse__home-table-menu-button popup-open-button' onClick={(event) => {
                        dispatch(setPopupOpenBlock((openBlock === `warehouseHomeTableElemMenu_${id}`)? "": `warehouseHomeTableElemMenu_${id}`))
                        dispatch(setPopupOpenBlockEvent(event.target))
                    }}></div>
                    <div className={`warehouse__home-table-menu-popup ${warehousePopupMenuPage} warehouseHomeTableElemMenu_${id} ${(openBlock === `warehouseHomeTableElemMenu_${id}`)? "active": ""}`}>
                        <div className={`warehouse__home-table-menu-popup-step main ${(warehousePopupMenuPage === 'main')? 'open': 'close'}`}>
                            <div className='warehouse__home-table-menu-popup-item d-flex align-center' onClick={() => {
                                dispatch(setWarehouseEditEnvelopeCheck(elem))
                                dispatch(setPopupOpenBlock((openBlock === "WarehouseAddEnvelope")? "": "WarehouseAddEnvelope"))
                                dispatch(setPopupBackgroundFunc(true))
                            }}>
                                <img className='warehouse__home-table-menu-popup-item-icon' src="/img/warehouse-edit-icon.svg" alt="" />
                                <div className='warehouse__home-table-menu-popup-item-title'>Редактировать</div>
                            </div>

                            <div className='warehouse__home-table-menu-popup-line'></div>

                            <div className='warehouse__home-table-menu-popup-item d-flex align-center' onClick={() => dispatch(setWarehousePopupMenuPage('writeoff'))}>
                                <img className='warehouse__home-table-menu-popup-item-icon' src="/img/warehouse-writeoff-envelope-icon.svg" alt="" />
                                <div className='warehouse__home-table-menu-popup-item-title'>Списать количество</div>
                            </div>

                            <div className='warehouse__home-table-menu-popup-line'></div>
                            
                            <div className='warehouse__home-table-menu-popup-item d-flex align-center' onClick={() => dispatch(setWarehousePopupMenuPage('delete'))}>
                                <img className='warehouse__home-table-menu-popup-item-icon' src="/img/delete-red-icon.svg" alt="" />
                                <div className='warehouse__home-table-menu-popup-item-title red'>Удалить</div>
                            </div>
                        </div>

                        <div className={`warehouse__home-table-menu-popup-step writeoff ${(warehousePopupMenuPage === 'writeoff')? 'open': 'close'}`}>
                            <div className='warehouse__home-table-menu-popup-item title d-flex align-center'>
                                <img className='warehouse__home-table-menu-popup-item-icon' src="/img/warehouse-envelope-popup-steps-title-icon.svg" alt="" />
                                <div className='warehouse__home-table-menu-popup-item-title'>Списать количество</div>
                            </div>
                            <div className='warehouse__home-table-menu-popup-item-input-container'>
                                <input className='warehouse__home-table-menu-popup-item-input' defaultValue={envelopeWriteOffCount} type="number" placeholder='Введите количество' onInput={(event)=>{
                                    setEnvelopeWriteOffCount(event.target.value)
                                }}/>
                            </div>                            
                            <div className='warehouse__home-table-menu-popup-button-container d-flex justify-between'>
                                <div className='warehouse__home-table-menu-popup-button red' onClick={()=>EnvelopeWriteOffFunc(id, count)}>Списать</div>
                                <div className='warehouse__home-table-menu-popup-button' onClick={() => dispatch(setWarehousePopupMenuPage('main'))}>Отмена</div>
                            </div>
                        </div>

                        <div className={`warehouse__home-table-menu-popup-step delete ${(warehousePopupMenuPage === 'delete')? 'open': 'close'}`}>
                            <div className='warehouse__home-table-menu-popup-item title d-flex align-center'>
                                <img className='warehouse__home-table-menu-popup-item-icon' src="/img/warehouse-envelope-popup-steps-title-icon.svg" alt="" />
                                <div className='warehouse__home-table-menu-popup-item-title'>Удалить артикул?</div>
                            </div>
                            <div className='warehouse__home-table-menu-popup-button-container d-flex justify-between'>
                                <div className='warehouse__home-table-menu-popup-button red' onClick={()=>EnvelopeDeleteFunc(id)}>Удалить</div>
                                <div className='warehouse__home-table-menu-popup-button' onClick={() => dispatch(setWarehousePopupMenuPage('main'))}>Отмена</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

	return (
        <React.Fragment>
            <div className="warehouse__home">
                <div className="warehouse__home-top d-flex justify-between align-center">
                    <div className="warehouse__home-title">Склад</div>
                    <div className='d-flex align-center'>
                        <div className='warehouse__home-top-error'>{error}</div>  
                        <div className={`warehouse__home-top-send-btn blue d-flex justify-between align-center popup-open-button ${(transferEnvelopeCheck.length === 0 && transferActive)? 'deactive': ''} ${(!transferActive)? 'none': ''}`} onClick={() => {
                            let data = transferEnvelopeCheck.filter((item)=> item.count > 0 && item.count !== '')
                            let fullData = warehouseHomeData.slice()
                            let countNullObj = [];
                            data.forEach((item)=>{
                                countNullObj = [...countNullObj, ...fullData.filter((elem)=> elem.id === item.elemId && elem.count < item.count)]
                            })
                            if(data.length > 0){
                                if(countNullObj.length === 0){
                                    setError('')
                                    dispatch(setWarehouseHomeTransferEnvelopeCheckData(data))
                                    dispatch(setPopupOpenBlock("warehouseTransferEnvelope"))
                                    dispatch(setPopupBackgroundFunc(true))
                                    setTransferActive(false)
                                    setTransferEnvelopeCheck([])
                                }
                                else{
                                    setTransferEnvelopeCheckNull(countNullObj)
                                    setError('Недостаточно пленок на складе*')
                                }
                                
                            }
                            else{
                                setError('Необходимо указать количество пленок*')
                            }
                            
                        }}>
                            <div className='popup-open-button'>Отправить</div>
                            <div className="warehouse__home-top-send-btn-icon popup-open-button"></div>
                        </div>
                        <div className={`warehouse__home-top-transfer-btn d-flex justify-between align-center ${(transferActive)? 'active': ''}`} onClick={()=>{
                            if(transferActive){
                                setTransferEnvelopeCheck([])
                                setTransferEnvelopeCheckNull([])
                            }
                            setTransferActive(!transferActive)
                        }}>
                            <div>Перенос</div>
                            <div className="warehouse__home-top-transfer-btn-icon"></div>
                        </div>

                        <div className={`warehouse__home-top-add-btn blue d-flex justify-between align-center popup-open-button ${(transferActive)? 'opacity': ''} ${(openBlock === "WarehouseAddEnvelope")? 'active': ''}`} onClick={(event) => {
                            dispatch(setPopupOpenBlock((openBlock === "WarehouseAddEnvelope")? "": "WarehouseAddEnvelope"))
                            dispatch(setPopupBackgroundFunc(true))
                        }}>
                            <div className='popup-open-button'>Добавить </div>
                            <div className="warehouse__home-top-add-btn-icon popup-open-button"></div>
                        </div>
                    </div>
                </div>
                <div className='warehouse__home-table-scroll'>
                    <div className="warehouse__home-table-line title d-flex align-center justify-between">
                        <div className={`warehouse__home-table-check ${(warehouseHomeData.length !== 0 && warehouseHomeData.length === transferEnvelopeCheck.length)? 'active': ''}`} onClick={() => CheckTransferElemFull()}></div>
                        <div className="warehouse__home-table-articul d-flex align-center cursor" onClick={() => sortByFunc(warehouseHomeData.slice(), 'article')}>Артикул <img className={`warehouse__home-table-sort-icon ${(warehouseEnvelopeSort.name ===  'article')? warehouseEnvelopeSort.type: ''}`} src="./img/arrow-up-grey.svg" alt="" /></div>
                        <div className="warehouse__home-table-name">Название</div>
                        <div className="warehouse__home-table-count d-flex align-center cursor" onClick={() => sortByFunc(warehouseHomeData.slice(), 'count')}>Количество <img className={`warehouse__home-table-sort-icon ${(warehouseEnvelopeSort.name ===  'count')? warehouseEnvelopeSort.type: ''}`} src="./img/arrow-up-grey.svg" alt="" /></div> 
                        <div className="warehouse__home-table-menu-container d-flex align-center justify-center"></div>
                    </div>
                    <div className="warehouse__home-table">
                        {
                            warehouseHomeData.map((item, key) => addTableItem(item, key))
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>        
	)
}

export default Home;