  const initialState = {
    ownerHomeData: [],
    ownerHomeLoading: true,

    ownerWarehouseData: [],
    ownerWarehouseLoading: true
  };
  
  const grafData = (state = initialState, action) => {

    if (action.type === 'SET_OWNER_HOME_GRAF_DATA') {
      return {
        ...state,
        ownerHomeData: action.payload,
        ownerHomeLoading: false,
      };
    }
    if (action.type === 'SET_OWNER_HOME_GRAF_LOADING') {
      return {
        ...state,
        ownerHomeLoading: action.payload,
      };
    }
    
    if (action.type === 'SET_OWNER_WAREHOUSE_GRAF_DATA') {
      return {
        ...state,
        ownerWarehouseData: action.payload,
        ownerWarehouseLoading: false,
      };
    }
    if (action.type === 'SET_OWNER_WAREHOUSE_GRAF_LOADING') {
      return {
        ...state,
        ownerWarehouseLoading: action.payload,
      };
    }
    return state;
  };
  
  export default grafData;