import React, { useState, useEffect } from 'react';
import Search from '../Search/Search';
import {useDispatch, useSelector} from 'react-redux';
import { setPopupOpenBlock, setPopupOpenBlockEvent} from '../../../redux/actions/popupBlocks';
import {setRole, setWarehouseNav} from '../../../redux/actions/navigation';
import { Link } from 'react-router-dom';

import './Header-1.0.scss';

const Header = ({status}) => {
    const dispatch = useDispatch();

    const { openBlock, userData, nav } = useSelector(({popupBlocks, navigation})=> {
        return {
            openBlock: popupBlocks.openBlock,
            userData: navigation.user,
            nav: navigation.warehouseNav,
        }
    })
    // useEffect(()=>{
    //     dispatch(fetchWorkZoneCashbox())
    //     dispatch(fetchWorkZoneNotification())
    // }, [])

 	return (
		<header className={`warehouse__header d-flex justify-between ${status}`}>
            <div className='warehouse__header-desktop justify-between'>
                <div className='d-flex align-center'><img src="/img/logo.svg" alt="" className="warehouse__logo"/></div>
                <div className='warehouse__buttons d-flex justify-between align-center'> 
                    <Link to='/' onClick={() =>{
                        dispatch(setWarehouseNav('Home'))
                        sessionStorage.setItem('warehousePage', 'Home')
                    }}>
                        <div className='warehouse__buttons-item'>
                            <div className={`warehouse__buttons-icon home ${(nav === 'Home')? 'active': ''}`}></div>
                        </div>
                    </Link>
                    <Link to='/leaders' onClick={() =>{
                        dispatch(setWarehouseNav('Leaders'))
                        sessionStorage.setItem('warehousePage', 'Leaders')
                    }}>
                        <div className='warehouse__buttons-item mr-none'>
                            <div className={`warehouse__buttons-icon leader ${(nav !== 'Home')? 'active': ''}`}></div>
                        </div>
                    </Link>
                </div> 
                <Search/>
                <div className="warehouse__buttons-user-container d-flex justify-between">
                    <div className="warehouse__buttons-user popup-open-button d-flex align-center" onClick={(event) =>{
                        dispatch(setPopupOpenBlock((openBlock === "warehouseUserMenu")? "": "warehouseUserMenu"))
                        dispatch(setPopupOpenBlockEvent(event.target))
                        
                    }}>
                        <div className="warehouse__buttons-user-photo-background popup-open-button d-flex justify-center align-center">
                            <img src={userData.src} alt="" className="warehouse__buttons-user-photo popup-open-button"/>
                        </div>
                        <div>
                            <div className="warehouse__buttons-user-name d-flex align-center popup-open-button">
                                <span className='popup-open-button'>{userData.name}</span> <img src="/img/arrow-down.svg" alt="" className="work-zone__buttons-user-button popup-open-button"/>
                            </div>
                            <div className="warehouse__buttons-user-name-verify d-flex align-center popup-open-button">
                                <img src="/img/verify.svg" alt="" className="warehouse__buttons-user-name-verify-icon popup-open-button"/>
                                Главный
                            </div>
                        </div>
                    </div>

                    <div className={`warehouse__buttons-popup user warehouseUserMenu ${(openBlock === "warehouseUserMenu")? "active": ""}`} >
                        <div className='warehouse__buttons-popup-user-item d-flex align-center'>
                            <img src={userData.src} alt="" className="warehouse__buttons-popup-user-photo"/>
                            <div className="warehouse__buttons-popup-user-name d-flex align-center">
                                {userData.name}                                   
                            </div>
                        </div>
                        <div className="warehouse__buttons-popup-user-logout d-flex align-center" onClick={()=>{
                            localStorage.setItem('token', '') 
                            localStorage.setItem('role', '')
                            sessionStorage.setItem('token', '') 
                            sessionStorage.setItem('role', '')
                            sessionStorage.setItem('warehousePage', 'Home')
                            dispatch(setRole(''))
                        }}>
                            <img src="/img/logout.svg" alt="" className="warehouse__buttons-popup-user-logout-icon"/>
                            <div className="warehouse__buttons-popup-user-logout-title">Выйти из аккаунта</div>                   
                        </div>                           
                    </div>
                </div>
            </div>
        </header>
	)
}
export default Header;