import React, {useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setFranchiserSalariesData } from '../../../../redux/actions/franchiserData';
import { setModeratorLeaderEditCheck } from '../../../../redux/actions/moderatorCheckData';
import { setPopupBackgroundFunc, setPopupOpenBlock} from '../../../../redux/actions/popupBlocks';

import './index.scss';

const AddEvent = () => {
    const dispatch = useDispatch()
    const [listActive, setListActive] = useState({
        salary: false,
        premium: false,
        fine: false,
        prepayment: false,
        zero: false
    })

    let {salariesCheckDayData, salariesData} = useSelector(({franchiserCheckData, franchiserData}) => {
        return{
            salariesCheckDayData: franchiserCheckData.franchiserSalariesCheckDay,
            salariesData: franchiserData.franchiserSalariesData
        }
    })

    let dat = [];
    
    if(salariesCheckDayData.salary !== 0) dat.push({type: 'Зарплата', value: salariesCheckDayData.salary, active: false})
    if(salariesCheckDayData.premium !== 0) dat.push({type: 'Премия', value: salariesCheckDayData.premium, active: false})
    if(salariesCheckDayData.fine !== 0) dat.push({type: 'Штраф', value: salariesCheckDayData.fine, active: false})
    if(salariesCheckDayData.prepayment !== 0) dat.push({type: 'Аванс', value: salariesCheckDayData.prepayment, active: false})

    let [data, setData] = useState(dat)
    let [eventsZeroBlock, setEventsZeroBlock] = useState((data.length === 0)? [{type: '', value: null}]: [])

    let [resetList, setResetList] = useState([])


    const [editInform, setEditInform] = useState({ 
        salary: false,
        premium: false,
        fine: false,
        zero: false
    })

    const [noneListElem, setNoneListElem] = useState({ 
        salary: (salariesCheckDayData.salary === 0)? true: false,
        premium: (salariesCheckDayData.premium === 0)? true: false,
        fine: (salariesCheckDayData.fine === 0)? true: false,
        prepayment: (salariesCheckDayData.prepayment === 0)? true: false,
    })

    const [error, setError] = useState('') 

    const postData = (event) =>{
        event.preventDefault();
    }

    let selectListItem = (id, type, zero, pos, select) =>{ 
        if(zero){
            let mass = eventsZeroBlock.slice();
            mass[0] = {...mass[0], type: select}
            setEventsZeroBlock(mass)
        }
        else{
            let mass = data.slice();
            mass[pos] = {...mass[pos], type: select}
            setData(mass)
            setResetList([...resetList, {before: type, after: select}])
        }
        setListActive({salary: false, premium: false, fine: false, zero: false})

        if(select === 'Зарплата'){ 
            setEditInform({...editInform, salary: true})           
        }
        else if(select === 'Премия'){
            setEditInform({...editInform, premium: true})    
        }
        else if(select === 'Штраф'){
            setEditInform({...editInform, fine: true})    
        }
        else if(select === 'Аванс'){
            setEditInform({...editInform, prepayment: true})    
        }

        if(type === 'Зарплата'){           
            if(id === 3){
                setNoneListElem({...noneListElem, premium: false, salary: true})
            }
            else if(id === 2){
                setNoneListElem({...noneListElem, fine: false, salary: true})
            }
            else if(id === 1){
                setNoneListElem({...noneListElem, prepayment: false, salary: true})
            }
        }
        else if(type === 'Премия'){
            if(id === 4){
                setNoneListElem({...noneListElem, salary: false, premium: true})
            }
            else if(id === 2){
                setNoneListElem({...noneListElem, fine: false, premium: true})
            }
            else if(id === 1){
                setNoneListElem({...noneListElem, prepayment: false, premium: true})
            }
        }
        else if(type === 'Штраф'){
            if(id === 4){
                setNoneListElem({...noneListElem, salary: false, fine: true})
            }
            else if(id === 3){
                setNoneListElem({...noneListElem, premium: false, fine: true})
            }
            else if(id === 1){
                setNoneListElem({...noneListElem, prepayment: false, fine: true})
            }
        }
        else if(type === 'Аванс'){
            if(id === 4){
                setNoneListElem({...noneListElem, salary: false, prepayment: true})
            }
            else if(id === 3){
                setNoneListElem({...noneListElem, premium: false, prepayment: true})
            }
            else if(id === 2){
                setNoneListElem({...noneListElem, fine: false, prepayment: true})
            }
        }
        else{
            if(id === 4){
                setNoneListElem({...noneListElem, salary: false})
            }
            else if(id === 3){
                setNoneListElem({...noneListElem, premium: false})
            }
            else if(id === 2){
                setNoneListElem({...noneListElem, fine: false})
            }
            else if(id === 1){
                setNoneListElem({...noneListElem, prepayment: false})
            }
        }

    }

    const DateFromat = (dat) =>{
        let date = dat.split('.')
        date = new Date(+date[2], +date[1]-1, +date[0])
        return date.toLocaleDateString('fr-CA')
    }
    DateFromat('20.11.2022')

    const post = async(url, data) =>{

        let response = await fetch(url, {
            method: 'POST', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })
        if (response.ok === true) {
            const data = await response.json();
            return data
            // dispatch(setFranchiserStaffListAdd({
            //     id: data.value.result, 
            //     name: cutName(inform.name),
            //     fullName: inform.name,
            //     status: 'Работающий',
            //     satausColor: 'green',
            //     phone: inform.phone, 
            //     historySales: 0, 
            //     salary: 0,
            //     login: inform.login,
            //     password: inform.password,
            //     position: inform.position
            // }))
        }
        // else{
        //     localStorage.setItem('role', '')
        // }
    }

    let addEvent = ({type, value}, key, zero) =>{

        return(
            <div key={key}>
                <div className="moderator__popup-input-container">
                    <label htmlFor="" className='moderator__popup-label'>Событие</label>
                    <div className="franchises-leaders__staf-points-buttons-position-select-container d-flex align-center">
                        <div className={`franchises-leaders__staf-points-buttons-position-select ${(type === 'Зарплата')? 'blue': (type === 'Премия')? 'green': (type === 'Штраф')? 'red': (type === 'Аванс')? 'orange': 'grey'} ${((type === 'Зарплата' && listActive.salary) || (type === 'Премия' && listActive.premium) || (type === 'Штраф' && listActive.fine) || (type === 'Аванс' && listActive.prepayment) || (zero && listActive.zero))? 'active': ''}`} onClick={()=>{
                                if(zero){
                                    setListActive({...listActive, zero: !listActive.zero})
                                }
                                else{
                                    if(type === 'Зарплата'){
                                        setListActive({...listActive, salary: !listActive.salary})
                                    }
                                    else if(type === 'Премия'){
                                        setListActive({...listActive, premium: !listActive.premium})
                                    }
                                    else if(type === 'Штраф'){
                                        setListActive({...listActive, fine: !listActive.fine})
                                    }
                                    else if(type === 'Аванс'){
                                        setListActive({...listActive, prepayment: !listActive.prepayment})
                                    }
                                    
                                }
                            }}>
                            {(type === '')? "Выберите вариант": type}
                        </div>                            
                        <div className={`franchises-leaders__staf-points-buttons-position-select-list add-event ${((type === 'Зарплата' && listActive.salary) || (type === 'Премия' && listActive.premium) || (type === 'Штраф' && listActive.fine) || (type === 'Аванс' && listActive.prepayment) || (zero && listActive.zero))? 'active': ''}`}>
                            <div className="franchises-leaders__staf-points-buttons-position-select-list-scroll add-event">
                                {
                                    (noneListElem.salary)? (
                                        <div className={`franchises-leaders__staf-points-buttons-position-select-list-item `} onClick={()=> selectListItem(4, type, zero, key, 'Зарплата', value)}>
                                            Зарплата
                                        </div>
                                    ): null
                                }

                                {
                                    (noneListElem.premium)? (
                                        <div className={`franchises-leaders__staf-points-buttons-position-select-list-item `} onClick={()=> selectListItem(3, type, zero, key, 'Премия', value)}>
                                            Премия
                                        </div>
                                    ): null
                                }

                                {
                                    (noneListElem.fine)? (
                                        <div className={`franchises-leaders__staf-points-buttons-position-select-list-item `} onClick={()=> selectListItem(2, type, zero, key, 'Штраф', value)}>
                                            Штраф
                                        </div>  
                                    ): null
                                }  

                                {
                                    (noneListElem.prepayment)? (
                                        <div className={`franchises-leaders__staf-points-buttons-position-select-list-item `} onClick={()=> selectListItem(1, type, zero, key, 'Аванс', value)}>
                                            Аванс
                                        </div>  
                                    ): null
                                }                                                                                                  
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="moderator__popup-input-container d-flex justify-between">
                    <div className="moderator__popup-input-fifty">
                        <label htmlFor="" className='moderator__popup-label'>Сумма</label>
                        <input name='Login' type="number" defaultValue={value} className="moderator__popup-input" onInput={(event)=>{
                            if(type === 'Зарплата'){
                                setEditInform({...editInform, salary: true})
                            }
                            else if(type === 'Премия'){
                                setEditInform({...editInform, premium: true})
                            }
                            else if(type === 'Штраф'){
                                setEditInform({...editInform, fine: true})
                            }
                            else if(type === 'Аванс'){
                                setEditInform({...editInform, prepayment: true})
                            }

                            if(zero){
                                let dat = eventsZeroBlock.slice()
                                dat[0] = {...dat[0], value: event.target.value}
                                setEventsZeroBlock(dat)
                            }
                            else{                                
                                let dat = data.slice()
                                dat[key] = {...dat[key], value: event.target.value}
                                setData(dat)
                            }
                        }}/>
                    </div>
                    <div className="moderator__popup-add-events-save-container">
                        <button type='submit' className={`moderator__popup-add-events-save ${((type === 'Зарплата' && !editInform.salary || type === 'Премия' && !editInform.premium || type === 'Штраф' && !editInform.fine || type === 'Аванс' && !editInform.prepayment) && !zero)? 'deactive': ''}`} onClick={(event)=>{
                            let dat = salariesData.slice()
                            let date = DateFromat(salariesCheckDayData.date)
                            let userIndex = salariesData.indexOf(salariesData.find(user => user.id === salariesCheckDayData.userId))
                            let eventsIndex = dat[userIndex].salaryData.indexOf(dat[userIndex].salaryData.find(events => events.date === salariesCheckDayData.date))
                            for(let i = 0; i < resetList.length; i++){
                                if(resetList[i].after === type){
                                    if(resetList[i].before === 'Зарплата'){
                                        dat[userIndex].total = dat[userIndex].total - dat[userIndex].salaryData[eventsIndex].salary
                                        dat[userIndex].salaryData[eventsIndex] = {...dat[userIndex].salaryData[eventsIndex], salary: 0}                                        
                                        post('/francheaser/RemoveWageEvent', {WageDate: date, WorkerId: salariesCheckDayData.userId, WageType: 4})
                                    }
                                    else if(resetList[i].before === 'Премия'){
                                        dat[userIndex].total = dat[userIndex].total - dat[userIndex].salaryData[eventsIndex].premium
                                        dat[userIndex].salaryData[eventsIndex] = {...dat[userIndex].salaryData[eventsIndex], premium: 0}                                        
                                        post('/francheaser/RemoveWageEvent', {WageDate: date, WorkerId: salariesCheckDayData.userId, WageType: 3})
                                    }
                                    else if(resetList[i].before === 'Штраф'){
                                        dat[userIndex].total = dat[userIndex].total + dat[userIndex].salaryData[eventsIndex].fine
                                        dat[userIndex].salaryData[eventsIndex] = {...dat[userIndex].salaryData[eventsIndex], fine: 0}
                                        post('/francheaser/RemoveWageEvent', {WageDate: date, WorkerId: salariesCheckDayData.userId, WageType: 2})
                                    }
                                    else if(resetList[i].before === 'Аванс'){
                                        dat[userIndex].total = dat[userIndex].total + dat[userIndex].salaryData[eventsIndex].prepayment
                                        dat[userIndex].salaryData[eventsIndex] = {...dat[userIndex].salaryData[eventsIndex], prepayment: 0}
                                        post('/francheaser/RemoveWageEvent', {WageDate: date, WorkerId: salariesCheckDayData.userId, WageType: 1})
                                    }
                                }
                                if(resetList[i].before === type){
                                    let mass = resetList.slice()
                                    mass.splice(i, 1)
                                    setResetList(mass)
                                }
                            }

                            if(type === 'Зарплата'){
                                dat[userIndex].total = dat[userIndex].total - dat[userIndex].salaryData[eventsIndex].salary + ((zero)? +(eventsZeroBlock[0].value): +(data[key].value))
                                dat[userIndex].salaryData[eventsIndex] = {...dat[userIndex].salaryData[eventsIndex], salary: (zero)? +(eventsZeroBlock[0].value): +(data[key].value)}
                                setEditInform({...editInform, salary: false})
                                if(!zero) post('/francheaser/EditWageEvent', {WageDate: date, WorkerId: salariesCheckDayData.userId, WageType: 4, WageSumm: value})
                            }
                            else if(type === 'Премия'){
                                dat[userIndex].total = dat[userIndex].total - dat[userIndex].salaryData[eventsIndex].premium + ((zero)? +(eventsZeroBlock[0].value): +(data[key].value))
                                dat[userIndex].salaryData[eventsIndex] = {...dat[userIndex].salaryData[eventsIndex], premium: (zero)? +(eventsZeroBlock[0].value): +(data[key].value)}
                                setEditInform({...editInform, premium: false})
                                if(!zero) post('/francheaser/EditWageEvent', {WageDate: date, WorkerId: salariesCheckDayData.userId, WageType: 3, WageSumm: value})
                            }
                            else if(type === 'Штраф'){
                                dat[userIndex].total = dat[userIndex].total + dat[userIndex].salaryData[eventsIndex].fine - ((zero)? +(eventsZeroBlock[0].value): +(data[key].value))
                                dat[userIndex].salaryData[eventsIndex] = {...dat[userIndex].salaryData[eventsIndex], fine: (zero)? +(eventsZeroBlock[0].value): +(data[key].value)}
                                setEditInform({...editInform, fine: false})
                                if(!zero) post('/francheaser/EditWageEvent', {WageDate: date, WorkerId: salariesCheckDayData.userId, WageType: 2, WageSumm: value})
                            }
                            else if(type === 'Аванс'){
                                dat[userIndex].total = dat[userIndex].total + dat[userIndex].salaryData[eventsIndex].prepayment - ((zero)? +(eventsZeroBlock[0].value): +(data[key].value))
                                dat[userIndex].salaryData[eventsIndex] = {...dat[userIndex].salaryData[eventsIndex], prepayment: (zero)? +(eventsZeroBlock[0].value): +(data[key].value)}
                                setEditInform({...editInform, prepayment: false})
                                if(!zero) post('/francheaser/EditWageEvent', {WageDate: date, WorkerId: salariesCheckDayData.userId, WageType: 1, WageSumm: value})
                            }

                            

                            if(zero){
                                setData([...data, {type: eventsZeroBlock[0].type, value: +(eventsZeroBlock[0].value)}])
                                post('/francheaser/SetWageEvent', {CalendarDay: date, StaffId: salariesCheckDayData.userId, EventType: (eventsZeroBlock[0].type === 'Зарплата')? 4: (eventsZeroBlock[0].type === 'Премия')? 3: (eventsZeroBlock[0].type === 'Штраф')? 2: (eventsZeroBlock[0].type === 'Аванс')? 1: 0, Summ: eventsZeroBlock[0].value})
                                setEventsZeroBlock([])
                            }

                            dispatch(setFranchiserSalariesData(dat))
                        }}>Сохранить</button>
                         
                    </div>                        
                </div> 
                <div className='popup__error'>{error}</div>
                <div className='moderator__popup-add-events-line-container'>
                    <div className={`moderator__popup-add-events-line ${(!(data.length-1 === key && !zero && eventsZeroBlock.length === 0 && data.length !== 4))? 'full-left': ''} ${(zero && data.length === 0)? 'full': ''}`}>
                        {
                            (data.length-1 === key && !zero && eventsZeroBlock.length === 0 && data.length !== 4 )? <img className="moderator__popup-add-events-btn" src="/img/add-events-icon.svg" alt="" onClick={()=>{
                                setEventsZeroBlock([...eventsZeroBlock, {type: '', value: null}])
                            }}/>: null
                        }
                        {
                            (!zero || data.length > 0)? <img className="moderator__popup-delite-events-btn" src="/img/delite-circle-icon.svg" alt="" onClick={()=>{
                                if(zero){
                                    setEventsZeroBlock([])
                                }
                                else{
                                    let dat = salariesData.slice()
                                    let userIndex = salariesData.indexOf(salariesData.find(user => user.id === salariesCheckDayData.userId))
                                    let eventsIndex = dat[userIndex].salaryData.indexOf(dat[userIndex].salaryData.find(events => events.date === salariesCheckDayData.date))
                                    let date = DateFromat(salariesCheckDayData.date)
                                    let mass = data.slice()
                                    mass.splice(key, 1)
                                    setData(mass)
    
                                    if(data.length === 1){
                                        setEventsZeroBlock([{type: '', value: null}])
                                    }
                                    
                                    if(type === 'Зарплата'){
                                        dat[userIndex].total = dat[userIndex].total - dat[userIndex].salaryData[eventsIndex].salary
                                        dat[userIndex].salaryData[eventsIndex] = {...dat[userIndex].salaryData[eventsIndex], salary: 0}
                                        setEditInform({...editInform, salary: false})
                                    }
                                    else if(type === 'Премия'){
                                        dat[userIndex].total = dat[userIndex].total - dat[userIndex].salaryData[eventsIndex].premium
                                        dat[userIndex].salaryData[eventsIndex] = {...dat[userIndex].salaryData[eventsIndex], premium: 0}
                                        setEditInform({...editInform, premium: false})
                                    }
                                    else if(type === 'Штраф'){
                                        dat[userIndex].total = dat[userIndex].total + dat[userIndex].salaryData[eventsIndex].fine
                                        dat[userIndex].salaryData[eventsIndex] = {...dat[userIndex].salaryData[eventsIndex], fine: 0}
                                        setEditInform({...editInform, fine: false})
                                    }
                                    else if(type === 'Аванс'){
                                        dat[userIndex].total = dat[userIndex].total + dat[userIndex].salaryData[eventsIndex].prepayment
                                        dat[userIndex].salaryData[eventsIndex] = {...dat[userIndex].salaryData[eventsIndex], prepayment: 0}
                                        setEditInform({...editInform, prepayment: false})
                                    }

                                    post('/francheaser/RemoveWageEvent', {WageDate: date, WorkerId: salariesCheckDayData.userId, WageType: (type === 'Зарплата')? 4: (type === 'Премия')? 3: (type === 'Штраф')? 2: (type === 'Аванс')? 1: 0})
                                }
                            }}/>: null
                        }
                    </div>
                </div>
            </div>
        )
    }

	return (
            <div className="moderator__popup-container add-event">
                <div className="moderator__popup-top d-flex justify-between align-center add-event">
                    <div className="popup__title green">Добавить событие</div>
                    <div className="popup__close-btn" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                        dispatch(setModeratorLeaderEditCheck({id: '', name: '', login: "", password: "", phone: "", mail: "", image: '', address: ''}))
                    }}></div>
                </div>
                <div className='moderator__popup-container-add-event-scroll'>                    
                    <form action="" onSubmit={postData}>
                        {
                            
                            data.map((item, key) => addEvent(item, key, false))
                        }

                        {                        
                            eventsZeroBlock.map((item, key) => addEvent(item, key, true))
                        }
                    </form>      
                </div>                    
            </div>
	)
}
export default AddEvent;