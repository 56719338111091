import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';

import {setModeratorNav} from '../../../../../redux/actions/navigation';
import { setPopupBackgroundFunc, setPopupOpenBlock } from '../../../../../redux/actions/popupBlocks';
import { fetchModeratorLeadersPoints, setModeratorLeadersPoints} from '../../../../../redux/actions/moderatorData';
import {setModeratorDeliteCheck, setModeratorPointEditCheck} from '../../../../../redux/actions/moderatorCheckData';

import AddPointsIcon from '../../../../../img/add-points-icon.svg';

import './index.scss';

const LeadersStaf = () => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(0)
    const [licensionKey, setLicensionKey] = useState({id: 0, key: ''})

    const openBlock = (id) =>{
        if(open === id) setOpen(0)
        else setOpen(id)
    }

    const {moderatorLeadersPoints, leader} = useSelector(({moderatorData, moderatorCheckData})=> {
        return {
            moderatorLeadersPoints: moderatorData.moderatorLeadersPoints,
            leader: moderatorCheckData.moderatorLeaderCheck
        }
    })

    useEffect(()=>{
        dispatch(setModeratorNav('LedersStaf'))
        dispatch(fetchModeratorLeadersPoints(leader.id))
    }, [])

    const editPoints = ({id, name, address, login, password, licensedKey, key}) =>{
        dispatch(setModeratorPointEditCheck({id, name, address, login, password, licensedKey, key}))
        dispatch(setPopupBackgroundFunc(true))
        dispatch(setPopupOpenBlock('AddPoints'))
    }

    const addTableItem = ({id, name, address, login, password, licensedKey}, key) =>{
        return(
            <div className={`moderator__leaders-staf-points-line-container moderator__scroll-leaders-staf-points-line-${id}`} key={key}>
                <div className={`moderator__leaders-staf-points-line d-flex align-center justify-between`} onClick={()=>{
                }}>
                    <div className="moderator__leaders-staf-points-name" title={name}>{name}</div>
                    <div className="moderator__leaders-staf-points-address" title={address}>{address}</div>
                    <div className="moderator__leaders-staf-points-login">{login}</div>
                    <div className="moderator__leaders-staf-points-password">{password}</div>
                    <div className='delite-icon-block d-flex justify-center align-center' onClick={()=>{
                        deletePoint(id, name)                        
                    }}>
                        <div className='delite-icon-block-center'>
                            <div className='delite-icon-block-line one'></div>
                            <div className='delite-icon-block-line two'></div>
                        </div>
                    </div>
                    <div className='moderator-edit-icon-block d-flex justify-center align-center'><img src="./img/edit-icon.svg" alt="" onClick={()=>{editPoints({id, name, address, login, password, licensedKey, key})}}/>
                    </div>
                    <img src="/img/arrow-down-block.svg" alt="" className={`moderator__leaders-staf-points-staf-open ${(open === id)? "active": ""}`} onClick={() => openBlock(id)}/>
                </div>
                <div className={`moderator__leaders-staf-points-key d-flex align-center ${(open === id)? "active": ""}`}>
                    <div className="moderator__leaders-staf-points-key-label d-flex align-center">Лицензионный ключ:</div>
                    <div className="moderator__leaders-staf-points-key-input">{(id === licensionKey.id)? licensionKey.key : licensedKey}</div>
                    <div className="moderator__leaders-staf-points-key-copy-button" onClick={()=>{
                        navigator.clipboard.writeText((id === licensionKey.id)? licensionKey.key : licensedKey)
                    } }></div>
                    <div className="moderator__leaders-staf-points-key-refresh-button" onClick={() => refreshKey(id, key)}></div>
                </div>
            </div>
        )
    }

    const deletePoint = async(id, name) =>{
        dispatch(setModeratorDeliteCheck({id: id, name: name, type: 'Point'}))
        dispatch(setPopupBackgroundFunc(true))
        dispatch(setPopupOpenBlock('Delite'))
    }

    const refreshKey = async(id, key) =>{
        let response = await fetch(`/Moderator/NewKeyGenerate?placeId=${id}`, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
        })
        if (response.ok === true) {
            const dat = await response.json();
            setLicensionKey({id: id, key: dat.value.resultObject})
            let points = moderatorLeadersPoints;
            points[key] = {...points[key], licensedKey: dat.value.resultObject}
            setModeratorLeadersPoints(points)
        }
        else{
          localStorage.setItem('role', '')
        }
    }

	return (
        <React.Fragment>
            <div className="moderator__leaders-staf-points">
                <div className="moderator__leaders-top d-flex justify-between align-center">
                    <div className="moderator__leaders-title staf">Список точeк</div>
                    <div className="moderator__leaders-add-leader-btn d-flex justify-center align-center" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(true))
                        dispatch(setPopupOpenBlock('AddPoints'))
                    }}>
                        <img src={AddPointsIcon} alt="" className="moderator__leaders-add-leader-btn-icon" />
                        <div className="moderator__leaders-add-leader-btn-title">Добавить точку</div>
                    </div>
                </div>
                <div className='moderator__leaders-staf-points-table-scroll'>
                    <div className="moderator__leaders-staf-points-line title d-flex align-center justify-between">
                        <div className="moderator__leaders-staf-points-name" title='Ibragimov Said-Hasan Salmanovich'>Наименование</div>
                        <div className="moderator__leaders-staf-points-address">Адрес</div>
                        <div className="moderator__leaders-staf-points-login">Логин</div>
                        <div className="moderator__leaders-staf-points-password">Пароль</div>
                        <div className='edit-icon-block d-flex justify-center align-center none'></div>
                        <div className='delite-icon-block none'></div>
                        <img src="/img/arrow-down-block.svg" alt="" className="moderator__leaders-staf-points-staf-open none"/>
                    </div>
                    <div className="moderator__leaders-staf-points-table">
                        {
                            moderatorLeadersPoints.map((item, key) => addTableItem(item, key))
                        }                        
                    </div>
                </div>
            </div>
        </React.Fragment>        
	)
}

export default LeadersStaf;