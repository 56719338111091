import {React, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GrafLine from '../../../Amchart/GrafLine'
import { setOwnerHomeButton} from '../../../../redux/actions/filters';
import {setOwnerHomeMonth, setOwnerHomeYear} from '../../../../redux/actions/popupFilters';
import './Sales-income-1.0.scss';
 
import {setCalendDisplay} from '../../../../redux/actions/display';
import {fetchOwnerHomeGrafData} from '../../../../redux/actions/grafData';
import {fetchOwnerHomeBtn} from '../../../../redux/actions/ownerData';
import { setOwnerNav } from './../../../../redux/actions/navigation';

const SalesIncome = () =>{
    const dispatch = useDispatch();
    const {buttonActive, range, month, year, ownerHomeCheckLeaders, ownerHomeCheckPoints, pointsList, grafLoading, grafData, buttons} = useSelector(({filters, popupFilters, grafData, ownerData})=> {
        return {
            buttonActive: filters.ownerHomeButton,
            range: popupFilters.ownerHomeRange,
            month: popupFilters.ownerHomeMonth,
            year: popupFilters.ownerHomeYear,
            ownerHomeCheckLeaders: filters.ownerHomeCheckLeaders,
            ownerHomeCheckPoints: filters.ownerHomeCheckPoints,
            grafLoading: grafData.ownerHomeLoading,
            grafData: grafData.ownerHomeData,
            pointsList: ownerData.ownerHomePoints,
            buttons: ownerData.ownerHomeBtn
        }
    })
    let dataMonth = [
        {
            id: 0,
            value: "Январь"
        },
        {
            id: 1,
            value: "Февраль"
        },
        {
            id: 2,
            value: "Март"
        },
        {
            id: 3,
            value: "Апрель"
        },
        {
            id: 4,
            value: "Май"
        },
        {
            id: 5,
            value: "Июнь"
        },
        {
            id: 6,
            value: "Июль"
        },
        {
            id: 7,
            value: "Август"
        },
        {
            id: 8,
            value: "Сентябрь"
        },
        {
            id: 9,
            value: "Октябрь"
        },
        {
            id: 10,
            value: "Ноябрь"
        },
        {
            id: 11,
            value: "Декабрь"
        }
    ];
    let defaultYear = new Date().getFullYear()
    let dataYear = [
        {
            id: 0,
            value: defaultYear - 5
        },
        {
            id: 1,
            value: defaultYear - 4
        },
        {
            id: 2,
            value: defaultYear - 3
        },
        {
            id: 3,
            value: defaultYear - 2
        },
        {
            id: 4,
            value: defaultYear - 1
        },
        {
            id: 5,
            value: defaultYear
        },
        {
            id: 6,
            value: defaultYear + 1
        },
        {
            id: 7,
            value: defaultYear + 2
        },
        {
            id: 8,
            value: defaultYear + 3
        },
        {
            id: 9,
            value: defaultYear + 4
        },
        {
            id: 10,
            value: defaultYear + 5
        }
    ]

    const [monthActive, setMonthActive]  = useState(false);
    const [yearActive, setYearActive]  = useState(false);

    useEffect(()=>{
        dispatch(setOwnerNav("Home"))
        sessionStorage.setItem('ovnerNav', 'Home')
    }, [])

    useEffect(()=>{
        dispatch(fetchOwnerHomeGrafData(buttonActive, range, month, year, ownerHomeCheckLeaders, ownerHomeCheckPoints))
    }, [buttonActive, ownerHomeCheckPoints.length, ownerHomeCheckLeaders, range, month, year])

    useEffect(()=>{
        dispatch(fetchOwnerHomeBtn(range, month, year, ownerHomeCheckLeaders, ownerHomeCheckPoints))
    }, [ownerHomeCheckPoints.length, ownerHomeCheckLeaders, range, month, year])


    const addValPercent = (type, value, color) => {
        if(color == "green"){
            return(
                <div className={`owner__sales-buttons-val-percent d-flex align-center justify-center green ${type}`}>
                    <img src="/img/arrow-up-green.svg" alt="" className="owner__sales-buttons-val-percent-icon"/> 
                    <div>{value}%</div>
                </div> 
            )
        }
        else if(color == "red"){
            return(
                <div className={`owner__sales-buttons-val-percent d-flex align-center justify-center red ${type}`}>
                    <img src="/img/arrow-down-red.svg " alt="" className="owner__sales-buttons-val-percent-icon"/> 
                    <div>{value}%</div>
                </div>
            )
        }
    }

    const monthActivate = () =>{
        setMonthActive(!monthActive)
        setYearActive(false)
    }
    const yearActivate = () =>{
        setYearActive(!yearActive)
        setMonthActive(false)
    }
    const monthChange = (id) =>{
        dispatch(setOwnerHomeMonth(dataMonth[id].value))
        setMonthActive(false)
    }
    const yearChange = (id) =>{
        dispatch(setOwnerHomeYear(dataYear[id].value))
        setYearActive(false)
    }

    const addMonth = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(month === value)? "active": ""}`} key={key} onClick={() => {monthChange(id)}}>{value}</div>
        )
    }
    const addYear = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(year === value)? "active": ""}`} key={key} onClick={() => {yearChange(id)}}>{value}</div>
        )
    }

	return (
		<div className="owner__sales">
            <div className="owner__sales-top d-flex align-center justify-between">
                <div className="owner__sales-title">Продажи и Доход</div>
                <div className='owner__sales-filters__block d-flex align-center'>
                    <div className="owner__sales-filters__block-item sales year">
                        <div className={`owner__sales-filters__block-item-title d-flex align-center justify-center year ${(year === "Год")? "": "active"} ${(yearActive)? "open": ""}`} onClick={yearActivate}>{year}<div className="owner__sales-filters__block-item-title-icon"/></div>                        
                        <div className={`owner__sales-filters__block-item-list ${(yearActive)? "open": ""}`}>
                            <div className='owner__sales-filters__block-item-list-scroll'>
                                {
                                    dataYear.map((item, key) => addYear(item, key))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="owner__sales-filters__block-item sales months">
                        <div className={`owner__sales-filters__block-item-title d-flex align-center justify-center months ${(month === "Месяц")? "": "active"} ${(monthActive)? "open": ""}`} onClick={monthActivate}>{month}<div className="owner__sales-filters__block-item-title-icon"/></div>                        
                        <div className={`owner__sales-filters__block-item-list ${(monthActive)? "open": ""}`}>
                            <div className='owner__sales-filters__block-item-list-scroll'>
                                {
                                    dataMonth.map((item, key) => addMonth(item, key))
                                }                               
                            </div>
                        </div>
                    </div>
                    <div className="owner__sales-filters d-flex justify-between">
                        <div className="owner__sales-filters months" onClick={() => {
                            dispatch(setCalendDisplay(""));
                            setTimeout(()=>{
                                dispatch(setCalendDisplay("open", "ownerHome"))
                            }, 0)
                        }}>
                            <div className="owner__sales-filters-title d-flex align-center justify-center months">Фильтры<img src="/img/arrow-down.svg" alt="" className="owner__sales-filters-title-icon"/></div>                        
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="owner__sales-buttons d-flex justify-between">
                <div className={`owner__sales-buttons-sale d-flex justify-center ${(buttonActive === "sale")? "active": ""}`} onClick={()=>{
                    dispatch(setOwnerHomeButton("sale"))
                }}>
                    <div className="owner__sales-buttons-icon sale align-center justify-center"><img src="/img/shopping-bag.svg" alt="" /></div>
                    <div>
                        <div className="owner__sales-buttons-title sale">Продажи</div>
                        <div className="owner__sales-buttons-val sale">{buttons[1].value}</div>
                        {
                            addValPercent("sale", buttons[1].percent, buttons[1].class)
                        }
                    </div>
                    
                </div>
                <div className={`owner__sales-buttons-income d-flex justify-center ${(buttonActive === "income")? "active": ""}`} onClick={()=>{
                    dispatch(setOwnerHomeButton("income"))
                }}>
                    <div className="owner__sales-buttons-icon income align-center justify-center"><img src="/img/dollar-circle.svg" alt="" /></div>
                    <div>
                        <div className="owner__sales-buttons-title income">Доход</div>
                        <div className="owner__sales-buttons-val income">{buttons[0].value}</div>
                        {
                            addValPercent("income", buttons[0].percent, buttons[0].class)
                        }
                    </div>
                </div>
            </div>
            <div className="owner__sales-graphik">
                    <GrafLine data = {grafData} selRange={!(range.length === 0 && month === "Месяц")} addClass={"sales-income__graf"} buttonActive={(buttonActive === "sale")? "Продажи": "Доход"}/>
                    <div className={`graf-loading justify-center align-center ${(grafLoading)? "active": ''}`}>
                        <div className='graf-loading__icon'></div>
                    </div>
            </div>
        </div>
	)
}

export default SalesIncome;