import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupBackgroundFunc, setPopupOpenBlock, setPopupOpenBlockEvent } from '../../../redux/actions/popupBlocks';
import { setWorkZoneCheckClient, setWorkZoneCheckClientId, setWorkZoneCheckLekaloResize, setWorkZonePlotterConfiguration, setWorkZonePopupPrintType } from '../../../redux/actions/workZoneCheckData';

import './Workspace.scss';
import { setWorkZoneCheckLekaloResizeFull } from './../../../redux/actions/workZoneCheckData';
import { setWorkZoneNav } from '../../../redux/actions/navigation';

const Workspase = () => {

    const dispatch = useDispatch()

    const {workZoneCheckLekalo, workZonePlotterConfiguration, openBlock, imgUrl, workZonePopupPrintType} = useSelector(({workZoneCheckData, popupBlocks, proxy})=> {
        return {
            workZoneCheckLekalo: workZoneCheckData.workZoneCheckLekalo,
            workZonePlotterConfiguration: workZoneCheckData.workZonePlotterConfiguration,
            workZonePopupPrintType: workZoneCheckData.workZonePopupPrintType,
            openBlock: popupBlocks.openBlock,
            imgUrl: proxy.url
        }
    })

    const [configuration, setConfiguration] = useState({
        pressure: workZonePlotterConfiguration.pressure,
        speed: workZonePlotterConfiguration.speed
    })
    const [clickAddBtn, setClickAddBtn] = useState(false)

    useEffect(()=>{
        dispatch(setWorkZoneNav('Workspase'))
        sessionStorage.setItem('workzonePage', 'Workspase')
    }, [])
    
    const PostFunc = async(url, data) => {
        const response =  await fetch(url, {
            method: 'POST', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })

        if (response.ok === true) {
            const dat = await response.json();
            return dat           
        }
        else{
            setClickAddBtn(false)
        }
    }

    const PrintFunc = async() =>{
        let date = new Date();
        let data = {
            lekaloId: workZoneCheckLekalo.id,
            width: (workZoneCheckLekalo.width-workZoneCheckLekalo.defaultWidth),
            height: (workZoneCheckLekalo.height-workZoneCheckLekalo.defaultHeight),
            force: configuration.pressure,
            speed: configuration.speed,
            formalized: true,
            dateTime: `${date.toLocaleDateString()}, ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`,
            // dateTimeUTC: date.getTimezoneOffset() / 60 * -1
        }
        // console.log(data)
        if(!clickAddBtn){
            setClickAddBtn(true)
            PostFunc('/Place/Prints', data).then((dat) =>{
                setClickAddBtn(false)
                dispatch(setWorkZonePlotterConfiguration({pressure: configuration.pressure, speed: configuration.speed}))
                dispatch(setWorkZoneCheckLekaloResizeFull({width: workZoneCheckLekalo.width, height: workZoneCheckLekalo.height}))
                sessionStorage.setItem('lekaloCheckWidth', workZoneCheckLekalo.width)
                sessionStorage.setItem('lekaloCheckHeight', workZoneCheckLekalo.height)
                
                if(dat.value.resultObject !== 'Error' || true){
                    dispatch(setWorkZoneCheckClientId({id: dat.value.resultObject.id, model: workZoneCheckLekalo.name, lekaloId: workZoneCheckLekalo.id, withOutFilm: dat.value.resultObject.withOutFilm }))                 
                    if(workZonePopupPrintType !== 'gurante') dispatch(setWorkZonePopupPrintType('interact'))
                    dispatch(setPopupBackgroundFunc(true))
                    dispatch(setPopupOpenBlock('PrintMold'))                 
                }          
            })
        }
                
        // dispatch(setWorkZonePopupPrintType('interact'))
        // dispatch(setPopupBackgroundFunc(true))     
        // dispatch(setPopupOpenBlock('PrintMold'))
    }

	return (
		<div className="work-zone__workspace">
            <div className="work-zone__workspace-buttons d-flex justify-between">
                <div className="work-zone__workspace-buttons-item-container resize">
                    <div className={`work-zone__workspace-buttons-item resize d-flex align-center popup-open-button justify-center  ${(openBlock === "workZoneCheckLekaloResizes")? "active": ""}`} onClick={(event)=>{
                        dispatch(setPopupOpenBlock((openBlock === "workZoneCheckLekaloResizes")? "": "workZoneCheckLekaloResizes"))
                        dispatch(setPopupOpenBlockEvent(event.target))
                    }}>
                        <img src="/img/work-zone-resize-icon.svg" alt="" className="work-zone__workspace-buttons-item-icon resize"/>
                        <div>Изменить размер</div>
                    </div>
                    <div className={`work-zone__workspace-buttons-item-popup workZoneCheckLekaloResizes resize ${(openBlock === "workZoneCheckLekaloResizes")? 'active': ''}`}>
                        <div>
                            <div className="work-zone__workspace-buttons-item-popup-item d-flex align-center one">
                                <button className="work-zone__workspace-buttons-item-popup-item-button left d-flex justify-center align-center" onClick={() =>{
                                    if(workZoneCheckLekalo.height > 0){
                                        // setResize({...resize, height: +(resize.height - 0.1).toFixed(1)})
                                        dispatch(setWorkZoneCheckLekaloResize({width: workZoneCheckLekalo.width, height: +(workZoneCheckLekalo.height - 0.1).toFixed(1)}))
                                    }
                                }}>
                                    <div className='work-zone__workspace-buttons-item-popup-item-button-icon'></div>
                                </button>

                                <div className="work-zone__workspace-buttons-item-popup-item-input d-flex align-center justify-center" onClick={(event)=>{
                                    event.currentTarget.querySelector('input').focus()
                                }}>
                                    <div className='work-zone__workspace-buttons-item-popup-item-input-container'>
                                        <span className='work-zone__workspace-buttons-item-popup-item-input-span'>Высота:</span>
                                        <input type="number" step="0.1" value={(workZoneCheckLekalo.height === 0)? '': workZoneCheckLekalo.height} onInput={(event) =>{
                                            // setResize({...resize, height: +event.target.value})
                                            dispatch(setWorkZoneCheckLekaloResize({width: workZoneCheckLekalo.width, height: +event.target.value}))
                                        }}/>
                                    </div>
                                </div>

                                <button className="work-zone__workspace-buttons-item-popup-item-button right d-flex justify-center align-center" onClick={() =>{
                                    // setResize({...resize, height: +(resize.height + 0.1).toFixed(1)})
                                    dispatch(setWorkZoneCheckLekaloResize({width: workZoneCheckLekalo.width, height: +(workZoneCheckLekalo.height + 0.1).toFixed(1)}))
                                }}>
                                    <div className='work-zone__workspace-buttons-item-popup-item-button-icon plus'></div>
                                </button>
                            </div>
                            <div className="work-zone__workspace-buttons-item-popup-item d-flex align-center">
                                <button className="work-zone__workspace-buttons-item-popup-item-button left d-flex justify-center align-center" onClick={() =>{
                                    if(workZoneCheckLekalo.width > 0){
                                        // setResize({...resize, width:+(resize.width - 0.1).toFixed(1)})
                                        dispatch(setWorkZoneCheckLekaloResize({height: workZoneCheckLekalo.height, width: +(workZoneCheckLekalo.width - 0.1).toFixed(1)}))
                                    }
                                }}>
                                    <div className='work-zone__workspace-buttons-item-popup-item-button-icon'></div>
                                </button>

                                <div className="work-zone__workspace-buttons-item-popup-item-input d-flex align-center justify-center" onClick={(event)=>{
                                    event.currentTarget.querySelector('input').focus()
                                }}>
                                    <div className='work-zone__workspace-buttons-item-popup-item-input-container'>
                                        <span className='work-zone__workspace-buttons-item-popup-item-input-span'>Ширина:</span>
                                        <input type="number" step="0.1" value={(workZoneCheckLekalo.width === 0)? '': workZoneCheckLekalo.width} onInput={(event) =>{
                                            // setResize({...resize, width: +event.target.value})
                                            dispatch(setWorkZoneCheckLekaloResize({height: workZoneCheckLekalo.height, width: +event.target.value}))
                                        }}/>
                                    </div>
                                </div>

                                <button className="work-zone__workspace-buttons-item-popup-item-button right d-flex justify-center align-center" onClick={() =>{
                                    // setResize({...resize, width: +(resize.width + 0.1).toFixed(1)})
                                    dispatch(setWorkZoneCheckLekaloResize({height: workZoneCheckLekalo.height, width: +(workZoneCheckLekalo.width + 0.1).toFixed(1)}))
                                }}>
                                    <div className='work-zone__workspace-buttons-item-popup-item-button-icon plus'></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="work-zone__workspace-buttons-item-container configuration">
                    <div className={`work-zone__workspace-buttons-item configuration popup-open-button d-flex align-center justify-center ${(openBlock === "workZonePlotterConfiguration")? 'active': ''}`}  onClick={(event)=>{
                        // setConfigurationActive(!configuration.isActive)
                        dispatch(setPopupOpenBlock((openBlock === "workZonePlotterConfiguration")? "": "workZonePlotterConfiguration"))
                        dispatch(setPopupOpenBlockEvent(event.target))
                    }}>
                        <img src="/img/work-zone-configuration-icon.svg" alt="" className="work-zone__workspace-buttons-item-icon configuration"/>
                        <div>Конфигурация плоттера</div> 
                    </div>
                    <div className={`work-zone__workspace-buttons-item-popup configuration workZonePlotterConfiguration ${(openBlock === "workZonePlotterConfiguration")? 'active': ''}`}>
                        <div>
                            <div className="work-zone__workspace-buttons-item-popup-item d-flex align-center one">
                                <button className="work-zone__workspace-buttons-item-popup-item-button left d-flex justify-center align-center" onClick={() =>{
                                    if(configuration.pressure > 0){
                                        // dispatch(setWorkZonePlotterConfiguration({...configuration, pressure: configuration.pressure - 1}))
                                        setConfiguration({...configuration, pressure: configuration.pressure - 1})
                                        localStorage.setItem('PlotterConfigurationPressure', configuration.pressure - 1)
                                    }
                                }}>
                                    <div className='work-zone__workspace-buttons-item-popup-item-button-icon'></div>
                                </button>

                                <div className="work-zone__workspace-buttons-item-popup-item-input d-flex align-center justify-center" onClick={(event)=>{
                                    event.currentTarget.querySelector('input').focus()
                                }}>
                                    <div className='work-zone__workspace-buttons-item-popup-item-input-container'>
                                        <span className='work-zone__workspace-buttons-item-popup-item-input-span'>Давление:</span>
                                        <input type="number" value={(configuration.pressure === 0)? '': configuration.pressure} onInput={(event) =>{
                                            // dispatch(setWorkZonePlotterConfiguration({...configuration, pressure: +event.target.value}))
                                            setConfiguration({...configuration, pressure: +event.target.value})
                                            localStorage.setItem('PlotterConfigurationPressure', +event.target.value)
                                        }}/>
                                    </div>
                                </div>

                                <button className="work-zone__workspace-buttons-item-popup-item-button right d-flex justify-center align-center" onClick={() =>{
                                    // dispatch(setWorkZonePlotterConfiguration({...configuration, pressure: configuration.pressure + 1}))
                                    setConfiguration({...configuration, pressure: configuration.pressure + 1})
                                    localStorage.setItem('PlotterConfigurationPressure', configuration.pressure + 1)
                                }}>
                                    <div className='work-zone__workspace-buttons-item-popup-item-button-icon plus'></div>
                                </button>
                            </div>
                            <div className="work-zone__workspace-buttons-item-popup-item d-flex align-center">
                                <button className="work-zone__workspace-buttons-item-popup-item-button left d-flex justify-center align-center" onClick={() =>{
                                    if(configuration.speed > 0){
                                        // dispatch(setWorkZonePlotterConfiguration({...configuration, speed: configuration.speed - 1}))
                                        setConfiguration({...configuration, speed: configuration.speed - 1})
                                        localStorage.setItem('PlotterConfigurationSpeed', configuration.speed - 1)
                                    }
                                }}>
                                    <div className='work-zone__workspace-buttons-item-popup-item-button-icon'></div>
                                </button>

                                <div className="work-zone__workspace-buttons-item-popup-item-input d-flex align-center justify-center" onClick={(event)=>{
                                    event.currentTarget.querySelector('input').focus()
                                }}>
                                    <div className='work-zone__workspace-buttons-item-popup-item-input-container'>
                                        <span className='work-zone__workspace-buttons-item-popup-item-input-span'>Скорость:</span>
                                        <input type="number" value={(configuration.speed === 0)? '': configuration.speed} onInput={(event) =>{
                                            // dispatch(setWorkZonePlotterConfiguration({...configuration, speed: +event.target.value}))
                                            setConfiguration({...configuration, speed: +event.target.value})
                                            localStorage.setItem('PlotterConfigurationSpeed', +event.target.value)
                                        }}/>
                                    </div>
                                </div>

                                <button className="work-zone__workspace-buttons-item-popup-item-button right d-flex justify-center align-center" onClick={() =>{
                                    // dispatch(setWorkZonePlotterConfiguration({...configuration, speed: configuration.speed + 1}))
                                    setConfiguration({...configuration, speed: configuration.speed + 1})
                                    localStorage.setItem('PlotterConfigurationSpeed', configuration.speed + 1)
                                }}>
                                    <div className='work-zone__workspace-buttons-item-popup-item-button-icon plus'></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="work-zone__workspace-content d-flex justify-center align-center">
                <img src={`${(workZoneCheckLekalo.src !== '')? imgUrl + workZoneCheckLekalo.src :'/img/default-moold.svg' }`} alt="" className="work-zone__workspace-content-lekalo"/>
            </div>

            <div className={`work-zone__workspace-print-btn popup-open-button d-flex justify-center align-center ${(workZoneCheckLekalo.id === 0 || clickAddBtn)? 'deactive': ''}`} onClick={()=> PrintFunc()}>
                <div>
                    Печать
                </div>
            </div>

		</div>
	)
}

export default Workspase; 