import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { setPopupBackgroundFunc, setPopupOpenBlock } from '../../../../redux/actions/popupBlocks';
import {setModeratorNav} from '../../../../redux/actions/navigation';
import { setModeratorLeaderCheck, setModeratorLeaderEditCheck, setModeratorDeliteCheck } from '../../../../redux/actions/moderatorCheckData';
import { fetchModeratorLeaders } from '../../../../redux/actions/moderatorData';

import AddLeaderIcon from '../../../../img/add-leader-icon.svg'

import './LeadersTable.scss';

const LeadersTable = () => {
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(setModeratorNav('Leaders'))
        dispatch(fetchModeratorLeaders())
    }, [])

    const {moderatorLeaders} = useSelector(({moderatorData})=> {
        return {
            moderatorLeaders: moderatorData.moderatorLeaders,
        }
    })

    const editLeader = ({id, name, login, password, phone, mail, image, address, key}) =>{
        // console.log(image)
        dispatch(setModeratorLeaderEditCheck({id, name, login, password, phone, mail, image, address, key}))
        dispatch(setPopupBackgroundFunc(true))
        dispatch(setPopupOpenBlock('AddLeaders'))
    }

    const addTableItem = ({id, name, abbreviatedName, login, password, phone, mail, image, address}, key) =>{
        return(
            <div className={`moderator__leaders-line d-flex align-center justify-between moderator__scroll-leaders-line-${id}`} key={key}>
                <div className="moderator__leaders-person" title={name}>{abbreviatedName}</div>
                <div className="moderator__leaders-login">{login}</div>
                <div className="moderator__leaders-password">{password}</div>
                <a href={`tel:${phone}`} className="moderator__leaders-phone">{phone}</a>
                <a href={`mailto:${mail}`} className="moderator__leaders-email">{mail}</a>
                <div className='delite-icon-block d-flex justify-center align-center' onClick={() => deleteLeader(id, name)}>
                    <div className='delite-icon-block-center'>
                        <div className='delite-icon-block-line one'></div>
                        <div className='delite-icon-block-line two'></div>
                    </div>
                </div>
                <div className='moderator-edit-icon-block d-flex justify-center align-center' onClick={() => editLeader({id, name, login, password, phone, mail, image, address, key})}><img src="./img/edit-icon.svg" alt="" /></div>
                <Link to='/leaders-staf' onClick={()=>{
                    dispatch(setModeratorLeaderCheck(moderatorLeaders[key]))
                    localStorage.setItem('moderatorLeaderCheckId', id)
                    localStorage.setItem('moderatorLeaderCheckName', name)
                }}>
                    <img src="./img/arrow-right-block.svg" alt="" className="moderator__leaders-open"></img>
                </Link>
            </div>
        )
    }

    const deleteLeader = async(id, name) =>{
        dispatch(setModeratorDeliteCheck({id: id, name: name, type: 'Leader'}))
        dispatch(setPopupBackgroundFunc(true))
        dispatch(setPopupOpenBlock('Delite'))
    }

	return (
        <React.Fragment>
            <div className="moderator__title">Список</div>
            <div className="moderator__leaders">
                <div className="moderator__leaders-top d-flex justify-between align-center">
                    <div className="moderator__leaders-title staf">Список руководителей</div>
                    <div className="moderator__leaders-add-leader-btn d-flex justify-center align-center" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(true))
                        dispatch(setPopupOpenBlock('AddLeaders'))
                    }}>
                        <img src={AddLeaderIcon} alt="" className="moderator__leaders-add-leader-btn-icon" />
                        <div className="moderator__leaders-add-leader-btn-title">Добавить руководителя</div>
                    </div>
                </div>
                <div className='moderator__leaders-table-scroll'>
                    <div className="moderator__leaders-line title d-flex align-center justify-between">
                        <div className="moderator__leaders-person">ФИО</div>
                        <div className="moderator__leaders-login">Логин</div>
                        <div className="moderator__leaders-password">Пароль</div>
                        <div className="moderator__leaders-phone">Телефон</div>
                        <div className="moderator__leaders-email">E-mail</div>
                        <div className='edit-icon-block d-flex justify-center align-center none'></div>
                        <div className='delite-icon-block none'></div>
                        <img src="/img/arrow-right-block" alt="" className="moderator__leaders-open none"></img>
                        
                    </div>
                    <div className="moderator__leaders-table">
                        {
                            moderatorLeaders.map((item, key) => addTableItem(item, key))
                        }                        
                    </div>
                </div>
            </div>
        </React.Fragment>        
	)
}

export default LeadersTable;