import React, { useState, useRef} from 'react';
import { useDispatch} from 'react-redux';

import { setRole, setUserData } from '../../redux/actions/navigation'

import Logo from '../../img/logo.svg'
import Login from '../../img/login.svg'
import Password from '../../img/password.svg'

import './index_0.1.scss'
import { Link } from 'react-router-dom';

const Autorization = () =>{
    const dispatch = useDispatch()

    const [inform, setInform] = useState({
        login: '',
        password: '',
        save: true
    })
    const resetUrlRef = useRef('')

    const [error, setError] = useState('')

    // const cutPath = (src) =>{
    //     console.log(src)
    //     if(src !== null && src !== '') return './' + src.split('/').slice(3).join('/')
    //     else return src
    // }

    const autorize = async (event) => {
        event.preventDefault()
        resetUrlRef.current.click() 
        const response = await fetch("/Login/AuthLogin", {
            method: "POST",
            headers: { "Accept": "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({
                login: inform.login,
                password: inform.password,
                save: inform.save
            })
        });
        if (response.ok === true) {
            // получаем данные
            const data = await response.json();
            
            if(data.value.result === 1){
                if(!inform.save){
                    localStorage.setItem('token', '');
                    localStorage.setItem('role','');
                    sessionStorage.setItem('token', data.value.resultObject.token);
                    sessionStorage.setItem('role', data.value.resultObject.role);
                    sessionStorage.setItem('userData', JSON.stringify((data.value.resultObject.role === 'workzone' || data.value.resultObject.role === 'warehouse' || data.value.resultObject.role === 'moderator')? {id: 0, name: data.value.resultObject.name, src: '/img/work-zone-userphoto.svg'}: {id: 0, name: data.value.resultObject.name, src:data.value.resultObject.src}))
                }
                else{
                    localStorage.setItem('save-autorize', true)
                    localStorage.setItem('token', data.value.resultObject.token);
                    localStorage.setItem('role', data.value.resultObject.role);
                    localStorage.setItem('userData', JSON.stringify((data.value.resultObject.role === 'workzone' || data.value.resultObject.role === 'warehouse' || data.value.resultObject.role === 'moderator')? {id: 0, name: data.value.resultObject.name, src: '/img/work-zone-userphoto.svg'}: {id: 0, name: data.value.resultObject.name, src: data.value.resultObject.src}))
                }
                dispatch(setRole(data.value.resultObject.role))
                dispatch(setUserData((data.value.resultObject.role === 'workzone' || data.value.resultObject.role === 'warehouse' || data.value.resultObject.role === 'moderator')? {id: 0, name: data.value.resultObject.name, src: '/img/work-zone-userphoto.svg'}: {id: 0, name: data.value.resultObject.name, src: data.value.resultObject.src}))


            }
            else{
                setError(data.value.informMessage, + '*')
            }
        }
    }

	return (
        <div className='autorization'>
            <img src={Logo} alt="" className="autorization__logo" />
            <div className="autorization__content d-flex justify-center align-center">
                <div className="autorization__content-form">
                    <div className="autorization__content-form-title">Авторизация</div>
                    <label className="autorization__content-form-lable">Логин</label>
                    <div className="autorization__content-form-field d-flex align-center justify-between">
                        <input  type="text" placeholder="Введите свой логин или e-mail" className="autorization__content-form-field-input" onInput={(event)=>{
                            setInform({...inform, login: event.target.value})
                        }}/>
                        <img src={Login} alt="" className="autorization__content-form-field-icon login"/>
                    </div>
                    <label className="autorization__content-form-lable">Пароль</label>
                    <div className="autorization__content-form-field d-flex align-center justify-between">
                        <input type="password" placeholder="Введите свой пароль" className="autorization__content-form-field-input" onInput={(event)=>{
                            setInform({...inform, password: event.target.value})
                        }}/>
                        <img src={Password} alt="" className="autorization__content-form-field-icon password"/>
                        <span></span>
                    </div>
                    <input type="checkbox" name="save_autorize" defaultChecked value="Сохранить меня" id="save_autorize" className="autorization__content-form-checkbox" onClick={() => {
                        setInform({...inform, save: !inform.save})
                        localStorage.setItem('save-autorize', !inform.save)
                    }}/>
                    <label className="autorization__content-form-checkbox-label" htmlFor="save_autorize">Сохранить меня</label>
                    <div className='popup__error'>{error}</div>
                    <button type="submit" className="autorization__content-form-btn" onClick={autorize}>Войти</button>
                    <Link to='/' ref={resetUrlRef} className='d-none'></Link>
                </div>
            </div>
        </div>
	)
}

export default Autorization;