const initialState = {
    moderatorNotification: 0,
    ownerNotification: 0,
  };
  
  const notification = (state = initialState, action) => {
    if (action.type === 'SET_MODERATOR') {
      return {
        ...state,
        moderatorNotification: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER') {
      return {
        ...state,
        ownerNotification: action.payload,
      };
    }
    
    return state;
  };
  
  export default notification;