import React, { useState, useEffect } from 'react';
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from './Sidebar/Sidebar';
import Catalog from './Catalog/Catalog';
import HistoryChanges from './HistoryChanges/HistoryChanges';
import LeadersTable from './Leaders/Leaders-table/LeadersTable';
import LeadersStaf from './Leaders/Leaders-staf/LeadersStaf';
import Complaints from './Сomplaints/Сomplaints';
import Popup from './Popup/Popup';

import {setPopupOpenBlock, setPopupOpenBlockEvent} from '../../redux/actions/popupBlocks'

import './Moderator.scss';
import Header from './Header/Header';
import { setSiginalConnect } from '../../redux/actions/siginalConnect';
import { setNotificationActive, setSession } from './../../redux/actions/navigation';

const Moderator = () => {
	const dispatch = useDispatch()
	const [connection, setConnection] = useState()

	const {role, session, openBlock, openBlockEvent} = useSelector(({navigation, popupBlocks})=> {
        return {
            role: navigation.role,
			      session: navigation.session,
            openBlock: popupBlocks.openBlock,
            openBlockEvent: popupBlocks.openBlockEvent
        }
    })

	let access_token = (localStorage.getItem('save-autorize') === 'true'? localStorage.getItem('token'): sessionStorage.getItem('token'));

    const notific = async () =>{
        const connection = new HubConnectionBuilder()
         //.withUrl('https://localhost:7119/chat', {accessTokenFactory: () => access_token, transport: HttpTransportType.WebSockets, skipNegotiation: true})
        .withUrl('https://bswork.pro/chat', {accessTokenFactory: () => access_token, transport: HttpTransportType.WebSockets, skipNegotiation: true})
        .configureLogging(LogLevel.Information)
        .build();

        connection.on("ModeratorNotificationAdd", (data) => {        
            dispatch(setSiginalConnect({type: 'new-moderator-notification', obj: {elem: data[0]}}))
            dispatch(setNotificationActive(true))
        });           
        await connection.start();
		setConnection(connection)

    }
    
    useEffect(() =>{
        if(role !== ''){
            notific()
        }
    }, [role])
    
      const closeConnection = async () => {
        try {
          await connection.stop();
        } catch (e) {



        }
      }

    const closePopup = (event) =>{ 
      if(session !== 1) dispatch(setSession(1))  
      if(openBlock !== '' && !event.target.classList.contains('popup-open-button')){
        let block = document.querySelector(`.${openBlock}`)
        let clickBlock = event.target; 
        if(block && clickBlock !== openBlockEvent && clickBlock !== block && !block.contains(clickBlock)){
            dispatch(setPopupOpenBlock(''))
            dispatch(setPopupOpenBlockEvent(''))
        }
      }
    }
	return (
		<React.Fragment>
			<div className="moderator" onClick={(event)=>closePopup(event)}>
				<Sidebar/>
				<div className='moderator__wrap'>
					<Header/>					
					<Route path='/Leaders' component={LeadersTable} exact />
					<Route path='/Leaders-staf' component={LeadersStaf} exact />
					<Route path='/Complaints' component={Complaints} exact />
					<Route path='/History-changes' component={HistoryChanges} exact /> 
					<Route path='/' component={Catalog} exact/>
				</div>
			</div>
			<Popup connection = {connection}/>
		</React.Fragment>
	)
}

export default Moderator; 