import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { fetchModeratorLeadersStaf } from '../../../../../redux/actions/moderatorData';
// import { Link } from 'react-router-dom';

import {setModeratorNav} from '../../../../../redux/actions/navigation';

import './index.scss';


const LeadersStafTable = () => {
    const dispatch = useDispatch();

    const {moderatorLeadersStaf, moderatorLeaderCheckId} = useSelector(({moderatorData, moderatorCheckData})=> {
        return {
            moderatorLeadersStaf: moderatorData.moderatorLeadersStaf,
            moderatorLeaderCheckId: moderatorCheckData.moderatorLeaderCheck.id
        }
    })

    useEffect(()=>{
        dispatch(setModeratorNav('LedersStaf'))
        dispatch(fetchModeratorLeadersStaf(moderatorLeaderCheckId))
    }, [])

    const addTableItem = ({id, name, abbreviatedName, login, password}, key) =>{
        return(
            <div className={`moderator__leaders-staf-line d-flex align-center justify-between moderator__scroll-leaders-staf-line-${id}`} key={key}>
                <div className="moderator__leaders-staf-person" title={name}>{abbreviatedName}</div>
                <div className="moderator__leaders-staf-login">{login}</div>
                <div className="moderator__leaders-staf-password">{password}</div>
            </div>
        )
    }

	return (
        <React.Fragment>
            <div className="moderator__leaders-staf">
                <div className="moderator__leaders-staf-title">Список работников</div>
                <div className='moderator__leaders-staf-table-scroll'>
                    <div className="moderator__leaders-staf-line title d-flex align-center justify-between">
                        <div className="moderator__leaders-staf-person">ФИО</div>
                        <div className="moderator__leaders-staf-login">Логин</div>
                        <div className="moderator__leaders-staf-password">Пароль</div>
                    </div>
                    <div className="moderator__leaders-staf-table">
                        {
                            moderatorLeadersStaf.map((item, key) => addTableItem(item, key))
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>        
	)
}

export default LeadersStafTable;