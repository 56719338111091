import React, {useEffect} from 'react';
import {useSelector } from 'react-redux';
import {Owner, Moderator, Autorization, FranchisesLeaders, Staff, WorkZone, Warehouse} from './components/';
import { getLanguage } from './languages/getLanguage';


const App = () =>{
    const {role} = useSelector(({navigation})=> {
        return {
            role: navigation.role,
        }
    })
    // console.log(lang)

    useEffect(()=> {
        if(role !== '') {
            getLanguage('eng', role)
            const  Check = async () => {
                const response = await fetch("/Login/CheckLogin", {
                    method: "Get",
                    headers: { 
                        "Accept": "application/json", 
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + (localStorage.getItem('save-autorize')  === 'true'? localStorage.getItem('token'): sessionStorage.getItem('token'))
                    },
                });
                if (response.ok === true) {
                    // получаем данные
                    const data = await response.json();
                    if(localStorage.getItem('save-autorization')){
                        localStorage.setItem('role', data.value)
                    }
                    else{
                        sessionStorage.setItem('role', data.value)
                    }
                   
                }
                else{
                    localStorage.setItem('role', '')
                    sessionStorage.setItem('role', '')
                }
        
            }
            Check()
        }
    }, [role])
          
    

    
    
    return(
        <React.Fragment>
            {
                (role === 'moderator')? <Moderator/>
                :(role === 'leader')? <FranchisesLeaders/>
                :(role === 'Owner')? <Owner/>                
                :(role === 'worker')? <Staff/>
                :(role === 'workzone')? <WorkZone/>
                :(role === 'warehouse')? <Warehouse/>
                : <Autorization/>
            }
        </React.Fragment>
    )
}

export default App;