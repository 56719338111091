const initialState = {
    moderatorComplaintsCheck: {
      id: 0, 
      point: "",
      address: "", 
      lekalo: "", 
      complaints: "", 
      statusColor: "",
      status: "",
      date: "",
      key: ''
    },
    moderatorCatalogLekaloCheck:{
      id: 7,
      name: null,
      type: 'lekalo',
      lekaloWidth: 0,
      lekaloHeight: 0
    },
    moderatorLeaderCheck:{
      id: (localStorage.getItem("moderatorLeaderCheckId") !== null)? localStorage.getItem("moderatorLeaderCheckId") : 1, 
      name: (localStorage.getItem("moderatorLeaderCheckName") !== null)? localStorage.getItem("moderatorLeaderCheckName") : "", 
      abbreviatedName: "____________", 
      login: "____________", 
      password: "____________", 
      phone: "_____________", 
      mail: "_____________"
    },
    moderatorLeaderEditCheck:{
      id: '', 
      name: '',
      login: "", 
      password: "", 
      phone: "", 
      mail: "",
      image: '', 
      address: ''
    },

    moderatorPointEditCheck:{
      id: '', 
      name: '',
      login: "", 
      password: "", 
      address: '',
      licensedKey: '',
      position: ''
    },

    moderatorDeliteCheck:{
      id: '', 
      name: '',
      type: '',
    },

    moderatorFolderDeliteLoadingCheckId: (sessionStorage.getItem('ModeratorFolderDeleteLoadingCheckId') !== null)? JSON.parse(sessionStorage.getItem('ModeratorFolderDeleteLoadingCheckId')) :[],

    moderatorFolderCheck: (sessionStorage.getItem('ModeratorCheckFolderId') !== null)? +sessionStorage.getItem('ModeratorCheckFolderId') :0,
    moderatorArkhiveLoadingFolderCheck: (sessionStorage.getItem('ModeratorArhiveLoadingFolderCheckId') !== null)? +sessionStorage.getItem('ModeratorArhiveLoadingFolderCheckId') :0,


};
    
    const moderatorCheckData = (state = initialState, action) => {
      if (action.type === 'SET_MODERATOR_CATALOG_LEKALO_CHECK') {
        return {
          ...state,
          moderatorCatalogLekaloCheck: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_FOLDER_CHECK') {
        return {
          ...state,
          moderatorFolderCheck: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_ARKHIVE_LOADING_FOLDER_CHECK') {
        return {
          ...state,
          moderatorArkhiveLoadingFolderCheck: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_COMPLAINTS_CHECK') {
        return {
          ...state,
          moderatorComplaintsCheck: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_LEADER_CHECK') {
        return {
          ...state,
          moderatorLeaderCheck: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_LEADER_EDIT_CHECK') {
        return {
          ...state,
          moderatorLeaderEditCheck: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_POINT_EDIT_CHECK') {
        return {
          ...state,
          moderatorPointEditCheck: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_FOLDER_DELITE_LOADING_CHECK_ID') {
        sessionStorage.setItem('ModeratorFolderDeleteLoadingCheckId', JSON.stringify([...state.moderatorFolderDeliteLoadingCheckId, action.payload]))
        return {
          ...state,
          moderatorFolderDeliteLoadingCheckId: [...state.moderatorFolderDeliteLoadingCheckId, action.payload],
        };
      }

      else if (action.type === 'SET_MODERATOR_FOLDER_DELITE_LOADING_CHECK_ID_DELETE') {
        let data = state.moderatorFolderDeliteLoadingCheckId.slice();
        let index = data.indexOf(action.payload)
        if(index !== -1){
          data.splice(index, 1)
          sessionStorage.setItem('ModeratorFolderDeleteLoadingCheckId', JSON.stringify(data))
          return {
            ...state,
            moderatorFolderDeliteLoadingCheckId: data,
          };
        }
      }
      else if (action.type === 'SET_MODERATOR_DELITE_CHECK') {
        return {
          ...state,
          moderatorDeliteCheck: action.payload,
        };
      }
      else if (action.type === 'SET_MODERATOR_ARHIVE_LOAD_FOLDER_CHECK') {
        return {
          ...state,
          moderatorArhiveLoadFolderCheck: action.payload,
        };
      }
      return state;
    };
    
    export default moderatorCheckData;