const initialState = {
    franchiserHomeSalesIncomeGraff: [
      {
        name: "1",
        value: 0
      },
      {
        name: "2",
        value: 0
      },
      {
        name: "3",
        value: 0
      },
      {
        name: ".4.",
        value: 0
      },
      {
        name: ".5.",
        value: 0
      },
      {
        name: ".6.",
        value: 0
      }
    ],
    franchiserHomeSalesIncomeButtons: {
      income: {
        val: 0,
        percent: 0,
        class: 'green'
      },
      sales: {
        val: 0,
        percent: 0,
        class: 'green'
      }
    },
    franchiserHomePointsList: [
    ],
    franchiserPointsPrinting:[
    ],
    franchiserStaffData:[
    ],
    franchiserStaffButtonsData:{
        salary: {
            value: 0,
            time: 'За всё время'
        },
        staff: {
            value: 0,
            layoffs: '0'
        },
    },
    franchiserStaffDetailStaffData:{
      id: 0, 
      name: '',
      fullName: '',
      phone: '', 
      login: '',
      password: '',
      position: 'Монтажник'
    },
    franchiserStaffDetail:[
    ],
    franchiserWarehouseMoldList: [
    ],
    franchiserWarehousePoints: [
    ],
    franchiserWarehousePointsMold: [
    ],
    franchiserSalariesDate:[
    ],
    franchiserSalariesData: [
    ]

};
    
    const moderatorData = (state = initialState, action) => {
      if (action.type === 'SET_FRANCHISER_HOME_SALES_INCOME') {
        return {
          ...state,
          franchiserHomeSalesIncome: action.payload,
        };
      }
	  else if (action.type === 'SET_FRANCHISER_HOME_INCOME_GRAFF') {
        return {
          ...state,
          franchiserHomeSalesIncomeGraff: action.payload,
        };
      }
	  else if (action.type === 'SET_FRANCHISER_HOME_INCOME_BUTTONS') {
        return {
          ...state,
          franchiserHomeSalesIncomeButtons: action.payload,
        };
      }
      else if (action.type === 'SET_FRANCHISER_HOME_POINTS_LIST') {
        return {
          ...state,
          franchiserHomePointsList: action.payload,
        };
      }
	  else if (action.type === 'SET_FRANCHISER_STAFF_LIST_ADD') {
        return {
          ...state,
          franchiserStaffData: [...state.franchiserStaffData, action.payload],
        };
      }
      else if (action.type === 'SET_FRANCHISER_POINTS_PRINTING') {
        return {
          ...state,
          franchiserPointsPrinting: action.payload,
        };
      }
      else if (action.type === 'SET_FRANCHISER_STAFF_BUTTONS_DATA') {
        return {
          ...state,
          franchiserStaffButtonsData: action.payload,
        };
      }
      else if (action.type === 'SET_FRANCHISER_STAFF_DATA') {
        return {
          ...state,
          franchiserStaffData: action.payload,
        };
      }
	  else if (action.type === 'SET_FRANCHISER_STAFF_DETAIL_STAFF_DATA') {
        return {
          ...state,
          franchiserStaffDetailStaffData: action.payload,
        };
      }
      else if (action.type === 'SET_FRANCHISER_STAFF_DETAIL') {
        return {
          ...state,
          franchiserStaffDetail: action.payload,
        };
      }
      else if (action.type === 'SET_FRANCHISER_WAREHOUSE_MOLD_LIST') {
        return {
          ...state,
          franchiserWarehouseMoldList: action.payload,
        };
      }

      else if (action.type === 'SET_FRANCHISER_WAREHOUSE_MOLD_LIST_EDIT') {
        let data = state.franchiserWarehouseMoldList.slice();
        let index = data.findIndex(x => x.id === action.payload.id)
        if(index !== -1){
          data[index].amount = action.payload.amount
          return {
            ...state,
            franchiserWarehouseMoldList: data,
          };
        }
      }
      else if (action.type === 'SET_FRANCHISER_POINTS') {
        return {
          ...state,
          franchiserWarehousePoints: action.payload,
        };
      }
      else if (action.type === 'SET_FRANCHISER_POINTS_MOLD') {
        return {
          ...state,
          franchiserWarehousePointsMold: action.payload,
        };
      }

      else if (action.type === 'SET_FRANCHISER_POINTS_MOLD_EDIT') {
        let data = state.franchiserWarehousePointsMold.slice();
        let index = data.findIndex(x => x.id === action.payload.id)
        if(index !== -1){
          data[index].amount = action.payload.amount
          return {
            ...state,
            franchiserWarehousePointsMold: data,
          };
        }
        
      }
	  else if (action.type === 'SET_FRANCHISER_SALARIES_DATE') {
        return {
          ...state,
          franchiserSalariesDate: action.payload,
        };
      }
	  else if (action.type === 'SET_FRANCHISER_SALARIES_DATA') {
        return {
          ...state,
          franchiserSalariesData: action.payload,
        };
      }
      return state;
    };
    
    export default moderatorData;