const initialState = {
    salariesTableDate: [],
    salariesTableData:[],
    salariesTableTotalData:{
		salary: 0,
		premium: 0,
        fine: 0,
		prepayment: 0,
	},
    salariesButtonsData:{
		salary: 0,
		premium: 0,
	},
    
};
    
    const salariesData = (state = initialState, action) => {
		if(action.type === 'SET_STAFF_TABLE_DATE') {
			return {
			  ...state,
			  salariesTableDate: action.payload,
			};
		  }
		else if(action.type === 'SET_STAFF_TABLE_DATA') {
        return {
          ...state,
          salariesTableData: action.payload,
        };
      }
	  else if (action.type === 'SET_STAFF_TABLE_TOTAL_DATA') {
        return {
          ...state,
          salariesTableTotalData: action.payload,
        };
      }
	  else if (action.type === 'SET_STAFF_BUTTONS_DATA') {
        return {
          ...state,
          salariesButtonsData: action.payload,
        };
      }
      return state;
    };
    
    export default salariesData;