export const setWorkZoneClientsData = (item) => ({
    type: 'SET_WORK_ZONE_CLIENTS_DATA',
    payload: item
});

export const setWorkZoneClientsFullData = (item) => ({
    type: 'SET_WORK_ZONE_CLIENTS_FULL_DATA',
    payload: item
});

export const setWorkZoneHistoryData = (item) => ({
    type: 'SET_WORK_ZONE_HISTORY_DATA',
    payload: item
});

export const setWorkZoneHistoryDataEdit = (item) => ({
    type: 'SET_WORK_ZONE_HISTORY_DATA_EDIT',
    id: item.id,
    payload: item
});

export const setWorkZoneCatalogData = (item) => ({
    type: 'SET_WORK_ZONE_CATALOG_DATA',
    payload: item
});

export const setWorkZoneCatalogPath = (item) => ({
    type: 'SET_WORK_ZONE_CATALOG_PATH',
    payload: item
});

export const setWorkZoneCashbox = (item) => ({
    type: 'SET_WORK_ZONE_CASHBOX',
    payload: item
});

export const setWorkZoneNotification = (item) => ({
    type: 'SET_WORK_ZONE_NOTIFICATION',
    payload: item
});

export const setWorkZoneNotificationAdd = (item) => ({
    type: 'SET_WORK_ZONE_NOTIFICATION_ADD',
    payload: item
});

export const setWorkZonePlottersData = (item) => ({
    type: 'SET_WORK_ZONE_PLOTTERS_DATA',
    payload: item
});


async function post(url, type, data = null){
    let response = await fetch(url, {
        method: type, 
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    })
    if (response.ok === true) {
        const user = await response.json();
        return user
    }
    else{
        localStorage.setItem('role', '')
    }
}

async function postNotData(url, type){
    let response = await fetch(url, {
        method: type, 
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
        },
    })
    if (response.ok === true) {
        const user = await response.json();
        return user
    }
    else{
        return response.json();
    }
}

let monthKey = {
    "Январь": 1,
    "Февраль": 2,
    "Март": 3,
    "Апрель": 4,
    "Май": 5,
    "Июнь": 6,
    "Июль": 7,
    "Август": 8,
    "Сентябрь": 9,
    "Октябрь": 10,
    "Ноябрь": 11,
    "Декабрь": 12
}

export const fetchWorkZoneClientsData = (date) => (dispatch) =>{
    // let data = (date !== null)? `?date='${date.replaceAll('/', '.')}'`: ''
    let data = (date !== null)? `?date=${date}`: ''
    postNotData(`/Place/GetClients${data}`, "GET").then((response)=> {
        // console.log(response)
        dispatch(setWorkZoneClientsFullData((response.value.resultObject === null)? []: response.value.resultObject))
    })

}

export const fetchWorkZoneHistoryData = (date) => (dispatch) =>{
    let data = (date !== null)? `?date=${date}`: ''
    postNotData(`/Place/GetPrintHistory${data}`, "GET").then((response)=> {
        // console.log(response)
        dispatch(setWorkZoneHistoryData((response.value.resultObject === null)? []: response.value.resultObject))
    })

}

export const fetchWorkZoneCatalogData = (id) => (dispatch) =>{
    postNotData(`/Place/GetCatalog?id=${id}`, "GET").then((response)=> {
        dispatch(setWorkZoneCatalogData((response.value.resultObject === null)? []: response.value.resultObject))
    })
    postNotData(`/Place/CatalogPath?id=${id}`, "GET").then((response)=> {
        dispatch(setWorkZoneCatalogPath((response.value.resultObject === null)? []: response.value.resultObject))
    })
    
}

export const fetchWorkZoneCashbox = () => (dispatch) =>{
    postNotData(`/Place/CashBox`, "GET").then((response)=> {
        dispatch(setWorkZoneCashbox((response.value.resultObject === null)? '': response.value.resultObject))
    })    
}

export const fetchWorkZoneNotification = () => (dispatch) =>{
    postNotData(`/Place/Notification`, "GET").then((response)=> {
        dispatch(setWorkZoneNotification((response.value.resultObject === null)? []: response.value.resultObject))
    })    
}

export const fetchWorkZonePlottersData = () => (dispatch) =>{
    postNotData(`/Place/PlotterList`, "GET").then((response)=> {
        dispatch(setWorkZonePlottersData((response.value.resultObject === null)? []: response.value.resultObject))
    })    
}