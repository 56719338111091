import React, {useState, useEffect} from 'react';
// import { Route } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
// import {setOwner} from '../../redux/actions/notification';
// import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import Salaries from './Salaries/Salaries';

// import Warehouse from './Warehouse/Warehouse';
// import LeadersTable from './Leaders/Leaders-table/Leaders-table';
// import LeaderStaf from './Leaders/Leaders-staff/Leader-staf';

import Calendar from './Calendaries/Picker';

 
import './FranchisesLeaders.scss';
import { setPopupOpenBlock, setPopupOpenBlockEvent } from '../../redux/actions/popupBlocks';




const FranchisesLeaders = () => {
	const dispatch = useDispatch()
	const { calendDisplay} = useSelector(({ display }) => display);
	// const [connection, setConnection] = useState()

	const {role, session, openBlock, openBlockEvent, location} = useSelector(({navigation, popupBlocks})=> {
    return {
        role: navigation.role,
        session: navigation.session,
        openBlock: popupBlocks.openBlock,
        openBlockEvent: popupBlocks.openBlockEvent,
        location: navigation.salariesCalendLocation
    }
})

	// let access_token = (localStorage.getItem('save-autorize') === 'true'? localStorage.getItem('token'): sessionStorage.getItem('token'));

  //   const notific = async () =>{
  //       const connection = new HubConnectionBuilder()
  //           .withUrl('https://localhost:7119/chat', {accessTokenFactory: () => access_token, transport: HttpTransportType.WebSockets, skipNegotiation: true})
  //           .configureLogging(LogLevel.Information)
  //           .build();
    
  //           connection.on("NotificationAdd", (notification) => {
  //               dispatch(setOwner(notification))
	// 			try {
	// 				let audio = new Audio('/img/notification-audio.mp3');
  //               	audio.play();
	// 			} catch (error) {
					
	// 			}
                
  //           });           
  //       await connection.start();
	// 	setConnection(connection)

  //   }
    const [calend, setCalend] = useState(true)
    
    // useEffect(() =>{
    //     if(role !== ''){
    //         notific()
    //     }
    // }, [role])

    useEffect(() =>{
      setCalend(false)
      setTimeout(() => {
        setCalend(true)
      }) 
    }, [location])
    
      // const closeConnection = async () => {
      //   try {
      //     await connection.stop();
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }

    const closePopup = (event) =>{
      if(openBlock !== ''){
          if(event.target !== openBlockEvent){
              dispatch(setPopupOpenBlock(''))
              dispatch(setPopupOpenBlockEvent(''))
          }
        }
    }
	return (
		<React.Fragment>
      <div className="franchises-leaders" onClick={(event)=>closePopup(event)}>
        <Sidebar/>
        <Header/>
        <div className="franchises-leaders__wrap">
          <Salaries/>
        </div>
        {
          (calend)? <Calendar  display = {calendDisplay}/>: null
        }      
      </div>
    </React.Fragment>
	)
}

export default FranchisesLeaders;