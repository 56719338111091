export const setWorkZoneCheckClient = (item) => ({
    type: 'SET_WORK_ZONE_CHECK_CLIENT',
    payload: item
});

export const setWorkZoneCheckClientId = (item) => ({
    type: 'SET_WORK_ZONE_CHECK_CLIENT_ID',
    payload: item
});

export const setWorkZoneCheckClientEdit = (item) => ({
    type: 'SET_WORK_ZONE_CHECK_CLIENT_EDIT',
    payload: item
});

export const setWorkZoneCheckClientReset = () => ({
    type: 'SET_WORK_ZONE_CHECK_CLIENT_RESET',
    payload: {
        id: 0,
        model: '', 
        date: '',
        model: '', 
        printType: '',
        phone: '',
        envelope: '',
        envelopeCount: 0,
        staff: '',
        price: 0,   
        guarantee: '',
        comment: '',
        width: 0,
        height: 0,
        withOutFilm: false,
        formalized: false
    }
});

export const setWorkZoneCheckLastStaff = (item) => ({
    type: 'SET_WORK_ZONE_CHECK_LAST_STAFF',
    payload: item
});

export const setWorkZoneCheckLekalo = (item) => ({
    type: 'SET_WORK_ZONE_CHECK_LEKALO',
    payload: item
});

export const setWorkZoneCheckLekaloResize = (item) => ({
    type: 'SET_WORK_ZONE_CHECK_LEKALO_RESIZE',
    payload: item
});

export const setWorkZoneCheckLekaloResizeFull = (item) => ({
    type: 'SET_WORK_ZONE_CHECK_LEKALO_RESIZE_FULL',
    payload: item
});

export const setWorkZonePlotterConfiguration = (item) => ({
    type: 'SET_WORK_ZONE_PLOTTER_CONFIGURATION',
    payload: item
});

export const setWorkZonePopupPrintType = (item) => ({
    type: 'SET_WORK_ZONE_POPUP_PRINT_TYPE',
    payload: item
});

export const setWorkZoneSearch = (item) => ({
    type: 'SET_WORK_ZONE_SEARCH',
    payload: item
});


async function postNotData(url, type){
    let response = await fetch(url, {
        method: type, 
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
        },
    })
    if (response.ok === true) {
        const user = await response.json();
        return user
    }
    let dat = response.json
    return dat 
}


export const fetchWorkZoneCheckClient = (id) => (dispatch) =>{
    postNotData(`/francheaser/MainButtonsForStaffs?id=${id}`, "GET").then((response)=> {
        // console.log(response)
        dispatch(setWorkZoneCheckClient(response.value.resultObject))
    })
}