const initialState = {
    role: (localStorage.getItem('save-autorize')  === 'true' ? localStorage.getItem('role'): (sessionStorage.getItem('role') !== null)? sessionStorage.getItem('role'): ''),
    leadersNav: "leaders",
    ownerNav: (sessionStorage.getItem('ovnerNav') !== null)? sessionStorage.getItem('ovnerNav') :"Home",
    moderatorNav: (sessionStorage.getItem('moderatorNav') !== null)? sessionStorage.getItem('moderatorNav') :'Catalog',
    franchiserNav: (sessionStorage.getItem('franchPage') !== null)? sessionStorage.getItem('franchPage') :'Home',
    workZoneNav: (sessionStorage.getItem('workzonePage') !== null)? sessionStorage.getItem('workzonePage') :'Workspase',
    warehouseNav: (sessionStorage.getItem('warehousePage') !== null)? sessionStorage.getItem('warehousePage') :'Home',
    salariesCalendLocation: 'Table',
    session: 0,
    url: 'https://bswork.pro/',  
    user: (localStorage.getItem('save-autorize')  === 'true' && localStorage.getItem('userData') !== null)? JSON.parse(localStorage.getItem('userData')): (sessionStorage.getItem('userData') !== null)? JSON.parse(sessionStorage.getItem('userData')): {id: 0, name: '', src: ''},
    notificationActive: false 
  };
  
  const navigation = (state = initialState, action) => {
    if (action.type === 'SET_ROLE') {
      return {
        ...state,
        role: action.payload,
      };
    }
    else if (action.type === 'SET_LEADERS_NAVIGATION') {
      return {
        ...state,
        leadersNav: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_NAVIGATION') {
      return {
        ...state,
        ownerNav: action.payload,
      };
    }
    else if (action.type === 'SET_MODERATOR_NAVIGATION') {
      return {
        ...state,
        moderatorNav: action.payload,
      };
    }
    else if (action.type === 'SET_FRANCHISES_NAVIGATION') {
      return {
        ...state,
        franchiserNav: action.payload,
      };
    }
    else if (action.type === 'SET_WORKZONE_NAVIGATION') {
      return {
        ...state,
        workZoneNav: action.payload,
      };
    }
    else if (action.type === 'SET_SESSION') {
      return {
        ...state,
        session: action.payload,
      };
    }
    else if (action.type === 'SET_USER_DATA') {
      return {
        ...state,
        user: action.payload,
      };
    }
    else if (action.type === 'SET_WAREHOUSE_NAVIGATION') {
      return {
        ...state,
        warehouseNav: action.payload,
      };
    }
    else if (action.type === 'SET_SALARIES_CALEND_LOACATION') {
      return {
        ...state,
        salariesCalendLocation: action.payload,
      };
    }
    else if (action.type === 'SET_NOTIFICATION_ACTIVE') {
      return {
        ...state,
        notificationActive: action.payload,
      };
    }
    return state;
  };
  
  export default navigation;