const initialState = {
    ownerHomeButton: "sale",
    ownerHomeCheckLeaders: [],
    ownerHomeCheckPoints: [],
    ownerWarehouseButton: "purchase",
    ownerLeadrersLeaderId: null,
  };
  
  const buttonFilter = (state = initialState, action) => {
    if (action.type === 'SET_OWNER_HOME_BUTTON') {
      return {
        ...state,
        ownerHomeButton: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_HOME_CHECK_LEADERS') {
      return {
        ...state,
        ownerHomeCheckLeaders: [...state.ownerHomeCheckLeaders, action.payload],
      };
    }
    else if (action.type === 'SET_OWNER_HOME_CHECK_LEADERS_DELETE') {
      return {
        ...state,
        ownerHomeCheckLeaders: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_HOME_CHECK_POINTS') {
      return {
        ...state,
        ownerHomeCheckPoints: [...state.ownerHomeCheckPoints, action.payload],
      };
    }
    else if (action.type === 'SET_OWNER_HOME_CHECK_POINTS_DELETE') {
      return {
        ...state,
        ownerHomeCheckPoints: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_WAREHOUSE_BUTTON') {
      return {
        ...state,
        ownerWarehouseButton: action.payload,
      };
    }
    else if (action.type === 'SET_OWNER_LEADERS_LEADER_ID') {
      return {
        ...state,
        ownerLeadrersLeaderId: action.payload,
      };
    }
    return state;
  };
  
  export default buttonFilter;