// import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export const setSiginalConnect = (item) => ({
    type: 'SET_CONNECTION',
    payload: item
});

export const setSiginalConnectReset = () => ({
  type: 'SET_CONNECTION_RESET'
});

  


// export const setConectionFunc = async (connection)  => (dispatch) =>{  
//   dispatch(setConection(connection))  
// }