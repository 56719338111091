import {React, useState} from 'react';
import Calendar from 'react-calendar';
import {useDispatch, useSelector} from 'react-redux';
import {setCalendDisplay} from '../../redux/actions/display';
import {setOwnerHomeRange, setOwnerHomeMonth, setOwnerHomeYear, setOwnerWarehouseGrafRange, setOwnerWarehouseGrafMonth, setOwnerWarehouseGrafYear, setOwnerWarehousePurchaseRange, setOwnerWarehousePurchaseMonth, setOwnerWarehousePurchaseYear} from '../../redux/actions/popupFilters';
import "./Picker.scss";

function Picker({display}){
    const [calendActive, setCalendActive]  = useState(false);
    const [monthActive, setMonthActive]  = useState(false);
    const [yearActive, setYearActive]  = useState(false);
    const dispatch = useDispatch();
    const {location, calendDisplay} = useSelector(({display})=> {
        return {
            location: display.location,
            calendDisplay: display.calendDisplay
        }
    })

    const {month, year, range} = useSelector(({popupFilters})=> {
        if(location === "ownerHome"){
            return{
                month: popupFilters.ownerHomeMonth,
                year: popupFilters.ownerHomeYear,
                range: popupFilters.ownerHomeRange,
            }
        }
        else if(location === "ownerWarehouseGraf"){
            return{
                month: popupFilters.ownerWarehouseGrafMonth,
                year: popupFilters.ownerWarehouseGrafYear,
                range: popupFilters.ownerWarehouseGrafRange,
            }
        }
        else if(location === "ownerWarehousePurchase"){
            return{
                month: popupFilters.ownerWarehousePurchaseMonth,
                year: popupFilters.ownerWarehousePurchaseYear,
                range: popupFilters.ownerWarehousePurchaseRange,
            }
        }
        else{
            return{
                month: "Месяц",
                year: "Год",
                range: [],
            }
        }
    })

    const [selRange, setSelRange] = useState(range)

    if(display === 'open'){
        document.body.style.overflow = 'hidden'
    }
    else{
        document.body.style.overflow = 'auto'
    }

    let dataMonth = [
        {
            id: 0,
            value: "Январь"
        },
        {
            id: 1,
            value: "Февраль"
        },
        {
            id: 2,
            value: "Март"
        },
        {
            id: 3,
            value: "Апрель"
        },
        {
            id: 4,
            value: "Май"
        },
        {
            id: 5,
            value: "Июнь"
        },
        {
            id: 6,
            value: "Июль"
        },
        {
            id: 7,
            value: "Август"
        },
        {
            id: 8,
            value: "Сентябрь"
        },
        {
            id: 9,
            value: "Октябрь"
        },
        {
            id: 10,
            value: "Ноябрь"
        },
        {
            id: 11,
            value: "Декабрь"
        }
    ];
    let defaultYear = new Date().getFullYear()
    let dataYear = [
        {
            id: 0,
            value: defaultYear - 5
        },
        {
            id: 1,
            value: defaultYear - 4
        },
        {
            id: 2,
            value: defaultYear - 3
        },
        {
            id: 3,
            value: defaultYear - 2
        },
        {
            id: 4,
            value: defaultYear - 1
        },
        {
            id: 5,
            value: defaultYear
        },
        {
            id: 6,
            value: defaultYear + 1
        },
        {
            id: 7,
            value: defaultYear + 2
        },
        {
            id: 8,
            value: defaultYear + 3
        },
        {
            id: 9,
            value: defaultYear + 4
        },
        {
            id: 10,
            value: defaultYear + 5
        }
    ]

    let onChange = date => {
        setSelRange(date)    
    }
    let reset = (bool = true) => {
        if(bool){
            dispatch(setCalendDisplay(""));
            setTimeout(()=>{
                dispatch(setCalendDisplay("close"))
            }, 0)
        }
        if(location === "ownerHome"){
            dispatch(setOwnerHomeMonth("Месяц"))
            dispatch(setOwnerHomeYear("Год"))
            dispatch(setOwnerHomeRange([]))
            sessionStorage.setItem('OwnerHomePopupFilters', JSON.stringify({month: "Месяц", year: "Год", range: []}))
            
        }
        else if(location === "ownerWarehouseGraf"){
            dispatch(setOwnerWarehouseGrafMonth("Месяц"))
            dispatch(setOwnerWarehouseGrafYear("Год"))
            dispatch(setOwnerWarehouseGrafRange([]))
            sessionStorage.setItem('OwnerWarehouseGrafRange', JSON.stringify({month: "Месяц", year: "Год", range: []}))
        }
        else if(location === "ownerWarehousePurchase"){
            dispatch(setOwnerWarehousePurchaseMonth("Месяц"))
            dispatch(setOwnerWarehousePurchaseYear("Год"))
            dispatch(setOwnerWarehousePurchaseRange([]))
            sessionStorage.setItem('OwnerWarehousePurchaseRange', JSON.stringify({month: "Месяц", year: "Год", range: []}))
        }
        setSelRange([])
    }
    const monthActivate = () =>{
        if(!calendActive){
            setMonthActive(!monthActive)
            setYearActive(false)
        }
    }
    const yearActivate = () =>{
        if(!calendActive){
            setYearActive(!yearActive)
            setMonthActive(false)
        }
    }
    const monthChange = (id) =>{
        setMonthActive(false)
        if(location === "ownerHome"){
            dispatch(setOwnerHomeMonth(dataMonth[id].value))
            sessionStorage.setItem('OwnerHomePopupFilters', JSON.stringify({month: dataMonth[id].value, year: year, range: []}))
        }
        else if(location === "ownerWarehouseGraf"){
            dispatch(setOwnerWarehouseGrafMonth(dataMonth[id].value))
            sessionStorage.setItem('OwnerWarehouseGrafRange', JSON.stringify({month: dataMonth[id].value, year: year, range: []}))
        }
        else if(location === "ownerWarehousePurchase"){
            dispatch(setOwnerWarehousePurchaseMonth(dataMonth[id].value))
            sessionStorage.setItem('OwnerWarehousePurchaseRange', JSON.stringify({month: dataMonth[id].value, year: year, range: []}))
        }
    }
    const yearChange = (id) =>{
        setYearActive(false)
        if(location === "ownerHome"){
            dispatch(setOwnerHomeYear(dataYear[id].value))
            sessionStorage.setItem('OwnerHomePopupFilters', JSON.stringify({month: month, year: dataYear[id].value, range: []}))
        }
        else if(location === "ownerWarehouseGraf"){
            dispatch(setOwnerWarehouseGrafYear(dataYear[id].value))
            sessionStorage.setItem('OwnerWarehouseGrafRange', JSON.stringify({month: month, year: dataYear[id].value, range: []}))
        }
        else if(location === "ownerWarehousePurchase"){
            dispatch(setOwnerWarehousePurchaseYear(dataYear[id].value))
            sessionStorage.setItem('OwnerWarehousePurchaseRange', JSON.stringify({month: month, year: dataYear[id].value, range: []}))
        }
    }

    const addMonth = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(month === value)? "active": ""}`} key={key} onClick={() => {monthChange(id)}}>{value}</div>
        )
    }
    const addYear = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(year === value)? "active": ""}`} key={key} onClick={() => {yearChange(id)}}>{value}</div>
        )
    }

    return (
        <div className={`filters__container justify-center align-center ${display}`}>
            <div className='d-flex justify-center align-center'>
                <div className='filters'>
                    <div className='filters__top d-flex justify-between align-center'>
                        <div className="filters__title">Фильтры</div>
                        <div className="filters__close-btn" onClick={() =>{
                                dispatch(setCalendDisplay(""));
                                setTimeout(()=>{
                                    dispatch(setCalendDisplay("close"))
                                }, 0)
                            }
                        }>
                        </div>                
                    </div>
                    <div className='filters__scroll'>
                        <div className='filters__block' >
                            <div className="filters__block-item year">
                                <div className={`filters__block-item-title d-flex align-center justify-center year ${(year === "Год")? "": "active"} ${(yearActive)? "open": ""}`} onClick={yearActivate}>{year}<div className="filters__block-item-title-icon"/></div>                        
                                <div className={`filters__block-item-list ${(yearActive)? "open": ""}`}>
                                    <div className='filters__block-item-list-scroll'>
                                        {
                                            dataYear.map((item, key) => addYear(item, key))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="filters__block-item months">
                                <div className={`filters__block-item-title d-flex align-center justify-center months ${(month === "Месяц")? "": "active"} ${(monthActive)? "open": ""}`} onClick={monthActivate}>{month}<div className="filters__block-item-title-icon"/></div>                        
                                <div className={`filters__block-item-list ${(monthActive)? "open": ""}`}>
                                    <div className='filters__block-item-list-scroll'>
                                        {
                                            dataMonth.map((item, key) => addMonth(item, key))
                                        }                               
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`filters__range align-center ${(calendActive)? "active": ""}`} onClick={()=>{
                                setCalendActive(!calendActive)
                                reset(false)
                            }}>
                            <div className='filters__range-checkbox'></div>
                            <div className='filters__range-title'>По периоду</div>
                        </div>
                        <div className={`filters__calendar-container ${(calendActive || window.innerWidth > 1500)? "": "off"}`}>
                            {
                                (calendDisplay === 'open')? <Calendar onChange={onChange} selectRange={true}/>: null
                            }
                            <div className='react-calendar__range d-flex justify-between'>
                                <div className='react-calendar__range-item start'>
                                    <div className='react-calendar__range-label'>С</div>
                                    <div className='react-calendar__range-value'>{(selRange.length > 0)? selRange[0].toLocaleDateString(): "ДД.ММ.ГГГГ"}</div>
                                </div>
                                <div className='react-calendar__range-item end'>
                                    <div className='react-calendar__range-label'>До</div>
                                    <div className='react-calendar__range-value'>{(selRange.length === 2)? selRange[1].toLocaleDateString(): "ДД.ММ.ГГГГ"}</div>
                                </div>
                            </div>                    
                        </div>
                    </div>
                    <div className='filters__buttons d-flex'>
                        <div className='filters__buttons-item reset' onClick={reset} >Сбросить</div>
                        <div className='filters__buttons-item accept' onClick={()=>{
                            dispatch(setCalendDisplay(""));
                            setTimeout(()=>{
                                dispatch(setCalendDisplay("close"))
                            }, 0)
                            if(location === "ownerHome"){
                                dispatch(setOwnerHomeMonth("Месяц"))
                                dispatch(setOwnerHomeYear("Год"))
                                dispatch(setOwnerHomeRange(selRange))
                                sessionStorage.setItem('OwnerHomePopupFilters', JSON.stringify({month: "Месяц", year: "Год", range: selRange}))
                            }
                            else if(location === "ownerWarehouseGraf"){
                                dispatch(setOwnerWarehouseGrafMonth("Месяц"))
                                dispatch(setOwnerWarehouseGrafYear("Год"))
                                dispatch(setOwnerWarehouseGrafRange(selRange))
                                sessionStorage.setItem('OwnerWarehouseGrafRange', JSON.stringify({month: "Месяц", year: "Год", range: selRange}))
                            }
                            else if(location === "ownerWarehousePurchase"){
                                dispatch(setOwnerWarehousePurchaseMonth("Месяц"))
                                dispatch(setOwnerWarehousePurchaseYear("Год"))
                                dispatch(setOwnerWarehousePurchaseRange(selRange))
                                sessionStorage.setItem('OwnerWarehousePurchaseRange', JSON.stringify({month: "Месяц", year: "Год", range: selRange}))
                            }
                                            
                        }}>Принять</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Picker;
