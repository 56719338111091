import { React, useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import {useDispatch, useSelector} from 'react-redux';
import {setCalendDisplay} from '../../../redux/actions/display';
import {setFranchiserHomeMonth, setFranchiserHomeYear, setFranchiserHomeRange, setFranchiserPointsPrintingMonth, setFranchiserPointsPrintingYear, setFranchiserPointsPrintingRange, setFranchiserStaffMonth, setFranchiserStaffYear, setFranchiserStaffRange, setFranchiserSalariesMonth, setFranchiserSalariesYear, setFranchiserSalariesRange, setFranchiserSalariesRangeActive} from '../../../redux/actions/popupFilters';
import "./Picker.scss";
import { setFranchiserSalariesRangeTypeSelect } from './../../../redux/actions/popupFilters';

function Picker({display}){
    const dispatch = useDispatch();

    const {location, calendDisplay} = useSelector(({display})=> {
        return {
            location: display.location,
            calendDisplay: display.calendDisplay
        }
    })

    const {month, year, range} = useSelector(({popupFilters})=> {
        if(location === "franchiserHome"){
            return{
                month:  popupFilters.franchiserHomeMonth,
                year:  popupFilters.franchiserHomeYear,
                range: popupFilters.franchiserHomeRange,
            }
        }
        else if(location === "franchiserPointsPrinting"){
            return{
                month: popupFilters.franchiserPointPrintingMonth,
                year: popupFilters.franchiserPointPrintingYear,
                range: popupFilters.franchiserPointPrintingRange,
            }
        }
        else if(location === "franchiserStaff"){
            return{
                month: popupFilters.franchiserStaffMonth,
                year: popupFilters.franchiserStaffYear,
                range: popupFilters.franchiserStaffRange,
            }
        }
        else if(location === "franchiserSalaries"){
            return{
                month: popupFilters.franchiserSalariesMonth,
                year: popupFilters.franchiserSalariesYear,
                range: popupFilters.franchiserSalariesRange,
            }
        }
        else{
            return{
                month: "Месяц",
                year: "Год",
                range: []
            }
        }
    })

    const [calendActive, setCalendActive]  = useState(range.length !== 0);
    const [monthActive, setMonthActive]  = useState(false);
    const [yearActive, setYearActive]  = useState(false);
    const [selRange, setSelRange] = useState([])

    const [yearActiveValue, setYearActiveValue]  = useState(year);
    const [monthActiveValue, setMonthActiveValue] = useState(month)


    if(display === 'open'){
        document.body.style.overflow = 'hidden'
    }
    else{
        document.body.style.overflow = 'auto'
    }

    let dataMonth = [
        {
            id: 0,
            value: "Январь"
        },
        {
            id: 1,
            value: "Февраль"
        },
        {
            id: 2,
            value: "Март"
        },
        {
            id: 3,
            value: "Апрель"
        },
        {
            id: 4,
            value: "Май"
        },
        {
            id: 5,
            value: "Июнь"
        },
        {
            id: 6,
            value: "Июль"
        },
        {
            id: 7,
            value: "Август"
        },
        {
            id: 8,
            value: "Сентябрь"
        },
        {
            id: 9,
            value: "Октябрь"
        },
        {
            id: 10,
            value: "Ноябрь"
        },
        {
            id: 11,
            value: "Декабрь"
        }
    ];
    let defaultYear = new Date().getFullYear()
    let dataYear = [
        {
            id: 0,
            value: defaultYear - 5
        },
        {
            id: 1,
            value: defaultYear - 4
        },
        {
            id: 2,
            value: defaultYear - 3
        },
        {
            id: 3,
            value: defaultYear - 2
        },
        {
            id: 4,
            value: defaultYear - 1
        },
        {
            id: 5,
            value: defaultYear
        },
        {
            id: 6,
            value: defaultYear + 1
        },
        {
            id: 7,
            value: defaultYear + 2
        },
        {
            id: 8,
            value: defaultYear + 3
        },
        {
            id: 9,
            value: defaultYear + 4
        },
        {
            id: 10,
            value: defaultYear + 5
        }
    ]

    let onChange = date => {
        setSelRange(date)    
    }

    let reset = () => {
        // dispatch(setCalendDisplay(""));
        // dispatch(setCalendDisplay("open"));
        setSelRange([])  
        if(location === "franchiserHome"){
            dispatch(setFranchiserHomeMonth("Месяц"))
            dispatch(setFranchiserHomeYear("Год"))
            dispatch(setFranchiserHomeRange([]))
            sessionStorage.setItem('franchiserHomePopupFilters', JSON.stringify({month: "Месяц", year: "Год", range: []}))
        }
        else if(location === "franchiserPointsPrinting"){
            dispatch(setFranchiserPointsPrintingMonth("Месяц"))
            dispatch(setFranchiserPointsPrintingYear("Год"))
            dispatch(setFranchiserPointsPrintingRange([]))
            sessionStorage.setItem('franchiserPointsPrintingPopupFilters', JSON.stringify({month: "Месяц", year: "Год", range: []}))
        }
        else if(location === "franchiserStaff"){
            dispatch(setFranchiserStaffMonth("Месяц"))
            dispatch(setFranchiserStaffYear("Год"))
            dispatch(setFranchiserStaffRange([]))
            sessionStorage.setItem('franchiserStaffPopupFilters', JSON.stringify({month: "Месяц", year: "Год", range: []}))
        }
        else if(location === "franchiserSalaries"){
            setMonthActiveValue("Месяц")
            setYearActiveValue("Год")
            dispatch(setFranchiserSalariesMonth("Месяц"))
            dispatch(setFranchiserSalariesYear("Год"))
            let to = new Date(new Date().toLocaleDateString().split('.')[2], new Date().toLocaleDateString().split('.')[1], 1)
            to.setDate(to.getDate() - 1)
            dispatch(setFranchiserSalariesRange([new Date(new Date().toLocaleDateString().split('.')[2], new Date().toLocaleDateString().split('.')[1] - 1, 1), to]))
            sessionStorage.setItem('franchiserSalariesPopupFilters', JSON.stringify({month: "Месяц", year: "Год", range: [new Date(new Date().toLocaleDateString().split('.')[2], new Date().toLocaleDateString().split('.')[1] - 1, 1), to]}))
            dispatch(setFranchiserSalariesRangeTypeSelect(({id: 3, value: 'Месяц'})))
            sessionStorage.setItem('franchiserSalariesPopupFiltersPeriodListActive', JSON.stringify({id: 3, value: 'Месяц'}))
        }        
    }
    const monthActivate = () =>{
        if(!calendActive){
            setMonthActive(!monthActive)
            setYearActive(false)
        }
    }
    const yearActivate = () =>{
        if(!calendActive){
            setYearActive(!yearActive)
            setMonthActive(false)
        }
    }
    const monthChange = (id) =>{
        setMonthActive(false)
        if(location === "franchiserHome"){
            dispatch(setFranchiserHomeMonth(dataMonth[id].value))
            sessionStorage.setItem('franchiserHomePopupFilters', JSON.stringify({month: dataMonth[id].value, year: year, range: []}))
        }
        else if(location === "franchiserPointsPrinting"){
            dispatch(setFranchiserPointsPrintingMonth(dataMonth[id].value))
            sessionStorage.setItem('franchiserPointsPrintingPopupFilters', JSON.stringify({month: dataMonth[id].value, year: year, range: []}))
        }
        else if(location === "franchiserStaff"){
            dispatch(setFranchiserStaffMonth(dataMonth[id].value))
            sessionStorage.setItem('franchiserStaffPopupFilters', JSON.stringify({month: dataMonth[id].value, year: year, range: []}))

        }
        else if(location === "franchiserSalaries"){
            // dispatch(setFranchiserSalariesMonth(dataMonth[id].value))
            setMonthActiveValue(dataMonth[id].value)
        }
    }
    const yearChange = (id) =>{
        setYearActive(false)
        if(location === "franchiserHome"){
            dispatch(setFranchiserHomeYear(dataYear[id].value))
            sessionStorage.setItem('franchiserHomePopupFilters', JSON.stringify({month: month, year: dataYear[id].value, range: []}))
        }
        else if(location === "franchiserPointsPrinting"){
            dispatch(setFranchiserPointsPrintingYear(dataYear[id].value))
            sessionStorage.setItem('franchiserPointsPrintingPopupFilters', JSON.stringify({month: month, year: dataYear[id].value, range: []}))
        }
        else if(location === "franchiserStaff"){
            dispatch(setFranchiserStaffYear(dataYear[id].value))
            sessionStorage.setItem('franchiserStaffPopupFilters', JSON.stringify({month: month, year: dataYear[id].value, range: []}))
        }
        else if(location === "franchiserSalaries"){
            setYearActiveValue(dataYear[id].value)
            // dispatch(setFranchiserSalariesYear(dataYear[id].value))
        }
    }

    const addMonth = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(month === value)? "active": ""}`} key={key} onClick={() => {monthChange(id)}}>{value}</div>
        )
    }
    const addYear = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(year === value)? "active": ""}`} key={key} onClick={() => {yearChange(id)}}>{value}</div>
        )
    }
    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target){
            dispatch(setCalendDisplay(""));
            setTimeout(()=>{
                dispatch(setCalendDisplay("close"))
                setMonthActiveValue(month)
                setYearActiveValue(year)
            }, 0)

        }
    }
    return (
        <div className={`filters__container justify-center align-center ${display}`} onClick={(event)=> ClosePopup(event)}>
            <div className='filters'>
                <div className='filters__top d-flex justify-between align-center'>
                    <div className="filters__title">Фильтры</div>
                    <div className="filters__close-btn" onClick={() =>{
                            dispatch(setCalendDisplay(""));
                            setTimeout(()=>{
                                dispatch(setCalendDisplay("close"))
                            }, 0)
                        }
                    }>
                    </div>                
                </div>
                <div className='filters__scroll'>
                    <div className={`filters__block`}>
                        <div className="filters__block-item year">
                            <div className={`filters__block-item-title d-flex align-center justify-center year ${(location === 'franchiserSalaries' && yearActiveValue === "Год" || year  === "Год")? "": "active-"} ${(yearActive)? "open": ""}`} onClick={yearActivate}>{(location === 'franchiserSalaries')? yearActiveValue: year}<div className="filters__block-item-title-icon"/></div>                        
                            <div className={`filters__block-item-list ${(yearActive)? "open": ""}`}>
                                <div className='filters__block-item-list-scroll'>
                                    {
                                        dataYear.map((item, key) => addYear(item, key))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="filters__block-item months">
                            <div className={`filters__block-item-title d-flex align-center justify-center months ${(location === 'franchiserSalaries' && monthActiveValue === "Месяц" || month === "Месяц")? "": "active-"} ${(monthActive)? "open": ""}`} onClick={monthActivate}>{(location === 'franchiserSalaries')? monthActiveValue: month}<div className="filters__block-item-title-icon"/></div>                        
                            <div className={`filters__block-item-list ${(monthActive)? "open": ""}`}>
                                <div className='filters__block-item-list-scroll'>
                                    {
                                        dataMonth.map((item, key) => addMonth(item, key))
                                    }                               
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`filters__range align-center ${(calendActive)? "active": ""}`} onClick={()=>{
                            setCalendActive(!calendActive)
                            reset()
                        }}>
                        <div className='filters__range-checkbox'></div>
                        <div className='filters__range-title'>По периоду</div>
                    </div>
                    <div className={`filters__calendar-container ${(calendActive || window.innerWidth > 1500)? "": "off"}`}>
                        {
                            (calendDisplay === 'open')? <Calendar onChange={onChange} selectRange={true}/>: null
                        }
                        <div className='react-calendar__range d-flex justify-between'>
                            <div className='react-calendar__range-item start'>
                                <div className='react-calendar__range-label'>С</div>
                                <div className='react-calendar__range-value'>{(range.length !== 0 && selRange.length === 0)? range[0].toLocaleDateString() :(selRange.length > 0)? selRange[0].toLocaleDateString(): "ДД.ММ.ГГГГ"}</div>
                            </div>
                            <div className='react-calendar__range-item end'>
                                <div className='react-calendar__range-label'>До</div>
                                <div className='react-calendar__range-value'>{(range.length !== 0 && selRange.length === 0)? range[1].toLocaleDateString() :(selRange.length === 2)? selRange[1].toLocaleDateString(): "ДД.ММ.ГГГГ"}</div>
                            </div>
                        </div>                    
                    </div>
                    <div className='filters__buttons d-flex'>
                        <div className='filters__buttons-item reset' onClick={reset} >Сбросить</div>
                        <div className='filters__buttons-item accept' onClick={()=>{
                            if(location === "franchiserHome"){
                                dispatch(setFranchiserHomeMonth("Месяц"))
                                dispatch(setFranchiserHomeYear("Год"))
                                dispatch(setFranchiserHomeRange(selRange))
                                sessionStorage.setItem('franchiserHomePopupFilters', JSON.stringify({month: "Месяц", year: "Год", range: selRange}))
                            }
                            else if(location === "franchiserPointsPrinting"){
                                dispatch(setFranchiserPointsPrintingMonth("Месяц"))
                                dispatch(setFranchiserPointsPrintingYear("Год"))
                                dispatch(setFranchiserPointsPrintingRange(selRange))
                                sessionStorage.setItem('franchiserPointsPrintingPopupFilters', JSON.stringify({month: "Месяц", year: "Год", range: selRange}))
                            }
                            else if(location === "franchiserStaff"){
                                dispatch(setFranchiserStaffMonth("Месяц"))
                                dispatch(setFranchiserStaffYear("Год"))
                                dispatch(setFranchiserStaffRange(selRange))
                                sessionStorage.setItem('franchiserStaffPopupFilters', JSON.stringify({month: "Месяц", year: "Год", range: selRange}))
                            }
                            else if(location === "franchiserSalaries"){
                                if((monthActiveValue === 'Месяц' && yearActiveValue === 'Год' && selRange.length !== 0) || window.innerWidth > 1500 && selRange.length !== 0){
                                    console.log(1, selRange)
                                    dispatch(setFranchiserSalariesRange(selRange))
                                    dispatch(setFranchiserSalariesRangeActive(true))
                                    sessionStorage.setItem('franchiserSalariesPopupFilters', JSON.stringify({month: "Месяц", year: "Год", range: selRange, rangeActive: true}))
                                }                                
                                else if(window.innerWidth < 1500 && !calendActive){
                                    console.log(2)
                                    let from,
                                        to; 
                                    if(monthActiveValue !== 'Месяц'){
                                        console.log(2.1)
                                        let monthId = dataMonth.findIndex(x => x.value === monthActiveValue)
                                        from = new Date((yearActiveValue === 'Год')? new Date().getFullYear(): yearActiveValue, monthId, 1);
                                        to = new Date((yearActiveValue === 'Год')? new Date().getFullYear(): yearActiveValue, monthId+1, 1);
                                        to.setDate(to.getDate() - 1)
                                        dispatch(setFranchiserSalariesRangeTypeSelect(({id: 3, value: 'Месяц'})))
                                        sessionStorage.setItem('franchiserSalariesPopupFiltersPeriodListActive', JSON.stringify({id: 3, value: 'Месяц'}))
                                    }
                                    else{
                                        console.log(2.2)
                                        from = new Date((yearActiveValue === 'Год')? new Date().getFullYear(): +yearActiveValue, 0, 1);
                                        to = new Date((yearActiveValue === 'Год')? new Date().getFullYear() + 1: +yearActiveValue + 1, 0, 1);
                                        to.setDate(to.getDate() - 1)
                                        dispatch(setFranchiserSalariesRangeTypeSelect(({id: 6, value: 'Год'})))
                                        sessionStorage.setItem('franchiserSalariesPopupFiltersPeriodListActive', JSON.stringify({id: 6, value: 'Год'}))
                                    }
                                    dispatch(setFranchiserSalariesRange([from, to]))                                    
                                    dispatch(setFranchiserSalariesMonth(monthActiveValue))
                                    dispatch(setFranchiserSalariesYear(yearActiveValue))
                                    sessionStorage.setItem('franchiserSalariesPopupFiltersPeriodListActive', JSON.stringify({id: 3, value: 'Месяц'}, {id: 6, value: 'Год'}))
                                    dispatch(setFranchiserSalariesRangeActive(false))
                                    sessionStorage.setItem('franchiserSalariesPopupFilters', JSON.stringify({month: monthActiveValue, year: from.getFullYear(), range: [from, to], rangeActive: false}))
                                }


                                // console.log((month === 'Месяц' && year === 'Год' && selRange.length !== 0) || calendActive, month, year, selRange.length, calendActive)
                                
                            }

                            dispatch(setCalendDisplay(""));
                            setTimeout(()=>{
                                dispatch(setCalendDisplay("close"))
                            }, 0)                                       
                        }}>Принять</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Picker;
