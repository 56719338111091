import React from 'react';
import { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import {setRole, setWorkZoneNav} from '../../../redux/actions/navigation';
import { setPopupBackgroundFunc, setPopupOpenBlock, setPopupOpenBlockEvent} from '../../../redux/actions/popupBlocks';
import { setWorkZoneCheckClientReset, setWorkZoneCheckLekalo, setWorkZonePopupPrintType, setWorkZoneSearch } from '../../../redux/actions/workZoneCheckData';
import { fetchWorkZoneCatalogData } from '../../../redux/actions/workZoneData';

import './Sidebar.scss';

const Sidebar = ({status, connection}) =>{
    const dispatch = useDispatch();
    const [noticationVal, setNoticationVal] = useState('')
    const { openBlock, nav, workZoneCatalogData, workZoneCatalogPath, workZoneSearch, workZoneCheckLekalo, workZoneCheckFolder} = useSelector(({popupBlocks, navigation, workZoneData, workZoneCheckData})=> {
        return {
            openBlock: popupBlocks.openBlock,
            nav: navigation.workZoneNav,
            workZoneCatalogData: workZoneData.workZoneCatalogData,
            workZoneSearch: workZoneCheckData.workZoneSearch,
            workZoneCatalogPath: workZoneData.workZoneCatalogPath,
            workZoneCheckLekalo: workZoneCheckData.workZoneCheckLekalo,
            workZoneCheckFolder: workZoneCheckData.workZoneCheckFolder
        }
    })

    function scrollTo(element, to, duration) {
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;
            
        var animateScroll = function(){        
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    useEffect(()=>{
        if(workZoneSearch.isActive && workZoneSearch.type === 'catalog'){
            // let elem = document.querySelector(`.work-zone__sidebar-catalog-item-${workZoneSearch.id}`);
            // elem.classList.add("active");
            // setTimeout(()=>{
            //     elem.classList.remove("active");
            // }, 3000)
            // scrollTo(document.querySelector('.work-zone__sidebar-catalog-container'), +elem.offsetTop - +document.querySelector('.work-zone__sidebar-catalog-container').offsetTop, 1000)
            // dispatch(setWorkZoneSearch({isActive: false, type: '', id: 0}))
            if(workZoneCatalogPath.length === 0 || workZoneCatalogPath[workZoneCatalogPath.length-1].id !== workZoneSearch.id){
                dispatch(fetchWorkZoneCatalogData(workZoneSearch.id))
                dispatch(setWorkZoneSearch({isActive: false, type: '', id: 0}))
            }
            
        }   
    })

    useEffect(()=>{
        dispatch(fetchWorkZoneCatalogData(workZoneCheckFolder))
    }, [])


    const addNotification = async() =>{
        if(noticationVal !== ''){
            const response =  await fetch(`/Place/setComplaint?title=${noticationVal}`, {
                method: 'GET', 
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                },

            })
            if (response.ok === true) {
                const dat = await response.json();
                // console.log(dat.value.resultObject)                
                dispatch(setPopupBackgroundFunc(false))
                dispatch(setPopupOpenBlock(''))
                await connection.invoke("SendClient", dat.value.resultObject);
                setNoticationVal('')        
            }
        }        
    }

    let AddCatalogItem = ({id, name, type, src, width, height}, key) =>{
        if(type === 'folder'){
            return(
                <div className={`work-zone__sidebar-catalog-item folder d-flex align-center work-zone__sidebar-catalog-item-${id}`} key={key} onClick={()=>{
                    dispatch(fetchWorkZoneCatalogData(id))
                    sessionStorage.setItem('CheckFolderId', id)
                }}>
                    <img src='../../../img/work-zone-folder-icon.svg' alt="" className='work-zone__sidebar-catalog-item-icon folder'/>
                    <div className='work-zone__sidebar-catalog-item-name folder'>{name}</div>
                </div> 
            )
        }
        else{
            return(
                <div className={`work-zone__sidebar-catalog-item lekalo d-flex align-center work-zone__sidebar-catalog-item-${id}`} key={key} onClick={()=>{
                    dispatch(setWorkZoneCheckLekalo({id, src, width, height, name, defaultWidth: width, defaultHeight: height}))
                    // sessionStorage.setItem('WorkzoneCheckLekalo', JSON.stringify({id, src, width, height, name, defaultWidth: width, defaultHeight: height}))
                    sessionStorage.setItem('lekaloCheckHeight', height)
                    sessionStorage.setItem('lekaloCheckWidth', width)
                    sessionStorage.setItem('lekaloCheckDefaultHeight', height)
                    sessionStorage.setItem('lekaloCheckDefaultWidth', width)
                    sessionStorage.setItem('lekaloCheckId', id)
                    sessionStorage.setItem('lekaloCheckName', name)
                    sessionStorage.setItem('lekaloCheckSrc', src)
                    sessionStorage.removeItem('WorkzoneCheckClient')
                    dispatch(setWorkZonePopupPrintType('interact'))
                    dispatch(setWorkZoneCheckClientReset())
                }}>
                    <img src='../../../img/work-zone-lekalo-icon.svg' alt="" className="work-zone__sidebar-catalog-item-icon lekalo" />
                    <div className={`work-zone__sidebar-catalog-item-name ${(workZoneCheckLekalo.id === id)? 'active': ''}`}>{name}</div>
                </div>
            )
        }
        
    }

    let AddCatalogPathItem = ({id, name}, key) =>{
        return(
            <div className="work-zone__sidebar-catalog-path-item" key={key} title={name} onClick={()=>{
                dispatch(fetchWorkZoneCatalogData(id))
                sessionStorage.setItem('CheckFolderId', id)
            }}><span>/</span>{name}</div>
        )
    }

    let AddCatalogPathListElem = ({id, name}, key) =>{
        return(
            <div className='work-zone__sidebar-catalog-path-button-list-block-item d-flex align-center' key={key} onClick={()=>{
                dispatch(setPopupOpenBlock(""))
                dispatch(setPopupOpenBlockEvent(''))
                dispatch(fetchWorkZoneCatalogData(id))
                sessionStorage.setItem('CheckFolderId', id)
            }}>
                <img src='../../../img/work-zone-folder-icon.svg' alt="" className="work-zone__sidebar-catalog-path-button-list-block-item-icon folder" />
                <div className='work-zone__sidebar-catalog-path-button-list-block-item-name' title={name}>{name}</div>
            </div>
        )
    }
	return (
		<aside className={`work-zone__sidebar ${status}`}>
            <div className='work-zone__top-buttons d-flex justify-between align-center'>
                <img src="/img/logo.svg" alt="" className="work-zone__logo"/>
                <Link to='/' onClick={() =>{
                    dispatch(setWorkZoneNav('Workspase'))
                    sessionStorage.setItem('workzonePage', 'Workspase')
                }}>
                    <div className='work-zone__top-buttons-item'>
                        <div className={`work-zone__top-buttons-icon workspase ${(nav === 'Workspase')? 'active': ''}`}></div>
                    </div>
                </Link>
                <Link to='/clients' onClick={() =>{
                    dispatch(setWorkZoneNav('Clients'))
                    sessionStorage.setItem('workzonePage', 'Clients')
                }}>
                    <div className='work-zone__top-buttons-item'>
                        <div className={`work-zone__top-buttons-icon clients ${(nav === 'Clients')? 'active': ''}`}></div>
                    </div>
                </Link>
                <Link to='/history' onClick={() =>{
                    dispatch(setWorkZoneNav('History'))
                    sessionStorage.setItem('workzonePage', 'History')
                }}>
                    <div className='work-zone__top-buttons-item'>
                        <div className={`work-zone__top-buttons-icon history  ${(nav === 'History')? 'active': ''}`}></div>
                    </div>
                </Link>

                <div className='work-zone__top-buttons-item'>
                    <div className={`work-zone__top-buttons-icon suport popup-open-button ${(openBlock === "workZoneSuport")? "active": ""}`} onClick={(event) =>{
                        let bool = (openBlock === "workZoneSuport")? "": "workZoneSuport";
                        dispatch(setPopupOpenBlock(bool))
                        dispatch(setPopupOpenBlockEvent(event.target))
                    }}></div>
                    <div className={`work-zone__top-buttons-popup workZoneSuport ${(openBlock === "workZoneSuport")? "active": ""}`} >
                        <div className="work-zone__top-buttons-popup-title" >Поддержка</div>
                        <div className="moderator__popup-description-input-container">
                            <textarea name="" id="" value={noticationVal} placeholder='Введите свое сообщение' className="moderator__popup-description-input"
                            onInput={(event)=>{
                                setNoticationVal(event.target.value)
                            }}>
                                                        
                            </textarea>
                        </div>
                        <div className={`work-zone__top-buttons-popup-btn notification d-flex align-center justify-center ${(noticationVal.length > 10)? '': 'deactive'}`} onClick={()=>addNotification()}>
                            Отправить
                        </div>                           
                    </div>
                </div>
            </div>
            <div className={`work-zone__sidebar-catalog ${status}`}>
                <div className="work-zone__sidebar-catalog-top d-flex align-center">
                    <div className="work-zone__sidebar-catalog-top-icon" onClick={()=>{
                        if(workZoneCatalogPath.length !== 0){
                            dispatch(fetchWorkZoneCatalogData(workZoneCatalogPath[workZoneCatalogPath.length-1].type))
                            sessionStorage.setItem('CheckFolderId', workZoneCatalogPath[workZoneCatalogPath.length-1].type)
                        }                        
                    }}></div>
                    <div>Каталог лекал</div>
                </div>
                <div className="work-zone__sidebar-catalog-path d-flex">
                    <div className={`work-zone__sidebar-catalog-path-button-container ${(workZoneCatalogPath.length > 2)? 'active': ''}`}>
                        <div className={`work-zone__sidebar-catalog-path-button popup-open-button ${(workZoneCatalogPath.length > 2)? 'active': ''}`} onClick={(event) =>{
                            dispatch(setPopupOpenBlock((openBlock === "workZoneCatalogPathPopup")? "": "workZoneCatalogPathPopup"))
                            dispatch(setPopupOpenBlockEvent(event.target))
                        }}>
                            <img src={`/img/catalog-path-llist-icon${(openBlock === 'workZoneCatalogPathPopup')? '-active.svg': '.svg'}`} alt="" className={`work-zone__sidebar-catalog-path-button-icon popup-open-button`} />
                        </div>
                        <div className={`work-zone__sidebar-catalog-path-button-list-block workZoneCatalogPathPopup ${(openBlock === 'workZoneCatalogPathPopup')? 'active': ''}`}>
                            <div className='work-zone__sidebar-catalog-path-button-list-block-scroll'>
                                <div className='work-zone__sidebar-catalog-path-button-list-block-item d-flex align-center' onClick={()=>{
                                    dispatch(setPopupOpenBlock(""))
                                    dispatch(setPopupOpenBlockEvent(''))
                                    dispatch(fetchWorkZoneCatalogData(0))
                                    sessionStorage.setItem('CheckFolderId', 0)
                                }}>
                                    <img src='../../../img/work-zone-folder-icon.svg' alt="" className="work-zone__sidebar-catalog-path-button-list-block-item-icon folder" />
                                    <div className='work-zone__sidebar-catalog-path-button-list-block-item-name'>Каталог</div>
                                </div>
                                {
                                    workZoneCatalogPath.map((item, key)=>AddCatalogPathListElem(item, key))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="work-zone__sidebar-catalog-path-item" onClick={()=>{
                        if(workZoneCatalogPath.length !== 0){
                            dispatch(fetchWorkZoneCatalogData(0))
                            sessionStorage.setItem('CheckFolderId', 0)
                        }                        
                    }}>Каталог</div>
                    {
                        (workZoneCatalogPath.length > 2)? <div className="work-zone__sidebar-catalog-path-item">/ .....</div>: null
                    }
                    {
                        ((workZoneCatalogPath.length > 2)? workZoneCatalogPath.slice(workZoneCatalogPath.length-2): workZoneCatalogPath).map((item, key)=>AddCatalogPathItem(item, key))
                    }
                </div>
                <div className="work-zone__sidebar-catalog-container">
                    {
                        workZoneCatalogData.map((item, key) => AddCatalogItem(item, key))
                    }
                </div>
            </div>            
        </aside>
	)
}

export default Sidebar;