import {React, useState} from 'react';
import Calendar from 'react-calendar';
import {useDispatch, useSelector} from 'react-redux';
import {setCalendDisplay} from '../../../redux/actions/display';
import {setSalariesTableMonth, setSalariesTableYear, setSalariesTableRange, setSalariesButtonsMonth, setSalariesButtonsYear, setSalariesButtonsRange, setSalariesTableRangeTypeSelect, setSalariesTableRangeActive} from '../../../redux/actions/popupFilters';
import "./Picker.scss";

function Picker({display}){
    const [calendActive, setCalendActive]  = useState(false);
    const [monthActive, setMonthActive]  = useState(false);
    const [yearActive, setYearActive]  = useState(false);
    const dispatch = useDispatch();
    const [selRange, setSelRange] = useState([])
    const {location, calendDisplay} = useSelector(({display})=> {
        return {
            location: display.location,
            calendDisplay: display.calendDisplay
        }
    })

    const {month, year} = useSelector(({popupFilters})=> {
        if(location === "Buttons"){
            return{
                month: popupFilters.salariesButtonsMonth,
                year: popupFilters.salariesButtonsYear,
            }
        }
        else if(location === "Table"){
            return{
                month: popupFilters.salariesTableMonth,
                year: popupFilters.salariesTableYear,
            }
        }
        else{
            return{
                month: 'Месяц',
                year: 'Год',
            }
        }
    })

    if(display === 'open'){
        document.body.style.overflow = 'hidden'
    }
    else{
        document.body.style.overflow = 'auto'
    }

    let dataMonth = [
        {
            id: 0,
            value: "Январь"
        },
        {
            id: 1,
            value: "Февраль"
        },
        {
            id: 2,
            value: "Март"
        },
        {
            id: 3,
            value: "Апрель"
        },
        {
            id: 4,
            value: "Май"
        },
        {
            id: 5,
            value: "Июнь"
        },
        {
            id: 6,
            value: "Июль"
        },
        {
            id: 7,
            value: "Август"
        },
        {
            id: 8,
            value: "Сентябрь"
        },
        {
            id: 9,
            value: "Октябрь"
        },
        {
            id: 10,
            value: "Ноябрь"
        },
        {
            id: 11,
            value: "Декабрь"
        }
    ];
    let defaultYear = new Date().getFullYear()
    let dataYear = [
        {
            id: 0,
            value: defaultYear - 5
        },
        {
            id: 1,
            value: defaultYear - 4
        },
        {
            id: 2,
            value: defaultYear - 3
        },
        {
            id: 3,
            value: defaultYear - 2
        },
        {
            id: 4,
            value: defaultYear - 1
        },
        {
            id: 5,
            value: defaultYear
        },
        {
            id: 6,
            value: defaultYear + 1
        },
        {
            id: 7,
            value: defaultYear + 2
        },
        {
            id: 8,
            value: defaultYear + 3
        },
        {
            id: 9,
            value: defaultYear + 4
        },
        {
            id: 10,
            value: defaultYear + 5
        }
    ]

    const [yearActiveValue, setYearActiveValue]  = useState(year);
    const [monthActiveValue, setMonthActiveValue] = useState(month)

    let onChange = date => {
        setSelRange(date)    
    }
    let reset = () => {
        // dispatch(setCalendDisplay(""));
        // dispatch(setCalendDisplay("open"));
        setSelRange([])  
        if(location === "Table"){
            dispatch(setSalariesTableRange([]))
            setMonthActiveValue("Месяц")
            setYearActiveValue("Год")
            dispatch(setSalariesTableMonth("Месяц"))
            dispatch(setSalariesTableYear("Год"))
            dispatch(setSalariesTableRange([new Date(new Date().toLocaleDateString().split('.')[2], new Date().toLocaleDateString().split('.')[1] - 1, 1), new Date(new Date().toLocaleDateString().split('.')[2], new Date().toLocaleDateString().split('.')[1], 1)]))
            sessionStorage.setItem('staffSalariesTablePopupFilters', JSON.stringify({month: "Месяц", year: "Год", range: [new Date(new Date().toLocaleDateString().split('.')[2], new Date().toLocaleDateString().split('.')[1] - 1, 1), new Date(new Date().toLocaleDateString().split('.')[2], new Date().toLocaleDateString().split('.')[1], 1)]}))
            dispatch(setSalariesTableRangeTypeSelect(({id: 3, value: 'Месяц'})))
            sessionStorage.setItem('staffSalariesTablePopupFiltersPeriodListActive', JSON.stringify({id: 3, value: 'Месяц'}))
        }
        else if(location === "Buttons"){
            dispatch(setSalariesButtonsMonth("Месяц"))
            dispatch(setSalariesButtonsYear("Год"))
            dispatch(setSalariesButtonsRange([]))
        }
        
    }
    const monthActivate = () =>{
        if(!calendActive){
            setMonthActive(!monthActive)
            setYearActive(false)
        }
    }
    const yearActivate = () =>{
        if(!calendActive){
            setYearActive(!yearActive)
            setMonthActive(false)
        }
    }
    const monthChange = (id) =>{
        setMonthActive(false)
        if(location === "Buttons"){
            dispatch(setSalariesButtonsMonth(dataMonth[id].value))
        }
        else if(location === "Table"){
            setMonthActiveValue(dataMonth[id].value)
        }
    }
    const yearChange = (id) =>{
        setYearActive(false)
        if(location === "Buttons"){
            dispatch(setSalariesButtonsYear(dataYear[id].value))
        }
        else if(location === "Table"){
            setYearActiveValue(dataYear[id].value)
        }
    }

    const addMonth = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(month === value)? "active": ""}`} key={key} onClick={() => {monthChange(id)}}>{value}</div>
        )
    }
    const addYear = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(year === value)? "active": ""}`} key={key} onClick={() => {yearChange(id)}}>{value}</div>
        )
    }

    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target){
            dispatch(setCalendDisplay(""));
            setTimeout(()=>{
                dispatch(setCalendDisplay("close"))
                setMonthActiveValue(month)
                setYearActiveValue(year)
            }, 0)

        }
    }

    return (
        <div className={`filters__container justify-center align-center ${display}`} onClick={(event)=> ClosePopup(event)}>
            <div className='filters'>
                <div className='filters__top d-flex justify-between align-center'>
                    <div className="filters__title">Фильтры</div>
                    <div className="filters__close-btn" onClick={() =>{
                            dispatch(setCalendDisplay(""));
                            setTimeout(()=>{
                                dispatch(setCalendDisplay("close"))
                                setMonthActiveValue(month)
                                setYearActiveValue(year)
                            }, 0)
                        }
                    }>
                    </div>                
                </div>
                <div className='filters__scroll'>
                    
                            <div className='filters__block' >
                                <div className="filters__block-item year">
                                    <div className={`filters__block-item-title d-flex align-center justify-center year ${(location === 'Table' && yearActiveValue === "Год" || year  === "Год")? "": "active-"} ${(yearActive)? "open": ""}`} onClick={yearActivate}>{(location === 'Table')? yearActiveValue: year}<div className="filters__block-item-title-icon"/></div>                        
                                    <div className={`filters__block-item-list ${(yearActive)? "open": ""}`}>
                                        <div className='filters__block-item-list-scroll'>
                                            {
                                                dataYear.map((item, key) => addYear(item, key))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="filters__block-item months">
                                    <div className={`filters__block-item-title d-flex align-center justify-center months ${(location === 'Table' && monthActiveValue === "Месяц" || month === "Месяц")? "": "active-"} ${(monthActive)? "open": ""}`} onClick={monthActivate}>{(location === 'Table')? monthActiveValue: month}<div className="filters__block-item-title-icon"/></div>                        
                                    <div className={`filters__block-item-list ${(monthActive)? "open": ""}`}>
                                        <div className='filters__block-item-list-scroll'>
                                            {
                                                dataMonth.map((item, key) => addMonth(item, key))
                                            }                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                       
                            <div className={`filters__range align-center ${(calendActive)? "active": ""}`} onClick={()=>{
                                    setCalendActive(!calendActive)
                                    reset()
                                }}>
                                <div className='filters__range-checkbox'></div>
                                <div className='filters__range-title'>По периоду</div>
                            </div>
                        
                    <div className={`filters__calendar-container ${(calendActive || window.innerWidth > 1500)? "": "off"}`}>
                        {
                            (calendDisplay === 'open')? <Calendar onChange={onChange} selectRange={true}/>: null
                        }
                        <div className='react-calendar__range d-flex justify-between'>
                            <div className='react-calendar__range-item start'>
                                <div className='react-calendar__range-label'>С</div>
                                <div className='react-calendar__range-value'>{(selRange.length > 0)? selRange[0].toLocaleDateString(): "ДД.ММ.ГГГГ"}</div>
                            </div>
                            <div className='react-calendar__range-item end'>
                                <div className='react-calendar__range-label'>До</div>
                                <div className='react-calendar__range-value'>{(selRange.length === 2)? selRange[1].toLocaleDateString(): "ДД.ММ.ГГГГ"}</div>
                            </div>
                        </div>                    
                    </div>
                    <div className='filters__buttons d-flex'>
                        <div className='filters__buttons-item reset' onClick={reset} >Сбросить</div>
                        <div className='filters__buttons-item accept' onClick={()=>{
                            
                            if(location === "Table"){
                                dispatch(setSalariesTableRange(selRange))
                                if((monthActiveValue === 'Месяц' && yearActiveValue === 'Год' && selRange.length !== 0) || window.innerWidth > 1500 && selRange.length !== 0){
                                    dispatch(setSalariesTableRange(selRange))
                                    dispatch(setSalariesTableRangeActive(true))
                                    sessionStorage.setItem('staffSalariesTablePopupFilters', JSON.stringify({month: "Месяц", year: "Год", range: selRange, rangeActive: true}))
                                }                                
                                else if(window.innerWidth < 1500 && !calendActive){
                                    let from,
                                        to; 
                                    if(monthActiveValue !== 'Месяц'){
                                        let monthId = dataMonth.findIndex(x => x.value === monthActiveValue)
                                        from = new Date((yearActiveValue === 'Год')? new Date().getFullYear(): yearActiveValue, monthId, 1);
                                        to = new Date((yearActiveValue === 'Год')? new Date().getFullYear(): yearActiveValue, monthId+1, 1);
                                        to.setDate(to.getDate() - 1)
                                        dispatch(setSalariesTableRangeTypeSelect(({id: 3, value: 'Месяц'})))
                                        sessionStorage.setItem('staffSalariesTablePopupFiltersPeriodListActive', JSON.stringify({id: 3, value: 'Месяц'}))
                                    }
                                    else{
                                        from = new Date((yearActiveValue === 'Год')? new Date().getFullYear(): +yearActiveValue, 0, 1);
                                        to = new Date((yearActiveValue === 'Год')? new Date().getFullYear() + 1: +yearActiveValue + 1, 0, 1);
                                        to.setDate(to.getDate() - 1)
                                        dispatch(setSalariesTableRangeTypeSelect(({id: 6, value: 'Год'})))
                                        sessionStorage.setItem('staffSalariesTablePopupFiltersPeriodListActive', JSON.stringify({id: 6, value: 'Год'}))
                                    }
                                    dispatch(setSalariesTableRange([from, to]))                                    
                                    dispatch(setSalariesTableMonth(monthActiveValue))
                                    dispatch(setSalariesTableYear(yearActiveValue))
                                    sessionStorage.setItem('staffSalariesTablePopupFiltersPeriodListActive', JSON.stringify({id: 3, value: 'Месяц'}, {id: 6, value: 'Год'}))
                                    dispatch(setSalariesTableRangeActive(false))
                                    sessionStorage.setItem('staffSalariesTablePopupFilters', JSON.stringify({month: monthActiveValue, year: from.getFullYear(), range: [from, to], rangeActive: false}))
                                }
                            }
                            else if(location === "Buttons"){
                                dispatch(setSalariesButtonsMonth("Месяц"))
                                dispatch(setSalariesButtonsYear("Год"))
                                dispatch(setSalariesButtonsRange(selRange))
                            }
                            dispatch(setCalendDisplay(""));
                            setTimeout(()=>{
                                dispatch(setCalendDisplay("close"))
                            }, 0)                                            
                        }}>Принять</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Picker;
