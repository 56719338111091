export const setPopupOpenBlock = (item) => ({
    type: 'POPUP_OPEN_BLOCK',
    payload: item,
});

export const setPopupBackground = (item) => ({
    type: 'POPUP_BACKGROUND',
    payload: item,
});

export const setPopupOpenBlockEvent = (item) => ({
    type: 'POPUP_OPEN_BLOCK_EVENT',
    payload: item,
});

export const setPopupBackgroundFunc = (item) => (dispatch) =>{
    if(item){
        dispatch(setPopupBackground("display"))
        setTimeout(()=>{
            dispatch(setPopupBackground("open"))
        }, 300)
    }
    else{
        dispatch(setPopupBackground("display"))
        setTimeout(()=>{
            dispatch(setPopupBackground(""))
        }, 300)
    }
}

export const setWarehousePopupMenuPage = (item) => ({
    type: 'POPUP_WAREHOUSE_POPUP_MENU_PAGE',
    payload: item,
});

export const setFranchisersWarehousePopupMenuPage = (item) => ({
    type: 'POPUP_FRANCHISER_WAREHOUSE_POPUP_MENU_PAGE',
    payload: item,
});