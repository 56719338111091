import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import {fetchOwnerLeaderLeaderData, fetchOwnerLeaderLeaderStaf} from '../../../../redux/actions/ownerData';
import {setLeadersNav, setOwnerNav} from '../../../../redux/actions/navigation';
import './Leader-staf.scss';
import { Link } from 'react-router-dom';

const LeaderStaf = () => {

    const dispatch = useDispatch();
    const {leaderData, stafData, leaderId} = useSelector(({filters, ownerData})=> {
        return {
            leaderId: filters.ownerLeadrersLeaderId,
            leaderData: ownerData.ownerLeadersLeaderData,
            stafData: ownerData.ownerLeadersLeaderStaf
        }
    })

    let [tablePageNum, setTablePageNum] = useState(0)

    useEffect(()=>{
        if(leaderId != null){
            dispatch(fetchOwnerLeaderLeaderData(leaderId))
            dispatch(fetchOwnerLeaderLeaderStaf(leaderId))            
        }
        else{
            dispatch(fetchOwnerLeaderLeaderData(localStorage.getItem("leaderId")))
            dispatch(fetchOwnerLeaderLeaderStaf(localStorage.getItem("leaderId")))
        }
        dispatch(setOwnerNav('Leaders'))
        dispatch(setLeadersNav("staf"))
    }, [])
    
    const addPercentBlock = (percent, color, type) =>{
        if(color === "green"){
            return(
                <div className={`owner__leaders-staf-buttons-val-percent d-flex align-center justify-center green ${type}`}>
                    <img src="/img/arrow-up-green.svg" alt="" className="owner__sales-buttons-val-percent-icon"/> 
                    <div>{percent}%</div>
                </div>
            )
        }
        else{
            return(
                <div className={`owner__leaders-staf-buttons-val-percent d-flex align-center justify-center red ${type}`}>
                    <img src="/img/arrow-down-red.svg" alt="" className="owner__sales-buttons-val-percent-icon"/> 
                    <div>{percent}%</div>
                </div>
            )
        }
    }

    const addStaf = ({id, photo, name, number, email, birthday}, key) =>{
        return(
            <div className={`owner__leaders-staf-line d-flex align-center justify-between scroll-leaders-staf-line-${id}`} key={key}>
                <div className="owner__leaders-staf-person d-flex align-center">
                    <div className="owner__leaders-staf-person-photo-container d-flex align-center justify-center">
                        <img src={photo} alt="" className="owner__leaders-staf-person-photo"/>
                    </div>
                    <span className="d-block">{name}</span>
                </div>
                <a href={`tel:${number}`} className="owner__leaders-staf-phone">{number}</a>
                {/* <a href={`mailto:${email}`} className="owner__leaders-staf-email">{(email !== null && email !== '')? email: 'Не заполнено'}</a> */}
                {
                    (email !== null && email !== '')? 
                    <a href={`mailto:${email}`} className="owner__leaders-staf-email">{email}</a>
                    : <div className="owner__leaders-staf-email border-none">Не заполнено</div>
                }
                <div className="owner__leaders-staf-birthday">{(birthday !== null && birthday !== '' && birthday !== ' ')? birthday: 'Не заполнено'}</div>
            </div>
        )
    }

    const pageButtons = (button) =>{
        let pet = tablePageNum
        if(button == "back"){
            setTablePageNum(pet-1)
        }
        else{
            setTablePageNum(pet+1)
        }
        // console.log(pet)
    }

	return (
        <React.Fragment>
            <Link to='/leaders'>
                <div className='owner__leaders-staf-back d-flex align-center' onClick={()=>{
                    dispatch(setLeadersNav("leaders"))
                }}> <img src="../../../../img/arrow-left.svg" alt="" /> Назад</div>
            </Link>
            <div className="owner__leaders-staf-buttons ">
                <div className="owner__leaders-staf-top d-flex justify-between align-center">
                    <div className="owner__leaders-staf-title leader">{leaderData.name}</div>
                </div>
                <div className="owner__leaders-staf-buttons-container justify-between align-center">
                    <div className="owner__leaders-staf-buttons-sale d-flex flex-column justify-center">
                        <div className="d-flex justify-center">
                            <div className="owner__leaders-staf-buttons-icon sale align-center justify-center">
                                <img src="/img/shopping-bag.svg" alt="" />
                            </div>
                            <div>
                                <div className="owner__leaders-staf-buttons-title sale">Продажи</div>
                                <div className="owner__leaders-staf-buttons-val sale">{leaderData.sale.value}</div>
                                {
                                    addPercentBlock(leaderData.sale.percent, leaderData.sale.class, "sale")
                                }
                            </div>
                        </div>
                        
                    </div>
                    <div className='owner__leaders-staf-buttons-line'></div>
                    <div className="owner__leaders-staf-buttons-income d-flex flex-column justify-center">
                        <div className="d-flex justify-center">
                            <div className="owner__leaders-staf-buttons-icon income align-center justify-center">
                                <img src="/img/dollar-circle.svg" alt="" /></div>
                            <div>
                                <div className="owner__leaders-staf-buttons-title income">Доход</div>
                                <div className="owner__leaders-staf-buttons-val income">{leaderData.income.value}</div>
                                {
                                    addPercentBlock(leaderData.income.percent, leaderData.income.class, "income")
                                }
                            </div>
                        </div>
                        
                    </div>
                    <div className='owner__leaders-staf-buttons-line'></div>
                    <div className="owner__leaders-staf-buttons-purchase d-flex flex-column justify-center">
                        <div className="d-flex justify-center">
                            <div className="owner__leaders-staf-buttons-icon purchase align-center justify-center">
                                <img src="/img/purchase-icon.svg" alt="" /></div>
                            <div>
                                <div className="owner__leaders-staf-buttons-title purchase">Закуп</div>
                                <div className="owner__leaders-staf-buttons-val purchase">{leaderData.purchase.value}</div>
                                {
                                    addPercentBlock(leaderData.purchase.percent, leaderData.purchase.class, "purchase")
                                }
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="owner__leaders-staf">
                <div className="owner__leaders-staf-title staf">Список сотрудников</div>
                <div className='owner__leaders-staf-table-scroll'>
                    <div className="owner__leaders-staf-line title d-flex align-center justify-between">
                        <div className="owner__leaders-staf-person">ФИО</div>
                        <div className="owner__leaders-staf-phone">Телефон</div>
                        <div className="owner__leaders-staf-email">E-mail</div>
                        <div className="owner__leaders-staf-birthday">Дата рождения</div>
                    </div>
                    <div className="owner__leaders-staf-table">
                        {
                            (window.innerWidth > 700)?(
                                stafData.map((item, key) => addStaf(item, key))
                             ):(
                                 (tablePageNum * 5 <= stafData.length)?(
                                    stafData.slice(tablePageNum * 5, tablePageNum * 5 + 5).map((item, key) => addStaf(item, key))
                                 ):(
                                    stafData.slice(tablePageNum * 5).map((item, key) => addStaf(item, key))
                                 )
                             )
                        }
                        
                    </div>
                </div>
                {
                    (window.innerWidth < 700)?(
                        <div className='owner__points-sale-table-buttons d-flex justify-between'>
                            <div className={`owner__points-sale-table-buttons-item back ${(tablePageNum > 0)? 'active': ''}`} onClick={()=>{pageButtons("back")}}></div>
                            <div className={`owner__points-sale-table-buttons-item forward ${((tablePageNum === 0 && stafData.length > 5) || stafData.length > (tablePageNum+1) * 5)? 'active': ''}`} onClick={()=>{pageButtons("forward")}}></div>
                        </div>
                    ): null
                }
            </div>
        </React.Fragment>        
	)
}

export default LeaderStaf;