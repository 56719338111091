import ru from './ru.json'
import eng from './eng.json'

export function getLanguage(lang, role){

    switch(lang){
        case 'ru': {
            console.log(ru[role])
        } break;
        case 'eng': {
            console.log(eng[role])
        } break;
    }
}