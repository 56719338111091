import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOwnerHomeCheckLeaders, setOwnerHomeCheckLeadersDel} from '../../../../redux/actions/filters';
import {fetchOwnerHomeLeaders} from '../../../../redux/actions/ownerData';
import './Leader-list.scss';

const LeaderList = () => {
    const {range, month, year} = useSelector(({popupFilters})=> {
        return {
            range: popupFilters.ownerHomeRange,
            month: popupFilters.ownerHomeMonth,
            year: popupFilters.ownerHomeYear,
        }
    })

    const dispatch = useDispatch();
    let {leadersCheck, leadersData} = useSelector(({filters, ownerData}) => {
        return{
            leadersCheck: filters.ownerHomeCheckLeaders,
            leadersData: ownerData.ownerHomeLeaders
        }
    })

    let [tablePageNum, setTablePageNum] = useState(0)

    useEffect(()=>{
        dispatch(fetchOwnerHomeLeaders(range, month, year))
    }, [])

    useEffect(()=>{
        dispatch(fetchOwnerHomeLeaders(range, month, year))
    }, [range, month, year])

    const leaderChange = (id) =>{
        if(leadersCheck.indexOf(id) !== -1){
            let dat = leadersCheck.slice()
            dat.splice(leadersCheck.indexOf(id), 1)
            dispatch(setOwnerHomeCheckLeadersDel(dat)) 
        }
        else{
            dispatch(setOwnerHomeCheckLeaders(id))
        }
    }
    // const cutPath = (src) =>{
    //     if(src !== null && src !== '') return './' + src.split('/').slice(3).join('/')
    //     else return src
    // }

    const cutName = (name) =>{
        let cut = name.split(' ')
        if(cut.length === 3){
            return cut[0] + ' ' + cut[1] + ' ' + cut[2][0] + '.'
        }
        // else if(cut.length === 2){
        //     return cut[0] + ' ' + cut[1][0] + '.'
        // }
        else return name
    }

    const addLeaderItem = ({id, leaderImg, name, city, pointsCount, income, incomePercent, incomeColor}, key) =>{
        return(
            <div className={`owner__right-list-item d-flex align-center justify-between ${(leadersCheck.indexOf(id) !== -1)? "active": ""}`} key={key} onClick={() => {leaderChange(id)}}>
                <div className="owner__right-list-leader d-flex align-center">
                    <div className="owner__right-list-leader-photo d-flex justify-center align-center">
                        <img src={leaderImg} alt="" className="owner__right-list-leader-photo-img"/>
                    </div>
                    <div className="owner__right-list-leader-name-container">
                        <div className="owner__right-list-leader-name" title={name}>{cutName(name)}</div>
                        <div className="owner__right-list-leader-city">{city}</div>
                    </div>
                </div>
                <div className="owner__right-points">{pointsCount}</div>
                <div className="owner__right-income d-flex flex-column">
                    <div className="owner__right-income-val">{income}</div>
                    <div className={`owner__right-income-percent ${incomeColor}`}>{incomePercent}%</div>
                </div>
            </div>
        )
    }

    const pageButtons = (button) =>{
        let pet = tablePageNum
        if(button == "back"){
            setTablePageNum(tablePageNum-1)
        }
        else{
            setTablePageNum(tablePageNum+1)
        }
        // console.log(pet)
    }

	return (
		<div className="owner__content-right">
            <div className="owner__right-title">Список руководителей</div>
            <div className='owner__right-list-scroll'>
                <div className="owner__right-top d-flex align-center justify-between">
                    <div className="owner__right-leader d-flex title">
                        <div className="owner__right-reset d-flex align-center" onClick={()=>{
                            dispatch(setOwnerHomeCheckLeadersDel([]))
                        }}>
                            <img src='/img/refresh-circle.svg' alt="" className="owner__right-reset-icon"/>
                            Сбросить
                        </div>
                    </div>
                    <div className="owner__right-points title" onClick={() => {leaderChange(2, false)}}>Точки</div>
                    <div className="owner__right-income title">Доход</div>
                </div>
                <div className="owner__right-top-line"></div>
                <div className="owner__right-list">
                    {
                        
                        (window.innerWidth > 700)?(
                            
                            leadersData.map((item, key) => addLeaderItem(item, key))

                        ):(
                            (tablePageNum * 5 <= leadersData.length)?(
                                leadersData.slice(tablePageNum * 5, tablePageNum * 5 + 5).map((item, key) => addLeaderItem(item, key))
                            ):(
                                leadersData.slice(tablePageNum * 5).map((item, key) => addLeaderItem(item, key))
                            )
                        )
                    }
                </div>
            </div>
            {
                (window.innerWidth < 700)?(
                    <div className='owner__points-sale-table-buttons d-flex justify-between'>
                        <div className={`owner__points-sale-table-buttons-item back ${(tablePageNum > 0)? 'active': ''}`} onClick={()=>{pageButtons("back")}}></div>
                        <div className={`owner__points-sale-table-buttons-item forward ${((tablePageNum === 0 && leadersData.length > 5) || leadersData.length > (tablePageNum+1) * 5)? 'active': ''}`} onClick={()=>{pageButtons("forward")}}></div>
                    </div>
                ): null
            }
        </div>
	)
}

export default LeaderList;