import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import Home from './Home/Home';
import Warehouse from './Warehouse/Warehouse';
import LeadersTable from './Leaders/Leaders-table/Leaders-table';
import LeaderStaf from './Leaders/Leaders-staff/Leader-staf';
import Calendar from '../Calendaries/Picker';

 
import './Owner.scss';




const Owner = () => {
	const { calendDisplay } = useSelector(({ display }) => display);
    
	return (
		<div className="owner">
			<Sidebar/>
			<Header/>
			<div className="owner__wrap">				
				<Route path='/Leaders' component={LeadersTable} exact/>
				<Route path='/Leaders/Staf' component={LeaderStaf} exact/>
				<Route path='/Warehouse' component={Warehouse} exact/>
        <Route path='/' component={Home} exact/>			
			</div>
			<Calendar  display = {calendDisplay}/>
		</div>
	)
}

export default Owner;