import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setFranchiserSalariesCheckDay } from '../../../redux/actions/franchiserCheckData';
import { setPopupBackgroundFunc, setPopupOpenBlock } from '../../../redux/actions/popupBlocks';
import {setCalendDisplay} from '../../../redux/actions/display';

import './Salaries_0.1.scss'
import { fetchFranchiserSalaries } from '../../../redux/actions/franchiserData';
import { setFranchiserSalariesMonth, setFranchiserSalariesRange, setFranchiserSalariesRangeTypeSelect } from '../../../redux/actions/popupFilters';
import { setFranchiserSalariesYear } from './../../../redux/actions/popupFilters';

const Salaries = () => {
	const dispatch = useDispatch();

	const {salariesData, salariesDate, range, year, month, rangeActive, listActive} = useSelector(({franchiserData, popupFilters})=> {
        return {
            salariesData: franchiserData.franchiserSalariesData,
			salariesDate: franchiserData.franchiserSalariesDate,
            range: popupFilters.franchiserSalariesRange,
            rangeActive: popupFilters.franchiserSalariesRangeActive,
			year: popupFilters.franchiserSalariesYear,
			month: popupFilters.franchiserSalariesMonth,
			listActive: popupFilters.franchiserSalariesRangeTypeSelect        
        }
    })

 	// useEffect(()=>{
    //     editDate(3, 'Месяц')
    // }, [])

    useEffect(()=>{
        dispatch(fetchFranchiserSalaries(range))
    }, [range])




	let scroll = (event) =>{			
		let title = document.querySelector('.franchises-leaders__salaries-tabel-title-scroll');
		let tabel = document.querySelector('.franchises-leaders__salaries-tabel-scroll');
		let scrollbar = event.target;
		
		let percent = (scrollbar.scrollLeft / 
		(scrollbar.scrollWidth - scrollbar.offsetWidth)) * 100;

		let scrollTopPos = (percent / 100) *
		(title.scrollWidth - title.offsetWidth );
		title.scrollTo(scrollTopPos, 0);

		let scrollTopPosW = (percent / 100) *
		(tabel.scrollWidth - tabel.offsetWidth );
		tabel.scrollTo(scrollTopPosW, 0);

	}

	const cutName = (name) =>{
        let cut = name.split(' ')
        if(cut.length === 3){
            return cut[0] + ' ' + cut[1][0] + '.' + ' ' + cut[2][0] + '.'
        }
        else return name
    }

	let addDates = (item, key) =>{
		return(
			<div className="franchises-leaders__salaries-tabel-item title d-flex justify-center align-center" key={key}>
				<div className="franchises-leaders__salaries-tabel-item-container title">{item}</div>
			</div>
		)
	}

	let addStaff = ({id, name, fullName, position}, key) =>{
		return(
			<div className={`franchises-leaders__salaries-staff-item d-flex justify-center align-center franchises__scroll-salaries-staff-line${id}`} key={key}>
				<div className="franchises-leaders__salaries-staff-item-container">
					<div className="franchises-leaders__salaries-staff-item-name">{cutName(fullName)}</div>
					<div className="franchises-leaders__salaries-staff-item-position">{position}</div>
				</div>
			</div>
		)
	}

	let addTabelLine = ({id, salaryData}, key) =>{
		return(
			<div className={`franchises-leaders__salaries-tabel-line d-flex franchiser__scroll-salaries-tabel-line${id}`} key={key}>
				{
					salaryData.map((item, key) => addTabelBlock(item, key, id))
				}
			</div>
		)
	}

	let addTabelBlock = ({salary, premium, fine, prepayment, date}, key, id) =>{
		return(
			<div className="franchises-leaders__salaries-tabel-item d-flex justify-center align-center" key={key}> 
				<div className='franchises-leaders__salaries-tabel-item-container' onClick={()=>{
						dispatch(setPopupBackgroundFunc(true))
						dispatch(setPopupOpenBlock('AddEvent'))
						dispatch(setFranchiserSalariesCheckDay({salary, premium, fine, prepayment, date, userId: id}))
						// console.log(date)
					}}>
					{
						(salary !== 0)? <div className="franchises-leaders__salaries-tabel-item-block salary">{salary}₽</div>: null
					}
					{
						(premium !== 0)? <div className="franchises-leaders__salaries-tabel-item-block premium">{premium}₽</div>: null
					}
					{
						(fine !== 0)? <div className="franchises-leaders__salaries-tabel-item-block fine">{fine}₽</div>: null
					}
					{
						(prepayment !== 0)? <div className="franchises-leaders__salaries-tabel-item-block prepayment">{prepayment}₽</div>: null
					}
				</div>
			</div>
		)
	}

	let addTotalBlock = ({id, total}, key) =>{
		return(
			<div className={`franchises-leaders__salaries-total-item d-flex justify-center align-center franchiser__scroll-salaries-total-line-${id}`} key={key}>
				<div className="franchises-leaders__salaries-total-item-container">
					{total}₽
				</div>
			</div>
		)
	}

	let dataMonth = [
        {
            id: 0,
            value: "Январь"
        },
        {
            id: 1,
            value: "Февраль"
        },
        {
            id: 2,
            value: "Март"
        },
        {
            id: 3,
            value: "Апрель"
        },
        {
            id: 4,
            value: "Май"
        },
        {
            id: 5,
            value: "Июнь"
        },
        {
            id: 6,
            value: "Июль"
        },
        {
            id: 7,
            value: "Август"
        },
        {
            id: 8,
            value: "Сентябрь"
        },
        {
            id: 9,
            value: "Октябрь"
        },
        {
            id: 10,
            value: "Ноябрь"
        },
        {
            id: 11,
            value: "Декабрь"
        }
    ];

	let defaultYear = new Date().getFullYear()

    let dataYear = [
        {
            id: 0,
            value: defaultYear - 5
        },
        {
            id: 1,
            value: defaultYear - 4
        },
        {
            id: 2,
            value: defaultYear - 3
        },
        {
            id: 3,
            value: defaultYear - 2
        },
        {
            id: 4,
            value: defaultYear - 1
        },
        {
            id: 5,
            value: defaultYear
        },
        {
            id: 6,
            value: defaultYear + 1
        },
        {
            id: 7,
            value: defaultYear + 2
        },
        {
            id: 8,
            value: defaultYear + 3
        },
        {
            id: 9,
            value: defaultYear + 4
        },
        {
            id: 10,
            value: defaultYear + 5
        }
    ]

    const [monthActive, setMonthActive]  = useState(false);
    const [yearActive, setYearActive]  = useState(false);

    const monthActivate = () =>{
        setMonthActive(!monthActive)
        setYearActive(false)        
    }
    const yearActivate = () =>{
        setYearActive(!yearActive)
        setMonthActive(false)
    }
    const monthChange = (id) =>{
        setMonthActive(false)
		let from = new Date((year === 'Год')? new Date().getFullYear(): year, id, 1);
		let to = new Date((year === 'Год')? new Date().getFullYear(): year, id+1, 1);
		to.setDate(to.getDate() - 1)
		dispatch(setFranchiserSalariesMonth(dataMonth[id].value))
		dispatch(setFranchiserSalariesRange([from, to]))
		sessionStorage.setItem('franchiserSalariesPopupFilters', JSON.stringify({month: dataMonth[id].value, year: from.getFullYear(), range: [from, to]}))
		dispatch(setFranchiserSalariesRangeTypeSelect(({id: 3, value: 'Месяц'})))
		sessionStorage.setItem('franchiserSalariesPopupFiltersPeriodListActive', JSON.stringify({id: 3, value: 'Месяц'}))
    }
    const yearChange = (id) =>{        
        setYearActive(false)
		let from = new Date(+dataYear[id].value, 0, 1);
		let to = new Date(+dataYear[id].value + 1, 0, 1);
		to.setDate(to.getDate() - 1)
		dispatch(setFranchiserSalariesYear(dataYear[id].value))
		dispatch(setFranchiserSalariesRange([from, to]))
		sessionStorage.setItem('franchiserSalariesPopupFilters', JSON.stringify({month: 'Месяц', year: dataYear[id].value, range: [from, to]}))
		if(month !== 'Месяц')dispatch(setFranchiserSalariesMonth('Месяц'))
		dispatch(setFranchiserSalariesRangeTypeSelect(({id: 6, value: 'Год'})))
		sessionStorage.setItem('franchiserSalariesPopupFiltersPeriodListActive', JSON.stringify({id: 6, value: 'Год'}))
    }

    const addMonth = ({id, value}, key)=>{
        return(
			<div className={`filters__block-item-list-elem ${(range.length !== 0 && range[0].getMonth() === id)? "active": ""}`} key={key} onClick={() => {monthChange(id)}}>{value}</div>
        )
    }
    const addYear = ({id, value}, key)=>{
        return(
			<div className={`filters__block-item-list-elem ${(range.length !== 0 && range[0].getFullYear === value)? "active": ""}`} key={key} onClick={() => {yearChange(id)}}>{value}</div>
        )
    }

	const [list, setList] = useState(false)

	let positionList = [
        // {
        //     id: 1, 
        //     value: 'Неделя'
        // },
        {
            id: 2, 
            value: 'Полмесяца'
        },
        {
            id: 3, 
            value: 'Месяц'
        },
		// {
        //     id: 4, 
        //     value: 'Квартал'
        // },
        // {
        //     id: 5, 
        //     value: 'Полгода'
        // },
        {
            id: 6, 
            value: 'Год'
        },
    ]

	let buttonTimeFunc = (range) =>{
        return (range.length !== 0 && range[0].toLocaleDateString() !== range[1].toLocaleDateString())? `${range[0].toLocaleDateString()} - ${range[1].toLocaleDateString()}`: (range.length !== 0)? `${range[0].toLocaleDateString()}`: 'Период'
    }

	const getReturnDateMass = (date) =>{
		let dat = date.toLocaleDateString().split('.')
		return dat
	}

	const editDate=(id, value)=>{
		let date = getReturnDateMass(new Date())
		let to = new Date(date[2], date[1]-1, 1)
		let from = new Date(date[2], date[1]-1, 1);
		dispatch(setFranchiserSalariesRangeTypeSelect(({ id: id, value: value})))
		sessionStorage.setItem('franchiserSalariesPopupFiltersPeriodListActive', JSON.stringify({ id: id, value: value}))
        setList(false)
		if(id === 1){
			to= new Date();
			from = new Date();
			if(to.getDay() === 0){
				from.setDate(from.getDate() - 6);
			}
			else{
				to.setDate(to.getDate() - to.getDay() + 7);
				from.setDate(from.getDate() - from.getDay() + 1);
			}
			dispatch(setFranchiserSalariesRange([from, to]))
		}
		if(id === 2){
			to.setDate(to.getDate()+14)
			dispatch(setFranchiserSalariesRange([from, to]))
		}
		if(id === 3){
			to.setMonth(to.getMonth()+1)
			to.setDate(to.getDate() - 1)
			dispatch(setFranchiserSalariesRange([from, to]))
			
		}
		if(id === 4){
			to.setMonth(to.getMonth() + (to.getMonth()+2)%4)
			to.setDate(to.getDate() - 1)
			from.setMonth(to.getMonth()-3)
			dispatch(setFranchiserSalariesRange([from, to]))

		}
		if(id === 5){
			to = new Date(new Date().getFullYear(), 0, 1)
			to.setMonth(to.getMonth()+6)
			to.setDate(to.getDate() - 1)
			from = new Date(new Date().getFullYear(), 0, 1)
			dispatch(setFranchiserSalariesRange([from, to]))
		}
		if(id === 6){
			to = new Date(new Date().getFullYear(), 0, 1)
			to.setMonth(to.getMonth()+12)
			to.setDate(to.getDate() - 1)
			from = new Date(new Date().getFullYear(), 0, 1)
			dispatch(setFranchiserSalariesRange([from, to]))
		}
		sessionStorage.setItem('franchiserSalariesPopupFilters', JSON.stringify({month: 'Месяц', year: from.getFullYear(), range: [from, to]}))
		if(year !== from.getFullYear()) dispatch(setFranchiserSalariesYear(from.getFullYear()))
		if(month !== 'Месяц')dispatch(setFranchiserSalariesMonth('Месяц'))
    }

	const sendDate = (id, type, date) =>{
		let to = date[1];
		let from = date[0]
		
		if(id === 1){ 
			to.setDate((type === 'plus')? to.getDate()+7: to.getDate()-7)
			from.setDate((type === 'plus')? from.getDate()+7: from.getDate()-7)			
			dispatch(setFranchiserSalariesRange([from, to]))
		}
		if(id === 2){
			if(to.getDate() === 15){
				if(type === 'plus'){
					to.setMonth(to.getMonth()+1)
					to = new Date(to.toLocaleDateString().split('.')[2], to.toLocaleDateString().split('.')[1]-1, 1)
					to.setDate(to.getDate() - 1)
					from.setDate(from.getDate()+15)
				}
				else{
					to = new Date(to.toLocaleDateString().split('.')[2], to.toLocaleDateString().split('.')[1]-1, 1)
					to.setDate(to.getDate() - 1)
					from = new Date(to.toLocaleDateString().split('.')[2], to.toLocaleDateString().split('.')[1]-1, 16)
				}
			}
			else{
				if(type === 'plus'){
					from.setDate(to.getDate()+1)
					to = new Date(from.toLocaleDateString().split('.')[2], from.toLocaleDateString().split('.')[1]-1, 15)
				}
				else{
					to = new Date(to.toLocaleDateString().split('.')[2], to.toLocaleDateString().split('.')[1]-1, 15)
					from = new Date(to.toLocaleDateString().split('.')[2], to.toLocaleDateString().split('.')[1]-1, 1)
				}
			}
			
			dispatch(setFranchiserSalariesRange([from, to]))
		}
		if(id === 3){
			if(type === 'plus'){
				to = new Date(getReturnDateMass(date[1])[2], +getReturnDateMass(date[1])[1]+1, 1)
				to.setDate(to.getDate() - 1)
				from.setMonth(from.getMonth() + 1)
			}
			else{
				to = new Date(getReturnDateMass(date[1])[2], +getReturnDateMass(date[1])[1] - 1, 1)
				to.setDate(to.getDate() - 1)
				from.setMonth(from.getMonth() - 1)
			}			
			dispatch(setFranchiserSalariesRange([from, to]))
		}
		if(id === 4){
			to.setMonth((type === 'plus')? to.getMonth()+4: to.getMonth()-4)
			from.setMonth((type === 'plus')? from.getMonth()+4: from.getMonth()-4)
			dispatch(setFranchiserSalariesRange([from, to]))
		}
		if(id === 5){
			to.setMonth((type === 'plus')? to.getMonth()+6: to.getMonth()-6)
			from.setMonth((type === 'plus')? from.getMonth()+6: from.getMonth()-6)
			dispatch(setFranchiserSalariesRange([from, to]))
		}
		if(id === 6){
			to.setMonth((type === 'plus')? to.getMonth()+12: to.getMonth()-12)
			from.setMonth((type === 'plus')? from.getMonth()+12: from.getMonth()-12)
			dispatch(setFranchiserSalariesRange([from, to]))
		}
		sessionStorage.setItem('franchiserSalariesPopupFilters', JSON.stringify({month: 'Месяц', year: from.getFullYear(), range: [from, to]}))
		if(year !== from.getFullYear()) dispatch(setFranchiserSalariesYear(from.getFullYear()))
		if(month !== 'Месяц')dispatch(setFranchiserSalariesMonth('Месяц'))
	}

	let positionListAdd = ({id, value}, key) =>{
        return(
            <div className={`franchises-leaders__staf-points-buttons-position-select-list-item salaries`} key={key} onClick={() => editDate(id, value)}>
                {value}
            </div>
        )
    }
    
	return (
		<React.Fragment>
			<div className="franchises-leaders__title">Таблица</div>
			<div className='franchises-leaders__salaries-filters d-flex'>
				<div className="franchises-leaders__staf-points-buttons-position-select-container salaries d-flex align-center">
					<div className={`franchises-leaders__staf-points-buttons-position-select ${(list)? 'active': ''}`} onClick={()=>{
							setList(!list)
						}}>
						{listActive.value}
					</div>                            
					<div className={`franchises-leaders__staf-points-buttons-position-select-list ${(list)? 'active': ''}`}>
						<div className="franchises-leaders__staf-points-buttons-position-select-list-scroll">
							{
								positionList.map((item, key)=>positionListAdd(item, key))
							}                                        
						</div>
					</div>
				</div>
				<div className="franchises-leaders__sales-filters__block-item mr-16 sales year">
					<div className={`franchises-leaders__sales-filters__block-item-title franchises-leaders__salaries-filters-block-item-title d-flex align-center justify-center year ${(yearActive)? "open": ""}`} onClick={yearActivate}>{(year === 'Год' && range.length !== 0 && range[0].getFullYear() === range[1].getFullYear())? range[0].getFullYear(): year}<div className="franchises-leaders__sales-filters__block-item-title-icon"/></div>                        
					<div className={`franchises-leaders__sales-filters__block-item-list ${(yearActive)? "open": ""}`}>
						<div className='franchises-leaders__sales-filters__block-item-list-scroll'>
							{
								dataYear.map((item, key) => addYear(item, key))
							}
						</div>
					</div>
				</div>
				<div className="franchises-leaders__sales-filters__block-item sales months">
					<div className={`franchises-leaders__sales-filters__block-item-title franchises-leaders__salaries-filters-block-item-title d-flex align-center justify-center months ${(monthActive)? "open": ""}`} onClick={monthActivate}>{(month === 'Месяц' && range.length !== 0 && range[0].getMonth() === range[1].getMonth())? dataMonth[+range[0].getMonth()].value: month}<div className="franchises-leaders__sales-filters__block-item-title-icon"/></div>                        
					<div className={`franchises-leaders__sales-filters__block-item-list ${(monthActive)? "open": ""}`}>
						<div className='franchises-leaders__sales-filters__block-item-list-scroll'>
							{
								dataMonth.map((item, key) => addMonth(item, key))
							}                               
						</div>
					</div>
				</div>
				<div className='franchises-leaders__salaries-filters-item d-flex'>
					<img className={`franchises-leaders__salaries-filters-item-icon left ${(rangeActive)? 'none-click': ''}`} src="/img/arrow-down-grey.svg" alt="" onClick={()=>{
						sendDate(listActive.id, 'minus', range)
					}}/> 
						<div onClick={() => {
							dispatch(setCalendDisplay(""));
							setTimeout(()=>{
								dispatch(setCalendDisplay("open", "franchiserSalaries"))
							}, 0)
						}}>
							{buttonTimeFunc(range)}
						</div> 
					<img className={`franchises-leaders__salaries-filters-item-icon right ${(rangeActive)? 'none-click': ''}`} src="/img/arrow-down-grey.svg" alt="" onClick={()=>{
						sendDate(listActive.id, 'plus', range)
					}}/>
				</div>
				{/* <div className='franchises-leaders__salaries-filters-item d-flex'>
					Доходы <img className='franchises-leaders__salaries-filters-item-icon bottom' src="/img/arrow-down-grey.svg" alt="" />
				</div> */}
			</div>
			<div className="franchises-leaders__salaries">
				<div className='d-flex franchises-leaders__salaries-title-block'>
					<div className="franchises-leaders__salaries-staff">
						<div className="franchises-leaders__salaries-staff-item title d-flex justify-center align-center">
							<div className="franchises-leaders__salaries-staff-item-container">
								Работник
							</div>
						</div>
					</div>
					<div className='franchises-leaders__salaries-tabel title'>
						<div className="franchises-leaders__salaries-tabel-title-scroll">
							<div className="franchises-leaders__salaries-line title d-flex">
								{
									salariesDate.map((item, key) => addDates(item, key))
								}
							</div>
						</div>
					</div>
					<div className="franchises-leaders__salaries-total">
						<div className="franchises-leaders__salaries-total-item title d-flex justify-center align-center">
							<div className="franchises-leaders__salaries-total-item-container">
								Итого
							</div>
						</div>
					</div>
				</div>
                <div className='franchises-leaders__salaries-data-block'>
					<div className="franchises-leaders__salaries-data-block-scrollY d-flex" style={{height: `${(salariesData.length !== 0)? (salariesData.length * 75)+'px': '100%'}`}}>
						<div className="franchises-leaders__salaries-staff">
							{
								salariesData.map((item, key) => addStaff(item, key))
							}
						</div>
						<div className='franchises-leaders__salaries-tabel'>
							<div className="franchises-leaders__salaries-tabel-scroll">

								{
									salariesData.map((item, key) => addTabelLine(item, key))
								}

							</div>
						</div>
						<div className="franchises-leaders__salaries-total">
							{
								salariesData.map((item, key) => addTotalBlock(item, key))
							}
						</div>
					</div>					
				</div>
				<div className="franchises-leaders__salaries-scrollbar" onScroll={(event) => scroll(event)}>
					<div className="franchises-leaders__salaries-scrollbar-width d-flex" style={{width: `${(salariesDate.length !== 0)? (salariesDate.length * 96)+'px': '100%'}`}}>
					</div>
				</div>

            </div>
		</React.Fragment>
	)
}

export default Salaries;