import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {setFranchisesNav} from '../../../redux/actions/navigation';
import {setFranchiserPointsPrintingMonth, setFranchiserPointsPrintingYear} from '../../../redux/actions/popupFilters';
import {setCalendDisplay} from '../../../redux/actions/display';

import './index.scss';
import { fetchFranchiserPointsPrinting } from '../../../redux/actions/franchiserData';

const PointPrinting = () => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(0)
    // const [licensionKey, setLicensionKey] = useState({id: 0, key: ''})

    const openBlock = (id) =>{
        if(open === id) setOpen(0)
        else setOpen(id)
    }

    const {pointsData, range, month, year, pointsCheck} = useSelector(({franchiserData, popupFilters, franchiserCheckData})=> {
        return {
            pointsData: franchiserData.franchiserPointsPrinting,
            range: popupFilters.franchiserPointPrintingRange,
            month: popupFilters.franchiserPointPrintingMonth,
            year: popupFilters.franchiserPointPrintingYear,
            pointsCheck: franchiserCheckData.franchiserHomePointsCheck
        }
    })
    let dataMonth = [
        {
            id: 0,
            value: "Январь"
        },
        {
            id: 1,
            value: "Февраль"
        },
        {
            id: 2,
            value: "Март"
        },
        {
            id: 3,
            value: "Апрель"
        },
        {
            id: 4,
            value: "Май"
        },
        {
            id: 5,
            value: "Июнь"
        },
        {
            id: 6,
            value: "Июль"
        },
        {
            id: 7,
            value: "Август"
        },
        {
            id: 8,
            value: "Сентябрь"
        },
        {
            id: 9,
            value: "Октябрь"
        },
        {
            id: 10,
            value: "Ноябрь"
        },
        {
            id: 11,
            value: "Декабрь"
        }
    ];
    let defaultYear = new Date().getFullYear()
    let dataYear = [
        {
            id: 0,
            value: defaultYear - 5
        },
        {
            id: 1,
            value: defaultYear - 4
        },
        {
            id: 2,
            value: defaultYear - 3
        },
        {
            id: 3,
            value: defaultYear - 2
        },
        {
            id: 4,
            value: defaultYear - 1
        },
        {
            id: 5,
            value: defaultYear
        },
        {
            id: 6,
            value: defaultYear + 1
        },
        {
            id: 7,
            value: defaultYear + 2
        },
        {
            id: 8,
            value: defaultYear + 3
        },
        {
            id: 9,
            value: defaultYear + 4
        },
        {
            id: 10,
            value: defaultYear + 5
        }
    ]

    const [monthActive, setMonthActive]  = useState(false);
    const [yearActive, setYearActive]  = useState(false);

    const monthActivate = () =>{
        setMonthActive(!monthActive)
        setYearActive(false)        
    }
    const yearActivate = () =>{
        setYearActive(!yearActive)
        setMonthActive(false)
    }
    const monthChange = (id) =>{
        dispatch(setFranchiserPointsPrintingMonth(dataMonth[id].value))
        setMonthActive(false)
    }
    const yearChange = (id) =>{
        dispatch(setFranchiserPointsPrintingYear(dataYear[id].value))
        setYearActive(false)
    }

    const addMonth = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(month === value)? "active": ""}`} key={key} onClick={() => {monthChange(id)}}>{value}</div>
        )
    }
    const addYear = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(year === value)? "active": ""}`} key={key} onClick={() => {yearChange(id)}}>{value}</div>
        )
    }

    useEffect(()=>{
        dispatch(fetchFranchiserPointsPrinting(pointsCheck.id, range, month, year))
    }, [])

    useEffect(()=>{
        dispatch(fetchFranchiserPointsPrinting(pointsCheck.id, range, month, year))
    }, [range, month, year])

    const cutName = (name) =>{
        let cut = name.split(' ')
        if(cut.length === 3){
            return cut[0] + ' ' + cut[1] + ' ' + cut[2][0] + '.'
        }
        else return name
    }

    const addTableItem = ({id, model, code, amount, staffFull, date, price, comment, clientPhone, printType}, key) =>{
        return(
            <div className={`franchises-leaders__staf-points-line-container franchiser__scroll-points-printing-line-${id}`} key={key}>
                <div className={`franchises-leaders__staf-points-line d-flex align-center justify-between`}>
                    <div className="franchises-leaders__staf-points-model" title={model}> {model}</div>
                    <div className="franchises-leaders__staf-points-code" >{(code !== null && code !== '')? code: 'Не заполнено'}{(amount !== null && amount !== 0)? `(${amount}шт)`: ''}</div>
                    <div className="franchises-leaders__staf-points-staff" title={staffFull}>{(staffFull !== null && staffFull !== '')? cutName(staffFull): 'Сотрудник не выбран'}</div>
                    <div className="franchises-leaders__staf-points-date">{date}</div>
                    <div className="franchises-leaders__staf-points-price">{price}₽</div>
                    <img src="/img/arrow-bottom-icon.svg" alt="" className={`franchises-leaders__staf-points-additionally-open ${(open === id)? 'active': ''}`} onClick={()=>openBlock(id)}/>
                </div>
                <div className={`franchises-leaders__staf-points-additionally d-flex ${(open === id)? 'active': ''}`}>
                    <div className="franchises-leaders__staf-points-additionally-item comment">
                        <div className="franchises-leaders__staf-points-additionally-item-label">Комментарий</div>
                        <div className="franchises-leaders__staf-points-additionally-item-comment-scroll">
                            <div className="franchises-leaders__staf-points-additionally-item-comment">
                                {comment}   
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='d-flex'>
                            <div className="franchises-leaders__staf-points-additionally-item phone">
                                <div className="franchises-leaders__staf-points-additionally-item-label">Сотовый клиента</div>
                                <div className="franchises-leaders__staf-points-additionally-item-phone">
                                    {(clientPhone !== '' && clientPhone !== null)? clientPhone: 'Не заполенено'}
                                </div>
                            </div>

                            <div className="franchises-leaders__staf-points-additionally-item printing-type">
                                <div className="franchises-leaders__staf-points-additionally-item-label">Тип печати</div>
                                <div className="franchises-leaders__staf-points-additionally-item-printing-type">
                                    {(printType !== '' && printType !== null)? printType: 'Не заполенено'} 
                                </div>
                            </div>
                        </div>
                        <div className="franchises-leaders__staf-points-additionally-item model">
                            <div className="franchises-leaders__staf-points-additionally-item-label model">Модель</div>
                            <div className="franchises-leaders__staf-points-additionally-item-model">
                                {(model !== '' && model !== null)? model: 'Не заполенено'}
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }

	return (
        <React.Fragment>
            <Link to='/'>
                <div className='franchises-leaders__staf-points-back d-flex align-center' onClick={()=>{
                    dispatch(setFranchisesNav("Home"))
                    sessionStorage.setItem('page', 'Home')
                }}> <img src="../../../img/arrow-left.svg" alt="" /> {pointsCheck.name}</div>
            </Link>
            <div className="franchises-leaders__staf-points">
                <div className="franchises-leaders__staf-points-top d-flex justify-between align-center">
                    <div className="franchises-leaders__staf-points-title">Печати</div>
                    <div className='franchises-leaders__sales-filters__block d-flex align-center'>
                        <div className="franchises-leaders__sales-filters__block-item sales year">
                            <div className={`franchises-leaders__sales-filters__block-item-title d-flex align-center justify-center year ${(year === "Год")? "": "active"} ${(yearActive)? "open": ""}`} onClick={yearActivate}>{year}<div className="franchises-leaders__sales-filters__block-item-title-icon"/></div>                        
                            <div className={`franchises-leaders__sales-filters__block-item-list ${(yearActive)? "open": ""}`}>
                                <div className='franchises-leaders__sales-filters__block-item-list-scroll'>
                                    {
                                        dataYear.map((item, key) => addYear(item, key))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="franchises-leaders__sales-filters__block-item sales months">
                            <div className={`franchises-leaders__sales-filters__block-item-title d-flex align-center justify-center months ${(month === "Месяц")? "": "active"} ${(monthActive)? "open": ""}`} onClick={monthActivate}>{month}<div className="franchises-leaders__sales-filters__block-item-title-icon"/></div>                        
                            <div className={`franchises-leaders__sales-filters__block-item-list ${(monthActive)? "open": ""}`}>
                                <div className='franchises-leaders__sales-filters__block-item-list-scroll'>
                                    {
                                        dataMonth.map((item, key) => addMonth(item, key))
                                    }                               
                                </div>
                            </div>
                        </div>
                        <div className="franchises-leaders__sales-filters d-flex justify-between">
                            <div className={`franchises-leaders__sales-filters months`} onClick={() => {
                                dispatch(setCalendDisplay(""));
                                setTimeout(()=>{
                                    dispatch(setCalendDisplay("open", "franchiserPointsPrinting"))
                                }, 0)
                            }}>
                                <div className={`franchises-leaders__sales-filters-title d-flex align-center justify-center months ${(range.length > 0)? 'active': ''}`}>Фильтры<div className="franchises-leaders__sales-filters-title-icon"/></div>                        
                            </div>
                        </div>
                    </div>
                </div>
                <div className='franchises-leaders__staf-points-table-scroll'>
                    <div className="franchises-leaders__staf-points-line title d-flex align-center justify-between">
                        <div className="franchises-leaders__staf-points-model">Модель</div>
                        <div className="franchises-leaders__staf-points-code">Артикул</div>
                        <div className="franchises-leaders__staf-points-staff">Сотрудник</div>
                        <div className="franchises-leaders__staf-points-date">Время и дата</div>
                        <div className="franchises-leaders__staf-points-price">Цена</div>
                        <img src="/img/arrow-down-block.svg" alt="" className="franchises-leaders__staf-points-additionally-open none"/>
                    </div>
                    <div className="franchises-leaders__staf-points-table">                        
                        {
                            pointsData.map((item, key) => addTableItem(item, key))
                        }                        
                    </div>
                </div>
            </div>
        </React.Fragment>        
	)
}

export default PointPrinting;