import {React, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOwnerHomeCheckPoints, setOwnerHomeCheckPointsDel } from '../../../../redux/actions/filters';
import {fetchOwnerHomePoints} from '../../../../redux/actions/ownerData';
import './Sales-points.scss';

const SalesPoints = () => {
    const dispatch = useDispatch();

    const {range, month, year, ownerHomeCheckLeaders} = useSelector(({filters, popupFilters, ownerData})=> {
        return {
            range: popupFilters.ownerHomeRange,
            month: popupFilters.ownerHomeMonth,
            year: popupFilters.ownerHomeYear,
            ownerHomeCheckLeaders: filters.ownerHomeCheckLeaders,
            leadersList: ownerData.ownerHomeLeaders
        }
    })
    
    let {pointsCheck, pointsData, pointsId} = useSelector(({filters, ownerData}) => {
        return{
            pointsCheck: filters.ownerHomeCheckPoints,
            pointsData: ownerData.ownerHomePoints,
            pointsId: ownerData.ownerHomePointsId
        }
    })

    let [tablePageNum, setTablePageNum] = useState(0)

    useEffect(()=>{
        dispatch(fetchOwnerHomePoints(range, month, year, ownerHomeCheckLeaders))
    }, [ownerHomeCheckLeaders.length, range, month, year])

    const checkPoint = (id) =>{
        if(pointsCheck.indexOf(id) !== -1){
            let dat = pointsCheck.slice()
            dat.splice(pointsCheck.indexOf(id), 1)
            dispatch(setOwnerHomeCheckPointsDel(dat)) 
        }
        else{
            dispatch(setOwnerHomeCheckPoints(id))
        }
    }

    // const cutPath = (src) =>{
    //     if(src !== null && src !== '') return './' + src.split('/').slice(3).join('/')
    //     else return src
    // }

    const cutName = (name) =>{        
        let cut = name.split(' ')
        if(cut.length === 3){
            return cut[0] + ' ' + cut[1] + ' ' + cut[2][0] + '.'
        }
        // else if(cut.length === 2){
        //     return cut[0] + ' ' + cut[1][0] + '.'
        // }
        else return name
    }

    const addLeadersPoints = ({id, city, name, leader, leaderImage, income}, key) =>{
        return(
            <div className="owner__points-sale-line d-flex align-center justify-between" key={key}>
                <div type="checkbox" className={`owner__points-sale-checkbox ${(pointsCheck.indexOf(id) !== -1)? "active": ""}`} onClick={() => checkPoint(id)}></div>
                <div className="owner__points-sale-town">{city}</div>
                <div className="owner__points-sale-name">{name}</div>
                <div className="owner__points-sale-leader align-center">
                    <div className="owner__points-sale-leader-photo  d-flex align-center justify-center">
                        <img src={leaderImage} alt="" className="owner__points-sale-leader-photo-icon"/>
                    </div>
                    <span className="d-block" title={leader}>{cutName(leader)}</span>
                </div>
                <div className="owner__points-sale-income">{income}</div>
            </div>
        )
    }

    const pageButtons = (button) =>{
        let pet = tablePageNum
        if(button == "back"){
            setTablePageNum(tablePageNum-1)
        }
        else{
            setTablePageNum(tablePageNum+1)
        }
        // console.log(pet)
    }

	return (
		<div className="owner__points-sale">
            <div className="owner__points-sale-top mb-30 d-flex align-center justify-between">
                <div className="owner__points-sale-title owner__sales-title"><div>Точки продаж</div></div>
                <div className="owner__points-sale-search d-flex align-center" style={{display: "none"}}>
                    <div className="owner__points-sale-search-container d-flex align-center">
                        <div className="owner__points-sale-search-icon"></div>
                        <input type="text" placeholder="Поиск" className="owner__points-sale-search-input"/>                    
                        <img src="/img/close-circle.svg" alt="" className="owner__points-sale-search-close"/>
                    </div>
                    <div className="owner__points-sale-search-list">
                        <div className="owner__points-sale-search-list-title">
                            Возможно вы ищете
                        </div>
                        <div className="owner__points-sale-search-list-scrol">
                            
                        </div>
                    </div>
                </div>
                <div className="owner__points-sale-income-title green d-flex align-center" style={{display: "none"}}>
                    <span>Доход</span>
                    <div className="owner__points-sale-income-title-icon"></div>
                </div>
            </div>
            <div className="owner__points-sale-content">
                <div className="owner__points-sale-line title d-flex align-center justify-between">
                    <div type="checkbox" className={`owner__points-sale-checkbox ${(pointsCheck.length === pointsId.length)? "active": ""}`} onClick={() =>{
                            if(pointsCheck.length === pointsId.length){
                                dispatch(setOwnerHomeCheckPointsDel([]))
                            }
                            else{
                                dispatch(setOwnerHomeCheckPointsDel(pointsId))
                            }
                        }  
                    }></div>
                    <div className="owner__points-sale-town">Город</div>
                    <div className="owner__points-sale-name">Название</div>
                    <div className="owner__points-sale-leader">Руководитель</div>
                    <div className="owner__points-sale-income">Доход</div> 
                </div>
                <div className="owner__points-sale-table">
                    {
                        (window.innerWidth > 700)?(
                            <div className='owner__points-sale-table-scroll'>
                                {
                                    pointsData.map((item, key) => addLeadersPoints(item, key))
                                }
                            </div>
                        ):(
                            (tablePageNum * 5 <= pointsData.length)?(
                                pointsData.slice(tablePageNum * 5, tablePageNum * 5 + 5).map((item, key) => addLeadersPoints(item, key))
                            ):(
                                pointsData.slice(tablePageNum * 5).map((item, key) => addLeadersPoints(item, key))
                            )
                        )
                    }
                </div>
                {
                    (window.innerWidth < 700)?(
                        <div className='owner__points-sale-table-buttons d-flex justify-between'>
                            <div className={`owner__points-sale-table-buttons-item back ${(tablePageNum > 0)? 'active': ''}`} onClick={()=>{pageButtons("back")}}></div>
                            <div className={`owner__points-sale-table-buttons-item forward ${((tablePageNum === 0 && pointsData.length > 5) || pointsData.length > (tablePageNum+1) * 5)? 'active': ''}`} onClick={()=>{pageButtons("forward")}}></div>
                        </div>
                    ): null
                }
            </div>
        </div>
	)
}

export default SalesPoints;