export const setOwnerHomeRange = (item) => ({
    type: 'SET_FILTERS_OWNER_HOME_RANGE',
    payload: item,
});

export const setOwnerHomeMonth = (item) => ({
    type: 'SET_FILTERS_OWNER_HOME_MONTH',
    payload: item,
});

export const setOwnerHomeYear = (item) => ({
    type: 'SET_FILTERS_OWNER_HOME_YEAR',
    payload: item,
});

export const setOwnerWarehouseGrafRange = (item) => ({
    type: 'SET_FILTERS_OWNER_WAREHOUSE_GRAF_RANGE',
    payload: item,
});

export const setOwnerWarehouseGrafMonth = (item) => ({
    type: 'SET_FILTERS_OWNER_WAREHOUSE_GRAF_MONTH',
    payload: item,
});

export const setOwnerWarehouseGrafYear = (item) => ({
    type: 'SET_FILTERS_OWNER_WAREHOUSE_GRAF_YEAR',
    payload: item,
});

export const setOwnerWarehousePurchaseRange = (item) => ({
    type: 'SET_FILTERS_OWNER_WAREHOUSE_PURCHASE_RANGE',
    payload: item,
});

export const setOwnerWarehousePurchaseMonth = (item) => ({
    type: 'SET_FILTERS_OWNER_WAREHOUSE_PURCHASE_MONTH',
    payload: item,
});

export const setOwnerWarehousePurchaseYear = (item) => ({
    type: 'SET_FILTERS_OWNER_WAREHOUSE_PURCHASE_YEAR',
    payload: item,
});


export const setFranchiserHomeRange = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_HOME_RANGE',
    payload: item,
});

export const setFranchiserHomeMonth = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_HOME_MONTH',
    payload: item,
});

export const setFranchiserHomeYear = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_HOME_YEAR',
    payload: item,
});

export const setFranchiserPointsPrintingRange = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_POINTS_PRINTING_RANGE',
    payload: item,
});

export const setFranchiserPointsPrintingMonth = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_POINTS_PRINTING_MONTH',
    payload: item,
});

export const setFranchiserPointsPrintingYear = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_POINTS_PRINTING_YEAR',
    payload: item,
});

export const setFranchiserStaffRange = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_STAFF_RANGE',
    payload: item,
});

export const setFranchiserStaffMonth = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_STAFF_MONTH',
    payload: item,
});

export const setFranchiserStaffYear = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_STAFF_YEAR',
    payload: item,
});

export const setFranchiserSalariesRange = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_SALARIES_RANGE',
    payload: item,
});

export const setFranchiserSalariesRangeActive = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_SALARIES_RANGE_ACTIVE',
    payload: item,
});

export const setFranchiserSalariesRangeTypeSelect = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_SALARIES_RANGE_TYPE_SELECT',
    payload: item,
});

export const setFranchiserSalariesMonth = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_SALARIES_MONTH',
    payload: item,
});

export const setFranchiserSalariesYear = (item) => ({
    type: 'SET_FILTERS_FRANCHISER_SALARIES_YEAR',
    payload: item,
});

export const setSalariesTableRange = (item) => ({
    type: 'SET_FILTERS_SALARIES_TABLE_RANGE',
    payload: item,
});

export const setSalariesTableRangeActive = (item) => ({
    type: 'SET_FILTERS_STAFF_SALARIES_TABLE_RANGE_ACTIVE',
    payload: item,
});

export const setSalariesTableRangeTypeSelect = (item) => ({
    type: 'SET_FILTERS_STAFF_SALARIES_TABLE_RANGE_TYPE_SELECT',
    payload: item,
});

export const setSalariesTableMonth = (item) => ({
    type: 'SET_FILTERS_SALARIES_TABLE_MONTH',
    payload: item,
});

export const setSalariesTableYear = (item) => ({
    type: 'SET_FILTERS_SALARIES_TABLE_YEAR',
    payload: item,
});

export const setSalariesButtonsRange = (item) => ({
    type: 'SET_FILTERS_SALARIES_BUTTONS_RANGE',
    payload: item,
});

export const setSalariesButtonsMonth = (item) => ({
    type: 'SET_FILTERS_SALARIES_BUTTONS_MONTH',
    payload: item,
});

export const setSalariesButtonsYear = (item) => ({
    type: 'SET_FILTERS_SALARIES_BUTTONS_YEAR',
    payload: item,
});

export const setWorkZoneHistoryDate = (item) => ({
    type: 'SET_WORK_ZONE_HISTORY_DATE',
    payload: item,
});

export const setWorkZoneClientsDate = (item) => ({
    type: 'SET_WORK_ZONE_CLIENTS_DATE',
    payload: item,
});