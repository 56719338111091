import React from 'react';
import SalesIncome from './Sales-income/Sales-income';
import LeaderList from './Leader-list/Leader-list';

const Home = () => {
	return (
		<React.Fragment>
			<div className="franchises-leaders__title mt-15">Статистика</div>
			<div className="franchises-leaders__content">
				{/* <div className="franchises-leaders__content-left">
					<SalesIncome/>
				</div>
				<LeaderList/> */}
				<SalesIncome/>
			</div>
		</React.Fragment>
	)
}

export default Home;