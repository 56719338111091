const initialState = {
    calendDisplay: "close",
    location: ""
  };
  
  const display = (state = initialState, action) => {
    if (action.type === 'SET_CALEND_DISPLAY') {
      return {
        ...state,
        calendDisplay: action.payload,
        location: action.location
      };
    }
    return state;
  };
  
  export default display;