import React from 'react';
import SalesIncome from './Sales-income/Sales-income';
import SalesPoints from './Sales-points/Sales-points';
import LeaderList from './Leader-list/Leader-list';

const Home = () => {
	return (
		<React.Fragment>
			<div className="owner__title">Статистика</div>
			<div className="owner__content">
				<div className="owner__content-left">
					<SalesIncome/>
					<SalesPoints/>
				</div>
				<LeaderList/>
			</div>
		</React.Fragment>
	)
}

export default Home;