const initialState = {
    franchiserHomeButton: "sales",
  };
  
  const franchiserFilters = (state = initialState, action) => {
    if (action.type === 'SET_FRANCHISER_HOME_BUTTON') {
      return {
        ...state,
        franchiserHomeButton: action.payload,
      };
    }
    return state;
  };
  
  export default franchiserFilters;