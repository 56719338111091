import {React, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {setFranchiserHomeMonth, setFranchiserHomeYear} from '../../../../redux/actions/popupFilters';
import './Sales-income-1.0.scss';
 
import {setCalendDisplay} from '../../../../redux/actions/display';
import { setFranchiserHomeButton } from '../../../../redux/actions/franchiserFilters';
import { fetchFranchiserHomeSalesIncomeButtons, fetchFranchiserHomeSalesIncomeGraff } from '../../../../redux/actions/franchiserData';
import { setFranchiserHomePointsCheck, setFranchiserPointsListCheckEdit } from './../../../../redux/actions/franchiserCheckData';
import { setFranchisesNav } from './../../../../redux/actions/navigation';
import { fetchFranchiserHomePointsList } from './../../../../redux/actions/franchiserData';


const SalesIncome = () =>{
    const dispatch = useDispatch();
    const {buttonActive, salesIncomeButtonsData, range, month, year, pointsList, pointsListCheck } = useSelector(({franchiserFilters, popupFilters, franchiserData, franchiserCheckData })=> {
        return {
            buttonActive: franchiserFilters.franchiserHomeButton,
            salesIncomeButtonsData: franchiserData.franchiserHomeSalesIncomeButtons,
            range: popupFilters.franchiserHomeRange,
            month: popupFilters.franchiserHomeMonth,
            year: popupFilters.franchiserHomeYear,
            pointsList: franchiserData.franchiserHomePointsList,
            pointsListCheck: franchiserCheckData.franchiserHomePointsListCheck
        }
    })
    let dataMonth = [
        {
            id: 0,
            value: "Январь"
        },
        {
            id: 1,
            value: "Февраль"
        },
        {
            id: 2,
            value: "Март"
        },
        {
            id: 3,
            value: "Апрель"
        },
        {
            id: 4,
            value: "Май"
        },
        {
            id: 5,
            value: "Июнь"
        },
        {
            id: 6,
            value: "Июль"
        },
        {
            id: 7,
            value: "Август"
        },
        {
            id: 8,
            value: "Сентябрь"
        },
        {
            id: 9,
            value: "Октябрь"
        },
        {
            id: 10,
            value: "Ноябрь"
        },
        {
            id: 11,
            value: "Декабрь"
        }
    ];
    let defaultYear = new Date().getFullYear()
    let dataYear = [
        {
            id: 0,
            value: defaultYear - 5
        },
        {
            id: 1,
            value: defaultYear - 4
        },
        {
            id: 2,
            value: defaultYear - 3
        },
        {
            id: 3,
            value: defaultYear - 2
        },
        {
            id: 4,
            value: defaultYear - 1
        },
        {
            id: 5,
            value: defaultYear
        },
        {
            id: 6,
            value: defaultYear + 1
        },
        {
            id: 7,
            value: defaultYear + 2
        },
        {
            id: 8,
            value: defaultYear + 3
        },
        {
            id: 9,
            value: defaultYear + 4
        },
        {
            id: 10,
            value: defaultYear + 5
        }
    ]

    const addValPercent = (type, value, color) => {
        if(color === "green"){
            return(
                <div className={`franchises-leaders__sales-buttons-val-percent d-flex align-center justify-center green ${type}`}>
                    <img src="/img/arrow-up-green.svg" alt="" className="franchises-leaders__sales-buttons-val-percent-icon"/> 
                    <div>{value}%</div>
                </div> 
            )
        }
        else if(color === "red"){
            return(
                <div className={`franchises-leaders__sales-buttons-val-percent d-flex align-center justify-center red ${type}`}>
                    <img src="/img/arrow-down-red.svg " alt="" className="franchises-leaders__sales-buttons-val-percent-icon"/> 
                    <div>{value}%</div>
                </div>
            )
        }
    }

    const [monthActive, setMonthActive]  = useState(false);
    const [yearActive, setYearActive]  = useState(false);
    const [tablePageNum, setTablePageNum] = useState(0)

    useEffect(()=>{
        dispatch(fetchFranchiserHomeSalesIncomeButtons(range, month, year, pointsListCheck))
        dispatch(fetchFranchiserHomePointsList())
        dispatch(setFranchisesNav("Home"))
        sessionStorage.setItem('franchPage', 'Home')
    }, [])

    useEffect(()=>{
        dispatch(fetchFranchiserHomeSalesIncomeButtons(range, month, year, pointsListCheck))
    }, [range, month, year, pointsListCheck])

    const pageButtons = (button) =>{
        let pet = tablePageNum
        if(button == "back"){
            setTablePageNum(tablePageNum-1)
        }
        else{
            setTablePageNum(tablePageNum+1)
        }
    }


    const monthActivate = () =>{
        setMonthActive(!monthActive)
        setYearActive(false)
    }
    const yearActivate = () =>{
        setYearActive(!yearActive)
        setMonthActive(false)
    }
    const monthChange = (id) =>{
        dispatch(setFranchiserHomeMonth(dataMonth[id].value))
        setMonthActive(false)
    }
    const yearChange = (id) =>{
        dispatch(setFranchiserHomeYear(dataYear[id].value))
        setYearActive(false)
    }

    const addMonth = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(month === value)? "active": ""}`} key={key} onClick={() => {monthChange(id)}}>{value}</div>
        )
    }
    const addYear = ({id, value}, key)=>{
        return(
            <div className={`filters__block-item-list-elem ${(year === value)? "active": ""}`} key={key} onClick={() => {yearChange(id)}}>{value}</div>
        )
    }

    const addLeadersPoints = ({id, name, address='Город'}, key) =>{
        return(
            <div className={`franchises-leaders__points-line d-flex align-center justify-between franchises-leaders__points-line-scroll-${id}`} key={key}>
                <div type="checkbox" className={`franchises-leaders__points-checkbox ${(pointsListCheck.indexOf(id) !== -1)? "active": ""}`} onClick={() => dispatch(setFranchiserPointsListCheckEdit(id))}></div>
                <div className="franchises-leaders__points-name">{name}</div>
                <div className="franchises-leaders__points-address">{address}</div>
                <Link to='/point-printing' key={key} onClick={()=>{
                    dispatch(setFranchisesNav("PointPrinting"))
                    sessionStorage.setItem('page', 'PointPrinting')
                    dispatch(setFranchiserHomePointsCheck({id: id, name: name}))
                    sessionStorage.setItem('pointsCheckId', id)
                    sessionStorage.setItem('pointsCheckName', name)
                }}>
                    <img src="/img/long-arrow-right.svg" alt="" className='franchises-leaders__points-open-icon'/>
                </Link>
            </div>
        )
    }
    
    const CheckFullPoints = () =>{
        if(pointsListCheck.length === pointsList.length){
            dispatch(setFranchiserHomePointsCheck([]))
        }
        else{
            let idData = []
            pointsList.forEach(({id}) => idData.push(id))
            dispatch(setFranchiserHomePointsCheck(idData))
        }
    } 

	return (
		<div className="franchises-leaders__sales">
            <div className="franchises-leaders__sales-top d-flex align-center justify-between">
                <div className="franchises-leaders__sales-title">Продажи и Доход</div>
                <div className='franchises-leaders__sales-filters__block d-flex align-center'>
                    <div className="franchises-leaders__sales-filters__block-item sales year">
                        <div className={`franchises-leaders__sales-filters__block-item-title d-flex align-center justify-center year ${(year === "Год")? "": "active"} ${(yearActive)? "open": ""}`} onClick={yearActivate}>{year}<div className="franchises-leaders__sales-filters__block-item-title-icon"/></div>                        
                        <div className={`franchises-leaders__sales-filters__block-item-list ${(yearActive)? "open": ""}`}>
                            <div className='franchises-leaders__sales-filters__block-item-list-scroll'>
                                {
                                    dataYear.map((item, key) => addYear(item, key))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="franchises-leaders__sales-filters__block-item sales months">
                        <div className={`franchises-leaders__sales-filters__block-item-title d-flex align-center justify-center months ${(month === "Месяц")? "": "active"} ${(monthActive)? "open": ""}`} onClick={monthActivate}>{month}<div className="franchises-leaders__sales-filters__block-item-title-icon"/></div>                        
                        <div className={`franchises-leaders__sales-filters__block-item-list ${(monthActive)? "open": ""}`}>
                            <div className='franchises-leaders__sales-filters__block-item-list-scroll'>
                                {
                                    dataMonth.map((item, key) => addMonth(item, key))
                                }                               
                            </div>
                        </div>
                    </div>
                    <div className="franchises-leaders__sales-filters d-flex justify-between">
                        <div className="franchises-leaders__sales-filters months" onClick={() => {
                            dispatch(setCalendDisplay(""));
                            setTimeout(()=>{
                                dispatch(setCalendDisplay("open", "franchiserHome"))
                            }, 0)
                        }}>
                            <div className={`franchises-leaders__sales-filters-title d-flex align-center justify-center months ${(range.length > 0)? 'active': ''}`}>Фильтры<div className="franchises-leaders__sales-filters-title-icon"/></div>                        
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="franchises-leaders__sales-buttons d-flex justify-between">
                <div className={`franchises-leaders__sales-buttons-sale d-flex justify-center ${(buttonActive === "sales")? "active": ""}`} onClick={()=>{
                    dispatch(setFranchiserHomeButton("sales"))
                }}>
                    <div className="franchises-leaders__sales-buttons-icon sale align-center justify-center"><img src="/img/shopping-bag.svg" alt="" /></div>
                    <div>
                        <div className="franchises-leaders__sales-buttons-title sale">Продажи</div>
                        <div className="franchises-leaders__sales-buttons-val sale">{salesIncomeButtonsData.sales.val}</div>
                        {
                            addValPercent("sale", salesIncomeButtonsData.sales.percent, salesIncomeButtonsData.sales.class)
                        }
                    </div>
                    
                </div>
                <div className={`franchises-leaders__sales-buttons-income d-flex justify-center ${(buttonActive === "income")? "active": ""}`} onClick={()=>{
                    dispatch(setFranchiserHomeButton("income"))
                }}>
                    <div className="franchises-leaders__sales-buttons-icon income align-center justify-center"><img src="/img/dollar-circle.svg" alt="" /></div>
                    <div>
                        <div className="franchises-leaders__sales-buttons-title income">Доход</div>
                        <div className="franchises-leaders__sales-buttons-val income">{salesIncomeButtonsData.income.val}</div>
                        {
                            addValPercent("income", salesIncomeButtonsData.income.percent, salesIncomeButtonsData.income.class)
                        }
                    </div>
                </div>
            </div>
            <div className="franchises-leaders__points-container">
                <div className="franchises-leaders__points-line title d-flex align-center justify-between">
                    <div type="checkbox" className={`franchises-leaders__points-checkbox ${(pointsListCheck.length === pointsList.length)? "active": ""}`} onClick={() => CheckFullPoints()}></div>
                    <div className="franchises-leaders__points-name">Название</div>
                    <div className="franchises-leaders__points-address">Адрес</div>
                    <div className='franchises-leaders__points-open-icon'></div>
                </div>
                <div className="franchises-leaders__points-table">
                    {
                        (window.innerWidth > 700)?(
                            <div className='franchises-leaders__points-table-scroll'>
                                {
                                    pointsList.map((item, key) => addLeadersPoints(item, key))
                                }
                            </div>
                        ):(
                            (tablePageNum * 5 <= pointsList.length)?(
                                pointsList.slice(tablePageNum * 5, tablePageNum * 5 + 5).map((item, key) => addLeadersPoints(item, key))
                            ):(
                                pointsList.slice(tablePageNum * 5).map((item, key) => addLeadersPoints(item, key))
                            )
                        )
                    }
                </div>
                {
                    (window.innerWidth < 700)?(
                        <div className='franchises-leaders__points-table-buttons d-flex justify-between'>
                            <div className={`franchises-leaders__points-table-buttons-item back ${(tablePageNum > 0)? 'active': ''}`} onClick={()=>{pageButtons("back")}}></div>
                            <div className={`franchises-leaders__points-table-buttons-item forward ${((tablePageNum === 0 && pointsList.length > 5) || pointsList.length > (tablePageNum+1) * 5)? 'active': ''}`} onClick={()=>{pageButtons("forward")}}></div>
                        </div>
                    ): null
                }
            </div>
        </div>
	)
}

export default SalesIncome;