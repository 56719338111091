import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {setWorkZoneNav} from '../../../redux/actions/navigation';
import {} from '../../../redux/actions/filters';
import './Search.scss';
import { setWorkZoneSearch } from '../../../redux/actions/workZoneCheckData';
import { setPopupOpenBlock, setPopupOpenBlockEvent } from '../../../redux/actions/popupBlocks';
import { setWorkZoneClientsDate, setWorkZoneHistoryDate } from '../../../redux/actions/popupFilters';

const Search = () => {
    const dispatch = useDispatch()

    const { openBlock} = useSelector(({popupBlocks})=> {
        return {
            openBlock: popupBlocks.openBlock
        }
    })

    let [searchList, setSearchList] = useState([]);
    let [searchRecentList, setSearchRecentList] = useState((sessionStorage.getItem('workZoneSearchRecent') !== null)? JSON.parse(sessionStorage.getItem('workZoneSearchRecent')): []);
    let [inputValue, setInputValue] = useState('');
    

    const scrollToFunc = (id, type, page, elem) =>{
        dispatch(setWorkZoneNav(page))

        if(type === 'clients') dispatch(setWorkZoneClientsDate({date: elem.date}))
        if(type === 'history') dispatch(setWorkZoneHistoryDate({date: elem.date}))

        sessionStorage.setItem('page', page)
        if(searchRecentList.indexOf(elem) === -1){            
            sessionStorage.setItem('workZoneSearchRecent', JSON.stringify([...searchRecentList, elem]))
            setSearchRecentList([...searchRecentList, elem])
        }
        setSearchList([])
        setInputValue('')
        dispatch(setWorkZoneSearch({isActive: true, type: type, id: id}))
        dispatch(setPopupOpenBlock(''))
        dispatch(setPopupOpenBlockEvent(''))
        
    }

    const deleteSearchRecent = (elem) => {
        let dat = searchRecentList.slice();
        dat.splice(dat.indexOf(elem), 1)
        setSearchRecentList(dat)
        sessionStorage.setItem('workZoneSearchRecent', JSON.stringify(dat))
        if(dat.length === 0){
            dispatch(setPopupOpenBlock(''))
            dispatch(setPopupOpenBlockEvent(''))
        }
    }

    const addListItem = (item, key, type) =>{
        if(item.page === 'Workspase'){
            return(                
                <div className='d-flex justify-between align-center' key={key}>
                    <Link to='/' onClick={() => scrollToFunc(item.id, item.type, item.page, item)}>
                    {/* <Link to='/' key={key} onClick={() => {
                        dispatch(fetchWorkZoneCatalogData(item.id))
                    }}> */}
                        <div className='moderator__search-list-item'>
                            <div className='d-flex'>
                                <div className={`moderator__search-list-item-home-leader mr-10`}>Каталог: </div>
                                <div className={`moderator__search-list-item-home-leader`}>{item.name}</div>
                            </div>                       
                        </div>
                    </Link>
                    {
                        (type === 'searchRecentList')?(
                            <div className='moderator__search-list-item-delite-icon-block d-flex justify-center align-center' onClick={() => deleteSearchRecent(item)}>
                                <div className='moderator__search-list-item-delite-icon-block-center d-flex justify-center align-center'>
                                    <img src="/img/delite-icon-two.svg" alt="" onClick={() => deleteSearchRecent(item)}/>
                                </div>                                
                            </div>                        
                        ): null
                    }
                </div>
            )
        }
        else if(item.page === 'History'){
            return(
                <div className='d-flex justify-between align-center' key={key}>
                    <Link to='/history' onClick={() => scrollToFunc(item.id, item.type, item.page, item)}>
                        <div className='moderator__search-list-item'>
                            <div className='d-flex'>
                                <div className={`moderator__search-list-item-home-leader mr-10`}>История: </div>
                                <div className={`moderator__search-list-item-home-leader mr-10`}>{item.staff},</div>
                                <div className={`moderator__search-list-item-home-leader mr-10`}>{item.model}</div>
                            </div>
                                                
                        </div>
                    </Link>
                    {
                        (type === 'searchRecentList')?(
                            <div className='moderator__search-list-item-delite-icon-block d-flex justify-center align-center' onClick={() => deleteSearchRecent(item)}>
                                <div className='moderator__search-list-item-delite-icon-block-center d-flex justify-center align-center'>
                                    <img src="/img/delite-icon-two.svg" alt="" onClick={() => deleteSearchRecent(item)}/>
                                </div>                                
                            </div>                        
                        ): null
                    }
                </div>
            )
        }
        else if(item.page === 'Clients'){
            return(
                <div className='d-flex justify-between align-center' key={key}>
                    <Link to='/clients' onClick={() => scrollToFunc(item.id, item.type, item.page, item)}>
                        <div className='moderator__search-list-item'>
                            <div className='d-flex'>
                                <div className={`moderator__search-list-item-home-leader mr-10`}>Клиент: </div>
                                <div className={`moderator__search-list-item-home-leader mr-10`}>{item.phone},</div>
                                <div className={`moderator__search-list-item-home-leader mr-10`}>{item.model}</div>                            
                            </div>                    
                            
                        </div>
                    </Link>
                    {
                        (type === 'searchRecentList')?(
                            <div className='moderator__search-list-item-delite-icon-block d-flex justify-center align-center' onClick={() => deleteSearchRecent(item)}>
                                <div className='moderator__search-list-item-delite-icon-block-center d-flex justify-center align-center'>
                                    <img src="/img/delite-icon-two.svg" alt="" onClick={() => deleteSearchRecent(item)}/>
                                </div>                                
                            </div>                        
                        ): null
                    }
                </div>
            )
        }
    }

    const searchFunc = async(val) =>{
        const response =  await fetch(`/Place/globalSearch?query=${val.toLowerCase()}`, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            }
        })
        if (response.ok === true) {
            const dat = await response.json();
            console.log(dat.value.resultObject)
            return dat.value.resultObject            
        }
        return []
    }


    const searchInput = async(event) =>{
        let val = event.target.value;
        setInputValue(event.target.value.replace('[', '').replace('+', '').replace('(', '').replace(')', '').replace('*', '').replace('?', '').replace('\\', ''));
        if(val.length > 2){
            searchFunc(val).then((data) =>{
                setSearchList(data)
                if(data.length !== 0) dispatch(setPopupOpenBlock('workZoneSearchBlock'))
                else dispatch(setPopupOpenBlock(''))
            })

            // const response =  await fetch(`/Place/globalSearch?query=${val}`, {
            //     method: 'GET', 
            //     headers: {
            //         "Accept": "application/json",
            //         'Content-Type': 'application/json',
            //         "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            //     }
            // })
            // if (response.ok === true) {
            //     const dat = await response.json();
            //     data = dat.value.resultObject
            //     // console.log(data)
            // }
            // setSearchList(data)
            // if(data.length !== 0) dispatch(setPopupOpenBlock('workZoneSearchBlock'))
            // else dispatch(setPopupOpenBlock(''))
        }
        else{
            setSearchList([])
            if(searchRecentList.length !== 0){
                dispatch(setPopupOpenBlock('workZoneSearchBlock'))
            }
            else dispatch(setPopupOpenBlock(''))
        }
    }

	return (
		<div className={`owner__search-blur workZoneSearchBlock popup-open-button ${(openBlock === 'workZoneSearchBlock')? "active": ""}`} onClick={(event)=>{
            if(openBlock !== 'workZoneSearchBlock'){
                if(searchList.length !== 0 || searchRecentList.length !== 0){
                    dispatch(setPopupOpenBlock('workZoneSearchBlock'))
                    dispatch(setPopupOpenBlockEvent(event.target))
                    event.currentTarget.querySelector('input').focus()
                }
            }
        }}>
            <div className={`owner__search d-flex align-center ${(openBlock === 'workZoneSearchBlock')? "active": ""}`}>
                <div className={`owner__search-container d-flex align-center ${(openBlock === 'workZoneSearchBlock')? "active": ""}`}>
                    <div className="owner__search-icon"></div>
                    <input type="text" placeholder="Поиск" className={`owner__search-input`} value={inputValue} onInput={searchInput}/>                    
                    <img src="/img/close-circle.svg" alt="" className="owner__search-close" onClick={()=>{
                        // document.querySelector(".owner__search-input").value = "";
                        setInputValue('')
                        setSearchList([])
                        dispatch(setPopupOpenBlock(''))
                        dispatch(setPopupOpenBlockEvent(''))
                    }}/>
                </div>
                <div className={`owner__search-list ${(openBlock === 'workZoneSearchBlock')? "active": ""}`}>
                    {
                        (searchList.length === 0)? (
                            <div>
                                <div className="owner__search-list-title">
                                    Недавний поиск
                                </div>
                                <div className='pl-10 mb-10'>
                                    {
                                        ((searchRecentList.length > 5)? searchRecentList.slice(searchRecentList.length - 5): searchRecentList).map((item, key) => addListItem(item, key, 'searchRecentList'))
                                    }
                                </div>
                            </div>
                        ): null
                    }
                    {
                        (searchList.length !== 0)?(
                            <div>
                                <div className="owner__search-list-title">
                                    Возможно вы ищете
                                </div>
                                <div className="owner__search-list-scrol">
                                    {
                                        searchList.map((item, key) => addListItem(item, key, 'searchList'))
                                    }
                                    
                                </div>
                            </div>
                        ): null
                    }
                </div>
            </div>
        </div>
	)
}

export default Search;