import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setWarehouseNav } from '../../../redux/actions/navigation';
import { setPopupOpenBlock, setPopupOpenBlockEvent } from '../../../redux/actions/popupBlocks';
import { setWarehouseHistoryOrderCheck } from '../../../redux/actions/warehouseCheckData';
import { fetchWarehouseOrderHistoryData, setWarehouseOrderHistoryDataEdit } from '../../../redux/actions/warehouseData';
import './index-1.0.scss';

const OrderHistory = () => {

    const dispatch = useDispatch();
    const {warehouseOrderHistoryData, openBlock, warehouseLeaderCheck} = useSelector(({warehouseData, popupBlocks, warehouseCheckData})=> {
        return {
            warehouseOrderHistoryData: warehouseData.warehouseOrderHistoryData,
            openBlock: popupBlocks.openBlock,
            warehouseLeaderCheck: warehouseCheckData.warehouseLeaderCheck
        }
    })

    useEffect(()=>{
        dispatch(fetchWarehouseOrderHistoryData(warehouseLeaderCheck.id))
    }, [warehouseLeaderCheck])

    const fetchGet = async(url, query='')=>{
        const response =  await fetch(url + query, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            }
        })
        if (response.ok === true) {
            const dat = await response.json();
            return dat.value
        }
    }

    const StatusTextReturn = (id) =>{
        switch(id){
            case 1: return 'Обработка';
            case 2: return 'Отправлено';
            case 3: return 'Доставлено';
            default: return 'Неопределено'
        }
    }

    let statusList = [
        {
            statusId: 1,
            val: 'Обработка',
            img: '/img/warehouse-order-status-treatment.svg', 
            color: 'orange'       
        },
        {
            statusId: 2,
            val: 'Отправлено',
            img: '/img/warehouse-order-status-sent.svg',
            color: 'blue'            
        },
        {
            statusId: 3,
            val: 'Доставлено',
            img: '/img/warehouse-order-status-delivered.svg',
            color: 'green'
        }
    ]

    const StatusSelectFunc = (id, statusId) =>{
        // console.log(id, statusId)
        fetchGet(`/stock/editPackpage?id=`, `${id}&statusId=${statusId}`).then((dat)=>{
            dispatch(setWarehouseOrderHistoryDataEdit(dat.resultObject))
            dispatch(setPopupOpenBlock(''))
            dispatch(setPopupOpenBlockEvent(''))
        })
    }

    const AddStatusListFunc = ({statusId, val, img, color}, id, key) =>{
        return(
            <div className={`warehouse__order-history-status-menu-popup-item ${color} d-flex align-center`} key={key} onClick={()=> StatusSelectFunc(id, statusId)}>
                <img className='warehouse__order-history-status-menu-popup-item-icon' src={img} alt="" />
                <div className="warehouse__order-history-status-menu-popup-item-title">{val}</div>
            </div>
        )
    }

    const addTableItem = (elem, key) =>{
        let {id, date, count, statusId, statusColor, point} = elem;
        return(
            <div className={`warehouse__order-history-line d-flex align-center justify-between warehouse__order-history-line-${id}`} key={key}>
                <div className="warehouse__order-history-date">{date}</div>
                <div className="warehouse__order-history-order-number">№{id}</div>
                <div className="warehouse__order-history-point">{point}</div>
                <div className="warehouse__order-history-envelope-count">{count} шт</div>
                <div className="warehouse__order-history-status-menu-container">
                    <div className={`warehouse__order-history-status popup-open-button ${statusColor}`} onClick={(event) => {
                        dispatch(setPopupOpenBlock((openBlock === `warehouseHistoryStatusMenu_${id}`)? "": `warehouseHistoryStatusMenu_${id}`))
                        dispatch(setPopupOpenBlockEvent(event.target))
                    }}>{StatusTextReturn(statusId)}</div>
                    <div className={`warehouse__order-history-status-menu-popup warehouseHistoryStatusMenu_${id} ${(openBlock === `warehouseHistoryStatusMenu_${id}`)? "active": ""}`}>
                        {
                            statusList.map((item, key) => AddStatusListFunc(item, id, key))
                        }
                    </div>
                </div>
                <Link className="warehouse__order-history-open-icon" to='/order-history-detail' onClick={()=>{
                    dispatch(setWarehouseHistoryOrderCheck({id: id, date: date}))
                    sessionStorage.setItem('WarehouseHistoryOrderCheck', JSON.stringify({id: id, date: date}))
                    dispatch(setWarehouseNav('OrderHistoryDetail'))
                    sessionStorage.setItem('warehousePage', 'OrderHistoryDetail')
                }}>
                    <img className="warehouse__order-history-open-icon" src="/img/long-arrow-right-black.svg" alt="" />
                </Link>
            </div>
        )
    }

	return (
        <div className="warehouse__order-history">
            <div className="warehouse__order-history-top d-flex justify-between align-center">
                <div className="warehouse__order-history-top-back d-flex align-center">
                    <Link className='warehouse__order-history-top-back-icon-container' to='/leader-detail' onClick={()=>{
                        dispatch(setWarehouseNav('LeaderDetail'))
                        sessionStorage.setItem('warehousePage', 'LeaderDetail')
                    }}>
                        <img className='warehouse__order-history-top-back-icon' src="/img/arrow-left-icon.svg" alt="" /> 
                    </Link>                        
                    <div>{warehouseLeaderCheck.name}</div>
                </div>
                <div className='d-flex align-center'>
                    <Link to='/leader-detail'>
                        <div className={`warehouse__order-history-top-history-btn d-flex justify-between align-center active`}>
                            <div>История</div>
                            <div className="warehouse__order-history-top-history-btn-icon"></div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className='warehouse__order-history-table-scroll'>
                <div className="warehouse__order-history-line title d-flex align-center justify-between">
                    <div className="warehouse__order-history-date">Дата и время</div>
                    <div className="warehouse__order-history-order-number">№</div>
                    <div className="warehouse__order-history-point">Точка</div>
                    <div className="warehouse__order-history-envelope-count">Общее количество</div>
                    <div className="warehouse__order-history-status-menu-container">Статус</div>
                    <div className="warehouse__order-history-open-icon"></div>                     
                </div>
                <div className="warehouse__order-history-table">
                    {
                        warehouseOrderHistoryData.map((item, key) => addTableItem(item, key))
                    }              
                </div>
            </div>
        </div>       
	)
}

export default OrderHistory;