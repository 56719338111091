async function post(url, type, data){
    let response = await fetch(url, {
        method: type, 
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    })
    if (response.ok === true) {
        const user = await response.json();
        return user
    }
    else{
        localStorage.setItem('role', '')
    }
    let dat = response.json
    return dat 
}
let monthKey = {
    "Январь": 1,
    "Февраль": 2,
    "Март": 3,
    "Апрель": 4,
    "Май": 5,
    "Июнь": 6,
    "Июль": 7,
    "Август": 8,
    "Сентябрь": 9,
    "Октябрь": 10,
    "Ноябрь": 11,
    "Декабрь": 12
}

export const setOwnerHomeGrafData = (item) => ({
    type: 'SET_OWNER_HOME_GRAF_DATA',
    payload: item
});

export const setOwnerHomeGrafLoading = (item) => ({
type: 'SET_OWNER_HOME_GRAF_LOADING',
payload: item
});

export const fetchOwnerHomeGrafData = (buttonActive, range, month, year, ownerHomeCheckLeaders, ownerHomeCheckPoints) => (dispatch) =>{
    dispatch(setOwnerHomeGrafLoading(true))
    let data = {
        month: (month === "Месяц")? 0: monthKey[month],
        year:  (year === "Год")? 0: year,
        SFrom: (range.length > 0)? range[0].toLocaleDateString(): null,
        STo: (range.length > 0)? range[1].toLocaleDateString(): null,
        DirectorId: ownerHomeCheckLeaders,
        incSale: (buttonActive === "sale")? 1: 0,
        PlaceId: ownerHomeCheckPoints
    }

    post("/api/grafdata", "POST", data).then((response)=> {
        dispatch(setOwnerHomeGrafData(response.value))
    })

}

export const setOwnerWarehouseGrafData = (item) => ({
    type: 'SET_OWNER_WAREHOUSE_GRAF_DATA',
    payload: item
});

export const setOwnerWarehouseGrafLoading = (item) => ({
type: 'SET_OWNER_WAREHOUSE_GRAF_LOADING',
payload: item
});

export const fetchOwnerWarehouseGrafData = (buttonActive, range, month, year) => (dispatch) =>{

    dispatch(setOwnerWarehouseGrafLoading(true))
    let data = {
        month: (month === "Месяц")? 0: monthKey[month],
        year:  (year === "Год")? 0: year,
        SFrom: (range.length > 0)? range[0].toLocaleDateString(): null,
        STo: (range.length > 0)? range[1].toLocaleDateString(): null,
        incSale: (buttonActive === "purchase")? 0: 1
    }

    post("/stock/grafdata", "POST", data).then((response)=> {
        dispatch(setOwnerWarehouseGrafData(response.value))
    })
}