import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {fetchOwnerWarehouseLekalsTop} from '../../../../redux/actions/ownerData';
import './Lekals-top.scss';

const LekalsTop = () => {

    const dispatch = useDispatch();
    let {lekalsTop, range, month, year} = useSelector(({popupFilters, ownerData})=> {
        return {
            month: popupFilters.ownerWarehouseGrafMonth,
            year: popupFilters.ownerWarehouseGrafYear,
            range: popupFilters.ownerWarehouseGrafRange,
            lekalsTop: ownerData.ownerWarehouseLekalsTop
        }
    })

    let [tablePageNum, setTablePageNum] = useState(0)

    useEffect(()=>{
        dispatch(fetchOwnerWarehouseLekalsTop(range, month, year))
    }, [])

    useEffect(()=>{
        dispatch(fetchOwnerWarehouseLekalsTop(range, month, year))
    }, [range, month, year])

    const addLekalsTopItem = ({id, name, code, sales}, key) =>{
        return(
            <div className="owner__lekals-top-line d-flex align-center justify-between" key = {key}>
                <div className="owner__lekals-top-number">{id}</div>
                <div className="owner__lekals-top-name">{name}</div>
                <div className="owner__lekals-top-code">{code}</div>
                <div className="owner__lekals-top-sales">{sales}</div>
            </div>
        )
    }

    const pageButtons = (button) =>{
        let pet = tablePageNum
        if(button == "back"){
            setTablePageNum(tablePageNum-1)
        }
        else{
            setTablePageNum(tablePageNum+1)
        }
        // console.log(pet)
    }

	return (
		<div className="owner__warehouse-right">
            <div className="d-flex justify-between">
                <div className="owner__lekals-top-title">Топ 10 пленок</div>
            </div>
            <div className='owner__lekals-top-scroll'>
                <div className="owner__lekals-top-line title d-flex align-center justify-between">
                    <div className="owner__lekals-top-number title">№</div>
                    <div className="owner__lekals-top-name title">Название</div>
                    <div className="owner__lekals-top-code title">Артикул</div>
                    <div className="owner__lekals-top-sales title">Продажи</div>
                </div>
                <div className="owner__lekals-top-list">
                    {
                        // lekalsTop.map((item, key) => addLekalsTopItem(item, key))
                        (window.innerWidth > 700)?(

                            lekalsTop.map((item, key) => addLekalsTopItem(item, key))

                         ):(
                             (tablePageNum * 5 <= lekalsTop.length)?(
                                lekalsTop.slice(tablePageNum * 5, tablePageNum * 5 + 5).map((item, key) => addLekalsTopItem(item, key))
                             ):(
                                lekalsTop.slice(tablePageNum * 5).map((item, key) => addLekalsTopItem(item, key))
                             )
                         )
                    }
                </div>
            </div>
            {
                (window.innerWidth < 700)?(
                    <div className='owner__points-sale-table-buttons d-flex justify-between'>
                        <div className={`owner__points-sale-table-buttons-item back ${(tablePageNum > 0)? 'active': ''}`} onClick={()=>{pageButtons("back")}}></div>
                        <div className={`owner__points-sale-table-buttons-item forward ${((tablePageNum === 0 && lekalsTop.length > 5) || lekalsTop.length > (tablePageNum+1) * 5)? 'active': ''}`} onClick={()=>{pageButtons("forward")}}></div>
                    </div>
                ): null
            }
        </div>
	)
}

export default LekalsTop;