import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setWarehouseNav } from '../../../redux/actions/navigation';
import { setWarehousePointCheck } from '../../../redux/actions/warehouseCheckData';
import { fetchWarehouseLeaderPointDetailData } from '../../../redux/actions/warehouseData';
import './index.scss';

const LeaderPointDetail = () => {

    const dispatch = useDispatch();

    const {warehouseLeaderPointDetailData, warehousePointCheck} = useSelector(({warehouseData, warehouseCheckData})=> {
        return {
            warehouseLeaderPointDetailData: warehouseData.warehouseLeaderPointDetailData,
            warehousePointCheck: warehouseCheckData.warehousePointCheck
        }
    })

    useEffect(()=>{
        dispatch(fetchWarehouseLeaderPointDetailData(warehousePointCheck.id))
    }, [warehousePointCheck])

    const addTableItem = (elem, key) =>{
        let {id, article, name, count} = elem; 
        return(
            <div className={`warehouse__leader-point-detail-line d-flex align-center justify-between warehouse__leader-point-detail-line-${article}`} key={key}>
                <div className="warehouse__leader-point-detail-article">#{article}</div>
                <div className="warehouse__leader-point-detail-envelope-name">{name}</div>
                <div className="warehouse__leader-point-detail-envelope-count">{count} шт</div>                 
            </div>
        )
    }

	return (
        <React.Fragment>
            <div className="warehouse__leader-point-detail">
                <div className="warehouse__leader-point-detail-top d-flex justify-between align-center">
                    <div className="warehouse__leader-point-detail-top-back d-flex align-center">
                        <Link className='warehouse__leader-point-detail-top-back-icon-container' to='/leader-detail' onClick={()=>{
                            dispatch(setWarehousePointCheck({id: 0, name: ''}))
                            sessionStorage.setItem('WarehousePointCheck', JSON.stringify({id: 0, name: ''}))
                            dispatch(setWarehouseNav('LeaderDetail'))
                            sessionStorage.setItem('warehousePage', 'LeaderDetail')
                        }}>
                            <img className='warehouse__leader-point-detail-top-back-icon' src="/img/arrow-left-icon.svg" alt="" /> 
                        </Link>                        
                        <div>{warehousePointCheck.name}</div>
                    </div>
                </div>
                <div className='warehouse__leader-point-detail-table-scroll'>
                    <div className="warehouse__leader-point-detail-line title d-flex align-center justify-between">
                        <div className="warehouse__leader-point-detail-article">Артикул</div>
                        <div className="warehouse__leader-point-detail-envelope-name">Название пленки</div>
                        <div className="warehouse__leader-point-detail-envelope-count">Количество</div>                       
                    </div>
                    <div className="warehouse__leader-point-detail-table">
                        {
                            warehouseLeaderPointDetailData.map((item, key) => addTableItem(item, key))
                        }              
                    </div>
                </div>
            </div>
        </React.Fragment>        
	)
}

export default LeaderPointDetail;