import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import {setModeratorNav, setRole} from '../../../redux/actions/navigation';

import './Sidebar.1.0.scss';

const Sidebar = () =>{
    const dispatch = useDispatch();
    const { moderatorNav } = useSelector(({ navigation }) => navigation);
	return (
		<aside className="moderator__sidebar">
            <img src="/img/logo.svg" alt="" className="moderator__logo"/>
            <div className="moderator__nav">
                <div className={`moderator__nav-item-active ${moderatorNav}`}>
                </div>
                <Link to='/'>
                    <div className="moderator__nav-item d-flex align-center" onClick={()=>{
                            dispatch(setModeratorNav("Catalog"))
                            sessionStorage.setItem('moderatorNav', 'Catalog')
                        }}>
                        <div className={`moderator__nav-item-icon catalog ${(moderatorNav === "Catalog")? "active" : ""}`}></div>
                        <div className={`moderator__nav-item-title ${(moderatorNav === "Catalog")? "active" : ""}`}>Каталог</div>
                    </div>
                </Link>
                <Link to='/leaders'>
                    <div className="moderator__nav-item d-flex align-center" onClick={()=>{
                            dispatch(setModeratorNav("Leaders"))
                            sessionStorage.setItem('moderatorNav', 'Leaders')
                        }}>
                        <div className={`moderator__nav-item-icon leaders ${(moderatorNav === "Leaders")? "active" : ""}`}></div>
                        <div className={`moderator__nav-item-title ${(moderatorNav === "Leaders")? "active" : ""}`}>Руководители</div>
                    </div>
                </Link>
                <Link to='/complaints'>
                    <div className="moderator__nav-item d-flex align-center" onClick={()=>{
                            dispatch(setModeratorNav("Complaints"))
                            sessionStorage.setItem('moderatorNav', 'Complaints')
                        }}>
                        <div className={`moderator__nav-item-icon сomplaints ${(moderatorNav === "Complaints")? "active" : ""}`}></div>
                        <div className={`moderator__nav-item-title ${(moderatorNav === "Complaints")? "active" : ""}`}>Жалобы</div> 
                    </div>
                </Link>
                
            </div> 
            <div className="moderator__sidebar-footer">
                <button className="moderator__logout d-flex align-center" onClick={()=>{
                    localStorage.setItem('token', '') 
                    localStorage.setItem('role', '')
                    sessionStorage.setItem('token', '') 
                    sessionStorage.setItem('role', '')
                    sessionStorage.setItem('moderatorNav', 'Catalog')
                    dispatch(setRole(''))
                }}>
                    <img src="/img/logout.svg" alt="" className="moderator__logout-icon"/>
                    <div className="moderator__logout-title">Выйти из аккаунта</div>                   
                </button>
            </div>
        </aside>
	)
}

export default Sidebar;