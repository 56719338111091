import React, {useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import MaskedInput from '../../../MaskedInput/MaskedInput';

import { fetchWorkZoneCashbox, setWorkZoneHistoryDataEdit } from '../../../../redux/actions/workZoneData';
import { setWorkZoneNav } from '../../../../redux/actions/navigation';
import {setWorkZoneCheckClientReset} from '../../../../redux/actions/workZoneCheckData';
import { setPopupBackgroundFunc, setPopupOpenBlock} from '../../../../redux/actions/popupBlocks';
import { setWorkZoneCheckClientEdit, setWorkZoneCheckLekalo, setWorkZonePopupPrintType, setWorkZoneCheckLastStaff } from './../../../../redux/actions/workZoneCheckData';

import './index.scss';



const PrintMold = () => {
    const dispatch = useDispatch()

    const [openListBlock, setOpenListBlock] = useState({name: '', block: ''})

    const {workZoneCheckLekalo, workZoneCheckClient, workZonePopupPrintType, workZoneCheckLastStaff} = useSelector(({workZoneCheckData})=> {
        return {
            workZoneCheckLekalo: workZoneCheckData.workZoneCheckLekalo,
            workZoneCheckClient: workZoneCheckData.workZoneCheckClient,
            workZonePopupPrintType: workZoneCheckData.workZonePopupPrintType,
            workZoneCheckLastStaff: workZoneCheckData.workZoneCheckLastStaff
        }
    })
    const inputsRequared = (workZonePopupPrintType === 'watch')

    const [inform, setInform] = useState((workZoneCheckLekalo.type === 'make' || workZoneCheckClient.id !== 0)? {
        printType: {id: 0, name: (workZoneCheckClient.printType !== '' && workZoneCheckClient.printType !== null)? workZoneCheckClient.printType: ''},
        client: {id: 0, phone: (workZoneCheckClient.phone !== null)? workZoneCheckClient.phone: ''},
        envelope: {id: 0, articul: (workZoneCheckClient.envelope !== 0 && workZoneCheckClient.envelope !== null)? workZoneCheckClient.envelope: '', name: ''},
        envelopeCount: (workZoneCheckClient.envelopeCount === 0 || workZoneCheckClient.envelopeCount === '' || workZoneCheckClient.envelopeCount === null)? 0: workZoneCheckClient.envelopeCount,
        // staff: {id: 0, name: (workZoneCheckClient.staff !== '' && workZoneCheckClient.staff !== 'Не указан' && workZoneCheckClient.staff !== null)? workZoneCheckClient.staff: ''},
        staff: {id: ((workZoneCheckClient.staff === null || workZoneCheckClient.staff === 'Не указан' || workZoneCheckClient.staff === '') && workZoneCheckLastStaff.name)? workZoneCheckLastStaff.id: 0, name: (workZoneCheckClient.staff !== '' && workZoneCheckClient.staff !== 'Не указан' && workZoneCheckClient.staff !== null)? workZoneCheckClient.staff : (workZoneCheckLastStaff.name)? workZoneCheckLastStaff.name: ''},
        price: (workZoneCheckClient.price !== null && workZoneCheckClient.price !== 0)? workZoneCheckClient.price: '',
        comment: (workZoneCheckClient.comment === '' || workZoneCheckClient.comment === null)? undefined: workZoneCheckClient.comment
    } :
    {
        printType: {id: 0, name: ''},
        client: {id: 0, phone: ''},
        envelope: {id: 0, articul: '', name: ''},
        envelopeCount: 0,
        staff: (workZoneCheckLastStaff.name)? workZoneCheckLastStaff :{id: 0, name: ''},
        price: '',
        comment: ''
    })

    const [editInform, setEditInform] = useState(
    {
        printType: false,
        client: false,
        envelope: false,
        envelopeCount: false,
        staff: ((workZoneCheckClient.staff === null || workZoneCheckClient.staff === 'Не указан' || workZoneCheckClient.staff === '') && workZoneCheckLastStaff.name)? true: false,
        price: false,
        comment: false
    })

    const [informNull, setInformNull] = useState(
    {
        printType: false,
        client: false,
        envelope: false,
        staff: false,
        price: false,
        envelopeCount: false,
        comment: false
    })

    let printTypeList = {
        'Продажа': 1,
        'Другое': 3,
        'Брак': 4,
        'Гарантия': 2
    }

    const [clickAddBtn, setClickAddBtn] = useState(false)

    let [printTypeListData, setPrintTypeListData] = useState([])

    let [staffData, setStaffData] = useState([])
    
    let [clientsData, setClientsData] = useState([])

    let [envelopeData, setEnvelopeData] = useState([])

    let [parrentKey, setParrentKey] = useState(1)

    const fetchSearchFunc = async(url, query='')=>{
        const response =  await fetch(url + query, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            }
        })
        if (response.ok === true) {
            const dat = await response.json();
            return dat.value
        }
    }

    useEffect(()=>{
        fetchSearchFunc('/Place/causeList').then((value)=>{
            if(value.resultObject.length !== 0){
                setPrintTypeListData(value.resultObject)
            }
        })

        fetchSearchFunc('/Place/getWorkers').then((value)=>{
            if(value.resultObject.length !== 0){
                setStaffData(value.resultObject)
            }
        })

        if(workZonePopupPrintType === 'gurante'){
            fetchSearchFunc('/Place/getGarantiPrint?id= ', workZoneCheckClient.gurnateId).then((value) =>{
                if(value.resultObject !== null){
                    setInform({...inform,
                        envelope: {id: value.resultObject.filmId, articul: value.resultObject.envelope, name: ''},
                        envelopeCount: value.resultObject.envelopeCount,
                        price: '',
                        comment: (value.resultObject.comment !== null)? value.resultObject.comment: '',
                        printType: {id: 2, name: 'Гарантия'},
                        staff: {id: 0, name: ''},
                    })
                    setEditInform({
                        printType: true,
                        client: true,
                        envelope: true,
                        envelopeCount: true,
                        staff: false,
                        price: false,
                        comment: true
                    })
                    // dispatch(setWorkZoneCheckClient(value.resultObject))
                    setParrentKey((parrentKey==1)? 2: 1)
                    setRequaredFieldsFunc(2)
                }
            })
        }

        if(printTypeList[workZoneCheckClient.printType] === 1){
            setInformNull({printType: false, phone: true, envelope: (workZoneCheckClient.withOutFilm)? false: true, envelopeCount: (workZoneCheckClient.withOutFilm)? false: true, staff: true, price: true, comment: false })
        }
        else if(printTypeList[workZoneCheckClient.printType] === 4){
            setInformNull({printType: false, phone: false, envelope: (workZoneCheckClient.withOutFilm)? false: true, envelopeCount: (workZoneCheckClient.withOutFilm)? false: true, staff: true, price: false, comment: false })
        }
        else if(printTypeList[workZoneCheckClient.printType] === 2){
            setInformNull({printType: false, phone: true, envelope: (workZoneCheckClient.withOutFilm)? false: true, envelopeCount: (workZoneCheckClient.withOutFilm)? false: true, staff: true, price: false, comment: false })
        }
        else if(printTypeList[workZoneCheckClient.printType] === 3){
            setInformNull({printType: false, phone: false, envelope: false, envelopeCount: false, staff: true, price: false, comment: true })
        }
    }, [])

    let setRequaredFieldsFunc = (typeId) =>{
        if(typeId === 1){
            setInformNull({printType: false, phone: true, envelope: (workZoneCheckClient.withOutFilm)? false: true, envelopeCount: (workZoneCheckClient.withOutFilm)? false: true, staff: true, price: true, comment: false })
        }
        else if(typeId === 4){
            setInformNull({printType: false, phone: false, envelope: (workZoneCheckClient.withOutFilm)? false: true, envelopeCount: (workZoneCheckClient.withOutFilm)? false: true, staff: true, price: false, comment: false })
        }
        else if(typeId === 2){
            setInformNull({printType: false, phone: true, envelope: (workZoneCheckClient.withOutFilm)? false: true, envelopeCount: (workZoneCheckClient.withOutFilm)? false: true, staff: true, price: false, comment: false })
        }
        else if(typeId === 3){
            setInformNull({printType: false, phone: false, envelope: false, envelopeCount: false, staff: true, price: false, comment: true })
        }
    }

    

    const [error, setError] = useState('')

    

    const cutName = (name) =>{
        let cut = name.split(' ')
        if(cut.length === 3){
            return cut[0] + ' ' + cut[1] + ' ' + cut[2][0] + '.'
        }
        else return name
    }


    let selectListItem = (type, id, name) =>{
        if(type === 'staffList'){
            setInform({...inform, staff: {id: +id, name: name}})
            setEditInform({...editInform, staff: true})
            setOpenListBlock({name: '', block: ''})
            dispatch(setWorkZoneCheckLastStaff({id: +id, name: name}))
        }
        else if(type === 'printTypeList'){
            if(+id !== 2) setInform({...inform, printType: {id: +id, name: name}})
            else setInform({
                printType: {id: +id, name: name},
                client: {id: 0, phone: ''},
                envelope: {id: 0, articul: '', name: ''},
                envelopeCount: 1,
                staff: {id: 0, name: ''},
                price: '',
                comment: ''
            })
            setEditInform({...editInform, printType: true})
            setOpenListBlock({name: '', block: ''})
            setRequaredFieldsFunc(+id)
            console.log(informNull)          
        }
    }

    const postData = async(event) =>{
        event.preventDefault();
        if(!clickAddBtn && (editInform.printType || editInform.envelope || editInform.envelopeCount || editInform.client || editInform.price || editInform.comment || editInform.staff)){
            let obj = { Id: workZoneCheckClient.id, formalized: true, lekaloId: workZoneCheckClient.lekaloId}
            setClickAddBtn(true)
            obj.article = +inform.envelope.articul
            obj.filmCount = inform.envelopeCount
            obj.garantyId = workZoneCheckClient.gurnateId
            // if(!inform.price) obj.Summ = 0
            // if(editInform.client || true) obj.phone = inform.client.phone
            // if((editInform.price && inform.price) || true) obj.Summ = inform.price
            // if(editInform.printType || true) obj.couseId = inform.printType.id
            // if(editInform.comment || true) obj.comment = inform.comment
            // if(editInform.staff || true) obj.workerId = inform.staff.id

            let printTypeId = inform.printType.id;
            if(printTypeId === 0 && inform.printType.name !== ''){
                printTypeId = printTypeListData.find(elem => elem.name === inform.printType.name).id
            }

            if(!inform.price) obj.Summ = 0 
            else obj.Summ = +inform.price
            if(inform.client.phone && inform.client.phone.replace(/\D/g, '').length === 11) obj.phone = inform.client.phone
            // if(inform.client.phone) obj.phone = inform.client.phone
            if(inform.printType.name) obj.couseId = printTypeId;
            if(inform.comment) obj.comment = inform.comment
            if(inform.staff.name) obj.workerId = inform.staff.id
            setError('')

            const response =  await fetch('/Place/EditPrint', {
                method: 'POST', 
                headers: {
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                },
                body: JSON.stringify(obj)
            })
            if (response.ok === true) {
                const dat = await response.json();
                console.log(dat.value.isSuccess, dat.value)
                if(dat.value.isSuccess){
                    dispatch(setWorkZoneHistoryDataEdit(dat.value.resultObject))
                    dispatch(fetchWorkZoneCashbox())
                    dispatch(setPopupBackgroundFunc(false))
                    dispatch(setPopupOpenBlock(''))
                    dispatch(setWorkZoneCheckClientReset())
                    dispatch(setWorkZonePopupPrintType('interact')) 
                }
                else{
                    setError(dat.value.informMessage)
                }
                setClickAddBtn(false)      
            }
            else{
                setClickAddBtn(false)
            }
        }
        else if(!clickAddBtn){
            setError('Изменений не найдено*')
        }        
    }

    const getClientList = (event) =>{
        setInform({...inform, client: {...inform.client, phone: event.target.value}})
        if(event.target.value.length > 2){
            fetchSearchFunc('/Place/SearchClient?query=', event.target.value).then((value) =>{
                if(value.resultObject.length !== 0){
                    setClientsData(value.resultObject)
                    setOpenListBlock({name: 'PrintMoldGuranteList', block: event.target.parentNode})
                }
            }) 
        }
        else{
            setClientsData([])
            setOpenListBlock({name: '', block: ''})
        }
    }

    const getEnvelopeList = (event) =>{
        setInform({...inform, envelope: {...inform.envelope, articul: event.target.value}})
        setEditInform({...editInform, envelope: true})
        if(event.target.value.length !== 0){
            fetchSearchFunc('/Place/SearchFilm?query=', event.target.value).then((value) =>{
                if(value.resultObject.length !== 0){
                    setEnvelopeData(value.resultObject)
                    setOpenListBlock({name: 'PrintMoldEnvelopeList', block: event.target.parentNode})
                }
            })            
        }
        else {
            setEnvelopeData([])
            setOpenListBlock({name: '', block: ''})
        }
    }

    let addSelectListItem = ({id, name}, key, type) =>{
        return(
            <div className={`moderator__popup-select-list-item`} key={key} onClick={()=> selectListItem(type, id, name, key)}>
                {cutName(name)}
            </div>
        )
    }

    

    let addClentListItem = ({id, phone, date}, key) =>{
        return(
            <div className={`moderator__popup-client-list-item ${(date < 0)? 'none': ''}`} key={key} onClick={()=> {
                setOpenListBlock({name: '', block: ''})
                dispatch(setWorkZoneCheckClientEdit({gurnateId: id}))
                fetchSearchFunc('/Place/getGarantiPrint?id=', id).then((value) =>{                    
                    if(value.resultObject !== null){
                        setInform({...inform,
                            client: {id: id, phone: phone},
                            envelope: {id: value.resultObject.filmId, articul: value.resultObject.envelope, name: ''},
                            envelopeCount: value.resultObject.envelopeCount,
                            price: '',
                            comment: (value.resultObject.comment !== null)? value.resultObject.comment: ''
                        })
                        setEditInform({
                            printType: true,
                            client: true,
                            envelope: true,
                            envelopeCount: true,
                            staff: false,
                            price: false,
                            comment: true
                        })
                        setParrentKey((parrentKey==1)? 2: 1)
                    }
                })
            }}>
                {phone} <span>(срок: {(date<0)? 0: date})</span>
            </div>
        )
    }

    let addEnvelopeListItem = ({id, article, name}, key) =>{
        return(
            <div className={`moderator__popup-client-list-item`} key={key} onClick={()=> {
                setInform({...inform, envelope: {id, articul: article, name}})
                setEditInform({...editInform, envelope: true})
                setOpenListBlock({name: '', block: ''})
            }}>
                {/* {article} */}
                {name} <span>({article})</span>
            </div>
        )
    }
    const closePopup = (event) =>{ 
        if(openListBlock.name !== '' && !event.target.classList.contains('popup-open-button')){
            let block = document.querySelector(`.${openListBlock.name}`)
            let clickBlock = event.target; 
            if(clickBlock !== openListBlock.block && clickBlock !== block && !block.contains(clickBlock)){
                setOpenListBlock({name: '', block: ''})
            }
        }
    }

	return (
            <div className="moderator__popup-container" porew={parrentKey} onClick={(event) => closePopup(event)}>
                <div className="moderator__popup-top d-flex justify-between align-center add-leaders">
                    <div className="popup__title blue">Печать</div>
                    <div className="popup__close-btn" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                        dispatch(setWorkZoneCheckClientReset())
                        dispatch(setWorkZonePopupPrintType('interact'))
                        sessionStorage.setItem('workZonePopupPrintType', 'interact')
                    }}></div>
                </div>
                <form action="" onSubmit={postData}>
                    <label htmlFor="" className='moderator__popup-label'>Лекало</label>
                    <input name='Login' type="text" readOnly value={(workZoneCheckClient.id !== 0)? workZoneCheckClient.model: workZoneCheckLekalo.name} className="moderator__popup-input"/>  
                    <div className="moderator__popup-input-container d-flex justify-between">
                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Тип печати<span className={(informNull.printType)? '': 'none'}>*</span></label>
                            <div className="moderator__popup-select-container d-flex align-center">
                                <div className={`moderator__popup-select popup-open-button ${(openListBlock.name === 'PrintMoldPrintTypeList')? 'active': ''} ${(informNull.printType)? 'red': ''} ${(inputsRequared)? 'deactive': ''}`} onClick={(event)=>{
                                        if(!inputsRequared){
                                            if(openListBlock.name === 'PrintMoldPrintTypeList') setOpenListBlock({name: '', block: ''})
                                            else setOpenListBlock({name: 'PrintMoldPrintTypeList', block: event.target})
                                        }
                                    }}>
                                    {
                                        (inform.printType.name === '' || inform.printType.name === null)? <div className='moderator__popup-select-null'>Выбери тип печати</div>: inform.printType.name
                                    }
                                </div>                            
                                <div className={`moderator__popup-select-list PrintMoldPrintTypeList ${(openListBlock.name === 'PrintMoldPrintTypeList')? 'active': ''}`}>
                                    <div className="moderator__popup-select-list-scroll">
                                        {
                                            printTypeListData.map((item, key) => addSelectListItem(item, key, 'printTypeList'))
                                        }                                            
                                    </div>
                                </div>
                            </div>
                        </div> 

                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>{(inform.printType.id !== 2)? 'Сотовый клиента': 'Поиск клиента'}<span className={(informNull.phone)? '': 'none'}>*</span></label>
                            {
                                (inform.printType.id !== 2)? ( //Если не гарантия
                                    <MaskedInput
                                        className={`moderator__popup-input ${(informNull.client)? 'red': ''}`}
                                        mask="+7(999) 999-99-99"
                                        defaultValue={inform.client.phone}
                                        name = 'phone'
                                        readOnly={(inputsRequared)? true: false}
                                        placeholder='Введите номер клиента'
                                        onChange={(event)=>{
                                        setInform({...inform, client:{id: 0, phone: event.target.value} })
                                        setEditInform({...editInform, client: true})
                                        }}
                                    />
                                ):(
                                    <div className="moderator__popup-client-container d-flex align-center">
                                        <div className={`moderator__popup-client-input-container popup-open-button ${(openListBlock.name === 'PrintMoldGuranteList')? 'active': ''} ${(informNull.phone)? 'red': ''}`}>
                                            <input name='Login' readOnly={(inputsRequared)? true: false} type="text" value={inform.client.phone} placeholder='Введите номер клиента' className={`moderator__popup-client-input popup-open-button ${(openListBlock.name === 'PrintMoldGuranteList')? 'active': ''}`} onInput={(event)=> getClientList(event)} onClick={(event)=> {if(!inputsRequared)getClientList(event)}}/>                                                
                                        </div>                            
                                        <div className={`moderator__popup-client-list PrintMoldGuranteList ${(openListBlock.name === 'PrintMoldGuranteList')? 'active': ''}`}>
                                            <div className="moderator__popup-client-list-scroll">
                                                {
                                                    clientsData.map((item, key) => addClentListItem(item, key))
                                                }                                                    
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>   
                    </div>
                    <div className="moderator__popup-input-container d-flex justify-between">
                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Пленка<span className={(informNull.envelope)? '': 'none'}>*</span></label>
                            <div className="moderator__popup-client-container d-flex align-center">
                                <div className={`moderator__popup-client-input-container popup-open-button ${(openListBlock.name === 'PrintMoldEnvelopeList')? 'active': ''}`}>
                                    <input name='Login' autoComplete='off' readOnly={(inputsRequared)? true: false} type="number" value={inform.envelope.articul} placeholder='Введите артикул пленки' className={`moderator__popup-client-input popup-open-button ${(openListBlock.name === 'PrintMoldEnvelopeList')? 'active': ''} ${(informNull.envelope)? 'red': ''}`} onInput={(event)=> getEnvelopeList(event)} onClick={(event)=> {if(!inputsRequared)getEnvelopeList(event)}}/>                                                
                                </div>                            
                                <div className={`moderator__popup-client-list PrintMoldEnvelopeList ${(openListBlock.name === 'PrintMoldEnvelopeList')? 'active': ''}`}>
                                    <div className="moderator__popup-client-list-scroll">
                                        {
                                            envelopeData.map((item, key) => addEnvelopeListItem(item, key))
                                        }                                                    
                                    </div>
                                </div>
                            </div>
                        </div> 

                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Количество пленки<span className={(informNull.envelopeCount)? '': 'none'}>*</span></label>
                            <div className={`moderator__popup-btn-input-container d-flex align-center justify-between ${(informNull.envelopeCount)? 'red': ''}`}>
                                <button type="button" className={`moderator__popup-btn-input-button  ${(inputsRequared)? 'deactive': ''}`} onClick={() =>{
                                    if(inform.envelopeCount > 0 && !inputsRequared){
                                        setInform({...inform, envelopeCount: inform.envelopeCount - 1})
                                        setEditInform({...editInform, envelopeCount: true})
                                    }
                                }}>
                                </button>

                                <input type="number" readOnly={(inputsRequared)? true: false} step="1" value={inform.envelopeCount} className="moderator__popup-btn-input-val" onInput={(event) =>{
                                    setInform({...inform, envelopeCount: +event.target.value})
                                    setEditInform({...editInform, envelopeCount: true})
                                }}/>

                                <button type="button" className={`moderator__popup-btn-input-button plus  ${(inputsRequared)? 'deactive': ''}`} onClick={() =>{
                                    if(!inputsRequared){
                                        setInform({...inform, envelopeCount: inform.envelopeCount + 1})
                                        setEditInform({...editInform, envelopeCount: true})
                                    }
                                    
                                }}>
                                </button>
                            </div>
                        </div>   
                    </div> 
                    <div className="moderator__popup-input-container d-flex justify-between">
                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Сотрудник<span className={(informNull.staff)? '': 'none'}>*</span></label>
                            <div className="moderator__popup-select-container d-flex align-center">
                                <div className={`moderator__popup-select popup-open-button ${(openListBlock.name === 'PrintMoldStaffList')? 'active': ''} ${(informNull.staff)? 'red': ''} ${(inputsRequared)? 'deactive': ''}`} onClick={(event)=>{
                                        if(!inputsRequared){
                                            if(openListBlock.name === 'PrintMoldStaffList') setOpenListBlock({name: '', block: ''})
                                            else setOpenListBlock({name: 'PrintMoldStaffList', block: event.target})
                                        }
                                        
                                    }}>
                                    {
                                        (inform.staff.name === '' || inform.staff.name === null)? <div className='moderator__popup-select-null'>Выберите сотрудника</div>: cutName(inform.staff.name)
                                    }
                                </div>                            
                                <div className={`moderator__popup-select-list PrintMoldStaffList ${(openListBlock.name === 'PrintMoldStaffList')? 'active': ''}`}>
                                    <div className="moderator__popup-select-list-scroll">
                                        {
                                            staffData.map((item, key) => addSelectListItem(item, key, 'staffList'))
                                        }                                           
                                    </div>
                                </div>
                            </div>
                        </div> 

                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Цена<span className={(informNull.price)? '': 'none'}>*</span></label>
                            <input name='Login' readOnly={(inputsRequared)? true: false} type="number" value={inform.price} placeholder='Введите цену' className={`moderator__popup-input ${(informNull.price)? 'red': ''}`} onInput={(event)=>{
                                setInform({...inform, price: event.target.value})
                                setEditInform({...editInform, price: true})
                            }}/>
                        </div>   
                    </div> 

                    <label htmlFor="" className='moderator__popup-label'>Комментарий<span className={(informNull.comment)? '': 'none'}>*</span></label>
                    <div className="moderator__popup-description-input-container">
                        <textarea name="" id="" readOnly={(inputsRequared)? true: false} className={`moderator__popup-description-input ${(informNull.comment)? 'red': ''}`} value={inform.comment} placeholder='Введите комментарий к продаже'
                        onInput={(event)=>{
                            setInform({...inform, comment: event.target.value})
                            setEditInform({...editInform, comment: true})
                        }}>
                                                    
                        </textarea>
                    </div>
                    <div className='popup__error'>{error}</div>               
                    {
                        (!inputsRequared)? <button type='submit' className={`moderator__popup-save ${(clickAddBtn)? 'deactive': ''}`}>Оформить печать</button>:
                        <Link to='/' onClick={() =>{
                            fetchSearchFunc('/Place/goOnGarantyPrint?id=', workZoneCheckClient.id).then(value =>{
                                if(value.isSuccess){
                                    dispatch(setWorkZoneNav('Workspase'))
                                    sessionStorage.setItem('workzonePage', 'Workspase')
                                    dispatch(setPopupBackgroundFunc(false))
                                    dispatch(setPopupOpenBlock(''))
                                    dispatch(setWorkZonePopupPrintType('gurante'))
                                    dispatch(setWorkZoneCheckClientEdit({gurnateId: workZoneCheckClient.id}))                   
                                    sessionStorage.setItem('workZonePopupPrintType', 'gurante')

                                    dispatch(setWorkZoneCheckLekalo({id: value.resultObject.lekaloId, src: value.resultObject.src, width: value.resultObject.width +  value.resultObject.eWidth, height: value.resultObject.height +  value.resultObject.eHeight, name: workZoneCheckClient.model, defaultWidth: value.resultObject.width, defaultHeight: value.resultObject.height}))
                                    sessionStorage.setItem('lekaloCheckHeight', value.resultObject.height +  value.resultObject.eHeight)
                                    sessionStorage.setItem('lekaloCheckWidth', value.resultObject.width +  value.resultObject.eWidth)
                                    sessionStorage.setItem('lekaloCheckDefaultHeight', value.resultObject.height)
                                    sessionStorage.setItem('lekaloCheckDefaultWidth', value.resultObject.width)
                                    sessionStorage.setItem('lekaloCheckId', value.resultObject.lekaloId)
                                    sessionStorage.setItem('lekaloCheckName', workZoneCheckClient.model)
                                    sessionStorage.setItem('lekaloCheckSrc', value.resultObject.src)
                                }
                            })                            
                        }}>
                            <div className={`moderator__popup-save`}>Перейти к печати</div>
                        </Link>
                    }    
                </form>                    
            </div>
	)
}
export default PrintMold;