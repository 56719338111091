export const setWarehouseHomeData = (item) => ({
    type: 'SET_WAREHOUSE_HOME_DATA',
    payload: item
});

export const setWarehouseHomeDataAdd = (item) => ({
    type: 'SET_WAREHOUSE_HOME_DATA_ADD',
    payload: item
});

export const setWarehouseHomeDataEdit = (item) => ({
    type: 'SET_WAREHOUSE_HOME_DATA_EDIT',
    payload: item
});

export const setWarehouseHomeDataDelete = (item) => ({
    type: 'SET_WAREHOUSE_HOME_DATA_DELETE',
    id: item
});

export const setWarehouseLeadersData = (item) => ({
    type: 'SET_WAREHOUSE_LEADERS_DATA',
    payload: item
});

export const setWarehouseLeaderPointsData = (item) => ({
    type: 'SET_WAREHOUSE_LEADER_POINTS_DATA',
    payload: item
});

export const setWarehouseLeaderPointDetailData = (item) => ({
    type: 'SET_WAREHOUSE_LEADER_POINT_DETAIL_DATA',
    payload: item
});

export const setWarehouseOrderHistoryData = (item) => ({
    type: 'SET_WAREHOUSE_ORDER_HISTORY_DATA',
    payload: item
});

export const setWarehouseOrderHistoryDataEdit = (item) => ({
    type: 'SET_WAREHOUSE_ORDER_HISTORY_DATA_EDIT',
    payload: item
});

export const setWarehouseOrderHistoryDetailData = (item) => ({
    type: 'SET_WAREHOUSE_ORDER_HISTORY_DETAIL_DATA',
    payload: item
});

export const setLeadersListData = (item) => ({
    type: 'SET_LEADERS_LIST_DATA',
    payload: item
});

export const setWarehousePointsListData = (item) => ({
    type: 'SET_POINTS_LIST_DATA',
    payload: item
});




async function post(url, type, data = null){
    let response = await fetch(url, {
        method: type, 
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    })
    if (response.ok === true) {
        const user = await response.json();
        return user
    }
    else{
        localStorage.setItem('role', '')
    }
}

async function postNotData(url, type){
    let response = await fetch(url, {
        method: type, 
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
        },
    })
    if (response.ok === true) {
        const user = await response.json();
        // console.log(user.value.resultObject)
        return user
    }
    else{
        return response.json();
    }
}

export const fetchWarehouseHomeData = () => (dispatch) =>{
    postNotData(`/stock/getStockFilms`, "GET").then((response)=> {
        dispatch(setWarehouseHomeData((response.value.resultObject === null)? []: response.value.resultObject))
    })
}

export const fetchWarehouseLeadersData = () => (dispatch) =>{
    postNotData(`/stock/getDirectots`, "GET").then((response)=> {
        dispatch(setWarehouseLeadersData((response.value.resultObject === null)? '': response.value.resultObject))
    })    
}

export const fetchWarehouseLeaderPointsData = (id) => (dispatch) =>{
    postNotData(`/stock/getPlace?id=${id}`, "GET").then((response)=> {
        dispatch(setWarehouseLeaderPointsData((response.value.resultObject === null)? []: response.value.resultObject))
    })
}

export const fetchWarehouseLeaderPointDetailData = (id) => (dispatch) =>{
    postNotData(`/stock/getPlaceStock?id=${id}`, "GET").then((response)=> {
        dispatch(setWarehouseLeaderPointDetailData((response.value.resultObject === null)? []: response.value.resultObject))
    })
}

export const fetchWarehouseOrderHistoryData = (id) => (dispatch) =>{
    postNotData(`/stock/getStockHistory?id=${id}`, "GET").then((response)=> {
        dispatch(setWarehouseOrderHistoryData((response.value.resultObject === null)? []: response.value.resultObject))
    })
}

export const fetchWarehouseOrderHistoryDetailData = (id) => (dispatch) =>{
    postNotData(`/stock/getPackpage?id=${id}`, "GET").then((response)=> {
        dispatch(setWarehouseOrderHistoryDetailData((response.value.resultObject === null)? []: response.value.resultObject))
    })
}

export const fetchLeadersListData = () => (dispatch) =>{
    postNotData(`/stock/getDirectots`, "GET").then((response)=> {
        dispatch(setLeadersListData((response.value.resultObject === null)? []: response.value.resultObject))
    })
}

export const fetchPointsListData = (id) => (dispatch) =>{
    postNotData(`/stock/getPlace?id=${id}`, "GET").then((response)=> {
        dispatch(setWarehousePointsListData((response.value.resultObject === null)? []: response.value.resultObject))
    })
}