import React from 'react';
import {useSelector, useDispatch} from 'react-redux';


import AddStaff from './AddStaff/AddStaff'
import TransferMold from './TransferMold/TransferMold'
import AddEvent from './AddEvent/AddEvent'
import LayoffsPopup from './LayoffsPopup/LayoffsPopup'
import { setPopupBackgroundFunc, setPopupOpenBlock } from '../../../redux/actions/popupBlocks';

import './index.scss';

const Popup = () => {
    const dispatch = useDispatch()
    const { popupBack, openBlock } = useSelector(({ popupBlocks}) => popupBlocks);

    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target){
            dispatch(setPopupBackgroundFunc(false))
            dispatch(setPopupOpenBlock(''))
        }
    }
	return (
        <div className={`popup__back ${popupBack}`}>
            <div className="popup__back-wrap d-flex justify-center align-center"  onClick={(event)=> ClosePopup(event)}>
                {
                    (openBlock === "AddStaff")? <AddStaff/>
                    :(openBlock === 'TransferMold')? <TransferMold  edit={false}/>
                    :(openBlock === 'AddEvent')? <AddEvent/>
                    :(openBlock === 'LayoffsStaff')? <LayoffsPopup/>
                    : null
                }
            </div>
        </div>
	)
}
export default Popup;