export const setRole = (item) => ({
  type: 'SET_ROLE',
  payload: item,
});

export const setLeadersNav = (item) => ({
  type: 'SET_LEADERS_NAVIGATION',
  payload: item,
});

export const setOwnerNav = (item) => ({
  type: 'SET_OWNER_NAVIGATION',
  payload: item,
});

export const setModeratorNav = (item) => ({
  type: 'SET_MODERATOR_NAVIGATION',
  payload: item,
});

export const setFranchisesNav = (item) => ({
  type: 'SET_FRANCHISES_NAVIGATION',
  payload: item,
});
export const setWorkZoneNav = (item) => ({
  type: 'SET_WORKZONE_NAVIGATION',
  payload: item,
});

export const setSession = (item) => ({
  type: 'SET_SESSION',
  payload: item,
});

export const setUserData = (item) => ({
  type: 'SET_USER_DATA',
  payload: item,
});

export const setWarehouseNav = (item) => ({
  type: 'SET_WAREHOUSE_NAVIGATION',
  payload: item,
});

export const setSalariesCalendLocation = (item) => ({
  type: 'SET_SALARIES_CALEND_LOACATION',
  payload: item,
});

export const setNotificationActive = (item) => ({
  type: 'SET_NOTIFICATION_ACTIVE',
  payload: item,
});