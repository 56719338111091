import React, {useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setPopupBackgroundFunc, setPopupOpenBlock, setPopupOpenBlockEvent} from '../../../../redux/actions/popupBlocks';
import { fetchLeadersListData, fetchPointsListData, fetchWarehouseHomeData } from '../../../../redux/actions/warehouseData';

import './index.scss';
import { setWarehouseHomeTransferLeaderCheck } from './../../../../redux/actions/warehouseCheckData';
import { setWarehousePointsListData } from './../../../../redux/actions/warehouseData';

const TransferEnvelope = () => {
    const dispatch = useDispatch()
    
    const {warehouseLadersListlData, warehouseTransferEnvelopeCheck, warehousePointsListlData, warehouseHomeTransferLeaderCheck} = useSelector(({warehouseData, warehouseCheckData})=> {
        return {
            warehouseLadersListlData: warehouseData.warehouseLadersListlData,
            warehouseTransferEnvelopeCheck: warehouseCheckData.warehouseTransferEnvelopeCheck,
            warehouseHomeTransferLeaderCheck: warehouseCheckData.warehouseHomeTransferLeaderCheck,
            warehousePointsListlData: warehouseData.warehousePointsListlData,
        }
    })

    useEffect(()=>{
        if(warehouseLadersListlData.length === 0) dispatch(fetchLeadersListData())
        dispatch(setWarehouseHomeTransferLeaderCheck({id: 0, name: ''}))
        dispatch(setWarehousePointsListData([]))
    }, [])

    useEffect(()=>{
        if(warehouseHomeTransferLeaderCheck.id !== 0) {
            dispatch(fetchPointsListData(warehouseHomeTransferLeaderCheck.id)) 
        }
    }, [warehouseHomeTransferLeaderCheck])

    const [leaderListIsActive, setLeaderListIsActive] = useState({elem: '', name: ''})
    const [pointListIsActive, setPointListIsActive] = useState({elem: '', name: ''})
    const [error, setError] = useState('')
    const [leaderListData, setLeaderListData] = useState([])
    const [pointsListData, setPointsListData] = useState([])
    const [selectLeader, setSelectLeader] = useState({id: 0, name: ''})
    const [selectPoint, setSelectPoint] = useState({id: 0, name: ''})
    const [clickAddBtn, setClickAddBtn] = useState(false)

    const postData = async(event) =>{
        event.preventDefault()
        if(!clickAddBtn){
            setClickAddBtn(true)
            const response =  await fetch('/stock/sendFilms', {
                method: 'POST', 
                headers: {
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                },
                body: JSON.stringify({directotId: selectLeader.id, films: warehouseTransferEnvelopeCheck, placeId: (selectPoint.id === 0)? null: selectPoint.id})
            })
            if (response.ok === true) {
                const dat = await response.json();
                dispatch(setPopupBackgroundFunc(false))
                dispatch(setPopupOpenBlock(''))
                dispatch(fetchWarehouseHomeData())
                setError('')
                setClickAddBtn(false)     
            }
            else{
                setClickAddBtn(false)
            }
        }
    }

    const CloseSelectList = (event) =>{
        if(leaderListIsActive.name !== '' && !event.target.classList.contains('popup-open-button')){
            let block = document.querySelector(`.${leaderListIsActive.name}`)
            let clickBlock = event.target; 
            if(clickBlock !== leaderListIsActive.elem && clickBlock !== block && !block.contains(clickBlock)){
                setLeaderListIsActive({elem: '', name: ''})
            }
        
        }

        if(pointListIsActive.name !== '' && !event.target.classList.contains('popup-open-button')){
            let block = document.querySelector(`.${pointListIsActive.name}`)
            let clickBlock = event.target; 
            if(clickBlock !== pointListIsActive.elem && clickBlock !== block && !block.contains(clickBlock)){
                setPointListIsActive({elem: '', name: ''})
            }
        
        }
    }

    const AddSelectList = (item, key) => {
        let {id, name} = item;
        return(
            <div className='warehouse__popup-select-list-item' key={key} onClick={()=>{
                setLeaderListIsActive({elem: '', name: ''})
                setSelectLeader({id, name})
                dispatch(setWarehouseHomeTransferLeaderCheck({id, name}))
            }}>{name}</div>
        )
    }

    const searchInputFunc = (event) =>{
        let val = event.target.value;
        setSelectLeader({id: 0, name: val})
        if(val !== ''){
            let data = warehouseLadersListlData.filter((item) => item.name.toLocaleLowerCase().search(val.toLocaleLowerCase()) !== -1)
            setLeaderListData(data)
        }
        else{
            setLeaderListData([])
        }
    }

    const AddSelectPointsList = (item, key) => {
        let {id, name} = item;
        return(
            <div className='warehouse__popup-select-list-item' key={key} onClick={()=>{
                setPointListIsActive({elem: '', name: ''})
                setSelectPoint({id, name})
                // dispatch(setWarehouseHomeTransferLeaderCheck({id, name}))
            }}>{name}</div>
        )
    }

    const searchInputPointsFunc = (event) =>{
        let val = event.target.value;
        setSelectPoint({id: 0, name: val})
        if(val !== ''){
            let data = warehousePointsListlData.filter((item) => item.name.toLocaleLowerCase().search(val.toLocaleLowerCase()) !== -1)
            setPointsListData(data)
        }
        else{
            setPointsListData([])
        }
    }

	return (
        <div className="warehouse__popup-container" onClick={CloseSelectList}>
            <div className="warehouse__popup-top d-flex justify-between align-center add-leaders">
                <div className="popup__title blue">Печать</div>
                <div className="popup__close-btn" onClick={()=>{
                    dispatch(setPopupBackgroundFunc(false))
                    dispatch(setPopupOpenBlock(''))
                }}></div>
            </div>
            <form action="" onSubmit={postData}>
            <label htmlFor="" className='warehouse__popup-label'>Руководитель</label>                
            <div className="warehouse__popup-select-container d-flex align-center ">
                <input name='LeaderName' autoComplete="off" type="text" placeholder='Выберите руководителя' value={selectLeader.name} className={`warehouse__popup-select ${(leaderListIsActive.name === 'warehouseTransferEnvelopeLeaderList' && (leaderListData.length !== 0 || selectLeader.name === ''))? "active deactive": ""}`} onInput={searchInputFunc} onClick={(event) => {
                    setLeaderListIsActive((leaderListIsActive !== 'warehouseTransferEnvelopeLeaderList')? {elem: event.target, name: 'warehouseTransferEnvelopeLeaderList'}: {elem: '', name: ''})
                }}/>                           
                <div className={`warehouse__popup-select-list warehouseTransferEnvelopeLeaderList ${(leaderListIsActive.name === 'warehouseTransferEnvelopeLeaderList' && (leaderListData.length !== 0 || selectLeader.name === ''))? "active": ""}`}>
                    <div className="warehouse__popup-select-list-scroll">
                            {
                            ((selectLeader.name !== '')? leaderListData: warehouseLadersListlData).map((item, key)=>AddSelectList(item, key))
                            }                                          
                    </div>
                </div>
            </div>

            <label htmlFor="" className='warehouse__popup-label'>Точка</label>                
            <div className="warehouse__popup-select-container d-flex align-center ">
                <input name='LeaderName' autoComplete="off" readOnly={(warehouseHomeTransferLeaderCheck.id === 0)} type="text" placeholder='Выберите точку' value={selectPoint.name} className={`warehouse__popup-select ${(pointListIsActive.name === 'warehouseTransferPointsList' && (pointsListData.length !== 0 || selectPoint.name === ''))? "active deactive": ""}`} onInput={searchInputPointsFunc} onClick={(event) => {
                    if(warehouseHomeTransferLeaderCheck.id !== 0) setPointListIsActive((pointListIsActive !== 'warehouseTransferPointsList')? {elem: event.target, name: 'warehouseTransferPointsList'}: {elem: '', name: ''})
                }}/>
                <div className={`warehouse__popup-select-list warehouseTransferPointsList ${(pointListIsActive.name === 'warehouseTransferPointsList' && (pointsListData.length !== 0 || selectPoint.name === ''))? "active": ""}`}>
                    <div className="warehouse__popup-select-list-scroll">
                        <div className='warehouse__popup-select-list-item' onClick={()=>{
                            setPointListIsActive({elem: '', name: ''})
                            setSelectPoint({id: 0, name: 'Склад'})
                        }}>Склад</div>
                        {
                        ((selectPoint.name !== '')? pointsListData: warehousePointsListlData).map((item, key)=>AddSelectPointsList(item, key))
                        }                                          
                    </div>
                </div>
            </div>

                <div className='popup__error'>{error}</div>
                <button type='submit' className={`warehouse__popup-save d-flex align-center justify-center ${(clickAddBtn)? 'deactive': ''}`}>Добавить <img className="warehouse__popup-save-icon"src="/img/plus-white-icon.svg" alt="" /></button>
                    
            </form>                    
        </div>
	)
}
export default TransferEnvelope;