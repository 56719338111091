import React, {useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { setFranchiserStaffListAdd } from '../../../../redux/actions/franchiserData';
import { setModeratorLeaderEditCheck } from '../../../../redux/actions/moderatorCheckData';
import { setModeratorLeaders } from '../../../../redux/actions/moderatorData';
import { setPopupBackgroundFunc, setPopupOpenBlock} from '../../../../redux/actions/popupBlocks';
import MaskedInput from '../../../MaskedInput/MaskedInput'

import './index.scss';

const AddLeaders = () => {
    const dispatch = useDispatch()
    const [list, setList] = useState(false)

    const {moderatorLeaders, moderatorLeaderEditCheck, moderatorLeadersPoints} = useSelector(({moderatorData, moderatorCheckData})=> {
        return {
            moderatorLeaders: moderatorData.moderatorLeaders,
            moderatorLeaderEditCheck: moderatorCheckData.moderatorLeaderEditCheck,
            moderatorLeadersPoints: moderatorData.moderatorLeadersPoints
        }
    })

    const reader = new FileReader();
    const [fil, setFile] = useState((moderatorLeaderEditCheck.id !== '')? moderatorLeaderEditCheck.image: '')

    const [inform, setInform] = useState((moderatorLeaderEditCheck.id !== '')? moderatorLeaderEditCheck:
    { 
        name: '',
        phone: '',
        login: '',
        password: '',
        position: 'Поклейщик'
    })

    const [editInform, setEditInform] = useState({ 
        name: false,
        phone: false,
        login: false,
        password: false
    })

    const [clickAddBtn, setClickAddBtn] = useState(false)

    const [error, setError] = useState('') 

    

    const uploadPhoto = (event) => {
        const files = event.target.files;
        const file = files[0];
        setInform({...inform, photo: event.target.files.length !== 0})
        reader.readAsDataURL(file);
        reader.addEventListener('load', (event) => {
            setFile(event.target.result)
            setInform({...inform, photo: true})
            setEditInform({...editInform, photo: true})
        });        
    }

    const cutName = (name) =>{
        let cut = name.split(' ')
        if(cut.length === 3){
            return cut[0] + ' ' + cut[1] + ' ' + cut[2][0] + '.'
        }
        else return name
    }

    const fetchCheckLogin = async(url, query='')=>{
        const response =  await fetch(url + query, {
            method: 'GET', 
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
            }
        })
        if (response.ok === true) {
            const dat = await response.json();
            // console.log(dat)
            return dat.value.resultObject
        }
    }

    const postData = async(event) =>{
        event.preventDefault();
        
        if(!clickAddBtn && inform.name !== '' && inform.phone !== '' && inform.login !== '' && inform.password !== ''){ 
            setClickAddBtn(true)          
            fetchCheckLogin('/Login/CheckHaveLogin?login=', inform.login).then(async(availability)=>{
                if(!availability){
                    dispatch(setPopupBackgroundFunc(false))
                    dispatch(setPopupOpenBlock(''))

                    let data = {
                        Name: inform.name,
                        fullName: inform.name,
                        Phone: inform.phone, 
                        Login: inform.login,
                        Password: inform.password,
                        Post: inform.position,
                        // Photo: './'
                    }

                    let response = await fetch('/francheaser/AddWorker', {
                        method: 'POST', 
                        headers: {
                            "Accept": "application/json",
                            'Content-Type': 'application/json',
                            "Authorization": `Bearer ${(localStorage.getItem('save-autorize') === 'false')? sessionStorage.getItem('token'): localStorage.getItem('token')}`
                        },
                        body: JSON.stringify(data)
                    })
                    if (response.ok === true) {
                        setClickAddBtn(false)
                        const data = await response.json();
                        // console.log(data)
                        dispatch(setFranchiserStaffListAdd({
                            id: data.value.result, 
                            name: cutName(inform.name),
                            fullName: inform.name,
                            status: 'Работающий',
                            statusColor: 'green',
                            phone: inform.phone, 
                            historySales: 0, 
                            salary: 0,
                            login: inform.login,
                            password: inform.password,
                            position: inform.position
                        }))
                    }
                    else{
                        localStorage.setItem('role', '')
                        setClickAddBtn(false)
                    }
                }
                else{
                    setClickAddBtn(false)
                    setError("Такой логин уже существует*")
                }
                
            }) 
        }
        else if(!clickAddBtn){
            setError('Необходимо заполнить все поля*')
        }
        // dispatch(setFranchiserHomeStaffListCheck({id: 0, name: '', fullName: '', status: '', satausColor: '', phone: '', historySales: 0, salary: 0, login: '', password: '', position: ''}))
        // if(moderatorLeaderEditCheck.id !== ''){
        //     editLeader(event)
        // }
        // else{
        //     addLeader(event)
        // }
    }

	return (
            <div className="moderator__popup-container">
                <div className="moderator__popup-top d-flex justify-between align-center add-leaders">
                    <div className="popup__title blue">Добавление сотрудника</div>
                    <div className="popup__close-btn" onClick={()=>{
                        dispatch(setPopupBackgroundFunc(false))
                        dispatch(setPopupOpenBlock(''))
                        dispatch(setModeratorLeaderEditCheck({id: '', name: '', login: "", password: "", phone: "", mail: "", image: '', address: ''}))
                    }}></div>
                </div>
                <form action="" onSubmit={postData}>
                    <label htmlFor="" className='moderator__popup-label mt-0'>ФИО</label>
                    <input name='Name' type="text" placeholder='Введите ФИО руководителя' className="moderator__popup-input" onInput={(event)=>{
                        setInform({...inform, name: event.target.value})
                        setEditInform({...editInform, name: true})
                    }}/>
                    <div className="moderator__popup-input-container d-flex justify-between">
                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Телефон</label>
                            <MaskedInput
                                className='moderator__popup-input'
                                mask="+7(999) 999-99-99"
                                // value={(moderatorLeaderEditCheck.id !== '')? moderatorLeaderEditCheck.phone: inform.phone}
                                name = 'phone'
                                placeholder='Введите телефон руководителя'
                                onChange={(event)=>{
                                    setInform({...inform, phone: event.target.value})
                                    setEditInform({...editInform, phone: true})
                                }}
                            />
                        </div> 

                        <div className="moderator__popup-input-fifty">
                        <label htmlFor="" className='moderator__popup-label'>Должность</label>
                            <div className="franchises-leaders__staf-points-buttons-position-select-container d-flex align-center">
                                <div className={`franchises-leaders__staf-points-buttons-position-select ${(list)? 'active': ''}`} onClick={()=>{
                                        setList(!list)
                                    }}>
                                    {
                                        (inform.position !== '')? inform.position: 'Поклейщик'
                                    }
                                </div>                            
                                <div className={`franchises-leaders__staf-points-buttons-position-select-list ${(list)? 'active': ''}`}>
                                    <div className="franchises-leaders__staf-points-buttons-position-select-list-scroll">
                                        <div className={`franchises-leaders__staf-points-buttons-position-select-list-item `} onClick={()=>{
                                            setInform({...inform, position: 'Поклейщик'})
                                            setList(false)
                                        }}>
                                            Поклейщик
                                        </div>
                                        <div className={`franchises-leaders__staf-points-buttons-position-select-list-item `} onClick={()=>{
                                            setInform({...inform, position: 'Управляющий'})
                                            setList(false)
                                        }}>
                                            Управляющий
                                        </div>
                                        <div className={`franchises-leaders__staf-points-buttons-position-select-list-item `} onClick={()=>{
                                            setInform({...inform, position: 'Маркетолог'})
                                            setList(false)
                                        }}>
                                            Маркетолог
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </div>
                    <div className="moderator__popup-input-container d-flex justify-between">
                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Логин</label>
                            <input name='Login' type="text" placeholder='Введите логин руководителя' className="moderator__popup-input" onInput={(event)=>{
                                setInform({...inform, login: event.target.value})
                                setEditInform({...editInform, login: true})
                            }}/>
                        </div> 

                        <div className="moderator__popup-input-fifty">
                            <label htmlFor="" className='moderator__popup-label'>Пароль</label>
                            <input name='Password' type="text" placeholder='Введите пароль руководителя' className="moderator__popup-input" onInput={(event)=>{
                                setInform({...inform, password: event.target.value})
                                setEditInform({...inform, password: true})
                            }}/>
                        </div>   
                    </div> 
                    <div className='popup__error'>{error}</div>               
                    <button type='submit' className={`moderator__popup-save ${(clickAddBtn)? 'deactive': ''}`}>Добавить сотрудника</button>    
                </form>                    
            </div>
	)
}
export default AddLeaders;