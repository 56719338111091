const initialState = {
    moderatorPopupHistoryChanges: [
    ],
    moderatorPopupNotifications: [
    ],
};
    
const moderatorPopupData = (state = initialState, action) => {
    if (action.type === 'SET_MODERATOR_POPUP_HISTORY_CHANGES') {
        return {
            ...state,
            moderatorPopupHistoryChanges: action.payload,
        };
    }
    else if (action.type === 'SET_MODERATOR_POPUP_NOTIFICATIONS') {
        return {
            ...state,
            moderatorPopupNotifications: action.payload,
        };
    }
    else if (action.type === 'SET_MODERATOR_POPUP_NOTIFICATIONS_ADD') {
        return {
            ...state,
            moderatorPopupNotifications: [action.payload, ...state.moderatorPopupNotifications],
        };
    }
    return state;
};

export default moderatorPopupData;